import React, { useEffect, useState } from "react";
import { Loading } from "../../../components/loaders";
import { SimpleTable } from "../../../components/tables";
import { useUserInfo } from "../../../features/userInfo/hooks";
import { useLazyGetCompanyInvitesQuery, useLazyGetCompanyUsersQuery } from "../../../services/endpoints/companies";
import { NewCompanyInviteRow } from "../../../containers/home";
import { Input } from "../../../components/inputs";
import { SearchIcon, TrashIcon } from "@heroicons/react/outline";
import { Button } from "../../../components/buttons";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import { CompanyInvite } from "../../../models/invites";
import { InviteStatusBadge, SimpleBadge } from "../../../components/badges";
import { CompanyId, CompanyUser } from "../../../models/company";
import { isCompanyAdmin } from "../../userInfo/hooks/useUserInfo";
import { useModalContext } from "../../../hooks/useModalContext";
import UserDetail from "../../userInfo/UserDetail";
import classNames from "classnames";
import DeleteCompanyInvitationModal from "../../company/components/DeleteCompanyInvitationModal";
import DeleteCompanyUserModal from "../../company/components/DeleteCompanyUserModal";
import { useAuth0 } from "@auth0/auth0-react";

type CompanyUsersProps = {
  clickable?: boolean;
  showSearch?: boolean;
};

const CompanyUsers = ({ clickable = true, showSearch = true }: CompanyUsersProps) => {
  const { company } = useUserInfo();
  const { user } = useAuth0();
  const [getCompanyUsers, {
    isLoading: isCompanyUsersLoading,
    data: companyUsers
  }] = useLazyGetCompanyUsersQuery();
  const [getCompanyInvites, {
    isLoading: isCompanyInvitesLoading,
    data: companyInvites
  }] = useLazyGetCompanyInvitesQuery();

  const { openModal } = useModalContext();

  const isAdmin = isCompanyAdmin();
  const [showNewInviteRow, setShowNewInviteRow] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (company) {
      getCompanyUsers({ companyId: company.id });
      getCompanyInvites({ companyId: company.id });
    }
  }, [company]);

  const showUserInfo = (user: CompanyUser) => {
    const { email, firstName, lastName, id } = user;
    openModal({
      renderContent: (props) => <UserDetail showReset={isAdmin} showSave={isAdmin} user={{ email, firstName, lastName, id }} {...props} />,
      position: "right",
      dismissable: true,
      fullHeight: true,
      fullWidth: true
    });
  };

  const handleDeleteInviteClicked = (invite: CompanyInvite) => {
    openModal({
      renderContent: DeleteCompanyInvitationModal,
      renderContentProps: {
        invite,
      },
      dismissable: true,
      fullWidth: false,
      fullHeight: false,
    });
  };

  const handleDeleteUserClicked = (user: CompanyUser) => {
    openModal({
      renderContent: DeleteCompanyUserModal,
      renderContentProps: {
        user,
        companyId: company.id as CompanyId
      },
      dismissable: true,
      fullWidth: false,
      fullHeight: false,
    });
  };

  if (isCompanyUsersLoading || isCompanyInvitesLoading) {
    return <Loading />;
  }

  let tableContent = [];

  if (companyUsers) {
    tableContent.push((searchText.trim() ?
      companyUsers.filter((item) => item.email.toLowerCase().includes(searchText.trim().toLowerCase())) : companyUsers).map((item) => (
        <UserRow key={item.id}
          user={item}
          clickable={clickable}
          onClick={() => clickable && showUserInfo(item)}
          deletable={isAdmin && user?.sub !== item.id}
          onDeleteClicked={isAdmin && user?.sub !== item.id ? () => handleDeleteUserClicked(item) : undefined} />
      )));
  }

  if (companyInvites?.items) {
    tableContent.push((searchText.trim() ?
      companyInvites.items.filter((item) => item.email.toLowerCase().includes(searchText.trim().toLowerCase())) : companyInvites.items)
      .filter(i => i.status !== 'COMPLETE')
      .map((item) => <InviteRow key={item.id} clickable={clickable} invite={item} deletable={isAdmin} onDeleteClicked={() => isAdmin && handleDeleteInviteClicked(item)} />)
    );
  }

  if (tableContent.length === 0) {
    tableContent = [
      <tr key={"empty"} className="bg-white-100 border-b border-gray-200">
        <td className="pl-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
          <p className="py-2 text-sm font-medium text-gray-900">No user found</p>
        </td>
      </tr>
    ];
  }

  return (
    <div>
      <div className="flex justify-end">
        {showSearch && <div className="w-72">
          <Input
            icon={SearchIcon}
            placeholder={"Search"}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </div>}
      </div>
      <SimpleTable>
        <colgroup>
          <col />
          <col style={{ width: "150px" }} />
          <col style={{ width: "150px" }} />
        </colgroup>
        <thead>
          <tr className={"bg-gray-50"}>
            <td className={"py-2 px-6 text-sm"}>USER</td>
            <td className={"py-2 px-6 text-sm"}>ROLE</td>
            <td className={"py-2 px-6 text-sm"}></td>
          </tr>
        </thead>
        <tbody>
          {tableContent}
          {showNewInviteRow && <NewCompanyInviteRow onComplete={() => setShowNewInviteRow(false)} />}
        </tbody>
      </SimpleTable>

      {!showNewInviteRow && (
        <Button variant="transparent" icon={() => <PlusIcon />} className="whitespace-nowrap"
          onClick={() => setShowNewInviteRow(!showNewInviteRow)}>
          Invite another collaborator
        </Button>
      )}
    </div>
  );
};

type InviteRowProps = {
  onDeleteClicked: () => void;
  deletable?: boolean;
  clickable: boolean;
  invite: CompanyInvite;
  onClick?: () => void
};

const InviteRow = ({ invite, onClick, clickable, deletable = false, onDeleteClicked }: InviteRowProps) => {
  return (
    <tr className={classNames((clickable && "cursor-pointer"), "bg-white-100 border-b border-gray-200 last:border-0")}>
      <td className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
        onClick={onClick}>
        {invite.email}
      </td>
      <td className="pl-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
        <SimpleBadge label={invite.role} />
      </td>
      <td className="flex justify-between pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        <InviteStatusBadge status={invite.status} />
        {deletable && <TrashIcon className={classNames(deletable ? "cursor-pointer hover:text-red-900" : "text-gray-900", "h-5 w-5 text-red-500 mr-3 ")} onClick={onDeleteClicked} />}
      </td>
    </tr>
  );
};

type UserRowProps = {
  clickable: boolean,
  user: CompanyUser,
  onClick?: () => void,
  deletable: boolean;
  onDeleteClicked?: () => void
}

const UserRow = ({ user, onClick, clickable, deletable = false, onDeleteClicked }: UserRowProps) => {
  return (
    <tr className={classNames("bg-white-100 border-b border-gray-200 last:border-0")}>
      <td className={classNames((clickable && "cursor-pointer"), "pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900")}
        onClick={onClick}>
        {user.email}
      </td>
      <td className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        {user.roles.map(role => <div key={role} className="mb-1"><SimpleBadge label={role} /></div>)}
      </td>
      <td className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        {deletable && <TrashIcon className={classNames(deletable ? "cursor-pointer hover:text-red-900" : "text-gray-900", "h-5 w-5 text-red-500 mr-3 ")} onClick={onDeleteClicked} />}
      </td>
    </tr>
  );
};

export default CompanyUsers;
