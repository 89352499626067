import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ISimpleTableProps, SimpleTable } from "../../../../components/tables";
import { BaseAudience } from "../../../../models/audiences";
import AudienceRow from "./AudienceRow";

interface IAudienceTableProps extends ISimpleTableProps {
  audiences: BaseAudience[];
  showSync: boolean;
}

const AudienceTable: FunctionComponent<IAudienceTableProps> = ({ audiences, showSync, ...rest }) => {
  const { t } = useTranslation("audiences");

  const headings = [
    {
      label: "",
      className: "text-left"
    },
    {
      label: t("table.id"),
      className: "text-left pl-3"
    },
    {
      label: t("table.audience_name"),
      className: "text-left"
    },
    {
      label: t("table.change"),
      className: "text-left"
    },
    {
      label: t("table.total_users"),
      className: "text-right"
    }
  ];

  showSync && headings.splice(3, 0, {
    label: t("table.sync"),
    className: "text-left"
  });

  const renderRow = (audience: BaseAudience, index: number) => {
    const key = `${audience.id}-${index}`;

    return <AudienceRow key={key} id={key} audience={audience} showSync={showSync} />;
  };

  return (
    <SimpleTable {...rest}>
      <colgroup>
        <col style={{ width: "30px" }} />
        <col style={{ width: "200px" }} />
        <col />
        {showSync && <col style={{ width: "180px" }} />}
        <col style={{ width: "150px" }} />
        <col style={{ width: "150px" }} />
      </colgroup>
      <thead>
      <tr>
        {headings.map((heading) => (
          <th
            key={heading.label}
            scope="col"
            className={`px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider sticky top-0 ${heading.className}`}
          >
            {heading.label}
          </th>
        ))}
      </tr>
      </thead>
      <tbody>{audiences?.map(renderRow)}</tbody>
    </SimpleTable>
  );
};

export default AudienceTable;
