import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, PlusSmIcon } from '@heroicons/react/outline';
import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useModalContext } from '../../../hooks/useModalContext';
import { Workspace } from '../../../models/workspace';
import { useAppDispatch } from '../../../reducers';
import RouteConstants from "../../router/RouteConstants";
import { useWorkspace } from '../hooks';
import { setCurrentWorkspace } from '../workspacesSlice';

interface IWorkspaceSelectorProps {
  workspaces: Workspace[];
}

const WorkspaceSelector: FunctionComponent<IWorkspaceSelectorProps> = ({ workspaces }) => {
  const { t } = useTranslation('workspaces');
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { openModal } = useModalContext();

  const currentWorkspace = useWorkspace();

  const handleWorkspaceClicked = (workspace: Workspace) => {
    history.push(RouteConstants.defaultRoute);

    dispatch(setCurrentWorkspace(workspace));
  };

  const handleCreateNewWorkspaceClicked = () => {
    history.push(RouteConstants.createWorkspace);
    /*openModal({
      renderContent: WorkspaceEdit,
      renderContentProps: {},
      dismissable: true,
      fullHeight: true,
    });*/
  };

  return (
    <Popover className='relative z-20'>
      {({ open }) => (
        <>
          <Popover.Button
            className={`border
                ${open ? '' : 'text-opacity-90'}
                w-full outline-none focus:outline-none bg-white-100 group px-3 py-2 rounded-md inline-flex items-center justify-between focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className='text-base font-medium text-gray-700 truncate'>{currentWorkspace.name}</span>
            <ChevronDownIcon
              className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-gray-700 transition ease-in-out duration-150`}
              aria-hidden='true'
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel static className='absolute origin-top-right w-full -right-full bottom-0 mt-1'>
              {({ close }) => (
                <div className='overflow-hidden rounded-lg shadow-lg w-full ml-3 border'>
                  <div className='relative gap-8 bg-white-100 py-2'>
                    {workspaces.map((ws) => (
                      <button
                        key={ws.id}
                        className={`${currentWorkspace.id === ws.id ? 'bg-blue-400' : 'bg-white-100'} group flex w-full py-3 px-6 hover:bg-blue-600`}
                        onClick={() => {
                          handleWorkspaceClicked(ws);

                          close();
                        }}
                      >
                        <span
                          className={`${currentWorkspace.id === ws.id ? 'text-white-100' : 'text-gray-700'} font-medium truncate group-hover:text-white-100`}
                        >
                          {ws.name}
                        </span>
                      </button>
                    ))}
                    <div
                      data-testid='createNewWorkspaceBtn'
                      className='group flex items-center justify-center mt-6 mb-3 px-2 cursor-pointer'
                      onClick={() => {
                        handleCreateNewWorkspaceClicked();

                        close();
                      }}
                    >
                      <PlusSmIcon className='h-5 w-5 text-blue-600 mr-2 group-hover:opacity-80' aria-hidden='true' />
                      <span className='text-base text-blue-600 group-hover:opacity-80'>{t('selector.create_new_workspace')}</span>
                    </div> 
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default WorkspaceSelector;
