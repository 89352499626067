import { Transition } from '@headlessui/react';
import { CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/outline';
import { delay } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { Button } from '../buttons';
import { DataSourceType } from '../../models/dataSource';

interface ISnippetPreviewProps {
    type: DataSourceType;
    script?: string;
    ctaRight?: boolean;
}

const ANIMATION_FADEOUT_DURATION = 1000;

const SnippetPreview: FunctionComponent<ISnippetPreviewProps> = ({ script, type, ctaRight }) => {
    const { t } = useTranslation('data_source_edit');

    console.log(type);

    let labelKey = '';
    switch (type.id) {
        case 'GOOGLE_TAG_MANAGER':
            labelKey = 'install_snippet_gtm';
            break;
        default:
            labelKey = 'install_snippet';
    }

    const [isCopying, setIsCopying] = useState(false);

    const handleCopySnippetClicked = () => {
        setIsCopying(true);
        if (script) {
            navigator.clipboard.writeText(script);
        }

        delay(() => {
            setIsCopying(false);
        }, ANIMATION_FADEOUT_DURATION);
    };

    return (
        <div className='flex flex-col'>
            <h1 className='text-base font-bold text-gray-900 mb-2 leading-4'>{t(`code.${labelKey}.code_title_install` as any)}</h1>
            <h4 className='text-sm font-normal text-gray-600 leading-4'>{t(`code.${labelKey}.code_subtitle_install` as any)}</h4>
            <div className='my-4 overflow-x-auto border-gray-300'>
                <Transition
                    show={!!script}
                    enter='transform ease-linear duration-150'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='transform ease-linear duration-150'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='relative border-2 rounded'>
                        <SyntaxHighlighter
                            className={
                                isCopying ? 'opacity-20 backdrop-blur-sm z-10 transition duration-75 ease-linear rounded' : 'z-10 transition duration-1000 ease-linear rounded'
                            }
                            language='javascript'
                            style={vs}
                        >
                            {script || ''}
                        </SyntaxHighlighter>
                        <Transition
                            show={isCopying}
                            enter='transition ease-linear duration-75'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='ease-linear duration-1000'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='z-50 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                <div className='inline-block place-items-center'>
                                    <CheckIcon className='rounded-full bg-green-100 text-green-600 h-8 p-1 mb-2 mx-auto' />
                                    <h3 className='text-gray-900'>{t(`code.${labelKey}.copied` as any)}</h3>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </Transition>
            </div>
            <Button variant='primary' icon={DocumentDuplicateIcon} onClick={handleCopySnippetClicked} className={ctaRight ? 'self-end' : 'self-start'}>
                {t(`code.${labelKey}.code_copy` as any)}
            </Button>
        </div>
    );
};

export default SnippetPreview;
