import { Fragment, FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';
import { DataSources } from '.';
import { DataSourceDetails } from '../dataSourceDetails';
import { PrivateRoute } from '../router/components';

const DataSourcesRouter: FunctionComponent = () => (
  <Fragment>
    <Switch>
      <PrivateRoute path='/data-sources/:id' component={DataSourceDetails} />
      <PrivateRoute path='/data-sources' component={DataSources} />
    </Switch>
  </Fragment>
);

export default DataSourcesRouter;
