import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerIcon } from '../../../../../components/icons';
import { DataSource, DataSourceCodeConfig } from '../../../../../models/dataSource';

interface ICheckCodeSnippetConnectionProps {
  dataSource?: Partial<DataSource>;
}

const CheckCodeSnippetConnection: FunctionComponent<ICheckCodeSnippetConnectionProps> = ({ dataSource }) => {
  const { t } = useTranslation();

  const configuration = dataSource?.configuration as DataSourceCodeConfig | undefined;

  return (
    <div className='m-auto align-middle'>
      <SpinnerIcon className='inline h-4' loading />
      <p className='inline text-sm font-normal text-gray-600 mr-1 ml-2 leading-5'>{t('data_source_edit:code.check_installation.checking')}</p>
      <p className='inline text-sm font-semibold text-gray-600 leading-5'>
        <a href={configuration?.url} target='_blank' rel='noreferrer' className='text-sm font-semibold text-gray-600'>
          {configuration?.url}
        </a>
        ...
      </p>
    </div>
  );
};

export default CheckCodeSnippetConnection;
