import { FunctionComponent, PropsWithChildren } from 'react';
import { Button } from '../../../../components/buttons';
import PieChartIcon from '../../../../assets/icons/pie-chart.svg?react';
import PaperPlaneIcon from '../../../../assets/icons/paper-plane.svg?react';
import EmptyAudienceImage from '../../../../assets/image/empty_audience.svg?react';
import { clearStashedAudience } from '../../../audienceEdit/audienceEditSlice';
import { AudienceEdit } from '../../../audienceEdit';
import { useAppDispatch } from '../../../../reducers';
import { useModalContext } from '../../../../hooks/useModalContext';

const EmptyAudiences: FunctionComponent<PropsWithChildren<{ isExtended?: boolean }>> = ({ isExtended }) => {
    const dispatch = useAppDispatch();
    const { openModal } = useModalContext();
    const create = () => {
        dispatch(clearStashedAudience());

        openModal({
            renderContent: AudienceEdit,
            renderContentProps: {},
            fullHeight: true,
            fullWidth: true,
        });
    };

    return (
        <div className={'flex items-center grow h-full'}>
            <div className={'w-100 md:w-1/2'}>
                <h3 className={'text-lg font-semibold mb-2'}>Make your data dance together</h3>
                <p className={'font-normal mb-8'}>Audiencerate CDP takes data from different sources and combines them together, enriching each other:</p>
                <ul className={'mb-8'}>
                    <li className={'flex items-center mb-2'}>
                        <PaperPlaneIcon className={'h-6 w-6 mr-2'} />
                        It shows you automatic statistics about your customers
                    </li>
                    <li className={'flex items-center'}>
                        <PieChartIcon className={'h-6 w-6 mr-2'} />
                        Send complete data to your other tools
                    </li>
                </ul>
                <Button onClick={create}>Create your first {isExtended && 'amplified'} audience</Button>
            </div>
            <div className={'w-full md:w-1/2 flex justify-center'}>
                <EmptyAudienceImage style={{ width: '400px', height: '400px' }} />
            </div>
        </div>
    );
};

export default EmptyAudiences;
