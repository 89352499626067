import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHeading } from '../../models/user';

const DEFAULT_USER_HEADINGS = [
  { label: 'Name', visible: true },
  { label: 'Title', visible: true },
  { label: 'Email', visible: true },
  { label: 'Role', visible: true },
];

export interface UserTableState {
  headings?: IHeading[];
}

export const initialState: UserTableState = {
  headings: DEFAULT_USER_HEADINGS
};

const userTableSlice = createSlice({
  name: 'userTable',
  initialState,
  reducers: {
    toggleHeading: (state, action: PayloadAction<IHeading>) => ({...state, headings: state.headings?.map( h => h.label === action.payload.label ? action.payload : h )})
  }
});

export const { toggleHeading } = userTableSlice.actions;

export default userTableSlice.reducer;
