import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import  UserGroupIcon  from "../../../../assets/icons/user-group.svg?react";
import { TimeAgo } from "../../../../components/time-ago";
import { AudienceSyncTimeBasedSchedule, BaseAudience } from "../../../../models/audiences";
import { AudienceStats } from "../index";

interface IAudienceRowProp {
  id: string;
  audience: BaseAudience;
  showSync: boolean;
}

const AudienceRow: FunctionComponent<IAudienceRowProp> = ({ id, audience, showSync }) => {
  const { t } = useTranslation("audiences");
  const history = useHistory();

  const handleClick = () => {
    if (audience.extended) {
      history.push(`/extended-audiences/${audience.id}`);
    }
    else {
      history.push(`/audiences/${audience.id}`);
    }
  };

  const renderScheduledSync = () => {
    const syncConfig: AudienceSyncTimeBasedSchedule = audience.scheduleConfiguration as AudienceSyncTimeBasedSchedule;
    const cardinality: string = syncConfig.repeatsEvery === 1 ? "one" : "other";
    return t(`table.syncs.scheduled_${syncConfig.unit}_${cardinality}` as any, { count: syncConfig.repeatsEvery });
  };

  const renderSync = () => {
    switch (audience.scheduleType) {
      case "REALTIME":
        return t("table.syncs.realtime");

      case "MANUAL":
        return t("table.syncs.manual");

      case "SCHEDULED":
        return renderScheduledSync();

      default:
        return "-";
    }
  };

  return (
    <tr id={id} className="bg-white-100 border-b border-gray-200 cursor-pointer hover:bg-gray-50 last:border-0" onClick={handleClick}>
      <td className="pl-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className={"inline-block align-middle mr-3 text-gray-500"}>
          <UserGroupIcon className={"w-5 h-5"} />
        </div>
      </td>
      <td className="pl-3 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
        <p className="py-2 text-sm font-medium text-gray-900">{audience.id}</p>
      </td>
      <td className="pl-6 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="py-2 text-sm font-medium text-blue-500 cursor-pointer">
          {audience.name}
          {audience.extended && (
            <span className="items-center mx-2 px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800">{t("extended")}</span>
          )}
        </div>
      </td>
      {showSync && (
        <td className="pl-6 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
          <p className="py-2 text-sm font-medium text-gray-900">{renderSync()}</p>
        </td>
      )}
      <td className="pl-6 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
        <p className="py-2 text-sm font-medium text-gray-900">{<TimeAgo time={audience.createdOn} />}</p>
      </td>
      <td className="pl-6 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="py-2 text-sm font-medium text-gray-900">{<AudienceStats audience={audience} />}</div>
      </td>
    </tr>
  );
};

export default AudienceRow;
