import { SaveIcon } from '@heroicons/react/solid';
import { Form, Formik } from 'formik';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components/buttons';
import { Input } from '../../../../../components/inputs';
import { DataSource, DataSourceCodeConfig, EDataSourceCategory, TDataSource } from '../../../../../models/dataSource';

interface IGeneralDataSourceSettingsProps {
  dataSource: DataSource;
  isUpdateLoading?: boolean;
  onSubmit: (form: DataSource) => void;
}

const GeneralDataSourceSettings: FunctionComponent<IGeneralDataSourceSettingsProps> = ({ dataSource, onSubmit, isUpdateLoading = false }) => {
  const { t } = useTranslation('data_source_details');

  const dataSourceType = dataSource?.type.category;

  return (
    <div>
      <Formik initialValues={{ ...dataSource }} onSubmit={onSubmit}>
        {({ values, handleChange }) => (
          <Form>
            <h1 className='text-lg text-black-100 font-semibold'>{t('settings.general.general')}</h1>
            <div className='flex justify-start items-end'>
              <div className='w-1/3 mr-4'>
                <Input id='name' name='name' label={t('settings.general.name')} value={values.name} onChange={(e) => handleChange('name')(e.target.value)} />
              </div>
              {dataSourceType === EDataSourceCategory.CODE && (
                <div className='w-1/3 mr-4'>
                  <Input label={t('settings.general.url')} value={(values as TDataSource<DataSourceCodeConfig>).configuration.url} disabled />
                </div>
              )}

              <div className='w-1/3'>
                <Button type='submit' icon={SaveIcon} loading={isUpdateLoading}>
                  {t('settings.general.save')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeneralDataSourceSettings;
