import React, { FunctionComponent } from 'react';
import { WorkspaceSelector } from '../../../features/workspaces/components/';
import { NavigationItem } from '../../../models/navigation';
import { useGetWorkspacesQuery } from '../../../services/endpoints/workspaces';
import { SidebarLink } from './';
import { commit, version } from '../../../version.json';
import { default as config } from '../../../config';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import RouteConstants from '../../router/RouteConstants';

interface ISidebarContentProps {
  items: NavigationItem[];
  onItemClick?: (item: NavigationItem) => void;
}

const SidebarContent: FunctionComponent<ISidebarContentProps> = ({ items, onItemClick }) => {
  const workspace = useSelector((state: RootState) => state.workspaces?.currentWorkspace);

  const { data } = useGetWorkspacesQuery({
    limit: 50,
    offset: 0,
  });

  const onLinkClicked = (item: NavigationItem) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  const workspaces = data?.items || (workspace ? [workspace] : []);

  return (
    <>
      <div className='flex items-center justify-between flex-shrink-0 px-4'>
        <img className='h-10 w-auto' src='/assets/sidebar/logo.svg' alt='logo' />
        {/*<span className="text-base font-medium text-white-100">BETA</span>*/}
      </div>
      <div className='mt-5 flex-1 h-0 flex flex-col'>
        <nav className='px-2 space-y-1'>
          {items.map((item) => (workspace || item.to === RouteConstants.home) && <SidebarLink key={item.name} item={item} onClick={onLinkClicked} />)}
        </nav>
        {workspaces.length > 0 ? (
          <div className='m-2 mt-auto'>
            <WorkspaceSelector workspaces={workspaces} />
          </div>
        ) : (
          <div className='m-2 mt-auto' />
        )}
        {config.env !== 'prod' && (
          <div className='flex justify-end px-3 -mb-2 text-gray-50 text-sm space-x-6 md:order-2'>
            <div>
              Version <span className='text-gray-400 px-1'>{version}</span> Build<span className='text-gray-400 px-1'>{commit}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SidebarContent;
