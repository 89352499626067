import { FunctionComponent } from 'react';
import { Audience } from '../../models/audiences';
import { ETABS, TabType } from '../../models/tabs';
import { AudienceOverview, AudienceSourcesAndRules, AudienceSettings } from './components';

interface IAudienceTabContentProps {
  tabs: TabType[];
  selectedAudience?: Audience;
}

const AudienceTabContent: FunctionComponent<IAudienceTabContentProps> = ({ tabs, selectedAudience }) => {
  const currentTab = tabs.find((tab) => tab.current);

  switch (currentTab?.id) {
    case ETABS.OVERVIEW:
      return <AudienceOverview audience={selectedAudience} />;
    case ETABS.DATA:
      return <AudienceSourcesAndRules audience={selectedAudience} />;
    case ETABS.SETTINGS:
      return <AudienceSettings audience={selectedAudience} />;
    default:
      return null;
  }
};

export default AudienceTabContent;
