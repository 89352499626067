import React, { FunctionComponent } from 'react';
import { concatClassNames } from '../../utils';
import { IconContainer } from '../icons';

interface IVisualInputProps {
    id?: string;
    value?: string;
    name?: string;
    label?: string;
    className?: string;
    error?: string;
    onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
    icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    iconUrl?: string;
}

type TInputType = IVisualInputProps & React.ComponentProps<'span'>;

const VisualInput: FunctionComponent<TInputType> = (props) => {
    const { id, value, name, label, className, error, iconUrl, icon, ...rest } = props;

    let spanStyle = 'inline-flex w-full my-auto px-4 sm:text-sm';

    if (props.icon || iconUrl) {
        spanStyle = concatClassNames(spanStyle, 'pl-2');
    }

    if (error) {
        spanStyle = concatClassNames(spanStyle, 'border-red-500 hover:border-red-500 focus:border-red-500 outline-none');
    }

    return (
        <div className='inline-flex flex-col w-full my-4 text-left'>
            {label && (
                <label htmlFor={name} className='block text-sm font-medium text-gray-700 py-2'>
                    {label}
                </label>
            )}
            <div className='mt-1 inline-flex rounded-md w-auto'>
                {props.icon && <props.icon />}
                {!props.icon && iconUrl && (
                    <div className='flex items-center'>
                        <IconContainer icon={icon} path={iconUrl} className='flex-shrink-0 h-6 w-6 text-gray-500' />
                    </div>
                )}
                <span id={id} className={className ? concatClassNames(spanStyle, className) : spanStyle} {...rest}>
                    {value}
                </span>
            </div>
            {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
        </div>
    );
};

export default VisualInput;
