import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { ChatAdapter, StreamingAdapterObserver, ChatAdapterExtras, ConversationItem } from '@nlux/core';
import { useAppSelector } from '../reducers';
import { useWorkspace } from '../features/workspaces/hooks';

type ChatCompletionResponse = {
    delta: string;
    last: boolean;
};

type WebSocketChatAdapterHook = {
    useAdapter: (url: string) => ChatAdapter;
    close: () => void;
    conversationHistory: { role: string; message: string }[];
    clearConversation: () => void;
};

export function useWebSocketChatAdapter(): WebSocketChatAdapterHook {
    const { id: workspaceId } = useWorkspace();
    const { token: jwt } = useAppSelector((s) => s.auth);
    const websocket = useRef<WebSocket>(null);

    const useAdapter = React.useCallback<(url: string) => ChatAdapter>((url) => {
        return {
            // eslint-disable-next-line no-unused-vars
            streamText: (message: string, observer: StreamingAdapterObserver, extras: ChatAdapterExtras): void => {
                console.log('send message', message, 'ws', websocket.current);
                if (!websocket.current || websocket.current.readyState >= WebSocket.CLOSING) {
                    websocket.current = new WebSocket(url);
                }

                // We register listeners for the WebSocket events here
                // and call the observer methods accordingly
                websocket.current.onmessage = (event: MessageEvent) => {
                    const { delta, last } = JSON.parse(event.data) as ChatCompletionResponse;
                    if (last || !delta) {
                        observer.complete();
                    } else {
                        observer.next(delta.replace(/\n/g, '\n\n')); // TODO remove this when MD is properly formatted
                        // observer.next(delta);
                    }
                };
                websocket.current.onerror = (event) => observer.error(new Error('error: ' + event));
                // console.log('headers', extras.headers, 'history', extras.conversationHistory);
                console.log(websocket.current.readyState);
                const payload = {
                    auth: {
                        jwt,
                    },
                    context: {
                        workspaceId,
                    },
                    messages: [...(extras.conversationHistory || []), { role: 'user', message }],
                };

                if (websocket.current.readyState === WebSocket.CONNECTING) {
                    websocket.current.onopen = () => {
                        console.log('ws open');
                        websocket.current.send(JSON.stringify(payload));
                    };
                } else if (websocket.current.readyState === WebSocket.OPEN) {
                    websocket.current.send(JSON.stringify(payload));
                } else if (websocket.current.readyState >= WebSocket.CLOSING) {
                    observer.error(new Error('Could not connect to server'));
                }
            },
        };
    }, []);

    const close = () => {
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
            websocket.current.close();
        }
    };

    return { useAdapter, close };
}

export const WebSocketChatAdapterContext = React.createContext<WebSocketChatAdapterHook>({} as WebSocketChatAdapterHook);

export const WebSocketChatAdapterProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { id: workspaceId } = useWorkspace();
    const { token: jwt } = useAppSelector((s) => s.auth);
    const websocket = useRef<WebSocket>(null);
    const [conversationHistory, setConversationHistory] = React.useState<{ role: string; message: string }[]>([]);
    const clearConversation = () => {
        setConversationHistory([]);
    };
    const useAdapter = React.useCallback<(url: string) => ChatAdapter>(
        (url) => {
            return {
                // eslint-disable-next-line no-unused-vars
                streamText: (message: string, observer: StreamingAdapterObserver, extras: ChatAdapterExtras): void => {
                    if (!websocket.current || websocket.current.readyState >= WebSocket.CLOSING) {
                        websocket.current = new WebSocket(url);
                    }

                    // We register listeners for the WebSocket events here
                    // and call the observer methods accordingly
                    let buffer = '';
                    websocket.current.onmessage = (event: MessageEvent) => {
                        const { delta, last } = JSON.parse(event.data) as ChatCompletionResponse;
                        const msg = delta?.replace(/\n/g, '\n\n') || '';
                        if (last || !delta) {
                            console.log('last', buffer + msg);
                            setConversationHistory([...payload.messages, { role: 'ai', message: buffer + msg }]);
                            buffer = '';
                            observer.complete();
                        } else {
                            buffer += msg;
                            observer.next(msg); // TODO remove this when MD is properly formatted
                        }
                    };
                    websocket.current.onerror = (event) => observer.error(new Error('error: ' + event));
                    // console.log('headers', extras.headers, 'history', extras.conversationHistory);
                    console.log(websocket.current.readyState);
                    const payload = {
                        auth: {
                            jwt,
                        },
                        context: {
                            workspaceId,
                        },
                        messages: [...(extras.conversationHistory || []), { role: 'user', message }],
                    };
                    setConversationHistory(payload.messages);

                    if (websocket.current.readyState === WebSocket.CONNECTING) {
                        websocket.current.onopen = () => {
                            console.log('ws open');
                            websocket.current.send(JSON.stringify(payload));
                        };
                    } else if (websocket.current.readyState === WebSocket.OPEN) {
                        websocket.current.send(JSON.stringify(payload));
                    } else if (websocket.current.readyState >= WebSocket.CLOSING) {
                        observer.error(new Error('Could not connect to server'));
                    }
                },
            };
        },
        [workspaceId, jwt]
    );

    const close = React.useCallback(() => {
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
            websocket.current.close();
        }
    }, [websocket]);

    return (
        <WebSocketChatAdapterContext.Provider value={{ close, useAdapter, conversationHistory, clearConversation }}>
            {children}
        </WebSocketChatAdapterContext.Provider>
    );
};

export const useWebSocketChatAdapterContext = () => React.useContext(WebSocketChatAdapterContext);
