import { DocumentDuplicateIcon } from '@heroicons/react/solid';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components/buttons';

const DuplicateAudience: FunctionComponent = () => {
  const { t } = useTranslation('audience_details');

  return (
    <div>
      <h1 className='text-lg text-black-100 font-semibold'>{t('settings.duplicate.duplicate_audience_title')}</h1>
      <h3 className='my-3 text-base'>{t('settings.duplicate.duplicate_audience_desc')}</h3>
      <Button icon={DocumentDuplicateIcon}>{t('settings.duplicate.duplicate_this_audience')}</Button>
    </div>
  );
};

export default DuplicateAudience;
