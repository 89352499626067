import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Workspace } from '../../models/workspace';

type WorkspacesState = {
    currentWorkspace?: Workspace;
};

export const initialState: WorkspacesState = {

};

const workspacesSlice = createSlice({
    name: 'workspaces',
    initialState,
    reducers: {
        setCurrentWorkspace: (state, action: PayloadAction<WorkspacesState['currentWorkspace']>) => ({
            ...state,
            currentWorkspace: action.payload
        })
    },
});

export const { setCurrentWorkspace } = workspacesSlice.actions;

export default workspacesSlice.reducer;