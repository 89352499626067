import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/buttons';
import { Checkbox } from '../../../../components/inputs';
import { DistributionChannelContent } from '../../../../models/distributionChannels';
import { useUpdateDistributionChannelFieldsMutation } from '../../../../services/endpoints/distributionChannels';
import { useWorkspace } from '../../../workspaces/hooks';

interface IDestinationStepDataProps {
  distributionChannelContent: DistributionChannelContent;
  nextStep?: (distributionChannelContent: DistributionChannelContent) => void;
  prevStep?: () => void;
}

const DestinationStepData: FunctionComponent<IDestinationStepDataProps> = ({ distributionChannelContent, prevStep, nextStep }) => {
  const { t } = useTranslation('destinations');
  const workspace = useWorkspace();
  const [selectedFields, setSelectedFields] = useState<{ [key: number]: boolean }>(
    distributionChannelContent.integrationsResult.cyclr.action.fields
      //.filter(field => !field.isOptional) // This was preventing the optional field to be checked. Was it correct?
      .reduce(
        (prev, field) => ({
          ...prev,
          [field.fieldId]: true,
        }),
        {}
      )
  );

  const [
    updateDistributionChannelFields,
    { isLoading: isUpdateFieldsLoading, isSuccess: isUpdateFieldsSuccess, isError: isUpdateFieldsError, data: updateFieldsData, error: updateFieldsError },
  ] = useUpdateDistributionChannelFieldsMutation();

  useEffect(() => {
    if (isUpdateFieldsSuccess && nextStep) {
      nextStep(updateFieldsData);
    }
  }, [isUpdateFieldsSuccess]);

  const changeField = (fieldId: number, checked: boolean) => {
    setSelectedFields({
      ...selectedFields,
      [fieldId]: checked,
    });
  };

  const updateFields = () => {
    const fieldsToIgnore = distributionChannelContent.integrationsResult.cyclr.action.fields
      .filter((field) => !selectedFields[field.fieldId])
      .map((field) => ({
        stepId: field.stepId,
        fieldId: field.fieldId,
        mappingType: 'Ignore',
        value: field.value,
      }));
    if (fieldsToIgnore.length === 0 && nextStep) {
      nextStep(distributionChannelContent);
      return;
    }
    updateDistributionChannelFields({
      workspaceId: workspace.id,
      audienceId: distributionChannelContent.distributionChannel.audienceId,
      distributionChannelId: distributionChannelContent.distributionChannel.id,
      fields: fieldsToIgnore,
    });
  };

  return (
    <Fragment>
      <div className={'p-6 flex-grow overflow-auto'}>
        <div className='text-left'>
          <h3 className='text-black text-base font-bold mb-1'>{t('edit.step.data.properties')}</h3>
          <p className='mb-5 text-base'>{t('edit.step.data.text')}</p>
        </div>
        <div className={'border-solid border border-gray-200 rounded-lg h-auto px-6 py-4 mt-6 text-left font-medium shadow'}>
          {distributionChannelContent.integrationsResult.cyclr.action.fields.map((field, i, fields) => (
            <Checkbox
              key={field.fieldId}
              label={field.name}
              id={field.fieldId + ''}
              description={field.description}
              disabled={!field.isOptional}
              required={!field.isOptional}
              forceShowingDescription={i !== fields.length - 1}
              checked={selectedFields[field.fieldId]}
              onChange={(e) => changeField(field.fieldId, e.target.checked)}
            />
          ))}
        </div>
      </div>

      <div className='flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg'>
        {prevStep && (
          <Button className='mr-2' loading={isUpdateFieldsLoading} onClick={prevStep}>
            {t('edit.step.back')}
          </Button>
        )}

        <Button loading={isUpdateFieldsLoading} onClick={updateFields}>
          {t('edit.step.next')}
        </Button>
      </div>
    </Fragment>
  );
};

export default DestinationStepData;
