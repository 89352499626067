import { Rule } from '../../models/rules';
import { audiencerateApi } from '../audiencerate';

const rulesApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    getRules: build.query<Rule[], GetRulesArg>({
      query: ({ workspaceId, type, datasourceIds }) => ({
        url: `/v1/workspaces/${workspaceId}/datasourcesrules`,
        params: {
          type,
          datasourceIds
        }
      }),
      providesTags: ['Rules'],
    }),
    getRulesByDataSourceId: build.query<Rule[], GetRulesByDatasourceIdArg>({
      query: ({ workspaceId, dataSourceId }) => ({
        url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/rules`,
      }),
      providesTags: ['Rules'],
    }),
    saveRules: build.mutation<Rule[], SaveRulesArg>({
      query: ({ workspaceId, dataSourceId, rules }) => ({
        url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/rules`,
        method: 'POST',
        body: {
          rules: rules,
        },
      }),
      invalidatesTags: ['Rules'],
    }),
    updateRule: build.mutation<Rule, UpdateRuleArg>({
      query: ({ workspaceId, dataSourceId, ruleId, rule }) => ({
        url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/rules/${ruleId}`,
        method: 'PATCH',
        body: rule,
      }),
      invalidatesTags: ['Rules'],
    }),
    deleteRule: build.mutation<void, DeleteRuleArg>({
      query: ({ workspaceId, dataSourceId, ruleId }) => ({
        url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/rules/${ruleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Rules'],
    }),
  }),
  overrideExisting: false,
});

type GetRulesByDatasourceIdArg = {
  workspaceId: string;
  dataSourceId: string;
};

type GetRulesArg = {
  workspaceId: string;
  type?: "TRACK" | "VIEW";
  datasourceIds?: string[];
};

type SaveRulesArg = {
  workspaceId: string;
  dataSourceId: string;
  rules: Partial<Rule>[];
};

type UpdateRuleArg = {
  workspaceId: string;
  dataSourceId: string;
  ruleId: string;
  rule: Partial<Rule>;
};

type DeleteRuleArg = {
  workspaceId: string;
  dataSourceId: string;
  ruleId: string;
};

export const {
  useGetRulesQuery,
  useLazyGetRulesQuery,
  useSaveRulesMutation,
  useUpdateRuleMutation,
  useGetRulesByDataSourceIdQuery,
  useLazyGetRulesByDataSourceIdQuery,
  useDeleteRuleMutation,
} = rulesApi;
