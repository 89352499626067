import { TrashIcon } from "@heroicons/react/solid";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../components/buttons";
import { Input } from "../../../../../../components/inputs";
import { Audience } from "../../../../../../models/audiences";
import { DistributionChannel } from "../../../../../../models/distributionChannels";
import { IModalContentProps } from "../../../../../../models/modals";
import { ToastType } from "../../../../../../models/toast";
import { useAppDispatch } from "../../../../../../reducers";
import { useDisconnectDistributionChannelMutation } from "../../../../../../services/endpoints/distributionChannels";
import { showToast } from "../../../../../toasts/toastsSlice";
import { useWorkspace } from "../../../../../workspaces/hooks";

interface IDisconnectDestinationModalProps {
  audience: Audience;
  destination: DistributionChannel;
}

const DisconnectDestinationModal: FunctionComponent<IDisconnectDestinationModalProps & IModalContentProps> = (
  {
    audience,
    destination,
    closeModal
  }
) => {
  const workspace = useWorkspace();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("audience_details");

  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const [disconnectDistributionChannel, { isLoading, isSuccess }] = useDisconnectDistributionChannelMutation();

  const handleDeleteClicked = () => {
    disconnectDistributionChannel({
      workspaceId: workspace.id,
      audienceId: audience.id,
      distributionChannelId: destination.id
    });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();

      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t("disconnect.success_title"),
          message: t("disconnect.success_body", { destinationName: destination.name })
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className="flex justify-center h-96 ">
      <div className="max-w-4xl my-auto text-left p-5">
        <h1 className="text-lg text-black-100 font-semibold mb-5">{t("disconnect.confirm_title")}</h1>
        <p className="text-base">
          {t("disconnect.confirm_body")} &nbsp;(<b>{destination.name}</b>)
        </p>
        <Input className="mt-5 mb-10" onChange={(e) => setButtonDisabled(e.target.value !== destination.name)} />
        <Button icon={TrashIcon} variant="delete" onClick={handleDeleteClicked} loading={isLoading} disabled={isButtonDisabled}>
          {t("disconnect.confirm_button")}
        </Button>
      </div>
    </div>
  );
};

export default DisconnectDestinationModal;
