import { SearchIcon } from "@heroicons/react/outline";
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/buttons";
import { SpinnerIcon } from "../../../../components/icons";
import { Input } from "../../../../components/inputs";
import { SimplePagination } from "../../../../components/tables/pagination";
import { useDebounce } from "../../../../hooks/useDebounce";
import { DistributionChannel } from "../../../../models/distributionChannels";
import { DestinationTable } from "../../../destinations/components";
import { useWorkspaceAdmin } from "../../../workspaces/hooks/useWorkspace";

interface IAudienceDestinationsProps {
  isLoading: boolean;
  destinations: DistributionChannel[];
  onConnectClick: () => void;
  onDelete: (destination: DistributionChannel) => void;
}

const AudienceDestinations: FunctionComponent<IAudienceDestinationsProps> = ({ isLoading, destinations, onConnectClick, onDelete }) => {
  const { t } = useTranslation("audience_details");

  const [pageNo, setPageNo] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [itemCount, setItemCount] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(-1);
  const pageSizeRef = useRef<number>(pageSize);

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 500);

  const [pagedDestinations, setPagedDestinations] = useState<DistributionChannel[]>([]);

  useEffect(() => {
    if (!destinations || !destinations.length) {
      setPageNo(0);
      setLastPage(0);
      setPagedDestinations([]);
    }
    else {
      const lcSearchText = debouncedSearchText.toLowerCase();
      const filteredDestinations = lcSearchText ? destinations.filter(item => item.name.toLowerCase().indexOf(lcSearchText) > -1) : destinations;

      const totalItemCount = filteredDestinations.length;
      const lastPage = Math.ceil(totalItemCount / pageSize) - 1;
      setLastPage(lastPage);

      const realPageNo = Math.min(pageNo, lastPage);
      setPageNo(realPageNo);

      const start = realPageNo * pageSize;
      const end = start + pageSize;
      setPagedDestinations(filteredDestinations.slice(start, end));
      setItemCount(end <= totalItemCount ? pageSize : pageSize + totalItemCount - end);
    }

    pageSizeRef.current = pageSize;
  }, [destinations, pageNo, pageSize, debouncedSearchText]);

  const updatePageSize = (pageSize: number) => {
    if (pageSize !== pageSizeRef.current) {
      setPageNo(0);
      setPageSize(pageSize);
    }
  };

  return (
    <div className="flex-1">
      <div className="py-8">
        <h1 className="text-base font-bold text-gray-900 mb-2 leading-4">{t("destinations.title")}</h1>
        {isLoading ? (
          <span className="text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
            <SpinnerIcon className="-ml-1 mr-3 h-5 w-5" loading />
          </span>
        ) : (
          !destinations.length ? (
            <div className="bg-white-100 shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm items-center">
                      <p className="font-medium text-blue-600 truncate">
                        {t("destinations.empty")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Fragment>
              {destinations.length > 10 && (
                <div className="flex justify-end mb-2">
                  <div className="w-72">
                    <Input
                      icon={SearchIcon}
                      placeholder={t("destinations.search_placeholder")}
                      value={searchText}
                      onChange={e => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
              )}

              <DestinationTable destinations={pagedDestinations} onDelete={onDelete} />

              {destinations.length > 10 && (
                <div>
                  <SimplePagination
                    firstPage={pageNo === 0}
                    lastPage={pageNo === lastPage}
                    nextPage={() => setPageNo(pageNo + 1)}
                    prevPage={() => setPageNo(pageNo - 1)}
                    extended={true}
                    pageNo={pageNo}
                    itemCount={itemCount}
                    pageSize={pageSize}
                    pageSizes={[10, 15]}
                    toFirstPage={() => setPageNo(0)}
                    onChangePageSize={updatePageSize}
                    loading={isLoading}
                  />
                </div>
              )}
            </Fragment>
          )
        )}
        <Button variant="primary" onClick={onConnectClick} disabled={!useWorkspaceAdmin()} className="mt-2">
          {t("destinations.connect")}
        </Button>
      </div>
    </div>
  );
};

export default AudienceDestinations;
