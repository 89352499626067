import { Form, Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { Button } from '../../components/buttons';
import { Input } from '../../components/inputs';
import { Workspace } from '../../models/workspace';
import { generateSlug } from '../../utils';

type WorkspaceModel = Pick<Workspace, 'name' | 'description' | 'slug'>;

interface IWorkspaceCreateProps {
  workspace?: Workspace;
  workspaceChange?: (workspace: Workspace) => void;
  nextStep?: () => void;
}

const WorkspaceCreate: FunctionComponent<IWorkspaceCreateProps> = ({ workspace, workspaceChange, nextStep }) => {
  const { t } = useTranslation('workspaces');
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const validationSchema = Yup.object({
    name: Yup.string().min(3, t('edit.validations.too_short')).max(50, t('edit.validations.too_long')).required(t('edit.validations.required')),
  });

  const handleOnSubmit = (form: WorkspaceModel) => {
    workspaceChange?.(form as Workspace);
    nextStep?.();
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className={'bg-wave flex items-center justify-center h-full w-full bg-gray-100'}>
      <div className={'flex flex-col items-center bg-white-100 p-8 shadow-md rounded-lg'} style={{ width: '448px' }}>
        <h3 className={'font-semibold mb-8 text-xl'}>{t('create.title')}</h3>
        {errorMessage && <div className={'border-red-300 bg-red-600 text-white-100 w-full p-2 mb-3 rounded'}>{errorMessage}</div>}
        <Formik<WorkspaceModel>
          initialValues={{
            name: workspace?.name || '',
            slug: workspace?.slug || '',
            description: workspace?.description || '',
          }}
          onSubmit={handleOnSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, setFieldError, setFieldValue }) => {
            return (
              <Form>
                <Input
                  name='name'
                  id='name'
                  type='text'
                  className={'mb-3 shadow-sm'}
                  value={values.name}
                  placeholder={t('create.workspace_name')}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFieldError('name', undefined);
                    handleChange('name')(value);
                    setFieldValue('slug', generateSlug(value));
                  }}
                  error={errors.name}
                />
                <Input
                  name='slug'
                  id='slug'
                  type='text'
                  placeholder={t('create.workspace_slug')}
                  className={
                    'mb-3 shadow-sm inline-flex w-full px-4 py-2 sm:text-sm border border-gray-300 focus:border-blue-600 focus:border focus:outline-none rounded-md placeholder-gray-400 bg-gray-100 text-gray-500'
                  }
                  value={values.slug}
                  disabled={true}
                  onChange={(e) => {
                    setFieldError('slug', undefined);
                    handleChange('slug')(e.target.value);
                  }}
                  error={errors.slug}
                />
                <textarea
                  className={
                    'inline-flex shadow-sm w-full px-4 sm:text-sm border border-gray-300 focus:border-blue-600 focus:border focus:outline-none rounded-md placeholder-gray-400 mb-6'
                  }
                  rows={5}
                  placeholder={t('create.description')}
                  value={values.description}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFieldError('description', undefined);
                    handleChange('description')(value);
                  }}
                />
                <div className='flex justify-end flex-col'>
                  <Button variant='primary' type='submit' className='justify-center py-2 px-4 w-full mb-4'>
                    {t('create.continue')}
                  </Button>
                  <Button variant='light' type='button' className='justify-center py-2 px-4 w-full' onClick={handleCancel}>
                    {t('create.cancel')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default WorkspaceCreate;
