import React, { FunctionComponent } from 'react';
import { Button } from '../buttons';

interface ISlideOverFooter {
  confirmLabel?: string;
  cancelLabel?: string;
  closeSlideOver: () => void;
  onConfirmClicked: () => void;
}

const SlideOverFooter: FunctionComponent<ISlideOverFooter> = ({ cancelLabel, confirmLabel, closeSlideOver, onConfirmClicked }) => {
  return (
    <div className='flex-shrink-0 px-4 py-4 flex justify-end'>
      <Button
        type='button'
        variant='secondary'
        className='bg-white-100 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        onClick={closeSlideOver}
      >
        {cancelLabel || 'Cancel'}
      </Button>
      <Button
        type='submit'
        className='ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white-100 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        onClick={onConfirmClicked}
      >
        {confirmLabel || 'Save'}
      </Button>
    </div>
  );
};

export default SlideOverFooter;
