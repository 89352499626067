import React, { FunctionComponent, memo, MouseEvent, useEffect, useState } from 'react';
import { RefreshIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { SpinnerIcon } from '../../../components/icons';
import { BaseAudience } from '../../../models/audiences';
import { ToastType } from '../../../models/toast';
import { useAppDispatch } from '../../../reducers';
import { useEvaluateAudienceMutation, useLazyGetEstimateQuery } from '../../../services/endpoints/audiences';
import { showToast } from '../../toasts/toastsSlice';
import { useWorkspace } from '../../workspaces/hooks';

interface IAudienceStatsProps {
    audience: BaseAudience;
}

const AudienceStats: FunctionComponent<IAudienceStatsProps> = ({ audience }) => {
    const { t } = useTranslation('audiences');
    const workspace = useWorkspace();
    const dispatch = useAppDispatch();

    const [evaluate, { isLoading: isEvaluateLoading }] = useEvaluateAudienceMutation();
    const [getEstimate, { data, isLoading }] = useLazyGetEstimateQuery();

    const [reloadAnimation, setReloadAnimation] = useState(false);

    useEffect(() => {
        getEstimate({
            workspaceId: workspace.id,
            audienceId: audience.id,
        });
    }, []);

    const handleRefreshClicked = (e: MouseEvent) => {
        e.stopPropagation();

        setReloadAnimation(true);
        setTimeout(() => setReloadAnimation(false), 2000);

        evaluate({
            workspaceId: workspace.id,
            audienceId: audience.id,
        });

        dispatch(
            showToast({
                type: ToastType.INFO,
                title: t('evaluate_title'),
                message: t('evaluate'),
            })
        );
    };

    return (
        <div className={'flex justify-end'}>
            {audience.scheduleType === 'MANUAL' && (
                <button
                    className={`bg-transparent border-0 inline-block  text-blue-500`}
                    onClick={handleRefreshClicked}
                    disabled={isLoading || isEvaluateLoading}
                    title={t('buttons.refresh_audience_stats')}
                >
                    <RefreshIcon className={`w-4 h-4 ${(reloadAnimation || isEvaluateLoading) && 'animate-spin'}`} />
                </button>
            )}
            {isLoading ? (
                <div className='flex justify-end py-1 px-3'>
                    <SpinnerIcon className='h-3 w-3' fill='rgba(21,68,155,1)' loading />
                </div>
            ) : (
                <p className='text-base text-gray-900 font-medium text-right px-3'>{data?.size || 0}</p>
            )}
        </div>
    );
};

export default memo(AudienceStats);
