import { FunctionComponent, useEffect, useState } from 'react';
import { DataSource, EDataSourceStatus } from '../../../../../models/dataSource';
import { useGetDataSourceStatusQuery } from '../../../../../services/dataSources';
import { useWorkspace } from '../../../../workspaces/hooks';
import { POLLING_INTERVAL } from './constants';
import { ActiveFileDataSource, PendingFileDataSource } from './file';

interface ICodeOverviewProps {
  dataSource?: DataSource;
}

const FileOverview: FunctionComponent<ICodeOverviewProps> = ({ dataSource }) => {
  const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL);

  const workspace = useWorkspace();

  const { data } = useGetDataSourceStatusQuery(
    {
      workspaceId: workspace.id,
      dataSourceId: dataSource?.id || '',
    },
    {
      skip: !dataSource?.id,
      pollingInterval,
    }
  );

  useEffect(() => {
    if (data?.status === EDataSourceStatus.ACTIVE) {
      setPollingInterval(0);
    }
  }, [data]);

  const active = dataSource?.status === EDataSourceStatus.ACTIVE || data?.status === EDataSourceStatus.ACTIVE;

  return <div>{active ? <ActiveFileDataSource dataSource={dataSource} /> : <PendingFileDataSource dataSource={dataSource} />}</div>;
};

export default FileOverview;
