import { TrashIcon, UserGroupIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../../../../../components/buttons';
import { Audience } from '../../../../../models/audiences';

interface IDeleteDataSourceProps {
  handleDeleteDataSourceClicked: () => void;
  audiences: Pick<Audience, 'id' | 'name'>[] | undefined;
  isLoading?: boolean;
}

const DeleteDataSource: FunctionComponent<IDeleteDataSourceProps> = ({ audiences, handleDeleteDataSourceClicked, isLoading = false }) => {
  const { t } = useTranslation('data_source_details');

  return (
    <div>
      <h1 className='text-lg text-black-100 font-semibold'>{t('settings.delete.delete_title')}</h1>
      {audiences && !isEmpty(audiences) && (
        <div>
          <h3 className='my-3 text-base w-2/3'>{t('settings.delete.delete_description')}</h3>
          <ul>
            {audiences.map((audience) => (
              <li key={audience.id} className='mb-1'>
                <span className='inline'>
                  <UserGroupIcon className='h-6 inline' />
                  <span className='inline mx-2'>{audience.name}</span>
                  <Link to={`/audiences/${audience.id}`} className='inline underline text-blue-600 my-auto'>
                    {t('settings.delete.go_to_audience')}
                  </Link>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      <Button
        icon={TrashIcon}
        variant='delete'
        onClick={handleDeleteDataSourceClicked}
        loading={isLoading}
        disabled={!audiences || !isEmpty(audiences)}
        className='mt-4'
      >
        {t('settings.delete.delete_this_datasource')}
      </Button>
    </div>
  );
};

export default DeleteDataSource;
