import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { version } from '../version.json';
const env = import.meta.env.VITE_CDP_ENV || "dev";
const applicationId = import.meta.env.VITE_DATADOG_APPLICATION_ID;
const clientToken = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
const service = import.meta.env.VITE_DATADOG_SERVICE;
const apiUrl = import.meta.env.VITE_API_URL;

console.log("version", version);

export default function () {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.eu',
    service,
    env,
    sampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackFrustrations: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingOrigins: [apiUrl],
    version,
  } as RumInitConfiguration);

  datadogLogs.init({
    applicationId,
    clientToken,
    site: 'datadoghq.eu',
    service,
    env,
    sampleRate: 100,
    forwardConsoleLogs: 'all',
    version,
  } as LogsInitConfiguration);

  datadogRum.startSessionReplayRecording();
}
