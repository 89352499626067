import { TrashIcon } from '@heroicons/react/solid';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '../../../../../../components/buttons';
import { Input } from '../../../../../../components/inputs';
import { Audience } from '../../../../../../models/audiences';
import { IModalContentProps } from '../../../../../../models/modals';
import { ToastType } from '../../../../../../models/toast';
import { useAppDispatch } from '../../../../../../reducers';
import { useDeleteAudienceMutation } from '../../../../../../services/endpoints/audiences';
import { showToast } from '../../../../../toasts/toastsSlice';
import { useWorkspace } from '../../../../../workspaces/hooks';

interface IDeleteAudienceModalProps {
  audience?: Audience;
}

const DeleteAudienceModal: FunctionComponent<IDeleteAudienceModalProps & IModalContentProps> = ({ audience, closeModal }) => {
  const { t } = useTranslation('audience_details');

  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const workspace = useWorkspace();

  const [deleteAudience, { isLoading, isSuccess }] = useDeleteAudienceMutation();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleDeleteDataSourceClicked = () => {
    if (workspace.id && audience && audience.id)
      deleteAudience({
        workspaceId: workspace.id,
        audienceId: audience.id,
      });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      history.push('/audiences');

      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('settings.delete.audience_eliminated'),
          message: t('settings.delete.removed_successfully', { audienceName: audience?.name }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className='flex justify-center h-96 '>
      <div className='max-w-4xl my-auto text-left p-5'>
        <h1 className='text-lg text-black-100 font-semibold mb-5'>{t('settings.delete.delete_edit_title')}</h1>
        <p className='text-base'>
          {t('settings.delete.confirm_deletion')}&nbsp;(<b>{audience?.name}</b>)
        </p>
        <Input className='mt-5 mb-10' onChange={(e) => setButtonDisabled(e.target.value !== audience?.name)} />
        <Button icon={TrashIcon} variant='delete' onClick={handleDeleteDataSourceClicked} loading={isLoading} disabled={isButtonDisabled}>
          {t('settings.delete.delete_this_audience')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteAudienceModal;
