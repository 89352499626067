import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { ISelectItem } from '../../models/select';
import { SpinnerIcon } from '../icons';

export interface ISelectProps {
  id?: string;
  label?: string;
  items: ISelectItem[];
  value?: ISelectItem;
  error?: string;
  className?: string;
  disabled?: boolean;
  isViewMode?: boolean;
  onChange?: (event: ISelectItem) => void;
  loading?: boolean;
  placeholder?: string;
}

// This can be used if we need to change props to generic and have typed props
// const Select = <T,>(props: PropsWithChildren<ISelectProps<T>>) => { }

const Select: FunctionComponent<ISelectProps> = ({ id, label, items, value, onChange, className, error, disabled, isViewMode, loading, placeholder }) => {
  const { t } = useTranslation('ui');

  const handleChange = (value: ISelectItem) => {
    if (onChange) {
      onChange(value);
    }
  };

  const styleListboxButton =
    'bg-white-100 relative w-auto border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm';
  const styleListboxOption =
    'absolute z-10 mt-1 w-auto bg-white-100 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm';

  return (
    <Listbox value={value} onChange={handleChange} disabled={disabled || isViewMode || loading}>
      {({ open }) => (
        <>
          {!!label && <Listbox.Label className='block text-sm font-medium text-gray-700'>{label}</Listbox.Label>}
          <div className='relative'>
            <Listbox.Button
              id={id}
              className={twMerge(
                className ? twMerge(styleListboxButton, className) : styleListboxButton,
                disabled ? 'bg-gray-200 text-gray-500' : '',
                error ? 'border-red-500  hover:border-red-500 focus:border-red-500 outline-none' : ''
              )}
            >
              {value ? (
                <span className='block truncate'>
                  <Fragment>
                    {value.image && <div className={'inline-block align-middle mr-3'}>{value.image}</div>}
                    {value.label}
                  </Fragment>
                </span>
              ) : (
                <span className='block truncate text-gray-400'>{placeholder || t('choose_placeholder')}</span>
              )}
              {loading && (
                <div className={'absolute h-full flex justify-center items-center -ml-3 rounded right-6 top-0 w-10'}>
                  <SpinnerIcon className='absolute h-6 w-6' loading />
                </div>
              )}
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </span>
            </Listbox.Button>
            <Transition show={open} as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
              <Listbox.Options static className={className ? twMerge(styleListboxOption, className) : styleListboxOption}>
                {items.map((item: ISelectItem) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      twMerge(active ? 'text-white-100 bg-blue-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9')
                    }
                    value={item}
                  >
                    {item.image && <div className={'inline-block align-middle mr-3'}>{item.image}</div>}
                    {item.label}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {!!error && (
            <div className='text-left'>
              <Listbox.Label className='block mt-2 text-sm text-red-600'>{error}</Listbox.Label>
            </div>
          )}
        </>
      )}
    </Listbox>
  );
};

export default Select;
