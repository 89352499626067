import { FormikProps } from "formik";
import { first, last } from "lodash";
import { FunctionComponent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DataSourceHeader } from "../..";
import { Button } from "../../../../../components/buttons";
import { EStepStatus, Step, Stepper } from "../../../../../components/steppers";
import { DataSourceCodeConfig, DataSourceCrmConfig, DataSourceType, TDataSource } from "../../../../../models/dataSource";
import { RootState, useAppDispatch } from "../../../../../reducers";
import {
  CreateDataSourceResponse,
  useCrmDataSourceAuthenticatedMutation,
  useSaveDataSourceMutation,
  useUpdateCrmDataSourceFieldsMutation,
  useUpdateCrmDataSourceParametersMutation,
  useUpdateDataSourceMutation
} from "../../../../../services/dataSources";
import { popupCenter } from "../../../../../utils";
import { stashFieldDefinitions, stashFields, stashParameterDefinitions, stashParameters } from "../../../../cyclr/dataSourceIntegrationSlice";
import { setDataSourceTabs, stashDataSource } from "../../../../dataSources/dataSourcesSlice";
import RouteConstants from "../../../../router/RouteConstants";
import { useWorkspace } from "../../../../workspaces/hooks";
import { ECrmSteps } from "../../../constants";
import { stashSteps } from "../../../dataSourceEditSlice";
import { useDataSourceSteps } from "../../../hooks/useDataSourceSteps";
import { DataSourceCrmCreateFormModel, ICrmFields, ICrmParameters } from "../../../types";
import { CrmDataSourceContent } from "./";

interface ICrmDataSourceContainer {
  dataSourceType: DataSourceType;
  closeModal: () => void;
}

const CrmDataSourceContainer: FunctionComponent<ICrmDataSourceContainer> = ({ dataSourceType, closeModal }) => {
  const { t } = useTranslation("data_source_edit");

  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const dataSourceInfoFormRef = useRef<FormikProps<DataSourceCrmCreateFormModel>>(null);
  const crmParametersFormRef = useRef<FormikProps<ICrmParameters>>(null);
  const crmFieldsFormRef = useRef<FormikProps<ICrmFields>>(null);

  const tabs = useSelector((state: RootState) => state.dataSources.ui.tabs);
  const dataSource = useSelector((state: RootState) => state.dataSources.edit?.dataSource) as Partial<TDataSource<DataSourceCrmConfig>> | undefined;

  const [steps, handleNextStep, handlePrevStep, skipToStep] = useDataSourceSteps();

  const [saveDataSource, { isLoading: isSaveLoading, data: createdDataSource }] = useSaveDataSourceMutation();
  const [updateDataSource, { isLoading: isUpdateLoading, data: updatedDataSource }] = useUpdateDataSourceMutation();
  const [dataSourceAuthenticated, { isLoading: isAuthenticatedLoading, data: authenticatedDataSource }] = useCrmDataSourceAuthenticatedMutation();
  const [updateParameters, { data: updateParametersResponse, isLoading: isUpdateParametersLoading }] = useUpdateCrmDataSourceParametersMutation();
  const [updateFields, { data: updateFieldsResponse, isLoading: isUpdateFieldsLoading }] = useUpdateCrmDataSourceFieldsMutation();

  const isLoading = isSaveLoading || isUpdateLoading || isAuthenticatedLoading || isUpdateParametersLoading || isUpdateFieldsLoading;

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);
  const isLastStepCurrent = last(steps)?.status === EStepStatus.CURRENT;
  const isFirstStepCompleted = first(steps)?.status === EStepStatus.COMPLETE;

  const crmSteps: Step[] = [
    {
      id: ECrmSteps.SOURCE_DETAILS,
      label: t("crm.steps.source_details"),
      status: EStepStatus.CURRENT
    },
    {
      id: ECrmSteps.UPDATE_PARAMETERS,
      label: t("crm.steps.set_parameters"),
      status: EStepStatus.UPCOMING
    },
    {
      id: ECrmSteps.UPDATE_FIELDS,
      label: t("crm.steps.set_fields"),
      status: EStepStatus.UPCOMING
    },
    {
      id: ECrmSteps.CHECK_INSTALLATION,
      label: t("crm.steps.check_installation"),
      status: EStepStatus.UPCOMING
    }
  ];

  useEffect(() => {
    dispatch(stashSteps(crmSteps));
  }, []);

  useEffect(() => {
    if (createdDataSource) {
      dispatch(stashDataSource(createdDataSource.dataSource));

      if (createdDataSource.integrationsResult?.cyclr.action.authUrl) {
        authenticationScreen(createdDataSource);
      }
      else if (createdDataSource.integrationsResult?.cyclr.action.parameters?.length) {
        dispatch(stashParameterDefinitions(createdDataSource.integrationsResult.cyclr.action.parameters));
        skipToStep(ECrmSteps.UPDATE_PARAMETERS);
      }
      else if (createdDataSource.integrationsResult?.cyclr.action.fields?.length) {
        dispatch(stashFieldDefinitions(createdDataSource.integrationsResult.cyclr.action.fields));
        skipToStep(ECrmSteps.UPDATE_FIELDS);
      }
      else {
        skipToStep(ECrmSteps.CHECK_INSTALLATION);
      }
    }
  }, [createdDataSource]);

  useEffect(() => {
    if (updatedDataSource) {
      dispatch(stashDataSource(updatedDataSource));

      if (updatedDataSource.configuration && (updatedDataSource.configuration as any).cyclr?.status === "ACTIVE") {
        closeModal();
        dispatch(setDataSourceTabs(tabs.map((t) => ({ ...t, current: t.id === "datasource.table" }))));
      }
      else {
        handleNextStep();
      }
    }
  }, [updatedDataSource]);

  useEffect(() => {
    if (authenticatedDataSource) {
      dispatch(stashDataSource(authenticatedDataSource.dataSource));

      if (authenticatedDataSource.integrationsResult?.cyclr.action.parameters?.length) {
        dispatch(stashParameterDefinitions(authenticatedDataSource.integrationsResult.cyclr.action.parameters));
        skipToStep(ECrmSteps.UPDATE_PARAMETERS);
      }
      else if (authenticatedDataSource.integrationsResult?.cyclr.action.fields?.length) {
        dispatch(stashFieldDefinitions(authenticatedDataSource.integrationsResult.cyclr.action.fields));
        skipToStep(ECrmSteps.UPDATE_FIELDS);
      }
      else {
        skipToStep(ECrmSteps.CHECK_INSTALLATION);
      }
    }
  }, [authenticatedDataSource]);

  useEffect(() => {
    if (updateParametersResponse) {
      dispatch(stashDataSource(updateParametersResponse.dataSource));

      if (updateParametersResponse.integrationsResult.cyclr.action.fields?.length) {
        dispatch(stashFieldDefinitions(updateParametersResponse.integrationsResult.cyclr.action.fields));
        skipToStep(ECrmSteps.UPDATE_FIELDS);
      }
      else {
        skipToStep(ECrmSteps.CHECK_INSTALLATION);
      }
    }
  }, [updateParametersResponse]);

  useEffect(() => {
    if (updateFieldsResponse) {
      dispatch(stashDataSource(updateFieldsResponse.dataSource));
      skipToStep(ECrmSteps.CHECK_INSTALLATION);
    }
  }, [updateFieldsResponse]);

  const createOrUpdateDataSource = (dataSource: Partial<TDataSource<DataSourceCodeConfig>>) => {
    if (dataSource.name) {
      if (!dataSource.id) {
        saveDataSource({
          workspaceId: workspace.id,
          dataSource: {
            type: dataSourceType.id,
            name: dataSource.name,
            configuration: { ...dataSource.configuration }
          }
        });
      }
      else {
        updateDataSource({
          workspaceId: workspace.id,
          dataSourceId: dataSource.id,
          dataSource: {
            name: dataSource.name,
            configuration: { ...dataSource.configuration }
          }
        });
      }
    }
  };

  const authenticationScreen = (createdDataSource: CreateDataSourceResponse) => {
    (window as any).cyclrAuthenticationCompleted = (success: boolean, w: Window) => {
      w.close.apply(w);
      delete (window as any).cyclrAuthenticationCompleted;

      dataSourceAuthenticated({
        workspaceId: workspace.id,
        dataSourceId: createdDataSource.dataSource.id
      });
    };

    const action = createdDataSource.integrationsResult.cyclr.action;
    const connector = action.accountConnectors?.length ? action.accountConnectors[0] : "";
    const url = `${action.authUrl}?id=${connector}&token=${action.token}&targetOrigin=${location.origin + RouteConstants.cyclrAuthRedirect}`;

    (window as any).authWindow = popupCenter({
      url,
      title: "Authenticate",
      w: 640,
      h: 640
    });
  };

  const submitParameters = (values: ICrmParameters) => {
    if (dataSource && dataSource.id) {
      updateParameters({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
        parameters: values.parameters.filter(
          param => param.value !== undefined && param.value !== null
        ).map(
          param => ({
            stepIds: param.stepIds,
            parameterId: param.parameterId,
            mappingType: param.mappingType,
            name: param.name,
            value: param.value as string
          })
        )
      });
    }
  };

  const submitFields = (values: ICrmFields) => {
    if (dataSource && dataSource.id) {
      updateFields({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
        fields: values.fields.filter(
          field => !field.checked
        ).map(
          field => ({
            stepId: field.stepId,
            fieldId: field.fieldId,
            mappingType: "Ignore",
            value: field.value
          })
        )
      });
    }
  };

  const activateDataSource = () => {
    if (dataSource && dataSource.id) {
      updateDataSource({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
        dataSource: {
          activate: true
        }
      });
    }
  };

  const handleNextButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;

    if (currentStepId === ECrmSteps.SOURCE_DETAILS) {
      await dataSourceInfoFormRef.current?.submitForm();

      if (dataSourceInfoFormRef.current?.isValid && dataSourceInfoFormRef.current?.values) {
        createOrUpdateDataSource(dataSourceInfoFormRef.current?.values);
      }

      return;
    }

    if (currentStepId === ECrmSteps.UPDATE_PARAMETERS && crmParametersFormRef.current) {
      const parametersForm = crmParametersFormRef.current;
      await parametersForm.submitForm();
      const values = parametersForm.values;

      if (parametersForm.isValid && values) {
        dispatch(stashParameters(values.parameters.filter(param => param.value !== undefined && param.value !== null)));
        submitParameters(values);
      }

      return;
    }

    if (currentStepId === ECrmSteps.UPDATE_FIELDS && crmFieldsFormRef.current) {
      const fieldsForm = crmFieldsFormRef.current;
      await fieldsForm.submitForm();
      const values = fieldsForm.values;

      if (fieldsForm.isValid && values) {
        dispatch(stashFields(values.fields));

        if (values.fields.every(field => field.checked)) {
          handleNextStep();
        }
        else {
          submitFields(values);
        }
      }

      return;
    }

    if (isLastStepCurrent) {
      activateDataSource();

      return;
    }

    handleNextStep();
  };

  return (
    <div className="h-full divide-y divide-gray-200 flex flex-col">
      <div className="flex-1 flex flex-col pt-6 bg-gray-50 rounded-lg">
        <DataSourceHeader closeModal={closeModal} title={t("title", { name: dataSourceType.name })} loading={isLoading} />
        <div className="mt-6 py-6 relative flex-1 px-4 sm:px-6 bg-white-100 overflow-y-auto">
          <Stepper steps={steps} />
          <CrmDataSourceContent
            dataSourceType={dataSourceType}
            step={currentStep}
            dataSourceInfoFormRef={dataSourceInfoFormRef}
            crmParametersFormRef={crmParametersFormRef}
            crmFieldsFormRef={crmFieldsFormRef}
          />
        </div>
      </div>
      <div className="flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg">
        {(isFirstStepCompleted && !isLastStepCurrent) && (
          <Button
            variant="light"
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            onClick={handlePrevStep}
            disabled={isLoading}
          >
            {t("back")}
          </Button>
        )}
        <Button
          variant={!isLastStepCurrent ? "primary" : "confirm"}
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4"
          onClick={handleNextButtonClicked}
          loading={isLoading}
        >
          {t(!isLastStepCurrent ? "next" : "finish")}
        </Button>
      </div>
    </div>
  );
};

export default CrmDataSourceContainer;
