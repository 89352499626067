import { CheckCircleIcon } from "@heroicons/react/outline";
import { format, parseISO } from "date-fns";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { DataSourceEvent, isTrackEvent } from "../../../../models/events";
import _ from "lodash";

export interface IDataSourceEventListProps {
  events?: DataSourceEvent[];
  onClick?: (item: DataSourceEvent) => void;
}

const DataSourceEventList: FunctionComponent<IDataSourceEventListProps> = ({ events, onClick }) => {
  const { t } = useTranslation("data_source_details");

  const getEventName = (ev: DataSourceEvent) => {
    return ev.event?.type?.toUpperCase() || "UNKNOWN";
  };

  const getBadgeColor = (ev: DataSourceEvent) => {
    switch (ev.channel?.toLowerCase() || "") {
      case "browser":
        return "bg-green-100 text-green-800";
      case "api":
        return "bg-purple-100 text-purple-800";
      case "android":
      case "ios":
        return "bg-blue-100 text-blue-800";
      default:
        return "bg-pink-100 text-pink-800";
    }
  };

  const displayUserData = (ev: DataSourceEvent) => {
    const event = ev.event;
    if (!event.userId && !event.anonymousId && !event.traits?.email && !event.traits?.first_name && !event.traits?.last_name) {
      return null;
    }

    const elements: string[] = [];
    event.anonymousId && elements.push(event.anonymousId + ' (anonId)');
    event.userId && elements.push(event.userId + ' (userId)');
    event.traits?.email && elements.push(event.traits.email);
    (event.traits?.first_name || event.traits?.last_name) && elements.push(`${event.traits.first_name || ''} ${event.traits.last_name || ''}`);

    return (
      <div className="pl-12 font-base text-sm text-gray-600 truncate">
        {elements.join(' - ')}
      </div>
    );
  };

  return (
    <div className="bg-white-100 shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {_.orderBy(events, (e) => parseISO(e.timestamp), 'desc').map(ev => (
          <li key={ev.event._id} className={onClick ? "cursor-pointer hover:bg-gray-50" : ""} onClick={() => onClick?.(ev)}>
            <div className="px-4 pt-4 flex items-center sm:px-6">
              <div className="flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="truncate flex-1">
                  <div className="flex items-center">
                    <div className="w-6 h-6 rounded-lg flex flex-wrap content-center justify-center mr-4">
                      <CheckCircleIcon className="h-6 w-6 text-blue-600" />
                    </div>
                    <p className="font-base text-sm w-20 text-gray-600 truncate">{getEventName(ev)}</p>
                    <div className="flex flex-grow justify-between">
                      <span className="font-base text-sm text-gray-900">{isTrackEvent(ev) ? ev.name : ev.path}</span>
                      <div className="">
                        <span className="font-base text-xs text-gray-700">{t("channel")}</span>
                        <span
                          className={twMerge("items-center mx-2 px-2.5 py-0.5 rounded-md text-sm font-medium", getBadgeColor(ev))}>{ev.channel || t("unknown_channel")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-40 my-auto ml-4">
                <span className="text-sm text-gray-900">{format(parseISO(ev.timestamp), "Ppp")}</span>
              </div>
            </div>
            <div className="px-4 pb-4">
              {displayUserData(ev)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DataSourceEventList;
