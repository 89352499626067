import classNames from "classnames";
import { useCallback } from "react";

type PercentageBadgeProps = {
  percentage?: number;
};

export default function PercentageBadge({ percentage }: PercentageBadgeProps) {
  const labelColor = useCallback(() => {
    if (!percentage || percentage == 0) {
      return 'bg-red-300';
    } else if (percentage < 35) {
      return 'bg-red-200';
    } else if (percentage < 70) {
      return 'bg-yellow-200';
    } else {
      return 'bg-green-200';
    }
  }, [percentage]);
  return (
    <i
      className={classNames(labelColor(), "ml-4 text-grey-800 flex items-center justify-center max-w-max rounded-xl py-0.5 px-5 text-xs leading-4")}>%{percentage}
    </i>
  );
}
