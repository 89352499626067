import { TFunction } from "react-i18next";
import { AudienceSyncSchedule, AudienceSyncScheduleType, AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";

type schedule = {
  scheduleType: AudienceSyncScheduleType;
  scheduleConfiguration: AudienceSyncSchedule;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const validateSchedule = ({ scheduleType, scheduleConfiguration }: schedule, t: TFunction<"audience_edit">) => {
  const errors: Record<string, string> = {};

  if (!scheduleType) {
    errors["scheduleType"] = "Select type";
  }

  if (scheduleType !== "SCHEDULED") {
    return errors;
  }

  const repeatConfiguration: AudienceSyncTimeBasedSchedule = scheduleConfiguration as AudienceSyncTimeBasedSchedule;

  if (!repeatConfiguration.startsOn) {
    errors["scheduleConfiguration.startsOn"] = "Select start date";
  }

  if (!repeatConfiguration.repeatsEvery || repeatConfiguration.repeatsEvery < 1) {
    errors["scheduleConfiguration.startsOn"] = "Select a positive repeat interval";
  }

  if (!repeatConfiguration.unit) {
    errors["scheduleConfiguration.startsOn"] = "Select a repeat unit";
  }

  if (repeatConfiguration.unit === "WEEKS" && (!repeatConfiguration.weekDays || !repeatConfiguration.weekDays.length)) {
    errors["scheduleConfiguration.startsOn"] = "Select weekdays to run syncing";
  }

  if (repeatConfiguration.unit === "MONTHS" && (!repeatConfiguration.dayOfMonth || repeatConfiguration.dayOfMonth < 1 || repeatConfiguration.dayOfMonth > 31)) {
    errors["scheduleConfiguration.startsOn"] = "Select day of the month to run syncing";
  }

  return errors;
};
