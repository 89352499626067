import { Form, Formik, FormikProps, getIn } from 'formik';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Input, VisualInput } from '../../../../../components/inputs';
import { DataSourceCodeConfig, TDataSource } from '../../../../../models/dataSource';
import { DataSourceFormModel } from '../../../types';

interface ICodeDataSourceFormProps {
  dataSourceInfoFormRef: React.RefObject<FormikProps<DataSourceFormModel>>;
  onSubmit: (form: DataSourceFormModel) => void;
  dataSource?: Partial<TDataSource<DataSourceCodeConfig>>;
}

const CodeDataSourceForm: FunctionComponent<ICodeDataSourceFormProps> = ({ dataSource, onSubmit, dataSourceInfoFormRef }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t('data_source_edit:validations.too_short'))
      .max(50, t('data_source_edit:validations.too_long'))
      .required(t('data_source_edit:validations.required')),
    configuration: Yup.object({
      url: Yup.string()
        .matches(
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
          t('data_source_edit:validations.not_valid_url')
        )
        .required(t('data_source_edit:validations.required')),
    }),
  });

  return (
    <Formik
      innerRef={dataSourceInfoFormRef}
      initialValues={{ ...dataSource }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, setFieldError }) => {
        return (
          <Form>
            <VisualInput
              name='type'
              id='type'
              label={t('data_source_edit:code.source_details.source_type')}
              value={dataSource?.type?.name}
              iconUrl={values.type?.logoUrl}
            />
            <Input
              name='name'
              id='name'
              type='text'
              label={t('data_source_edit:code.source_details.source_name')}
              value={values.name}
              placeholder={t('data_source_edit:code.source_details.source_name_placeholder')}
              onChange={(e) => {
                setFieldError('name', undefined);
                handleChange('name')(e.target.value);
              }}
              error={errors.name}
            />
            <Input
              name='configuration.url'
              id='configuration.url'
              type='text'
              label={t('data_source_edit:code.source_details.source_website')}
              value={values.configuration?.url}
              placeholder={t('data_source_edit:code.source_details.source_website_placeholder')}
              onChange={(e) => {
                setFieldError('configuration.url', undefined);
                handleChange('configuration.url')(e.target.value);
              }}
              error={getIn(errors.configuration, 'url')}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CodeDataSourceForm;
