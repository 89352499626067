import { Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Button } from '../../components/buttons';
import { SpinnerIcon } from '../../components/icons';
import { Input } from '../../components/inputs';
import { Select } from '../../components/selects';
import { useUserInfo } from '../../features/userInfo/hooks';
import { useInviteCompanyUserMutation } from '../../services/endpoints/companies';

type Role = 'ADMIN' | 'READONLY' | 'ACCOUNTING';

type RoleItem = {
  id: Role;
  label: string;
};

type InviteFormType = {
  email: string;
  role: Role;
};

interface INewCompanyInviteRowProps {
  onComplete: (saved: boolean) => void;
}

const NewCompanyInviteRow: FunctionComponent<INewCompanyInviteRowProps> = ({ onComplete }) => {
  const userInfo = useUserInfo();
  const formikFormRef = useRef<FormikProps<InviteFormType>>(null);
  const [inviteCompanyUser, { data, isLoading, isError }] = useInviteCompanyUserMutation();

  useEffect(() => {
    if (data && !isLoading && !isError) {
      onComplete(false);
    }
  }, [data, isLoading, isError]);

  const roleItems: RoleItem[] = [
    {
      id: 'READONLY',
      label: 'Readonly',
    },
    {
      id: 'ACCOUNTING',
      label: 'Accounting',
    },
    {
      id: 'ADMIN',
      label: 'Admin',
    },
  ];

  const validationSchema = Yup.object({
    email: Yup.string().email('Not a valid email').required('Email is required'),
    role: Yup.string().oneOf(['ADMIN', 'READONLY', 'ACCOUNTING'], 'Invalid role'),
  });

  const handleSaveButtonClicked = async () => {
    await formikFormRef.current?.submitForm();
    const invite = formikFormRef.current?.values;

    if (formikFormRef.current?.isValid && invite) {
      inviteCompanyUser({
        companyId: userInfo.company.id,
        userList: invite,
      });
    }
  };

  const handleCancelButtonClicked = () => {
    onComplete(false);
  };

  return (
    <Formik initialValues={{ email: '', role: roleItems[0].id }} validationSchema={validationSchema} innerRef={formikFormRef} onSubmit={(e) => console.log(e)}>
      {({ values, handleChange, errors }) => (
        <tr className='bg-white-100 border-b border-gray-200'>
          <td className='pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900'>
            <Form>
              <div className='flex flex-row space-x-6'>
                <div className='flex flex-row space-x-2 w-1/3'>
                  <label htmlFor='email' className='block text-sm font-medium text-gray-700 py-2'>
                    Email:
                  </label>
                  <Input
                    id='email'
                    name='email'
                    placeholder='john.doe@company.com'
                    value={values.email}
                    onChange={(e) => handleChange('email')(e.target.value)}
                    error={errors.email}
                    disabled={isLoading}
                  />
                </div>

                <div className='flex flex-row items-start space-x-2 w-1/3'>
                  <label htmlFor='role' className='block text-sm font-medium text-gray-700 py-2'>
                    Role:
                  </label>
                  <Select
                    items={roleItems}
                    value={roleItems.find((item) => item.id === values.role)}
                    onChange={(item) => handleChange('role')(item.id as string)}
                  />
                </div>
              </div>
            </Form>
          </td>

          <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-right align-top' colSpan={2}>
            <div className='flex flex-row space-x-2 justify-end'>
              {isLoading && (
                <span className='text-blue-500 opacity-75 my-2 relative'>
                  <SpinnerIcon className='mr-1 h-5 w-5' loading />
                </span>
              )}

              <Button variant='primary' type='submit' className='inline-flex justify-center py-2 px-4' disabled={isLoading} onClick={handleSaveButtonClicked}>
                Save
              </Button>
              <Button
                variant='light'
                type='button'
                className='ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'
                disabled={isLoading}
                onClick={handleCancelButtonClicked}
              >
                Cancel
              </Button>
            </div>
          </td>
        </tr>
      )}
    </Formik>
  );
};

export default NewCompanyInviteRow;
