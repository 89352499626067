import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FileUploadingFooter } from '.';
import { DataSourceTypePanelContent, DataSourceTypePanelHeader } from '..';
import { SlideOverContentWrapper, SlideOverFooter } from '../../../../components/slideOvers';
import { DataSourceType, EDataSourceCategory } from '../../../../models/dataSource';
import { EStatus } from '../../../../models/fileUpload';
import { ISlideOverContentProps } from '../../../../models/slideOver';
import { RootState } from '../../../../reducers';

interface IDataSourceTypePanelProps {
  dataSourceType: DataSourceType;
  onAddDataSourceClicked: (source: DataSourceType) => void;
}

const DataSourceTypePanel: FunctionComponent<PropsWithChildren<IDataSourceTypePanelProps & ISlideOverContentProps>> = ({
  dataSourceType,
  closeSlideOver,
  onAddDataSourceClicked,
}) => {
  const { t } = useTranslation();
  const fileUploadStatus = useSelector((state: RootState) => state.fileUploading.status);

  return (
    <div className='h-full divide-y divide-gray-200 flex flex-col bg-white-100 shadow-xl'>
      <div className='min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll'>
        <DataSourceTypePanelHeader
          title={dataSourceType.name}
          subtitle={dataSourceType.subtitle}
          icon={dataSourceType.logoUrl}
          closeSlideOver={closeSlideOver}
        />
        <SlideOverContentWrapper className='border-t-2 border-gray-100'>
          <DataSourceTypePanelContent dataSourceType={dataSourceType} />
        </SlideOverContentWrapper>
      </div>
      {fileUploadStatus?.uploadingStatus === EStatus.UPLOADING && dataSourceType.category === EDataSourceCategory.FILE ? (
        <FileUploadingFooter />
      ) : (
        <SlideOverFooter
          closeSlideOver={closeSlideOver}
          confirmLabel={t('data_sources:slideover.footer.add_this_data_source')}
          onConfirmClicked={() => onAddDataSourceClicked(dataSourceType)}
        />
      )}
    </div>
  );
};

export default DataSourceTypePanel;
