import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, FunctionComponent } from 'react';
import { DefaultModal } from '../components/modals';
import { IManagedModalOptions } from '../models/modals';
import { concatClassNames } from '../utils';

interface IModalProviderContentProps {
  modalsOptions?: IManagedModalOptions<unknown>;
  // This callback is needed and is the callback that will handle the "closing" of the modal
  closeModal: () => void;
  // This callback is for doing actions on backdrop of modal
  onModalBackdropClicked: () => void;
  // This callback is fired after animation of modal closing is finished
  onModalClosed: () => void;
  // This callback is fired for toggling the dismissability of the modal
  // i.e. during an API call in the component, I want to block the dismissability of the modal
  onToggleDismissable?: (toggle: boolean) => void;
}

const ModalProviderContent: FunctionComponent<IModalProviderContentProps> = ({
  modalsOptions,
  closeModal,
  onModalClosed,
  onModalBackdropClicked,
  onToggleDismissable,
}) => {
  modalsOptions = modalsOptions || {} as IManagedModalOptions<unknown>;
  if (!modalsOptions?.position) {
    modalsOptions && (modalsOptions.position = 'center');
  }
  const positionClass = {
    center: {
      outer: 'justify-center  sm:block',
      content: 'max-w-7xl py-16',
    },
    right: {
      outer: 'justify-end',
      content: 'max-w-xl',
    },
  };
  const renderContentProps: any = modalsOptions.renderContentProps;
  return (
    <Transition.Root show={modalsOptions?.visible || false} as={Fragment} afterLeave={onModalClosed}>
      <Dialog as='div' static className='fixed z-20 inset-0 overflow-y-auto' open={modalsOptions?.visible} onClose={onModalBackdropClicked}>
        <div className={`flex items-end min-h-screen pt-4 px-4 pb-20 text-center sm:p-0  ${modalsOptions?.position && positionClass[modalsOptions.position].outer}`}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0'>
              <div className='absolute inset-0 opacity-75 bg-gray-300' />
            </Dialog.Overlay>
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          {!modalsOptions?.renderContent && (
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
          )}
          {/* Remember that inside Transition.Child we need to have a forwardable component
            If we use our JSX, we need to use forwardRef. Otherwise we can move here the container and just 
            create a normal child without ref (use a div as a container) */}
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            {modalsOptions?.renderContent ? (
              <div className={`inline-flex relative items-center w-screen h-screen pointer-events-none ${modalsOptions?.position && positionClass[modalsOptions.position].content}`}>
                <div
                  className={concatClassNames(
                    modalsOptions.fullHeight ? 'h-full' : 'h-auto',
                    modalsOptions.fullWidth ? 'w-full' : 'w-auto mx-auto max-w-6xl',
                    'bg-white-100 rounded-lg pointer-events-auto'
                  )}
                >
                  <modalsOptions.renderContent {...renderContentProps} closeModal={closeModal} onToggleDismissable={onToggleDismissable} />
                </div>
              </div>
            ) : (
              <DefaultModal closeModal={closeModal} />
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalProviderContent;
