import { delay, isEmpty } from 'lodash';
import React, { Fragment, FunctionComponent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SpinnerIcon } from '../../components/icons';
import Paginated from '../../components/tables/pagination/Paginated';
import { useDelayedBoolean } from '../../hooks/useDelayedBoolean';
import { useModalContext } from '../../hooks/useModalContext';
import { useSlideOverContext } from '../../hooks/useSlideOverContext';
import { DataSource, DataSourceType } from '../../models/dataSource';
import { EStatus } from '../../models/fileUpload';
import { SLIDEOVER_TRANSITION_DURATION } from '../../providers';
import { RootState, useAppDispatch } from '../../reducers';
import { useLazyGetDataSourcesQuery } from '../../services/dataSources';
import { clearIntegrationData } from '../cyclr/dataSourceIntegrationSlice';
import { DataSourceEdit } from '../dataSourceEdit';
import { resetUploadStatus } from '../fileUploading/fileUploadingSlice';
import { MainContentWrapper } from '../layout/main';
import { clearStashedRules } from '../rules/rulesSlice';
import { useWorkspace } from '../workspaces/hooks';
import { AddFirstDataSource, DataSourceList } from './components';
import { clearStashedDataSource, clearStashedFileDataSource, setPagination } from './dataSourcesSlice';

const MINIMUM_LOADING_DURATION = 400;

const DataSources: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { closeSlideOver } = useSlideOverContext();
  const { openModal } = useModalContext();

  const workspace = useWorkspace();

  const fileUploadStatus = useSelector((state: RootState) => state.fileUploading.status);
  const searchText = useSelector((state: RootState) => state.dataSources.ui.searchText);

  const [getDataSources, { data, isLoading, isUninitialized, isFetching }] = useLazyGetDataSourcesQuery();
  const [dataSources, setDataSources] = useState<DataSource[]>([]);

  const isLoadingDelayed = useDelayedBoolean(isLoading, MINIMUM_LOADING_DURATION);
  const shouldShowLoading = isLoading || isLoadingDelayed || isUninitialized;

  const queryCallback = useCallback((pageNo: number, pageSize: number) => getDataSources({
    workspaceId: workspace.id,
    offset: pageNo * pageSize,
    limit: pageSize,
    searchText: searchText && searchText.length > 2 ? searchText : undefined
  }), [searchText]);

  const onAddDataSourceClicked = (dataSourceType: DataSourceType) => {
    if (fileUploadStatus?.uploadingStatus !== EStatus.UPLOADING) {
      dispatch(resetUploadStatus());
      dispatch(clearStashedFileDataSource());
    }

    dispatch(clearStashedDataSource());
    dispatch(clearStashedRules());
    dispatch(clearIntegrationData());

    closeSlideOver();
    // open data source modal after animation of slideover is finished
    delay(() => {
      openModal({
        renderContent: DataSourceEdit,
        renderContentProps: {
          dataSourceType,
        },
        fullHeight: true,
        fullWidth: true,
      });
    }, SLIDEOVER_TRANSITION_DURATION);
  };

  const renderContent = (pager: React.ReactNode) => {
    return shouldShowLoading ? (
      <Fragment>
        <MainContentWrapper>
          <span className='text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0'>
            <SpinnerIcon className='-ml-1 mr-3 h-5 w-5' loading />
          </span>
        </MainContentWrapper>
      </Fragment>
    ) : (isFetching || searchText || !isEmpty(dataSources)) ? (
      <DataSourceList dataSources={dataSources} pager={pager} />
    ) : (
      <AddFirstDataSource onConfirmClicked={onAddDataSourceClicked} />
    );
  };

  return (
    <Paginated
      stateSelector={(state) => state.dataSources.ui.pagination}
      stateUpdater={setPagination}
      query={queryCallback}
      queryResponse={data ? data.items : undefined}
      setItems={(ds) =>
        setDataSources(
          ds.map((d) => ({
            ...d,
            createdOnDate: d.createdOn ? new Date(d.createdOn) : d.createdOnDate,
          }))
        )
      }
      querying={isFetching}
    >
      {renderContent}
    </Paginated>
  );
};

export default DataSources;
