import React, { FunctionComponent, useState } from 'react';
import CompanyCreate from './companyCreate/CompanyCreate';
import AdministratorInfo from './companyCreate/AdministratorInfo';
import InviteCollaborators from './companyCreate/InviteCollaborators';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useGetUserInfoQuery } from '../../../services/endpoints/users';
import { Redirect } from 'react-router-dom';
import RouteConstants from '../../router/RouteConstants';
import { Loading } from '../../../components/loaders';

interface CompanyCreationProps { }

const CompanyCreation: FunctionComponent<CompanyCreationProps> = () => {
  const { isLoading, data: userInfo } = useGetUserInfoQuery();

  const company = useSelector((r: RootState) => r.userInfo.company);
  if (userInfo?.company) {
    return <Redirect to={RouteConstants.home} />;
  }
  if (isLoading) {
    return <Loading />;
  }

  const [activeTab, setActiveTab] = useState<string>(company ? 'administrativeInfo' : 'create');

  let tabTemplate;
  switch (activeTab) {
    case 'create':
      tabTemplate = <CompanyCreate complete={() => {
        setActiveTab('administrativeInfo');
      }} />;
      break;
    case 'administrativeInfo':
      tabTemplate = <AdministratorInfo back={() => setActiveTab('create')} complete={() => setActiveTab('invite')} />;
      break;
    case 'invite':
      tabTemplate = <InviteCollaborators back={() => setActiveTab('administrativeInfo')} />;
      break;
  }

  return <>{tabTemplate}</>;
};

export default CompanyCreation;
