import { TrashIcon } from '@heroicons/react/solid';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '../../../../../../components/buttons';
import { Input } from '../../../../../../components/inputs';
import { DataSource } from '../../../../../../models/dataSource';
import { IModalContentProps } from '../../../../../../models/modals';
import { ToastType } from '../../../../../../models/toast';
import { useAppDispatch } from '../../../../../../reducers';
import { useDeleteDataSourceMutation } from '../../../../../../services/dataSources';
import { showToast } from '../../../../../toasts/toastsSlice';
import { useWorkspace } from '../../../../../workspaces/hooks';

interface IDeleteDataSourceModalProps {
  dataSource: DataSource | undefined;
}

const DeleteDataSourceModal: FunctionComponent<IDeleteDataSourceModalProps & IModalContentProps> = ({ dataSource, closeModal }) => {
  const { t } = useTranslation('data_source_details');

  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const workspace = useWorkspace();

  const [deleteDataSource, { isLoading, isSuccess }] = useDeleteDataSourceMutation();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleDeleteDataSourceClicked = () => {
    if (workspace.id && dataSource && dataSource.id)
      deleteDataSource({
        workspaceId: workspace.id,
        dataSourceId: dataSource?.id,
      });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      history.push('/data-sources');

      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('settings.delete.datasource_eliminated'),
          message: t('settings.delete.removed_successfully', { dataSourceName: dataSource?.name }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className='flex justify-center h-96 '>
      <div className='max-w-4xl my-auto text-left p-5'>
        <h1 className='text-lg text-black-100 font-semibold mb-5'>{t('settings.delete.delete_edit_title')}</h1>
        <p className='text-base'>
          {t('settings.delete.confirm_deletion')}&nbsp;(<b>{dataSource?.name}</b>)
        </p>
        <Input className='mt-5 mb-10' onChange={(e) => setButtonDisabled(e.target.value !== dataSource?.name)} />
        <Button icon={TrashIcon} variant='delete' onClick={handleDeleteDataSourceClicked} loading={isLoading} disabled={isButtonDisabled}>
          {t('settings.delete.delete_this_datasource')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteDataSourceModal;
