import { FunctionComponent } from 'react';
import { StatusBadge } from '../../../../components/badges';
import { IconContainer } from '../../../../components/icons';
import { DataSource, EDataSourceCategory, DataSourceCodeConfig, EDataSourceStatus } from '../../../../models/dataSource';

interface IActivationStatusProps {
  dataSource?: DataSource;
}

const ActivationStatus: FunctionComponent<IActivationStatusProps> = ({ dataSource }) => {
  return (
    <div className='flex bg-gray-50 rounded w-full'>
      <div className='flex-col py-1 whitespace-nowrap text-sm font-medium text-gray-900 '>
        <div className=' px-2 py-1 sm:px-3 flex space-x-3'>
          <div className='flex-shrink-0'>
            <div className='w-14 h-14 rounded-lg flex flex-wrap content-center justify-center'>
              <IconContainer path={dataSource?.type.logoUrl} className='h-10' />
            </div>
          </div>
          <div className='flex flex-wrap content-center'>
            <div className='flex-col'>
              <p className='text-sm font-medium text-gray-900'>{dataSource?.name}</p>
              {dataSource?.type.category === EDataSourceCategory.CODE ? (
                <p className='text-sm font-normal text-gray-500'>{`${dataSource.type.name} • ${(dataSource.configuration as DataSourceCodeConfig).url}`} </p>
              ) : (
                <p className='text-sm font-normal text-gray-500'>{`${dataSource?.type.name}`} </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='px-2 py-4 flex m-auto whitespace-nowrap text-sm font-medium text-gray-900'>
        <StatusBadge status={dataSource?.status || EDataSourceStatus.PENDING} />
      </div>
    </div>
  );
};
export default ActivationStatus;
