import { TypedUseSelectorHook, useDispatch, useSelector as useReduxSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { AppDispatch } from '../App';
import audienceEdit from '../features/audienceEdit/audienceEditSlice';
import audiences from '../features/audiences/audiencesSlice';
import auth from '../features/auth/authSlice';
import bootstrap from '../features/bootstrap/bootstrapSlice';
import dataSourceIntegration from '../features/cyclr/dataSourceIntegrationSlice';
import dataSourceEdit from '../features/dataSourceEdit/dataSourceEditSlice';
import dataSources from '../features/dataSources/dataSourcesSlice';
import destinations from '../features/destinations/destinationsSlice';
import fileUploading from '../features/fileUploading/fileUploadingSlice';
import rules from '../features/rules/rulesSlice';
import settings from '../features/settings/settingsSlice';
import sidebar from '../features/sidebar/sidebarSlice';
import slideOver from '../features/slideOver/slideOverSlice';
import theme from '../features/theme/themeSlice';
import toasts from '../features/toasts/toastsSlice';
import userTable from '../features/userTable/userTableSlice';
import workspaces from '../features/workspaces/workspacesSlice';
import userInfo from '../features/userInfo/userInfoSlice';
import chat from '../features/chat/chatSlice';

import { audiencerateApi } from '../services/audiencerate';

export const rootReducer = combineReducers({
  // Put your slices here
  chat,
  auth,
  audiences,
  audienceEdit,
  bootstrap,
  dataSources,
  dataSourceEdit,
  destinations,
  fileUploading,
  settings,
  sidebar,
  slideOver,
  theme,
  toasts,
  userTable,
  workspaces,
  rules,
  dataSourceIntegration,
  userInfo,
  [audiencerateApi.reducerPath]: audiencerateApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
