import React, { FunctionComponent, useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { SpinnerIcon } from '../../components/icons';
import { WorkspaceInvite } from '../../models/workspace';
import { useAcceptWorkspaceInviteMutation } from '../../services/endpoints/workspaces';
import { useAppDispatch } from '../../reducers';
import { storeToken } from '../../features/auth/authSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { showToast } from '../../features/toasts/toastsSlice';
import { ToastType } from '../../models/toast';
import { useTranslation } from 'react-i18next';

const WorkspaceInviteRow: FunctionComponent<{ invite: WorkspaceInvite }> = ({ invite }) => {
    const { t } = useTranslation();
    const [acceptInvitation, { isLoading, isSuccess }] = useAcceptWorkspaceInviteMutation();
    const dispatch = useAppDispatch();

    const { logout, getAccessTokenSilently } = useAuth0();

    const accept = () => {
        acceptInvitation({ inviteId: invite.id });
    };

    useEffect(() => {
        async function postAccept() {
            console.log(isSuccess);
            if (isSuccess) {
                try {
                    const token = await getAccessTokenSilently({ cacheMode: 'off' });

                    dispatch(storeToken(token));
                    dispatch(
                        showToast({
                            type: ToastType.SUCCESS,
                            title: t('settings.delete.datasource_eliminated'),
                        })
                    );
                    window.location.reload();
                } catch (err) {
                    console.log('could not init token', err);

                    /*
                    If we target a route without being authenticated, getAccessToken will fail
                    with an error, so we need to send user back to landing
                    */
                    logout({
                        logoutParams: { returnTo: window.location.origin },
                    });
                }
            }
        }
        postAccept();
    }, [isSuccess]);

    return (
        <tr className='bg-white-100 border-b last:border-0 border-gray-200 group cursor-pointer hover:bg-gray-100'>
            <td className='pl-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
                <div className={'my-2 flex items-center'}>
                    {invite.workspaceName} {invite.company && '(' + invite?.company?.name + ')'}
                    <div className='rounded-full h-1 w-1 inline-block bg-gray-900 mx-2' />
                    <span className={'text-gray-500 text-xs font-normal'}>Invited by {invite.invitedBy}</span>
                </div>
            </td>
            <td className={'relative'}>
                {isLoading ? (
                    <SpinnerIcon className='absolute h-4 w-4 top-3 right-5' loading />
                ) : invite.status === 'PENDING' ? (
                    <div className={'flex my-2 items-center justify-end mr-5'}>
                        <span className={'text-blue-600 underline flex items-center text-sm font-normal'} onClick={accept}>
                            Join the workspace
                            <ArrowRightIcon className={'h-5 ml-3'} />
                        </span>
                    </div>
                ) : (
                    <p />
                )}
            </td>
        </tr>
    );
};

export default WorkspaceInviteRow;
