import { PlusIcon } from '@heroicons/react/outline';
import React, { FunctionComponent } from 'react';
import { Button } from '../../../../../../components/buttons';

interface IAddButtonProps {
  label: string;
  onClick?: () => void;
}

const AddButton: FunctionComponent<IAddButtonProps> = ({ label, onClick }) => {
  return (
    <Button variant='transparent' icon={PlusIcon} className='h-9 mr-6 max-w-max' onClick={onClick} type='button'>
      {label}
    </Button>
  );
};

export default AddButton;
