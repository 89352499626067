import { XIcon } from "@heroicons/react/solid";
import { FieldArray, FieldArrayRenderProps, FormikErrors, getIn } from "formik";
import { isEmpty } from "lodash";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { Button } from "../../../../../../components/buttons";
import { Input } from "../../../../../../components/inputs";
import { Select } from "../../../../../../components/selects";
import { ISelectItem } from "../../../../../../models/select";
import { concatClassNames } from "../../../../../../utils";
import { ESelector, ITrackClickEvents, ITrackPageVisitEvents, ITrackWebsiteValue } from "../../../../types";
import { AddButton } from "../buttons";

interface ITrackUserClickProps {
  values: ITrackWebsiteValue;
  errors: FormikErrors<ITrackWebsiteValue>;
  handleSetFieldValue: (field: string, value: ITrackClickEvents | ITrackPageVisitEvents) => void;
  handleSetFieldError: (field: string, message: string | undefined) => void;
  handleDeleteRule: (ruleId: string) => void;
}

const TrackUserClick: FunctionComponent<ITrackUserClickProps> = ({ values, handleSetFieldValue, handleSetFieldError, handleDeleteRule, errors }) => {
  const { t } = useTranslation("data_source_edit");

  const selectItems: ISelectItem[] = [
    { id: ESelector.ID, label: t("code.track_events.clicks_on_id") },
    { id: ESelector.CLASS, label: t("code.track_events.clicks_on_class") }
  ];

  const handleOnAddClicked = (clickEventHelper: FieldArrayRenderProps) => {
    const identifier = uuidv4();
    clickEventHelper.push({ identifier, selectorType: ESelector.ID, selectorName: "", eventName: "" });
  };

  const handleDeleteEvent = (clickEventHelper: FieldArrayRenderProps, index: number, ruleId?: string) => {
    if (ruleId) {
      handleDeleteRule(ruleId);
    }
    clickEventHelper.remove(index);
  };

  return (
    <div>
      <FieldArray
        name="clickEvents"
        validateOnChange={false}
        render={(clickEventHelper) => (
          <div>
            {clickEventHelper &&
              !isEmpty(clickEventHelper) &&
              values.clickEvents.map((event, index) => (
                <div key={index} className="flex px-4 my-2">
                  <div className="flex w-1/2">
                    <Select
                      items={selectItems}
                      value={selectItems[event.selectorType]}
                      className="ml-1 w-52"
                      disabled={event.id !== undefined}
                      onChange={(value) => {
                        handleSetFieldValue(`clickEvents.${index}`, {
                          ...event,
                          selectorType: value.id === ESelector.ID ? ESelector.ID : ESelector.CLASS
                        });
                      }}
                    />
                    <div className="relative flex-auto">
                      <span
                        className={concatClassNames("absolute z-10 text-gray-400", event.selectorType === ESelector.ID ? "mt-2 ml-2.5" : "mt-2 ml-4")}
                      >
                        {event.selectorType === ESelector.ID ? "#" : "."}
                      </span>
                      <Input
                        className="mx-1 flex-auto"
                        placeholder={event.selectorType === ESelector.ID ? t("code.track_events.name_of_the_id") : t("code.track_events.name_of_the_class")}
                        value={event.selectorName}
                        onChange={(e) => {
                          handleSetFieldError(`clickEvents.${index}.selectorName`, undefined);
                          handleSetFieldValue(`clickEvents.${index}`, { ...event, selectorName: e.target.value });
                        }}
                        disabled={event.id !== undefined}
                        error={errors.clickEvents && errors.clickEvents[index] ? getIn(errors.clickEvents[index], "selectorName") : null}
                      />
                    </div>
                  </div>
                  <div className="flex w-1/2">
                    <span className="py-2 ml-2 mr-1">{t("code.track_events.creates")}</span>
                    <div className="flex-auto">
                      <Input
                        placeholder={t("code.track_events.name_of_the_event")}
                        value={event.eventName}
                        onChange={(e) => {
                          handleSetFieldError(`clickEvents.${index}.eventName`, undefined);
                          handleSetFieldValue(`clickEvents.${index}`, {
                            ...event,
                            eventName: e.target.value
                          });
                        }}
                        disabled={event.id !== undefined}
                        error={errors.clickEvents && errors.clickEvents[index] ? getIn(errors.clickEvents[index], "eventName") : null}
                      />
                    </div>
                    <Button
                      variant="transparent"
                      type="button"
                      className="flex-0 ml-1"
                      onClick={() => handleDeleteEvent(clickEventHelper, index, event.id)}
                    >
                      <XIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                    </Button>
                  </div>
                </div>
              ))}
            <div className="bg-white py-6 px-5 my-auto">
              <AddButton label={t("code.track_events.buttons.add_event")} onClick={() => handleOnAddClicked(clickEventHelper)} />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default TrackUserClick;
