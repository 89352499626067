
const InviteStatusBadge  = ({ status }: {status: string}) => {
  switch (status) {
    case 'COMPLETE':
      return <div className='bg-green-100 text-green-800 flex justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4'>COMPLETE</div>;

    case 'PENDING':
      return <div className='bg-yellow-100 text-yellow-800 flex justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4'>PENDING</div>;

    case 'EXPIRED':
      return <div className='bg-red-100 text-red-800 flex justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4'>EXPIRED</div>;

    default:
      return null;
  }
};

export default InviteStatusBadge;
