import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Loading } from '../../components/loaders';
import { ToastType } from '../../models/toast';
import { useAppDispatch } from '../../reducers';
import { useLazyGetUserInfoQuery } from '../../services/endpoints/users';
import { useAcceptCompanyInviteMutation } from '../../services/endpoints/companies';
import RouteConstants from '../router/RouteConstants';
import { showToast } from '../toasts/toastsSlice';

export default function CallbackLanding() {
  const { t } = useTranslation('company');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const type = new URLSearchParams(location.search).get('type');
  const [getUserInfo] = useLazyGetUserInfoQuery();
  const [acceptInvitation] = useAcceptCompanyInviteMutation();

  useEffect(() => {
    console.log('accept invitation');
    const fn = async () => {
      if (!type) {
        history.push(RouteConstants.home);
      } else if (type === 'company-invite') {
        try {
          const userInfo = await getUserInfo().unwrap();
          if (userInfo?.company) {
            // user is already associated with a company, redirect to home
            history.push(RouteConstants.home);
          } else if (userInfo?.onboard?.companyInvitePending) {
            await acceptInvitation().unwrap();
            dispatch(
              showToast({
                type: ToastType.SUCCESS,
                title: t('invite.success_message', { company: userInfo?.company?.name }),
              })
            );
            history.push(RouteConstants.home);
          }
        } catch (e) {
          dispatch(
            showToast({
              type: ToastType.ERROR,
              title: t('invite.error_message'),
            })
          );
          history.push(RouteConstants.home);
        }
      } else if (type === 'password-change') {
        // TODO understand how to login seamlessly after password change flow
        history.push(RouteConstants.home);
      } else {
        history.push(RouteConstants.home);
      }
    };
    fn();
  }, []);

  return <Loading />;
}
