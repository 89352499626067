import { format } from 'date-fns';
import { omit } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/inputs';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import { ExpressionCondition } from '../../../../models/audiences';
import { ISelectItem } from '../../../../models/select';
import { TraitSelect } from '../selects';
import  IdBadgeIcon  from '../../../../assets/icons/id-badge.svg?react';
import { SearchDropDown } from '../../../../components/dropdowns';

interface IPropertyConditionProps {
  expression: ExpressionCondition;
  onChange?: (condition: ExpressionCondition) => void;
  isViewMode?: boolean;
  datasourceIds?: string[];
}

const PropertyCondition: FunctionComponent<IPropertyConditionProps> = ({ expression, isViewMode, onChange, datasourceIds }) => {
  const { t } = useTranslation('audience_edit');

  // const comparisonOp: ISelectItem[] = [
  //   {
  //     id: 'EQUALS',
  //     label: t('rules.equals'),
  //   },
  //   {
  //     id: 'NOT_EQUALS',
  //     label: t('rules.not_equals'),
  //   },
  //   {
  //     id: 'CONTAINS',
  //     label: t('rules.contains'),
  //   },
  //   {
  //     id: 'NULL',
  //     label: t('rules.null'),
  //   },
  //   {
  //     id: 'NOT_NULL',
  //     label: t('rules.not_null'),
  //   },
  //   {
  //     id: 'GT',
  //     label: t('rules.gt'),
  //   },
  //   {
  //     id: 'GTE',
  //     label: t('rules.gte'),
  //   },
  //   {
  //     id: 'LT',
  //     label: t('rules.lt'),
  //   },
  //   {
  //     id: 'LTE',
  //     label: t('rules.lte'),
  //   },
  // ];

  const textComparisonOp: (ISelectItem & { displayName: string })[] = [
    {
      id: 'EQUALS',
      label: t('rules.matches'),
      displayName: t('rules.matches'),
    },
    {
      id: 'NOT_EQUALS',
      label: t('rules.not_matches'),
      displayName: t('rules.not_matches'),
    },
    {
      id: 'CONTAINS',
      label: t('rules.contains'),
      displayName: t('rules.contains'),
    },
    {
      id: 'NULL',
      label: t('rules.null'),
      displayName: t('rules.null'),
      doNotRenderValue: true,
    },
    {
      id: 'NOT_NULL',
      label: t('rules.not_null'),
      displayName: t('rules.not_null'),
      doNotRenderValue: true,
    },
  ];

  const numberComparisonOp: (ISelectItem & { displayName: string })[] = [
    {
      id: 'LT',
      label: t('rules.number.less_than'),
      displayName: t('rules.number.less_than'),
    },
    {
      id: 'LTE',
      label: t('rules.number.less_than_equal'),
      displayName: t('rules.number.less_than_equal'),
    },
    {
      id: 'EQUALS',
      label: t('rules.exactly'),
      displayName: t('rules.exactly'),
    },
    {
      id: 'GT',
      label: t('rules.number.greater_than'),
      displayName: t('rules.number.greater_than'),
    },
    {
      id: 'GTE',
      label: t('rules.number.greater_than_equal'),
      displayName: t('rules.number.greater_than_equal'),
    },
  ];

  const dateComparisonOp: (ISelectItem & { displayName: string })[] = [
    {
      id: 'LT',
      label: t('rules.date.before'),
      displayName: t('rules.date.before'),
    },
    {
      id: 'LTE',
      label: t('rules.date.before_equal'),
      displayName: t('rules.date.before_equal'),
    },
    {
      id: 'EQUALS',
      label: t('rules.exactly'),
      displayName: t('rules.exactly'),
    },
    {
      id: 'GT',
      label: t('rules.date.after'),
      displayName: t('rules.date.after'),
    },
    {
      id: 'GTE',
      label: t('rules.date.after_equal'),
      displayName: t('rules.date.after_equal'),
    },
  ];

  const retrieveComparisonOp = (expression: ExpressionCondition) => {
    switch (expression.dataType) {
      default:
      case 'TEXT':
        return textComparisonOp;
      case 'NUMBER':
        return numberComparisonOp;
      case 'DATE':
        return dateComparisonOp;
    }
  };

  const renderInputFromDataType = (expression: ExpressionCondition) => {
    console.log('expr value', expression.value);
    switch (expression.dataType) {
      default:
      case 'TEXT':
        return (
          <Input
            name='value'
            id='value'
            type='text'
            className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
            value={expression.value}
            isViewMode={isViewMode}
            onChange={(ev) => {
              onChange?.({
                ...expression,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value: ev.target.value,
              });
            }}
          />
        );
      case 'NUMBER':
        return (
          <Input
            name='value'
            id='value'
            type='number'
            className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
            value={expression.value == undefined ? 0 : expression.value}
            isViewMode={isViewMode}
            onChange={(ev) => {
              console.log('ev', ev.target.value, 'ev parsed', parseInt(ev.target.value.replace(/\D/, '')));
              onChange?.({
                ...expression,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value: parseInt(ev.target.value),
              });
            }}
          />
        );
      case 'DATE':
        return (
          <DatePickerInput
            className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
            selected={expression.value ? new Date(expression.value) : undefined}
            dateFormat='P'
            isViewMode={isViewMode}
            onChange={(date) => {
              if (date instanceof Date) {
                onChange?.({
                  ...expression,
                  value: format(date, 'yyyy-MM-dd'),
                });
              }
            }}
          />
        );
    }
  };

  useEffect(() => {
    const comparisonOp = retrieveComparisonOp(expression);
    setComparisonOps(comparisonOp);
    setSelectedComparisonOp(traitDataType === expression.dataType ? comparisonOp.find((op) => op.id === expression.comparisonOp) : null);
    setTraitDataType(expression.dataType);
  }, [expression]);

  const [traitDataType, setTraitDataType] = useState(expression.dataType);
  const [comparisonOps, setComparisonOps] = useState(retrieveComparisonOp(expression));
  const [selectedComparisonOp, setSelectedComparisonOp] = useState<ISelectItem & { displayName: string } | null>();
  const [comparisonQuery, setComparisonQuery] = useState('');
  return (
    <div className='flex w-full items-center'>
      <div className='flex-1 inline-flex mx-2 items-center'>
        <div>
          <IdBadgeIcon className={'w-7 h-7'} />
        </div>
        <div className='text-sm mr-2 font-bold mx-2 block'>{t('step.rules.property.whose_property')}</div>
        <div className=''>
          <TraitSelect
            traitId={expression.property}
            datasourceIds={datasourceIds}
            onChange={(traitItemValue) => {
              onChange?.({
                ...omit(expression, 'comparisonOp', 'value'),
                ...traitItemValue,
                comparisonOp: traitItemValue.dataType === expression.dataType ? expression?.comparisonOp : undefined,
              });
            }}
            isViewMode={isViewMode}
          />
        </div>
        <div className='ml-3'>
          <SearchDropDown
            items={comparisonQuery?.trim() ? comparisonOps.filter((item) => item.displayName.toLowerCase().includes(comparisonQuery?.trim().toLocaleLowerCase())) : comparisonOps}
            item={selectedComparisonOp}
            setItem={(opItemValue) => {
              setSelectedComparisonOp(opItemValue);
              onChange?.({
                ...expression,
                comparisonOp: String(opItemValue.id),
              });
            }}
            setQuery={setComparisonQuery}
            loading={false}
            openWithFocus={true}
            acceptCustomValue={false}
            placeholder={'condition'}
            readOnly={isViewMode}
            className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
          >
            {(item) => <div className={'whitespace-nowrap'}>{item.displayName}</div>}
          </SearchDropDown>
        </div>
        {comparisonOps.find((op) => op.id === expression.comparisonOp)?.doNotRenderValue || (
          <div className='ml-3'>{renderInputFromDataType(expression)}</div>
        )}
      </div>
    </div>
  );
};

export default PropertyCondition;
