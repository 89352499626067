import { FunctionComponent } from 'react';
import { EDataSourceStatus } from '../../models/dataSource';

interface IStatusBadgeProps {
  status: EDataSourceStatus;
}

const StatusBadge: FunctionComponent<IStatusBadgeProps> = ({ status }) => {
  switch (status) {
    case EDataSourceStatus.ACTIVE:
      return <div className='bg-green-100 text-green-800 flex justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4'>Active</div>;

    case EDataSourceStatus.ACTIVATING:
      return <div className='bg-blue-100 text-blue-800 flex justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4'>Activating</div>;

    case EDataSourceStatus.CONFIGURATION_REQUIRED:
      return <div className='bg-red-100 text-red-800 flex justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4'>Configuration required</div>;

    case EDataSourceStatus.PENDING:
      return <div className='bg-yellow-100 text-yellow-800 flex justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4'>Waiting activation</div>;

    case EDataSourceStatus.ERROR:
      return <div className='bg-red-100 text-red-800 flex justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4'>Error</div>;

    default:
      return null;
  }
};

export default StatusBadge;
