import { SaveIcon } from '@heroicons/react/solid';
import { Formik, Form } from 'formik';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components/buttons';
import { Input } from '../../../../../components/inputs';
import { Audience } from '../../../../../models/audiences';

interface IGeneralAudienceSettingsProps {
  audience: Audience;
  isUpdateLoading?: boolean;
  onSubmit: (form: Audience) => void;
}

const GeneralAudienceSettings: FunctionComponent<IGeneralAudienceSettingsProps> = ({ audience, onSubmit, isUpdateLoading = false }) => {
  const { t } = useTranslation('data_source_details');

  return (
    <div>
      <Formik initialValues={{ ...audience }} onSubmit={onSubmit}>
        {({ values, handleChange }) => (
          <Form>
            <h1 className='text-lg text-black-100 font-semibold'>{t('settings.general.general')}</h1>
            <div className='flex justify-start items-end'>
              <div className='w-1/3 mr-4'>
                <Input id='name' name='name' label={t('settings.general.name')} value={values.name} onChange={(e) => handleChange('name')(e.target.value)} />
              </div>

              <div className='w-1/3'>
                <Button type='submit' icon={SaveIcon} loading={isUpdateLoading}>
                  {t('settings.general.save')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeneralAudienceSettings;
