import React, { FunctionComponent } from 'react';
import { NoFileSelected, UploadCompleted, UploadingFile } from '../../..';
import { CSVIcon } from '../../../../../../components/icons';
import { EStatus, IFileUploadStatus } from '../../../../../../models/fileUpload';

interface IFileDataSourceProgressBarProps {
  fileUpload: IFileUploadStatus;
  filename: string;
}

const FileDataSourceProgressBar: FunctionComponent<IFileDataSourceProgressBarProps> = ({ fileUpload, filename }) => {
  switch (fileUpload.status) {
    case EStatus.UPLOADING:
      return <UploadingFile fileUpload={fileUpload} filename={filename} icon={CSVIcon} />;
    case EStatus.SUCCESS:
      return <UploadCompleted filename={filename} />;
    case EStatus.NOT_SELECTED:
      return <NoFileSelected />;
    default:
      return null;
  }
};

export default FileDataSourceProgressBar;
