import { Dialog } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import React, { forwardRef, Ref } from 'react';
import { Button } from '../buttons';

interface IDefaultModalProps {
  closeModal?: () => void;
}

function DefaultModal({ closeModal }: IDefaultModalProps, ref: Ref<HTMLDivElement>) {
  return (
    <div
      ref={ref}
      className='inline-block align-bottom bg-white-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'
    >
      <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
        <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
      </div>
      <div className='mt-3 text-center sm:mt-5'>
        <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
          Payment successful
        </Dialog.Title>
        <div className='mt-2'>
          <p className='text-sm text-gray-500'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.</p>
        </div>
      </div>
      <div className='mt-5 sm:mt-6'>
        <Button className='inline-flex justify-center w-full' onClick={closeModal}>
          Close modal
        </Button>
      </div>
    </div>
  );
}

export default forwardRef(DefaultModal);
