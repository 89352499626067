import { omit } from 'lodash';
import React, { FunctionComponent } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { twMerge } from 'tailwind-merge';

interface IDatePickerInputProps extends ReactDatePickerProps {
    id?: string;
    label?: string;
    className?: string;
    error?: string;
    iconComponent?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    extraInfo?: string;
    isViewMode?: boolean;
}

const DatePickerInput: FunctionComponent<IDatePickerInputProps> = ({ id, name, label, className, onChange, error, extraInfo, isViewMode, ...rest }) => {
    let inputStyle =
        'inline-flex w-full px-2 sm:text-sm border border-gray-300 focus:border-blue-600 focus:border focus:outline-none rounded-md placeholder-gray-400';

    if (rest.icon) {
        inputStyle = twMerge(inputStyle, 'pl-10');
    }

    if (error) {
        inputStyle = twMerge(inputStyle, 'border-red-500 hover:border-red-500 focus:border-red-500 outline-none');
    }

    if (rest.disabled) {
        inputStyle = twMerge(inputStyle, 'bg-gray-200 text-gray-500');
    }

    const handleChange = (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => {
        if (onChange) {
            onChange(date, event);
        }
    };

    return (
        <div className='inline-flex flex-col w-full text-left'>
            {label && (
                <label htmlFor={name} className='block text-sm font-medium text-gray-700 py-2'>
                    {label}
                </label>
            )}
            <div className='relative rounded-md w-auto'>
                {rest.icon && (
                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                        <rest.iconComponent className='mr-4 flex-shrink-0 h-6 w-6 text-gray-500' />
                    </div>
                )}
                <DatePicker
                    {...omit(rest, 'icon')}
                    id={id}
                    name={name}
                    onChange={handleChange}
                    className={className ? twMerge(inputStyle, className) : inputStyle}
                    disabled={rest.disabled || isViewMode}
                />
            </div>
            {extraInfo && <p className='mt-2 text-sm text-gray-800'>{extraInfo}</p>}
            {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
        </div>
    );
};

export default DatePickerInput;
