import { ArrowRightIcon } from '@heroicons/react/solid';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/buttons';
import { Select } from '../../../../components/selects';
import { BaseAudience } from '../../../../models/audiences';
import {
    CyclrActionParameter,
    CyclrActionTriggerValue,
    DistributionChannelContent,
    DistributionChannelParameterUpdate,
} from '../../../../models/distributionChannels';
import { ISelectItem } from '../../../../models/select';
import { useGetAllAudiencesQuery } from '../../../../services/endpoints/audiences';
import {
    useCreateDistributionChannelMutation,
    useDistributionChannelAuthenticatedMutation,
    useUpdateDistributionChannelParametersMutation,
} from '../../../../services/endpoints/distributionChannels';
import { CyclrTemplate, useCyclrTemplatesQuery } from '../../../../services/endpoints/integrations';
import { popupCenter } from '../../../../utils';
import RouteConstants from '../../../router/RouteConstants';
import { useWorkspace } from '../../../workspaces/hooks';
import templateImage from '../template-image';
import GoogleButtonNormal from '../../../../assets/img/btn_google_signin_dark_normal_web@2x.png';
import GoogleButtonDisabled from '../../../../assets/img/btn_google_signin_dark_disabled_web@2x.png';
import { SpinnerIcon } from '../../../../components/icons';
import UserGroupIcon from '../../../../assets/icons/user-group.svg?react';
import { Input } from '../../../../components/inputs';

interface IDestinationStepDestinationProps {
    distributionChannelContent?: DistributionChannelContent;
    nextStep: (p: DistributionChannelContent, isExtended: boolean) => void;
    forceAudience?: BaseAudience;
}

const DestinationStepDestination: FunctionComponent<IDestinationStepDestinationProps> = ({ nextStep, distributionChannelContent, forceAudience }) => {
    const { t } = useTranslation('destinations');
    const workspace = useWorkspace();

    const {
        data: audiences,
        isLoading: audienceIsLoading,
        isUninitialized: audienceIsUninitialized,
    } = useGetAllAudiencesQuery({
        workspaceId: workspace.id,
    });

    const {
        data: templates,
        isLoading: templateIsLoading,
        isUninitialized: templateIsUninitialized,
    } = useCyclrTemplatesQuery({
        workspaceId: workspace.id,
    });

    const [createDistributionChannel, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError, data: createData, error: createError }] =
        useCreateDistributionChannelMutation();

    const [distributionChannelAuthenticated, { isLoading: isGetLoading, isSuccess: isGetSuccess, isError: isGetError, data: getData, error: getError }] =
        useDistributionChannelAuthenticatedMutation();

    const [
        updateDistributionChannelParameters,
        {
            isLoading: isUpdateParametersLoading,
            isSuccess: isUpdateParametersSuccess,
            isError: isUpdateParametersError,
            data: updateParametersData,
            error: updateParametersError,
        },
    ] = useUpdateDistributionChannelParametersMutation();

    const [selectedAudience, setSelectedAudience] = useState<(ISelectItem & BaseAudience) | undefined>(
        forceAudience
            ? {
                ...forceAudience,
                label: forceAudience.name,
                image: <UserGroupIcon className={'w-5 h-5 text-gray-500'} />,
            }
            : undefined
    );
    const [selectedTemplate, setSelectedTemplate] = useState<(ISelectItem & CyclrTemplate) | undefined>(undefined);

    const [status, setStatus] = useState<string>(distributionChannelContent?.integrationsResult.cyclr.status || 'CREATE');
    const [distributionChannel, setDistributionChannel] = useState<DistributionChannelContent | undefined>(distributionChannelContent);
    const [selectedParameters, setSelectedParameters] = useState<{ [id: string]: DistributionChannelParameterUpdate }>({});
    const [triggerParam, setTriggerParam] = useState<{ [paramId: string]: CyclrActionTriggerValue | undefined }>({});

    useEffect(() => {
        const tempDistributionChannel = getData || createData || distributionChannel;
        setDistributionChannel(tempDistributionChannel);
        setStatus(tempDistributionChannel?.integrationsResult.cyclr.status || status);
        // console.log(createData?.integrationsResult.cyclr.status);
        if (tempDistributionChannel?.integrationsResult.cyclr.status === 'AUTHENTICATION_REQUIRED') {
            // window.open(createData.integrationsResult.cyclr.action.authUrl, '__blank');
            const action = tempDistributionChannel.integrationsResult.cyclr.action;
            authenticationScreen(
                `${action.authUrl}?id=${action.accountConnectors[0]}&token=${action.token}&targetOrigin=${location.origin + RouteConstants.cyclrAuthRedirect}`,
                tempDistributionChannel as any
            );
        } else if (tempDistributionChannel?.integrationsResult.cyclr.status === 'ACTIVABLE') {
            nextStep(tempDistributionChannel, !!selectedAudience?.extended);
        }
    }, [createData, getData]);

    useEffect(() => {
        if (updateParametersData) {
            nextStep(updateParametersData, !!selectedAudience?.extended);
        }
    }, [updateParametersData]);

    const authenticationScreen = (url: string, distributionChannel: DistributionChannelContent) => {
        (window as any).cyclrAuthenticationCompleted = (success: boolean, w: Window) => {
            w.close.apply(w);
            delete (window as any).cyclrAuthenticationCompleted;
            distributionChannelAuthenticated({
                workspaceId: workspace.id,
                audienceId: selectedAudience?.id as any,
                distributionChannelId: distributionChannel?.distributionChannel.id as any,
            });
        };
        const newWindow = popupCenter({
            url,
            title: 'Authenticate',
            w: 640,
            h: 640,
        });
        (window as any).authWindow = newWindow;
    };

    const saveDestination = () => {
        createDistributionChannel({
            workspaceId: workspace.id,
            audienceId: selectedAudience?.id as string,
            configData: {
                templateId: selectedTemplate?.Id,
                templateName: selectedTemplate?.Name,
            },
        });
    };

    const updateParameters = () => {
        updateDistributionChannelParameters({
            workspaceId: workspace.id,
            audienceId: selectedAudience?.id as string,
            distributionChannelId: distributionChannel?.distributionChannel.id as string,
            parameters: Object.values(selectedParameters),
        });
    };

    const parameterValueChange = (update: DistributionChannelParameterUpdate) => {
        setSelectedParameters({ ...selectedParameters, [update.parameterId]: update });
    };

    const parameterTemplate = (param: CyclrActionParameter, stepIds?: string[], index = 1) => {
        const translationKey = distributionChannel?.distributionChannel?.name.toLowerCase().replace(/\s+/g, '_');
        const { name, description, triggerParameterLookupValues } = param;
        const paramName = (triggerParameterLookupValues?.trigger?.Name || name).toLowerCase().replace(/\s+/g, '_');
        return (
            <Fragment key={param.parameterId}>
                <div className={'border-solid border border-gray-200 rounded-lg h-auto px-6 py-4 mt-6 text-left font-medium text-gray-800 shadow-sm'}>
                    {paramName && <h3 className={'mb-0 font-semibold w-full md:w-2/3 lg:w-1/2'}>{t(`edit.cyclr.${translationKey}.${paramName}` as any)}</h3>}
                    {param.triggerParameterLookupValues?.values?.length == 0 && (
                        <p className={'mt-5 text-gray-500 italic text-base font-normal w-full md:w-2/3 lg:w-1/2'}>
                            {t(`edit.cyclr.${translationKey}.${paramName}_empty` as any)}
                        </p>
                    )}
                    {param.mappingType !== null ? (
                        <Fragment>
                            {param.triggerParameterLookupValues?.values.map((lookupValue) => (
                                <div key={lookupValue.value}>
                                    <label className={'flex items-center pt-6 font-normal'}>
                                        <input
                                            type={'radio'}
                                            className={'mr-3'}
                                            name={param.parameterId + '_' + index}
                                            value={lookupValue.value}
                                            onChange={() => {
                                                if (lookupValue.nextTrigger) {
                                                    setTriggerParam({
                                                        ...triggerParam,
                                                        [index]: lookupValue,
                                                    });
                                                } else {
                                                    parameterValueChange({
                                                        isAccountConnectorParameter: param.isAccountConnectorParameter,
                                                        accountConnectorId: param.accountConnectorId,
                                                        propertyId: param.propertyId,
                                                        stepIds: stepIds || param.stepIds,
                                                        parameterId: param.parameterId,
                                                        mappingType: param.mappingType,
                                                        value: lookupValue.value,
                                                        name: lookupValue.name,
                                                    });
                                                }
                                            }}
                                        />
                                        {lookupValue.name}
                                    </label>
                                </div>
                            ))}
                        </Fragment>
                    ) : (
                        <Input
                            className={'mt-6 mb-4 w-full md:w-2/3 lg:w-1/2'}
                            onChange={(e) => {
                                parameterValueChange({
                                    isAccountConnectorParameter: param.isAccountConnectorParameter,
                                    accountConnectorId: param.accountConnectorId,
                                    propertyId: param.propertyId,
                                    stepIds: param.stepIds,
                                    parameterId: param.parameterId,
                                    mappingType: 'StaticValue',
                                    value: e.target.value,
                                    name: null,
                                });
                            }}
                        />
                    )}
                </div>

                {triggerParam[index]?.nextTrigger &&
                    parameterTemplate(
                        {
                            triggerParameterLookupValues: {
                                values: triggerParam[index]?.nextTrigger?.values,
                            },
                            name: triggerParam[index]?.nextTrigger?.trigger.Name,
                            description: '',
                            parameterId: param.parameterId,
                            mappingType: param.mappingType,
                        } as any,
                        stepIds || param.stepIds,
                        index + 1
                    )}
            </Fragment>
        );
    };

    const statusTemplate = () => {
        let action;
        switch (status) {
            case 'PARAMETERS_REQUIRED':
                return <div className={'mt-6'}>{distributionChannel?.integrationsResult.cyclr.action.parameters.map((param) => parameterTemplate(param))}</div>;
            case 'AUTHENTICATION_REQUIRED':
                action = distributionChannel?.integrationsResult.cyclr.action;
                return action && <div></div>;
            default:
            case 'CREATE':
                return (
                    <div className={'border-solid border border-gray-200 rounded-lg h-auto p-6 mt-6 text-left text-gray-500'}>
                        {t('edit.step.destination.select_destination')}
                    </div>
                );
        }
    };

    const actionButton = () => {
        switch (status) {
            case 'CREATE':
                if (selectedTemplate?.Name === 'Google Ads')
                    return (
                        <button onClick={saveDestination} disabled={!selectedAudience || !selectedTemplate} className={'bg-white border-0'}>
                            {isCreateLoading ? (
                                <div className={'w-full h-full flex justify-center items-center rounded relative cursor-not-allowed'}>
                                    <SpinnerIcon className='h-6 w-6' loading />
                                </div>
                            ) : (
                                <img className={'h-12'} src={!selectedAudience || !selectedTemplate ? GoogleButtonDisabled : GoogleButtonNormal} />
                            )}
                        </button>
                    );
                else
                    return (
                        <Button onClick={saveDestination} disabled={!selectedAudience || !selectedTemplate} loading={isCreateLoading}>
                            {t('edit.step.destination.save')}
                        </Button>
                    );

            case 'PARAMETERS_REQUIRED':
                return (
                    <Button
                        onClick={updateParameters}
                        loading={isUpdateParametersLoading}
                        disabled={
                            !!distributionChannel?.integrationsResult.cyclr.action.parameters.find((param) => {
                                return !selectedParameters[param.parameterId] || !selectedParameters[param.parameterId].value;
                            })
                        }
                    >
                        {t('edit.step.next')}
                    </Button>
                );
            case 'AUTHENTICATION_REQUIRED':
                return (
                    <div className='flex'>
                        <span className='text-gray-800 italic mr-3'>{t('edit.step.destination.authenticating')}</span>
                        <SpinnerIcon className='h-6 w-6' loading />
                    </div>
                );
            default:
                return <Button>{t('edit.step.next')}</Button>;
        }
    };

    const connectorImageOrTemplateImage = (template: CyclrTemplate) => {
        const connector = template.Connectors.find((item) => item.Name !== 'Generic Webhook');
        if (connector && connector.Icon) {
            return <img className={'w-5'} src={connector.Icon} />;
        } else {
            return templateImage(template.Name);
        }
    };

    return (
        <Fragment>
            <div className={'p-6 flex-grow'}>
                <div className='text-left'>
                    <h3 className='text-black text-base font-bold mb-1'>{t('edit.step.destination.title')}</h3>
                    <p className='mb-5 text-base'>{t('edit.step.destination.text')}</p>
                </div>
                <div className='flex w-full'>
                    <div className='w-1/2'>
                        <Select
                            loading={audienceIsLoading}
                            isViewMode={!!distributionChannel || isCreateLoading}
                            disabled={!!forceAudience}
                            items={
                                audiences?.audiences.map<ISelectItem & BaseAudience>((audience) => ({
                                    ...audience,
                                    label: audience.name,
                                    image: <UserGroupIcon className={'w-5 h-5 text-gray-500'} />,
                                })) || []
                            }
                            className='w-full text-left'
                            value={selectedAudience}
                            onChange={(value) => setSelectedAudience(value as ISelectItem & BaseAudience)}
                        />
                    </div>
                    <ArrowRightIcon height={'20px'} className={'px-5 mt-2 text-gray-500'} />
                    <div className='w-1/2'>
                        <Select
                            loading={templateIsLoading}
                            isViewMode={!!distributionChannel || isCreateLoading}
                            items={
                                templates?.map<ISelectItem & CyclrTemplate>((template) => ({
                                    ...template,
                                    id: template.Id,
                                    label: template.Name,
                                    image: connectorImageOrTemplateImage(template),
                                })) || []
                            }
                            className='w-full text-left'
                            value={selectedTemplate}
                            onChange={(value) => setSelectedTemplate(value as ISelectItem & CyclrTemplate)}
                        />
                    </div>
                </div>
                {statusTemplate()}
            </div>
            <div className='flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg'>{actionButton()}</div>
        </Fragment>
    );
};

export default DestinationStepDestination;
