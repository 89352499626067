import { isEmpty } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SpinnerIcon } from '../../components/icons';
import Paginated from '../../components/tables/pagination/Paginated';
import { useDelayedBoolean } from '../../hooks/useDelayedBoolean';
import { DistributionChannel } from '../../models/distributionChannels';
import { RootState } from '../../reducers';
import { useLazyGetDistributionChannelsQuery } from '../../services/endpoints/distributionChannels';
import { MainContentWrapper } from '../layout/main';
import { useWorkspace } from '../workspaces/hooks';
import DestinationList from './DestinationList';
import { setPagination } from './destinationsSlice';

interface IDestinationsProps { }

const MINIMUM_LOADING_DURATION = 400;

const Destinations: FunctionComponent<IDestinationsProps> = () => {
    const workspace = useWorkspace();

    const searchText = useSelector((state: RootState) => state.destinations.ui.searchText);

    const [getDistributionChannels, { data, isLoading, isFetching, isUninitialized }] = useLazyGetDistributionChannelsQuery();
    const [destinations, setDestinations] = useState<DistributionChannel[]>([]);

    const isLoadingDelayed = useDelayedBoolean(isLoading, MINIMUM_LOADING_DURATION);
    const shouldShowLoading = isLoading || isLoadingDelayed || isUninitialized;

    const queryCallback = useCallback(
        (pageNo: number, pageSize: number) =>
            getDistributionChannels({
                workspaceId: workspace.id,
                offset: pageNo * pageSize,
                limit: pageSize,
                searchText: searchText && searchText.length > 2 ? searchText : undefined,
            }),
        [searchText]
    );

    return (
        <Paginated
            stateSelector={(state) => state.destinations.ui.pagination}
            stateUpdater={setPagination}
            query={queryCallback}
            queryResponse={data ? data : undefined}
            setItems={setDestinations}
            querying={isFetching}
        >
            {(pager) => {
                return shouldShowLoading ? (
                    <MainContentWrapper>
                        <span className='text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0'>
                            <SpinnerIcon className='-ml-1 mr-3 h-5 w-5' loading />
                        </span>
                    </MainContentWrapper>
                ) : (
                    <DestinationList destinations={destinations} pager={pager} />
                );
            }}
        </Paginated>
    );
};

export default Destinations;
