import { ChevronRightIcon } from '@heroicons/react/outline';
import { isEmpty } from 'lodash';
import { Fragment, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { concatClassNames } from '../../../utils';

export type Breadcrumb = {
  label: string;
  path?: string;
};

interface IBreadcrumbProps {
  items: Breadcrumb[];
}

const Breadcrumbs: FunctionComponent<IBreadcrumbProps> = ({ items }) => {
  const history = useHistory();

  const handleClick = (path: string) => {
    history.push(path);
  };

  return (
    <>
      {!isEmpty(items)
        ? items.map((item, i, arr) => {
            let style = 'text-xl text-gray-900';

            const isLastItem = arr.length - 1 === i && i > 0;
            const isUniqueItem = i === 0 && arr.length === 1;

            if (i === 0) {
              style = concatClassNames(style, 'ml-4 md:ml-6');
            }

            if (isLastItem) {
              style = concatClassNames(style, 'text-blue-600');
            }

            return (
              <Fragment key={item.label}>
                <h1
                  className={item.path ? concatClassNames(style, 'cursor-pointer') : style}
                  onClick={item.path ? () => handleClick(item.path || '') : undefined}
                >
                  {item.label}
                </h1>
                {!isLastItem && !isUniqueItem ? <ChevronRightIcon className='flex-shrink-0 h-5 w-5 text-gray-400 mx-2' aria-hidden='true' /> : null}
              </Fragment>
            );
          })
        : items.map((item) => {
            return (
              <h1 key={item.label} className='text-gray-900 text-xl ml-4 md:ml-6'>
                {item.label}
              </h1>
            );
          })}
    </>
  );
};

export default Breadcrumbs;
