import React, { FunctionComponent, useState } from 'react';
import { ExclamationIcon, IconContainer, SpinnerIcon } from '../../icons';
import { Button } from '../../buttons';
import { concatClassNames } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { IFileUploadStatus } from '../../../models/fileUpload';

interface IUploadingStatus {
  fileUpload: IFileUploadStatus;
  handleCancel: () => void;
}

const UploadingStatus: FunctionComponent<IUploadingStatus> = ({ fileUpload, handleCancel }) => {
  const [isCancelClicked, setCancelClicked] = useState(false);

  const { t } = useTranslation('data_source_edit');

  return (
    <div className='w-full mt-1 sm:mt-0 sm:col-span-2 '>
      <div
        className={concatClassNames(
          'flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md h-52',
          isCancelClicked ? 'border-gray-200 bg-gray-50' : 'border-blue-300 bg-blue-50'
        )}
      >
        {isCancelClicked ? (
          <div className='space-y-1 text-center m-auto'>
            <div className='flex text-center justify-center h-7 mb-2'>
              <IconContainer icon={ExclamationIcon} path='assets/fileuploading/exclamation.svg' />
            </div>
            <div className='flex flex-col'>
              <p className='bg-whitpe rounded-md font-medium text-xl text-blue-900 mb-3'>{t('file.name_and_file.file_upload_input_component.cancel_upload')}</p>
              <br />
              <div>
                <Button variant='delete' onClick={handleCancel} className='mr-2'>
                  {t('file.name_and_file.file_upload_input_component.delete_file')}
                </Button>
                <Button variant='transparent' onClick={() => setCancelClicked(false)} className='text-blue-700'>
                  {t('file.name_and_file.file_upload_input_component.resume_upload')}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className='space-y-1 text-center m-auto'>
            <div className='flex text-center justify-center h-7 mb-2'>
              <SpinnerIcon className='h-8 w-8' fill='rgba(21,68,155,1)' loading />
            </div>
            <div className='flex flex-col'>
              <p className='bg-white rounded-md font-medium text-xl text-blue-900 mb-3'>
                {`${t('file.name_and_file.file_upload_input_component.uploading')} ${fileUpload.percentage.percentageCompleted} %...`}
              </p>
              <br />
              <Button variant='light' onClick={() => setCancelClicked(true)}>
                {t('file.name_and_file.file_upload_input_component.cancel_upload')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadingStatus;
