import React, { PropsWithChildren } from 'react';
import { DataSourceType } from '../../models/dataSource';
import { IconContainer } from '../icons';

// base type for simple list structure
type SimpleItem = {
  id: string;
  name: string;
  description?: string;
  type?: DataSourceType;
};

export interface ISimpleListProps<T> {
  items: T[];
  rightElement?: (item: T) => JSX.Element;
  onClick?: (item: T) => void;
}

const SimpleList = <T extends SimpleItem>({ items, rightElement, onClick }: PropsWithChildren<ISimpleListProps<T>>) => {
  return (
    <div className='bg-white-100 shadow overflow-hidden sm:rounded-md'>
      <ul role='list' className='divide-y divide-gray-200'>
        {items.map((item) => (
          <li key={item.id} className={onClick ? 'cursor-pointer hover:bg-gray-50' : ''} onClick={() => onClick?.(item)}>
            <div className='px-4 py-4 flex items-center sm:px-6'>
              <div className='min-w-0 flex-1 sm:flex sm:items-center sm:justify-between'>
                <div className='truncate'>
                  <div className='flex text-sm items-center'>
                    {item.type && (
                      <div className='w-14 h-14 rounded-lg flex flex-wrap content-center justify-center mr-2'>
                        <IconContainer path={item.type.logoUrl} className='h-10' />
                      </div>
                    )}
                    <p className='font-medium text-blue-600 truncate'>{item.name}</p>
                  </div>
                </div>
              </div>
              <div className='ml-5 flex-shrink-0'>
                {rightElement?.(item)}
                {/* <ChevronRightIcon className='h-5 w-5 text-gray-400' aria-hidden='true' /> */}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SimpleList;
