import { FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Step } from "../../../../../components/steppers";
import { DataSourceCrmConfig, DataSourceType, TDataSource } from "../../../../../models/dataSource";
import { RootState, useAppDispatch } from "../../../../../reducers";
import { stashDataSource } from "../../../../dataSources/dataSourcesSlice";
import { ECrmSteps } from "../../../constants";
import { DataSourceCrmCreateFormModel, ICrmFields, ICrmParameters } from "../../../types";
import { CrmDataSourceCreateForm } from "./";
import CrmDataSourceFieldsForm from "./CrmDataSourceFieldsForm";
import CrmDataSourceParametersForm from "./CrmDataSourceParametersForm";

interface ICrmDataSourceContentProps {
  dataSourceType: DataSourceType;
  step?: Step;
  dataSourceInfoFormRef: React.RefObject<FormikProps<DataSourceCrmCreateFormModel>>;
  crmParametersFormRef: React.RefObject<FormikProps<ICrmParameters>>;
  crmFieldsFormRef: React.RefObject<FormikProps<ICrmFields>>;
}

const CrmDataSourceContent: FunctionComponent<ICrmDataSourceContentProps> = (
  {
    dataSourceType,
    step,
    dataSourceInfoFormRef,
    crmParametersFormRef,
    crmFieldsFormRef
  }
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("data_source_edit");

  // switch base on data source type category here!
  const dataSource = useSelector((state: RootState) => state.dataSources.edit?.dataSource) as Partial<TDataSource<DataSourceCrmConfig>> | undefined;
  const parameters = useSelector((state: RootState) => state.dataSourceIntegration.parameters);
  const fields = (useSelector((state: RootState) => state.dataSourceIntegration.fields) || []).filter(field => field.checked);

  const handleSubmit = async (form: DataSourceCrmCreateFormModel) => {
    if (form.name) {
      dispatch(
        stashDataSource({
          ...form,
          name: form.name,
          configuration: { ...form.configuration },
          type: dataSourceType
        })
      );
    }
  };

  switch (step?.id) {
    case ECrmSteps.SOURCE_DETAILS:
      return (
        <div className="border-solid border border-gray-200 rounded-lg h-auto p-6 mt-6">
          <CrmDataSourceCreateForm
            onSubmit={handleSubmit}
            dataSourceInfoFormRef={dataSourceInfoFormRef}
            dataSource={{ ...dataSource, type: dataSourceType }}
          />
        </div>
      );
    case ECrmSteps.UPDATE_PARAMETERS:
      return (
        <div className="h-auto p-6 mt-6">
          <CrmDataSourceParametersForm dataSourceParametersFormRef={crmParametersFormRef} />
        </div>
      );
    case ECrmSteps.UPDATE_FIELDS:
      return (
        <div className="border-solid border border-gray-200 rounded-lg h-auto p-6 mt-6">
          <CrmDataSourceFieldsForm dataSourceFieldsFormRef={crmFieldsFormRef} />
        </div>
      );
    case ECrmSteps.CHECK_INSTALLATION:
      return (
        <div className="relative border-solid border border-gray-200 rounded-lg h-auto p-6 mt-6 text-left">
          <h1 className="text-base font-bold text-gray-900 mb-2 leading-5">{t("crm.check_installation.title")}</h1>
          <p className="text-sm font-normal text-gray-600 leading-5">{t("crm.check_installation.source_name")}: {dataSource?.name}</p>
          <br />
          <p className="text-sm font-semibold text-gray-600 leading-5">{t("crm.check_installation.parameters")}:</p>
          <ul className="list-disc list-inside">
            {(parameters && parameters.length) ? parameters.map(param => (
              <li key={param.parameterId} className="text-sm font-normal text-gray-600 leading-5">
                {param.description}: {param.name}
              </li>
            )) : (
              <li className="text-sm font-normal text-gray-600 leading-5">{t("crm.check_installation.no_parameters")}</li>
            )}
          </ul>
          <br />
          <p className="text-sm font-semibold text-gray-600 leading-5">{t("crm.check_installation.fields")}:</p>
          <ul className="list-disc list-inside">
            {(fields && fields.length) ? fields.map(field => (
              <li key={field.fieldId} className="text-sm font-normal text-gray-600 leading-5">
                {field.name} {field.description ? `(${field.description})` : ""}
              </li>
            )) : (
              <li className="text-sm font-normal text-gray-600 leading-5">{t("crm.check_installation.no_fields")}</li>
            )}
          </ul>
        </div>
      );
    default:
      return null;
  }
};

export default CrmDataSourceContent;
