import { Form, Formik, FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Audience } from "../../../../models/audiences";
import { RootState } from "../../../../reducers";
import { useGetWorkspaceUsageSummaryQuery } from "../../../../services/endpoints/workspaces";
import { useWorkspace } from "../../../workspaces/hooks";
import AudienceSyncConfig from "./AudienceSyncConfig";
import { validateSchedule } from "./validation";

export type AudienceSyncFormModel = Pick<Audience, "scheduleType" | "scheduleConfiguration">;

interface IAudienceSyncContainerProps {
  formRef: React.RefObject<FormikProps<AudienceSyncFormModel>>;
}

const AudienceSyncContainer: FunctionComponent<IAudienceSyncContainerProps> = ({ formRef }) => {
  const workspace = useWorkspace();
  const audience = useSelector((state: RootState) => state.audienceEdit.audience);
  const { t } = useTranslation("audience_edit");

  const { isFetching, data: workspaceUsageSummary } = useGetWorkspaceUsageSummaryQuery({ workspaceId: workspace.id });

  const onSubmit = () => {
    console.log("submit sync");
  };

  return (
    <div className="text-left">
      <h3 className="text-black text-base font-bold mb-1">Sync</h3>
      <p className="mb-5 text-base text-gray-600 w-1/2">
        Decide when and how much your audience needs to update, based on your specific needs.
      </p>

      <Formik
        innerRef={formRef}
        initialValues={{
          scheduleType: audience?.scheduleType || "MANUAL",
          scheduleConfiguration: audience?.scheduleConfiguration || {}
        }}
        validate={values => validateSchedule(values, t)}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <AudienceSyncConfig
              isFetchingSummary={isFetching}
              realTimeAudienceLeft={workspaceUsageSummary?.realTimeAudienceLeft || 0}
              scheduleType={values.scheduleType}
              scheduleConfiguration={values.scheduleConfiguration}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />

            {/*<AudienceSyncAdvancedOptions
                scheduleType={values.scheduleType}
                scheduleConfiguration={values.scheduleConfiguration}
                setFieldValue={setFieldValue}
              />*/}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AudienceSyncContainer;
