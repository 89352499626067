import { XIcon } from '@heroicons/react/outline';
import { filter, isEmpty, map, reduce, values } from 'lodash';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConditionSelector, ExpressionConditionRender } from '..';
import { Expression, ExpressionCondition } from '../../../../models/audiences';
import { AudienceRule, Condition } from '../../../../models/rules';
import { RootState, useAppDispatch } from '../../../../reducers';
import { usePostEstimateMutation } from '../../../../services/endpoints/audiences';
import { useWorkspace } from '../../../workspaces/hooks';
import { stashAudience } from '../../audienceEditSlice';
import { RefreshIcon } from '@heroicons/react/solid';
import { showToast } from '../../../toasts/toastsSlice';
import { ToastType } from '../../../../models/toast';

interface IExpressionContainerProps { }

function mapRulesToAudienceExpression(rules: AudienceRule[]): Record<string, Expression> {
    return reduce<AudienceRule, Record<string, Expression>>(
        rules,
        (result, rule, ruleIndex) => {
            return {
                ...result,
                [String(ruleIndex)]: reduce(
                    rule.expressions,
                    (result, expression, expressionIndex) => {
                        return {
                            ...result,
                            [String(expressionIndex)]: expression,
                        };
                    },
                    {}
                ),
            };
        },
        {}
    );
}

function mapAudienceExpressionToRules(expression?: Record<string, Expression>): AudienceRule[] {
    return map(values(expression), (exp, index) => {
        return {
            id: String(index),
            expressions: values(exp),
        };
    });
}

const ExpressionContainer: FunctionComponent<IExpressionContainerProps> = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('audience_edit');

    const audience = useSelector((state: RootState) => state.audienceEdit.audience);
    const workspace = useWorkspace();

    const [rules, setRules] = useState<AudienceRule[]>(mapAudienceExpressionToRules(audience?.expression));
    const [canEvaluate, setEvaluate] = useState(false);

    const [getEstimate, { isLoading, data, isSuccess, isError }] = usePostEstimateMutation();

    useEffect(() => {
        if (audience?.id && audience?.expression) {
            getEstimate({
                workspaceId: workspace.id,
                audienceId: audience.id,
                audience: {
                    expression: audience.expression,
                },
            });
        }
    }, []);

    const handleAddConditionButtonClicked = (ruleId: string, condition: Condition) => {
        setRules(
            map(rules, (r) =>
                r.id === ruleId
                    ? {
                        ...r,
                        expressions: [
                            ...r.expressions,
                            {
                                type: condition.kind === 'EVENT' || condition.kind === 'VISIT' ? 'ACTION' : condition.kind,
                                kind: condition.kind === 'EVENT' || condition.kind === 'VISIT' ? condition.kind : undefined,
                            },
                        ],
                    }
                    : r
            )
        );
    };

    const handleRemoveExpressionButtonClicked = (ruleId: string, expressionId: number) => {
        setRules(
            filter(
                map(rules, (r) =>
                    r.id === ruleId
                        ? {
                            ...r,
                            expressions: filter(r.expressions, (_, index) => index !== expressionId),
                        }
                        : r
                ),
                (r) => !isEmpty(r.expressions)
            )
        );
    };

    const handleOnNewRuleSelected = (condition: Condition) => {
        setRules([
            ...rules,
            {
                id: String(rules.length + 1),
                expressions: [
                    {
                        type: condition.kind === 'EVENT' || condition.kind === 'VISIT' ? 'ACTION' : condition.kind,
                        kind: condition.kind === 'EVENT' || condition.kind === 'VISIT' ? condition.kind : undefined,
                        // special case based on condition type here
                    },
                ],
            },
        ]);
    };

    const handleExpressionChanged = (ruleId: string, expression: ExpressionCondition, expressionId: number) => {
        setRules(
            filter(
                map(rules, (r) =>
                    r.id === ruleId
                        ? {
                            ...r,
                            expressions: map(r.expressions, (exp, index) => (index === expressionId ? expression : exp)),
                        }
                        : r
                ),
                (r) => !isEmpty(r.expressions)
            )
        );
    };

    useEffect(() => {
        dispatch(
            stashAudience({
                ...audience,
                expression: mapRulesToAudienceExpression(rules),
            })
        );

        setEvaluate(true);
    }, [rules]);

    useEffect(() => {
        if (!isLoading && (isSuccess || isError)) {
            setEvaluate(false);
        }
    }, [isLoading, isSuccess, isError]);

    const handleEvaluateClicked = () => {
        if (audience?.id && audience?.expression) {
            const isErrorArray = Object.values(audience.expression).flatMap((or) => {
                return Object.values(or).map((rule) => {
                    switch (rule.type) {
                        case 'PROPERTY':
                            return rule.comparisonOp !== 'NULL' && rule.comparisonOp !== 'NOT_NULL' && (rule.value === null || rule.value === '');
                        default:
                            return false;
                    }
                });
            });
            if (isErrorArray.find((e) => e)) {
                dispatch(
                    showToast({
                        type: ToastType.WARNING,
                        title: t('step.audience.evaluation.invalid_rules'),
                        message: t('step.audience.evaluation.invalid_rules_message'),
                    })
                );
            } else {
                getEstimate({
                    workspaceId: workspace.id,
                    audienceId: audience.id,
                    audience: {
                        expression: audience.expression,
                    },
                });
            }
        }
    };

    return (
        <div>
            <div className='flex place-content-between items-center'>
                <div className={'w-1/2 md:w-1/3'}>
                    <h3 className='text-base font-bold text-left'>{t('step.rules.title')}</h3>
                    <p className={'text-left'}>{t('step.rules.description')}</p>
                </div>
                <div className='flex flex-col items-end justify-end w-1/2 flex group'>
                    <h3 className={'font-bold text-right'}>{t('step.rules.recalculate_title')}</h3>
                    <div className={'flex align-center'}>
                        <button
                            className={`bg-transparent border-0 group-hover:inline-block ${!isLoading ? 'hidden' : 'inline-block'} text-blue-500`}
                            onClick={handleEvaluateClicked}
                            title={t(canEvaluate ? 'rules.buttons.recalculate' : 'rules.buttons.modify_to_recalculate')}
                        >
                            <RefreshIcon className={`w-4 h-4 ${isLoading && 'animate-spin'}`} />
                        </button>
                        <p className='text-4xl text-blue-900 font-bold ml-2'>{data?.size || 0}</p>
                    </div>
                </div>
            </div>
            <div className={'mt-16 border rounded-lg shadow pb-2'}>
                <table className='table-fixed w-full'>
                    <colgroup>
                        <col style={{ width: '90px' }} />
                    </colgroup>
                    <tbody>
                        {rules.map((rule, i) => {
                            return (
                                <Fragment key={i}>
                                    {rule.expressions.map((expression, j) => {
                                        return (
                                            <tr key={j} className='p-3 items-center w-full'>
                                                <td>
                                                    <div className={`text-sm font-bold text-gray-900 text-right mr-6 mb-3 ${j === 0 && 'mt-5'}`}>
                                                        {j === 0 ? t(i === 0 ? 'rules.users_who' : 'rules.and') : t('rules.or')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={`flex mb-3 border shadow p-3 rounded-md mr-4 ${j === 0 && 'mt-5'}`}>
                                                        <ExpressionConditionRender
                                                            expression={expression}
                                                            datasourceIds={audience?.datasourceIds}
                                                            onChange={(exp) => handleExpressionChanged(rule.id, exp, j)}
                                                        />
                                                        <button
                                                            className='rounded-md text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-700'
                                                            onClick={() => handleRemoveExpressionButtonClicked(rule.id, j)}
                                                        >
                                                            <XIcon className='h-6 w-6' aria-hidden='true' />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr className='w-full p-3 items-center'>
                                        <td>
                                            <div className='text-sm font-bold text-gray-900 text-right mr-6 mb-3'>{t('rules.or')}</div>
                                        </td>
                                        <td>
                                            <div className={'mb-3'}>
                                                <ConditionSelector
                                                    onConditionSelected={(condition) => handleAddConditionButtonClicked(rule.id, condition)}
                                                    datasourceIds={audience?.datasourceIds}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                        <tr className='table-row flex mb-4 items-center'>
                            <td>
                                <div className={`text-gray-900 font-bold text-right mr-6 mb-3 mt-5`}>{t(isEmpty(rules) ? 'rules.users_who' : 'rules.and')}</div>
                            </td>
                            <td className='space-y-2 w-full'>
                                <div className={`mb-3 mt-5`}>
                                    <ConditionSelector onConditionSelected={handleOnNewRuleSelected} isFirstCondition={isEmpty(rules)} datasourceIds={audience?.datasourceIds} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ExpressionContainer;
