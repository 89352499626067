import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TrackPageVisitView, TrackUserClickView } from "..";
import { DataSourceCodeConfig, TDataSource } from "../../../../models/dataSource";
import { useLazyGetDataSourceEventsQuery } from "../../../../services/dataSources";
import { useLazyGetRulesByDataSourceIdQuery } from "../../../../services/endpoints/rules";
import { mapRulesToEvents } from "../../../dataSourceEdit/components/categories/code/tracking/utils";
import { useWorkspace } from "../../../workspaces/hooks";
import { DataSourceEventList } from "../lists";

interface ICodeDataSourceDataProps {
  dataSource?: TDataSource<DataSourceCodeConfig>;
}

const CodeDataSourceData: FunctionComponent<ICodeDataSourceDataProps> = ({ dataSource }) => {
  const { t } = useTranslation("data_source_details");

  const workspace = useWorkspace();

  const [getDataSourceEvents, { data: realTimeEvents }] = useLazyGetDataSourceEventsQuery();
  const [getDataSourceRules, { data: rules }] = useLazyGetRulesByDataSourceIdQuery();

  useEffect(() => {
    if (dataSource) {
      getDataSourceEvents({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id
      });

      getDataSourceRules({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id
      });
    }
  }, []);

  const events = mapRulesToEvents(rules || [], `${dataSource?.configuration?.url}/` || "");

  return (
    <div className="flex flex-col">
      <div className="flex-1">
        <div className="flex flex-col py-8">
          <h1 className="text-base font-bold text-gray-900 mb-4 leading-4">{t("data.events.title")}</h1>
          <div className="flex flex-col 3xl:flex-row 3xl:space-x-4 space-y-4 3xl:space-y-0">
            <div className="flex-shrink 3xl:flex-shrink-0">
              <TrackPageVisitView dataSource={dataSource} events={events.pageVisitEvents} />
            </div>
            <div className="flex-shrink 3xl:flex-shrink-0">
              <TrackUserClickView dataSource={dataSource} events={events.clickEvents} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="py-8">
          <h1 className="text-base font-bold text-gray-900 mb-4 leading-4">{t("data.debugger.title")}</h1>
          <DataSourceEventList events={realTimeEvents} />
        </div>
      </div>
    </div>
  );
};

export default CodeDataSourceData;
