import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BootstrapState {
  isLoading: boolean;
  didInit: boolean;
}

export const initialState: BootstrapState = {
  isLoading: false,
  didInit: false,
};

const bootstrapSlice = createSlice({
  name: 'bootstrap',
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<BootstrapState['isLoading']>) {
      state.isLoading = payload;
    },
    setDidInit(state, { payload }: PayloadAction<BootstrapState['didInit']>) {
      state.didInit = payload;
    },
  },
});

export default bootstrapSlice.reducer;
export const { setLoading, setDidInit } = bootstrapSlice.actions;
