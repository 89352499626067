import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { Input } from "../../../components/inputs";
import { SpinnerIcon } from "../../../components/icons";
import { Button } from "../../../components/buttons";
import { FunctionComponent, useEffect } from "react";
import { useInviteWorkspaceMutation } from "../../../services/endpoints/workspaces";
import { Workspace } from "../../../models/workspace";

type Role = "ADMIN" | "ACCOUNTING" | "READONLY";

type RoleItem = {
  id: Role;
  label: string;
};

interface INewCompanyInviteRowProps {
  onComplete: (saved: boolean) => void;
  workspace: Workspace;
}

const NewWorkspaceInviteRow: FunctionComponent<INewCompanyInviteRowProps> = ({ onComplete, workspace }) => {
  const [inviteCompanyUser, { data, isLoading, isError }] = useInviteWorkspaceMutation();

  useEffect(() => {
    if (data && !isLoading && !isError) {
      onComplete(false);
    }
  }, [data, isLoading, isError]);

  const roleItems: RoleItem[] = [
    {
      id: "ADMIN",
      label: "Admin"
    },
    {
      id: "READONLY",
      label: "Readonly"
    },
    {
      id: "ACCOUNTING",
      label: "Accounting"
    }
  ];

  const validationSchema = Yup.object({
    email: Yup.string().email("Not a valid email").required("Email is required"),
    role: Yup.string().oneOf(["ADMIN", "READONLY", "ACCOUNTING"], "Invalid role")
  });

  const handleSaveButtonClicked = async ({ email, role }: FormikValues) => {
    inviteCompanyUser({
      workspaceId: workspace.id,
      collaborator: {
        email,
        role
      }
    });
  };

  const handleCancelButtonClicked = () => {
    onComplete(false);
  };

  return (
    <Formik initialValues={{ email: "", role: roleItems[0].id }} validationSchema={validationSchema}
      onSubmit={handleSaveButtonClicked}>
      {({ values, handleChange, errors }) => (
        <tr className="bg-white-100 border-b border-gray-200">
          <td colSpan={3} className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
            <Form>
              <div className="flex flex-row">
                <div className="flex flex-row space-x-2 w-1/3">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 py-2">
                    Email:
                  </label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="john.doe@company.com"
                    value={values.email}
                    onChange={(e) => handleChange("email")(e.target.value)}
                    error={errors.email}
                    disabled={isLoading}
                  />
                </div>
                <div className="flex flex-row items-start space-x-2 w-1/3 ml-3">
                  <label htmlFor="role" className="block text-sm font-medium text-gray-700 py-2">
                    Role:
                  </label>
                  <select
                    id="role"
                    name="role"
                    className="block focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md text-sm"
                    onChange={(e) => handleChange("role")(e.target.value)}
                    disabled={isLoading}
                  >
                    {roleItems.map((role) => (
                      <option key={role.id} value={role.id}>{role.label}</option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-row justify-end ml-auto">
                  {isLoading && (
                    <span className="text-blue-500 opacity-75 my-2 relative">
                      <SpinnerIcon className="mr-1 h-5 w-5" loading />
                    </span>
                  )}

                  <Button variant="primary" type="submit" className="inline-flex justify-center py-2 px-4"
                    disabled={isLoading} >
                    Save
                  </Button>
                  <Button
                    variant="light"
                    type="button"
                    className="ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
                    disabled={isLoading}
                    onClick={handleCancelButtonClicked}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </td>
        </tr>
      )}
    </Formik>
  );
};

export default NewWorkspaceInviteRow;
