import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import React, { Fragment, FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/buttons';
import { DataSourceCodeConfig, TDataSource } from '../../../models/dataSource';
import { useSaveRulesMutation } from '../../../services/endpoints/rules';
import { customValidation, mapEventsToRules } from '../../dataSourceEdit/components/categories/code/tracking/utils';
import { ITrackWebsiteValue } from '../../dataSourceEdit/types';
import { useWorkspace } from '../../workspaces/hooks';

interface ITrackEventCountProps {
    eventCountFn: (values: ITrackWebsiteValue) => number;
    handleEventCountChange: (eventCount: number) => void;
}

const TrackEventCount: FunctionComponent<PropsWithChildren<ITrackEventCountProps>> = ({ eventCountFn, handleEventCountChange, children }) => {
    const { values } = useFormikContext<ITrackWebsiteValue>();
    const eventCountRef = useRef<number>(0);

    useEffect(() => {
        const currentEventCount = eventCountFn(values);
        if (eventCountRef.current != currentEventCount) {
            eventCountRef.current = currentEventCount;
            handleEventCountChange(currentEventCount);
        }
    }, [values]);

    return <Fragment>{children}</Fragment>;
};

interface ITrackRuleFormProps {
    dataSource?: TDataSource<DataSourceCodeConfig>;
    currentEventCount: number;
    newEventCountFn: (values: ITrackWebsiteValue) => number;
    children: (props: FormikProps<ITrackWebsiteValue>) => React.ReactNode;
}

const TrackRuleForm: FunctionComponent<ITrackRuleFormProps> = ({ dataSource, currentEventCount, newEventCountFn, children }) => {
    const { t } = useTranslation('data_source_edit');

    const workspace = useWorkspace();

    const initialValues: ITrackWebsiteValue = { clickEvents: [], pageVisitEvents: [] };
    const formikFormRef = useRef<FormikProps<ITrackWebsiteValue>>(null);

    const [newEventCount, setNewEventCount] = useState<number>(0);
    const newEventCountRef = useRef<number>(0);
    const targetEventCountRef = useRef<number>(0);

    const [saveRules, { isUninitialized: isSaveUninitialized, isLoading: isSaveLoading, isSuccess: isSaveSuccess, isError: isSaveError }] =
        useSaveRulesMutation();

    const handleEventCountChange = (currentEventCount: number) => {
        newEventCountRef.current = currentEventCount;
        setNewEventCount(currentEventCount);
    };

    useEffect(() => {
        if (!isSaveUninitialized && !isSaveLoading && isSaveSuccess && currentEventCount === targetEventCountRef.current) {
            formikFormRef.current?.resetForm();
        } else if (!isSaveUninitialized && !isSaveLoading && isSaveError) {
            window.alert(t('code.track_events.save_error'));
        }
    }, [isSaveUninitialized, isSaveLoading, isSaveSuccess, isSaveError, currentEventCount]);

    const handleOnSubmit = () => {
        console.debug('On submit');
    };

    const handleCancelButtonClicked = async () => {
        formikFormRef.current?.resetForm();
    };

    const handleSaveButtonClicked = async () => {
        await formikFormRef.current?.submitForm();
        const events = formikFormRef.current?.values;

        console.log(formikFormRef.current?.isValid, formikFormRef.current?.errors);

        if (formikFormRef.current?.isValid && events) {
            targetEventCountRef.current = currentEventCount + newEventCountRef.current;
            submitRules(events);
        }
    };

    const submitRules = (events: ITrackWebsiteValue) => {
        const rules = mapEventsToRules(events);

        if (dataSource && dataSource.id) {
            const dataSourceId = dataSource.id;

            // adding new rules (without id)
            const createRules = rules.filter((rule) => rule.id === undefined);

            if (createRules) {
                saveRules({
                    workspaceId: workspace.id,
                    dataSourceId: dataSourceId,
                    rules: createRules,
                });
            }
        }
    };

    return (
        <Fragment>
            <Formik
                onSubmit={handleOnSubmit}
                validate={(values) => customValidation(values, t)}
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
                innerRef={formikFormRef}
            // validationSchema={validationSchema}
            >
                {(props) => {
                    return (
                        <TrackEventCount eventCountFn={newEventCountFn} handleEventCountChange={handleEventCountChange}>
                            <Form className='space-y-8'>{children(props)}</Form>
                        </TrackEventCount>
                    );
                }}
            </Formik>

            <div className='flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg'>
                <Button
                    variant='primary'
                    type='submit'
                    className='inline-flex justify-center py-2 px-4'
                    onClick={handleSaveButtonClicked}
                    loading={isSaveLoading}
                    disabled={newEventCount === 0}
                >
                    {t('code.track_events.buttons.save')}
                </Button>
                <Button
                    variant='light'
                    type='button'
                    className='ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'
                    onClick={handleCancelButtonClicked}
                    disabled={newEventCount === 0 || isSaveLoading}
                >
                    {t('code.track_events.buttons.cancel')}
                </Button>
            </div>
        </Fragment>
    );
};

export default TrackRuleForm;
