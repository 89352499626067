import { FunctionComponent } from 'react';
import getYear from 'date-fns/getYear';
import ChatFloatingActionButton from '../../../components/buttons/ChatFloatingButtonAction';

const Footer: FunctionComponent = () => {
    const chatEnabled = import.meta.env.VITE_FEATURES_ENABLED?.includes('chat');

    return (
        <footer className='bg-white-100'>
            {chatEnabled && <ChatFloatingActionButton />}
            <div className='py-4 px-4 sm:px-6 md:flex md:items-center justify-end lg:px-8'>
                <div className='mt-4 md:mt-0 md:order-1 hidden md:flex md:flex-shrink-0'>
                    <p className='text-center text-sm text-gray-400'>&copy; {getYear(new Date())} CDP - Audiencerate Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
