const templateImage = (name: string) => {
  switch (name) {
    case 'MAILCHIMP':
    case 'MailChimp':
    case 'Mailchimp':
    case 'mailchimp':
    case 'Send Audiences to MailChimp':
      return <img src={'/assets/datasourcetypes/mailchimp-logo.svg'} className={'w-5'} />;
    case 'facebook':
    case 'Facebook':
    case 'FACEBOOK':
      return <img src={'/assets/datasourcetypes/facebook.svg'} className={'w-5'} />;
    case 'googleads':
    case 'googleAds':
    case 'GoogleAds':
    case 'GOOGLEADS':
    case 'Google Ads':
    case 'Send Audiences to Google Ads':
      return <img src={'/assets/datasourcetypes/googlads.svg'} className={'w-5'} />;
    case 'salesforce':
    case 'Salesforce':
    case 'SALESFORCE':
    case 'Salesforce Destination':
      return <img src={'/assets/datasourcetypes/salesforce.svg'} className={'w-5'} />;
    case 'webhook':
    case 'Webhook':
    case 'WEBHOOK':
      return <img src={'/assets/datasourcetypes/webhook.svg'} className={'w-5'} />;
    default:
      return null;
  }
};

export default templateImage;
