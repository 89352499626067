import { CheckIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React, { FunctionComponent, useState } from 'react';
import { Button } from '../../../../components/buttons';
import { IconContainer } from '../../../../components/icons';
import { DataSourceRequestedConfig, DataSourceType, RequestedDataSourceType } from '../../../../models/dataSource';
import { concatClassNames, isDataSourceType } from '../../../../utils';

interface IDataSourceTypeCardHeaderProps {
  dataSourceType: DataSourceType | RequestedDataSourceType;
}

const DataSourceTypeCardHeader: FunctionComponent<IDataSourceTypeCardHeaderProps> = ({ dataSourceType }) => {
  const [isUpvoted, setUpvoted] = useState(false);

  // switch base on data source type category here!
  const configuration = dataSourceType?.configuration as DataSourceRequestedConfig | undefined;

  const handleUpvote = () => {
    // increment or decrement vote
    // temp useState
    setUpvoted(!isUpvoted);
  };

  return (
    <div className='bg-white-100 px-4 py-5 sm:px-6 flex space-x-3 rounded-lg shadow-md'>
      <div className='flex flex-1'>
        <div className='flex-shrink-0 mr-3'>
          <div className='w-14 h-14 rounded-lg flex flex-wrap content-center justify-center'>
            <IconContainer path={dataSourceType.logoUrl} className='h-12' />
          </div>
        </div>
        <div className='flex flex-wrap content-center'>
          <div className='flex-col'>
            <p className='text-base font-medium text-gray-900'>{dataSourceType.name}</p>
          </div>
        </div>
      </div>
      {!isDataSourceType(dataSourceType) ? (
        <div className='flex flex-1 justify-end m-auto'>
          <Button
            variant='light'
            onClick={handleUpvote}
            icon={isUpvoted ? CheckIcon : ChevronUpIcon}
            className={concatClassNames('border-2 rounded-md shadow-sm py-4 h-10', isUpvoted ? 'border-green-100 text-green-700' : 'border-gray-300')}
          >
            {configuration?.upvotes}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default DataSourceTypeCardHeader;
