import { FunctionComponent } from "react";
import { DataSource, EDataSourceCategory } from "../../../../models/dataSource";
import { CodeOverview, CrmOverview, FileOverview } from "./overview";

interface IDataSourceOverviewProps {
  dataSource?: DataSource;
}

const DataSourceOverview: FunctionComponent<IDataSourceOverviewProps> = ({ dataSource }) => {
  switch (dataSource?.type.category) {
    case EDataSourceCategory.CODE:
      return <CodeOverview dataSource={dataSource} />;
    case EDataSourceCategory.FILE:
      return <FileOverview dataSource={dataSource} />;
    case EDataSourceCategory.CRM:
      return <CrmOverview dataSource={dataSource} />;
    default:
      return <div></div>;
  }
};

export default DataSourceOverview;
