import { FieldArray, Form, Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";
import React, { Fragment, FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { ICrmParameter, ICrmParameters } from "../../../types";

interface ICrmDataSourceParametersFormProps {
  dataSourceParametersFormRef: React.RefObject<FormikProps<ICrmParameters>>;
}

const CrmDataSourceParametersForm: FunctionComponent<ICrmDataSourceParametersFormProps> = ({ dataSourceParametersFormRef }) => {
  const paramDefs = useSelector((state: RootState) => state.dataSourceIntegration.parameterDefinitions);

  const storedParams = useSelector((state: RootState) => state.dataSourceIntegration.parameters);
  const storedParamMap = new Map<number, ICrmParameter>(storedParams.map(param => [param.parameterId, param]));

  const params: ICrmParameter[] = paramDefs.map(paramDef => storedParamMap.has(paramDef.parameterId) ? ({
    ...(storedParamMap.get(paramDef.parameterId) as ICrmParameter)
  }) : ({
    ...paramDef,
    value: null
  }));

  const onSubmit = () => {
    console.debug("On parameters submit");
  };

  return (
    <Formik
      innerRef={dataSourceParametersFormRef}
      initialValues={{ parameters: params }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <FieldArray
              name="parameters"
              validateOnChange={false}
              render={(clickEventHelper) => (
                <Fragment>
                  {
                    clickEventHelper && !isEmpty(clickEventHelper) && values.parameters.map((param, index) => (
                      <div
                        key={param.parameterId}
                        className="border-solid border border-gray-200 rounded-lg h-auto px-6 py-4 mt-6 text-left font-medium bg-gray-50"
                      >
                        <h3 className="mb-3">{param.description}</h3>
                        {param.lookupValues.map((lookupValue) => (
                          <div key={lookupValue.Value} className={"flex items-center py-1"}>
                            <label>
                              <input
                                type={"radio"}
                                className={"mr-3"}
                                name={param.parameterId + ""}
                                value={lookupValue.Value}
                                checked={param.value === lookupValue.Value}
                                onChange={() => setFieldValue(
                                  `parameters.${index}`,
                                  { ...param, name: lookupValue.Name, value: lookupValue.Value }
                                )}
                              />
                              {lookupValue.Name}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))
                  }
                </Fragment>
              )}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CrmDataSourceParametersForm;
