import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SidebarState = {
  show: boolean;
};

export const initialState: SidebarState = {
  show: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean>) => ({ ...state, show: action.payload })
  },
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
