import { PayloadAction } from "@reduxjs/toolkit";
import React, { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SearchInput } from "../../../../components/tables/search";
import { BaseAudience } from "../../../../models/audiences";
import { RootState } from "../../../../reducers";
import AudienceTable from "./AudienceTable";

interface IAudienceListProps {
  audiences: BaseAudience[];
  showSync: boolean;
  searchStateSelector: (state: RootState) => string;
  searchStateUpdater: (searchText: string) => PayloadAction<string>;
  pager: React.ReactNode;
}

const AudienceList: FunctionComponent<IAudienceListProps> = ({ audiences, showSync, searchStateSelector, searchStateUpdater, pager }) => {
  const { t } = useTranslation("audiences");
  return (
    <Fragment>
      <SearchInput
        stateSelector={searchStateSelector}
        stateUpdater={searchStateUpdater}
        placeholder={t("search_placeholder")}
      />
      <AudienceTable audiences={audiences} showSync={showSync} />
      {pager}
    </Fragment>
  );
};

export default AudienceList;
