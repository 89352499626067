import { Fragment, FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';
import { Audiences } from '.';
import { AudienceDetails } from '../audienceDetails';
import { PrivateRoute } from '../router/components';

const AudiencesRouter: FunctionComponent = () => (
  <Fragment>
    <Switch>
      <PrivateRoute path='/audiences/:id' component={AudienceDetails} />
      <PrivateRoute path='/audiences' component={Audiences} />
    </Switch>
  </Fragment>
);

export default AudiencesRouter;
