import { FunctionComponent } from "react";
import { DataSource } from "../../../../../../models/dataSource";
import { CommonActiveDataSource } from "../common";

interface IActiveCodeDataSourceProps {
  dataSource?: DataSource;
}

const ActiveCodeDataSource: FunctionComponent<IActiveCodeDataSourceProps> = ({ dataSource }) => {
  return dataSource ? (<CommonActiveDataSource dataSource={dataSource} polling={true} />) : (<div />);
};

export default ActiveCodeDataSource;
