import { omit } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface IPrefixInputProps {
  id?: string;
  type?: 'text' | 'number' | 'email' | 'password' | 'file' | 'submit';
  label?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  prefix?: string;
  disabled?: boolean;
  extraInfo?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  isViewMode?: boolean;
}

type TInputType = IPrefixInputProps & React.ComponentProps<'input'>;

const PrefixInput: FunctionComponent<TInputType> = ({
  id,
  type = 'text',
  name,
  label,
  prefix,
  className,
  onChange,
  error,
  placeholder,
  extraInfo,
  isViewMode,
  ...rest
}) => {
  const [inputFocus, setInputFocus] = useState(false);

  let inputStyle = 'inline-flex w-full py-2 px-4 sm:text-sm border border-gray-300 rounded-md placeholder-gray-400 bg-white-100';

  if (rest.icon) {
    inputStyle = twMerge(inputStyle, 'pl-10');
  }

  if (error) {
    inputStyle = twMerge(inputStyle, 'border-red-500 hover:border-red-500 focus:border-red-500 outline-none');
  }

  if (rest.disabled) {
    inputStyle = twMerge(inputStyle, 'bg-gray-200 text-gray-500');
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  const focusStyle = 'border border-blue-600 outline-none ring-1 ring-blue-600';
  const inputClassname = className ? twMerge(inputStyle, className) : inputStyle;

  return (
    <div className='inline-flex flex-col w-full text-left'>
      {label && (
        <label htmlFor={name} className='block text-sm font-medium text-gray-700 py-2'>
          {label}
        </label>
      )}

      <div className={inputFocus ? twMerge(inputClassname, focusStyle) : inputClassname}>
        {prefix && <span className='text-gray-500 text-sm flex-none'>{prefix}</span>}
        <input
          {...omit(rest, 'icon')}
          id={id}
          name={name}
          type={type}
          onChange={handleChange}
          placeholder={placeholder}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          className='p-0 leading-none bg-transparent border-none focus:ring-0 text-sm flex-auto'
          disabled={rest.disabled || isViewMode}
        />
      </div>
      {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
      {extraInfo && !error && <p className='mt-2 text-sm text-gray-500'>{extraInfo}</p>}
    </div>
  );
};

export default PrefixInput;
