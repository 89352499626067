import React, { Fragment, FunctionComponent } from 'react';
import { DistributionChannel } from '../../../../models/distributionChannels';
import templateImage from '../template-image';
import { format } from 'date-fns';
import { ArrowRightIcon, TrashIcon, UserGroupIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { useGetDistributionChannelLastTransactionQuery } from '../../../../services/endpoints/distributionChannels';
import { useWorkspace } from '../../../workspaces/hooks';
import { SpinnerIcon } from '../../../../components/icons';
import { TimeAgo } from "../../../../components/time-ago";

interface IDestinationRowProp {
  id: string;
  destination: DistributionChannel;
  prevDestination?: DistributionChannel;
  onDelete?: (destination: DistributionChannel) => void;
}

const DestinationRow: FunctionComponent<IDestinationRowProp> = ({ id, destination, onDelete, prevDestination }) => {
  const history = useHistory();
  const workspace = useWorkspace();

  const query = useGetDistributionChannelLastTransactionQuery({
    workspaceId: workspace.id,
    audienceId: destination.audienceId,
    distributionChannelId: destination.id,
  });

  const goToAudience = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    history.push('/audiences/' + destination.audience.id);
  };
  return (
    <tr id={id} className={`bg-white-100 ${prevDestination && destination.audience.id !== prevDestination?.audience.id && 'border-t border-gray-200'}`}>
      <td className='px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
        {(!prevDestination || destination.audience.id !== prevDestination.audience.id) && (
          <div className='py-3 text-sm font-medium text-gray-900'>
            <div className={'inline-block align-middle mr-3 text-gray-500'}>
              <UserGroupIcon className={'w-4'} />
            </div>
            <a href={'/audiences/' + destination.audience.id} className={'hover:underline'} onClick={goToAudience}>
              {destination.audience.name}
            </a>
          </div>
        )}
      </td>
      <td className='px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
        <div className={'relative'}>
          <ArrowRightIcon className={'w-4 text-gray-500 absolute'} style={{ left: '-45px', top: '15px' }} />
          <div className='py-3 text-sm font-medium text-gray-900'>
            <div className={'inline-block align-middle mr-3'}>{templateImage(destination.name)}</div>
            {destination.name}
          </div>
        </div>
      </td>
      <td className='px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
        <div className='py-3 text-sm font-medium text-gray-900'>
          {destination.destination ? (
            <Fragment>
              <div className={'inline-block align-middle mr-3 text-gray-500'}>
                <UserGroupIcon className={'w-4'} />
              </div>
              {destination.destination}
            </Fragment>
          ) : (
            '-'
          )}
        </div>
      </td>
      <td className='px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
        <p className='py-3 text-sm font-medium text-gray-900 w-full'>
          {query.isLoading && query.isFetching ? (
            <div className={'w-full text-center'}>
              <SpinnerIcon className='absolute h-4 w-4' loading />
            </div>
          ) : (
            query.data?.LastRunTimestamp && <TimeAgo time={query.data.LastRunTimestamp} />
          )}
        </p>
      </td>
      <td className='px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
        <p className='py-3 text-sm font-medium text-gray-900'>{format(new Date(destination.createdOn), 'yyyy-MM-dd')}</p>
      </td>
      {/*<td className='px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
        <p className='py-3 text-sm font-medium text-gray-900'>{''}</p>
      </td>*/}
      {onDelete && (
        <td className='text-center'>
          <TrashIcon className='h-6 w-6 text-red-600 mx-auto cursor-pointer' onClick={() => onDelete(destination)} />
        </td>
      )}
    </tr>
  );
};

export default DestinationRow;
