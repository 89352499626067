import { FunctionComponent } from 'react';
import { DataSource, EDataSourceCategory, TDataSource, DataSourceFileConfig, DataSourceCodeConfig } from '../../models/dataSource';
import { ETABS, TabType } from '../../models/tabs';
import { DataSourceOverview, FileDataSourceData, CodeDataSourceData, DataSourceSettings } from './components';

interface ITabContentProps {
  tabs: TabType[];
  selectedDataSource?: DataSource;
}

const DataSourceTabContent: FunctionComponent<ITabContentProps> = ({ tabs, selectedDataSource }) => {
  const currentTab = tabs.find((tab) => tab.current);

  switch (currentTab?.id) {
    case ETABS.OVERVIEW:
      return <DataSourceOverview dataSource={selectedDataSource} />;

    case ETABS.DATA:
      switch (selectedDataSource?.type.category) {
        case EDataSourceCategory.FILE:
          return <FileDataSourceData dataSource={selectedDataSource as TDataSource<DataSourceFileConfig>} />;

        case EDataSourceCategory.CODE:
          return <CodeDataSourceData dataSource={selectedDataSource as TDataSource<DataSourceCodeConfig>} />;

        default:
          return null;
      }

    case ETABS.SETTINGS:
      return <DataSourceSettings dataSource={selectedDataSource} />;

    default:
      return null;
  }
};

export default DataSourceTabContent;
