import { FunctionComponent } from 'react';
import { DataSource } from '../../../../../../models/dataSource';
import { ActivationStatus } from '../../../status';

interface IPendingFileDataSourceProps {
  dataSource?: DataSource;
}

const PendingFileDataSource: FunctionComponent<IPendingFileDataSourceProps> = ({ dataSource }) => {
  return (
    <div className='flex'>
      <ActivationStatus dataSource={dataSource} />
    </div>
  );
};

export default PendingFileDataSource;
