import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Input, PrefixInput } from "../../../components/inputs";
import { DataSourceCodeConfig, TDataSource } from "../../../models/dataSource";
import { TrackPageVisit, TrackPageVisitPanel } from "../../dataSourceEdit/components/categories/code/tracking";
import { ITrackPageVisitEvents } from "../../dataSourceEdit/types";
import TrackRuleForm from "./TrackRuleForm";
import { Button } from "../../../components/buttons";
import { TrashIcon } from "@heroicons/react/solid";
import { useDeleteRuleMutation } from "../../../services/endpoints/rules";
import { useWorkspace } from "../../workspaces/hooks";

interface ITrackPageVisitViewProps {
  dataSource?: TDataSource<DataSourceCodeConfig>;
  events: ITrackPageVisitEvents[];
  className?: string;
}

const TrackPageVisitView: FunctionComponent<ITrackPageVisitViewProps> = ({ dataSource, events, className }) => {
  const { t } = useTranslation("data_source_edit");
  const workspace = useWorkspace();

  const [deleteRule] = useDeleteRuleMutation();

  const handleDeleteRule = (ruleId: string) => {
    if (ruleId && dataSource && workspace) {
      deleteRule({
        workspaceId: workspace.id,
        ruleId,
        dataSourceId: dataSource.id,
      });
    }
  };

  return (
    <TrackPageVisitPanel className={className}>
      <div className="mt-4">
        {events?.map((event, index) => (
          <div key={index} className="flex px-4 my-2">
            <div className="flex-auto w-1/2">
              <PrefixInput value={event.pagePath} prefix={event.baseUrl} isViewMode={true} />
            </div>
            <div className="flex w-1/2">
              <span className="py-2 ml-2 mr-1">{t("code.track_events.creates")}</span>
              <div className="flex-auto">
                <Input placeholder={t("code.track_events.name_of_the_event")} value={event.eventName} isViewMode={true} />
              </div>
              <div className='flex items-center'>
                <TrashIcon className={'cursor-pointer hover:text-red-900 h-6 w-6 text-red-500 ml-3'} onClick={() => handleDeleteRule(event.id)} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <TrackRuleForm
        dataSource={dataSource}
        currentEventCount={events?.length || 0}
        newEventCountFn={values => values.pageVisitEvents.length}
      >
        {({ values, setFieldValue, setFieldError, errors }) => {
          return (
            <TrackPageVisit
              values={values}
              handleSetFieldValue={setFieldValue}
              handleSetFieldError={setFieldError}
              handleDeleteRule={handleDeleteRule}
              errors={errors}
              dataSource={dataSource}
            />
          );
        }}
      </TrackRuleForm>
    </TrackPageVisitPanel>
  );
};

export default TrackPageVisitView;
