import { differenceInMinutes, differenceInSeconds, format as formatFn, formatDistanceToNow } from 'date-fns';
import { FunctionComponent } from 'react';

type TimeAgoProps = {
  time: string | number | Date | null | undefined;
  thresholdInMinutes?: number;
  format?: string;
};

const TimeAgo: FunctionComponent<TimeAgoProps> = ({ thresholdInMinutes = 5, time, format = 'yyyy-MM-dd HH:mm' }) => {
  if (time) {
    if (typeof time === 'string' || typeof time === 'number') {
      try {
        time = new Date(time);
      } catch (e) {
        return null;
      }
    }
    const createdAgo = differenceInMinutes(Date.now(), time);
    if (createdAgo <= thresholdInMinutes) {
      return (
        <span className={'text-green-500'}>
          <b>Added</b> {formatDistanceToNow(time)} ago
        </span>
      );
    } else {
      return <>{formatFn(time, format)}</>;
    }
  }
  return <></>;
};

export default TimeAgo;
