import { format } from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/inputs';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import { ExpressionCondition } from '../../../../models/audiences';
import { EventSelect } from '../selects';
import { SearchDropDown } from '../../../../components/dropdowns';
import HandPointerIcon from '../../../../assets/icons/hand-pointer.svg?react';
import EyeIcon from '../../../../assets/icons/eye.svg?react';

interface IActionConditionProps {
    expression: ExpressionCondition;
    onChange?: (condition: ExpressionCondition) => void;
    isViewMode?: boolean;
}

const ActionCondition: FunctionComponent<IActionConditionProps> = ({ expression, isViewMode, onChange }) => {
    const { t } = useTranslation('audience_edit');

    const comparisonOp: { id: string; displayName: string }[] = [
        {
            id: 'AT_LEAST',
            displayName: t('rules.at_least'),
        },
        {
            id: 'EXACTLY',
            displayName: t('rules.exactly'),
        },
        {
            id: 'AT_MOST',
            displayName: t('rules.at_most'),
        },
        {
            id: 'NEVER',
            displayName: t('rules.no_times'),
        },
    ];

    const temporalHorizonOp: { id: string; displayName: string }[] = [
        {
            id: 'WITHIN',
            displayName: t('rules.within'),
        },
        {
            id: 'IN_BETWEEN',
            displayName: t('rules.in_between'),
        },
    ];

    const temporalHorizonUnits: { id: string; displayName: string }[] = [
        {
            id: 'DAY',
            displayName: t('rules.days'),
        },
        {
            id: 'MONTH',
            displayName: t('rules.months'),
        },
        {
            id: 'YEAR',
            displayName: t('rules.years'),
        },
    ];

    const [comparisonSearchQuery, setComparisonSearchQuery] = useState('');
    const [temporalHorizonSearchQuery, setTemporalHorizonSearchQuery] = useState('');
    const [temporalHorizonUnitsSearchQuery, setTemporalHorizonUnitsSearchQuery] = useState('');

    return (
        <div className='flex w-full items-center'>
            <div className='inline-flex mx-2 items-center'>
                <span className='text-sm mr-2 whitespace-nowrap'>
                    {expression.kind === 'EVENT' ? (
                        <span className={'font-bold'}>
                            <HandPointerIcon className={'w-6 h-6 inline-block mr-2'} /> {t('rules.have_performed')}
                        </span>
                    ) : (
                        <span className={'font-bold'}>
                            <EyeIcon className={'w-6 h-6 inline-block mr-2'} /> {t('rules.have_visited')}
                        </span>
                    )}
                </span>
                <div className=''>
                    <EventSelect
                        ruleId={expression.dataSourceRuleId}
                        type={expression.kind === 'EVENT' ? 'TRACK' : 'VIEW'}
                        onChange={(ruleItemValue) => {
                            onChange?.({
                                ...expression,
                                dataSourceRuleId: String(ruleItemValue.id),
                            });
                        }}
                        isViewMode={isViewMode}
                    />
                </div>
            </div>
            <div className='mx-2 inline-flex items-center'>
                <div className=''>
                    <SearchDropDown
                        items={
                            comparisonSearchQuery?.trim()
                                ? comparisonOp.filter((item) => item.displayName.toLowerCase().includes(comparisonSearchQuery?.trim().toLowerCase()))
                                : comparisonOp
                        }
                        item={comparisonOp.find((op) => op.id === expression.occurrences?.comparisonOp)}
                        setItem={(opItemValue) => {
                            onChange?.({
                                ...expression,
                                occurrences: {
                                    ...expression.occurrences,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    comparisonOp: String(opItemValue.id),
                                },
                            });
                        }}
                        setQuery={setComparisonSearchQuery}
                        loading={false}
                        readOnly={isViewMode}
                        openWithFocus={true}
                        className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
                    >
                        {(item) => <div className={'whitespace-nowrap'}>{item.displayName}</div>}
                    </SearchDropDown>
                </div>
            </div>
            {expression.occurrences?.comparisonOp !== 'NEVER' && (
                <div className='inline-flex mx-2 items-center'>
                    <Input
                        name='times'
                        id='times'
                        type='number'
                        className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
                        value={expression.occurrences?.times}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(ev) => {
                            onChange?.({
                                ...expression,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                occurrences: {
                                    ...expression.occurrences,
                                    times: parseInt(ev.target.value.replace(/\D/, '')),
                                },
                            });
                        }}
                        isViewMode={isViewMode}
                    />
                    <span className='text-sm text-left mx-2'>{t('rules.times')}</span>
                    <SearchDropDown
                        items={
                            temporalHorizonSearchQuery?.trim()
                                ? temporalHorizonOp.filter((item) => item.displayName.toLowerCase().includes(temporalHorizonSearchQuery?.trim().toLowerCase()))
                                : temporalHorizonOp
                        }
                        item={temporalHorizonOp.find((op) => op.id === expression.temporalHorizon?.type)}
                        setItem={(opItemValue) => {
                            onChange?.({
                                ...expression,
                                temporalHorizon: {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    type: String(opItemValue.id),
                                },
                            });
                        }}
                        setQuery={setTemporalHorizonSearchQuery}
                        loading={false}
                        readOnly={isViewMode}
                        openWithFocus={true}
                        className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
                    >
                        {(item) => <div className={'whitespace-nowrap'}>{item.displayName}</div>}
                    </SearchDropDown>
                </div>
            )}

            {expression.occurrences?.comparisonOp !== 'NEVER' && expression.temporalHorizon?.type == 'WITHIN' && (
                <div className='inline-flex mx-2 items-center'>
                    <div className='w-20 mr-2'>
                        <Input
                            name='value'
                            id='value'
                            type='number'
                            className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
                            value={expression.temporalHorizon?.value}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(ev) => {
                                onChange?.({
                                    ...expression,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    temporalHorizon: {
                                        ...expression.temporalHorizon,
                                        value: parseInt(ev.target.value.replace(/\D/, '')),
                                    },
                                });
                            }}
                            isViewMode={isViewMode}
                        />
                    </div>
                    <div className='w-full'>
                        <SearchDropDown
                            items={
                                temporalHorizonUnitsSearchQuery?.trim()
                                    ? temporalHorizonUnits.filter((item) => item.displayName.toLowerCase().includes(temporalHorizonUnitsSearchQuery?.trim().toLowerCase()))
                                    : temporalHorizonUnits
                            }
                            item={temporalHorizonUnits.find((op) => op.id === expression.temporalHorizon?.unit)}
                            setItem={(opItemValue) => {
                                onChange?.({
                                    ...expression,
                                    temporalHorizon: {
                                        ...expression.temporalHorizon,
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        unit: String(opItemValue.id),
                                    },
                                });
                            }}
                            setQuery={setTemporalHorizonUnitsSearchQuery}
                            loading={false}
                            readOnly={isViewMode}
                            openWithFocus={true}
                            className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
                        >
                            {(item) => <div className={'whitespace-nowrap'}>{item.displayName}</div>}
                        </SearchDropDown>
                    </div>
                </div>
            )}
            {expression.occurrences?.comparisonOp !== 'NEVER' && expression.temporalHorizon?.type == 'IN_BETWEEN' && (
                <div className='inline-flex mx-2 items-center'>
                    <div className='w-full mr-2'>
                        <DatePickerInput
                            selected={expression.temporalHorizon?.from ? new Date(expression.temporalHorizon?.from) : undefined}
                            dateFormat='P'
                            onChange={(date) => {
                                if (date instanceof Date) {
                                    onChange?.({
                                        ...expression,
                                        temporalHorizon: {
                                            ...expression.temporalHorizon,
                                            from: format(date, 'yyyy-MM-dd'),
                                        },
                                    });
                                }
                            }}
                            isViewMode={isViewMode}
                        />
                    </div>
                    <div className='w-full'>
                        <DatePickerInput
                            selected={expression.temporalHorizon?.to ? new Date(expression.temporalHorizon?.to) : undefined}
                            dateFormat='P'
                            onChange={(date) => {
                                if (date instanceof Date) {
                                    onChange?.({
                                        ...expression,
                                        temporalHorizon: {
                                            ...expression.temporalHorizon,
                                            to: format(date, 'yyyy-MM-dd'),
                                        },
                                    });
                                }
                            }}
                            isViewMode={isViewMode}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActionCondition;
