import { FunctionComponent } from 'react';
import { DataSource } from '../../../../../../models/dataSource';
import { ActivationStatus } from '../../../status';

interface IPendingCrmDataSourceProps {
  dataSource?: DataSource;
}

const PendingCrmDataSource: FunctionComponent<IPendingCrmDataSourceProps> = ({ dataSource }) => {
  return (
    <div className='flex'>
      <ActivationStatus dataSource={dataSource} />
    </div>
  );
};

export default PendingCrmDataSource;
