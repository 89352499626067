import { Form, Formik, FormikProps } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TrackCustomEvents, TrackPageVisit, TrackPageVisitPanel, TrackUserClick, TrackUserClickPanel } from ".";
import { DataSourceCodeConfig, TDataSource } from "../../../../../../models/dataSource";
import { RootState } from "../../../../../../reducers";
import { useDeleteRuleMutation } from "../../../../../../services/endpoints/rules";
import { useWorkspace } from "../../../../../workspaces/hooks";
import { ITrackWebsiteValue } from "../../../../types";
import { customValidation, mapRulesToEvents } from "./utils";

interface ITrackWebsiteActivityProps {
  handleOnSubmit: () => void;
  trackWebsiteActivityFormRef: React.RefObject<FormikProps<ITrackWebsiteValue>>;
}

const TrackWebsiteActivity: FunctionComponent<ITrackWebsiteActivityProps> = ({ trackWebsiteActivityFormRef, handleOnSubmit }) => {
    console.log("TrackWebsiteActivity");
  const { t } = useTranslation("data_source_edit");

  const workspace = useWorkspace();

  const dataSource = useSelector((state: RootState) => state.dataSources.edit?.dataSource) as Partial<TDataSource<DataSourceCodeConfig>> | undefined;
  const rules = useSelector((state: RootState) => state.rules.rulesList);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteRule, _] = useDeleteRuleMutation();

  const initialValues: ITrackWebsiteValue = { clickEvents: [], pageVisitEvents: [] };

  if (rules) {
    const events = mapRulesToEvents(rules, `${dataSource?.configuration?.url}/` || "");
    initialValues.clickEvents = [...events.clickEvents];
    initialValues.pageVisitEvents = [...events.pageVisitEvents];
  }

  const handleDeleteRule = (ruleId: string) => {
    if (ruleId && dataSource && dataSource.id) {
      deleteRule({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
        ruleId: ruleId
      });
    }
  };

  return (
    <div className="text-left">
      <h1 className="text-gray-900 font-semibold text-sm mb-2">{t("code.track_events.track_website_activity")}</h1>
      <h2 className="text-gray-600 font-normal text-sm mb-8">{t("code.track_events.user_perform_actions")}</h2>

      <Formik
        onSubmit={handleOnSubmit}
        validate={(values) => customValidation(values, t)}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        // validationSchema={validationSchema}
        innerRef={trackWebsiteActivityFormRef}
      >
        {({ values, setFieldValue, setFieldError, errors }) => {
          return (
            <Form className="space-y-8">
              <TrackUserClickPanel>
                <TrackUserClick
                  values={values}
                  handleSetFieldValue={setFieldValue}
                  handleSetFieldError={setFieldError}
                  handleDeleteRule={handleDeleteRule}
                  errors={errors}
                />
              </TrackUserClickPanel>
              <TrackPageVisitPanel>
                <TrackPageVisit
                  values={values}
                  handleSetFieldValue={setFieldValue}
                  handleSetFieldError={setFieldError}
                  handleDeleteRule={handleDeleteRule}
                  errors={errors}
                  dataSource={dataSource}
                />
              </TrackPageVisitPanel>
              <TrackCustomEvents />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TrackWebsiteActivity;
