import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Step } from '../../components/steppers';

type DataSourceEditState = {
  ui: {
    steps: Step[];
  };
};

export const initialState: DataSourceEditState = {
  ui: {
    steps: [],
  },
};

const dataSourceEditSlice = createSlice({
  name: 'dataSourceEdit',
  initialState,
  reducers: {
    stashSteps: (state, action: PayloadAction<Step[]>) => ({
      ...state,
      ui: {
        ...state.ui,
        steps: action.payload,
      },
    }),
  },
});

export const { stashSteps } = dataSourceEditSlice.actions;

export default dataSourceEditSlice.reducer;
