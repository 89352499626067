import React, { FunctionComponent, useState } from 'react';
import { Workspace, WorkspaceId, WorkspaceInvite, WorkspaceUser, WorkspaceUserWithRole } from '../../../models/workspace';
import { Loading } from '../../../components/loaders';
import { Input } from '../../../components/inputs';
import { SearchIcon, TrashIcon } from '@heroicons/react/outline';
import { SimpleTable } from '../../../components/tables';
import { useWorkspaceUsersQuery, useGetWorkspaceInvitesQuery } from '../../../services/endpoints/workspaces';
import { Button } from '../../../components/buttons';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import NewWorkspaceInviteRow from './NewWorkspaceInviteRow';
import { useModalContext } from '../../../hooks/useModalContext';
import UserDetail from '../../userInfo/UserDetail';
import { isCompanyAdmin, isWorkspaceAdmin } from '../../userInfo/hooks/useUserInfo';
import _ from 'lodash';
import InviteStatusBadge from '../../../components/badges/InviteStatusBadge';
import { SimpleBadge } from '../../../components/badges';
import classNames from 'classnames';
import DeleteWorkspaceInvitationModal from './DeleteWorkspaceInvitationModal';
import DeleteWorkspaceUserModal from './DeleteWorkspaceUserModal';
import { useAuth0 } from '@auth0/auth0-react';

interface IWorkspaceUsersProps {
    workspace: Workspace;
}

const WorkspaceUsers: FunctionComponent<IWorkspaceUsersProps> = ({ workspace }) => {
    const { user } = useAuth0();
    const { isLoading, data } = useWorkspaceUsersQuery({ workspaceId: workspace.id });
    const { isLoading: isLoadingInvites, data: invites } = useGetWorkspaceInvitesQuery({ workspaceId: workspace.id });

    const { openModal } = useModalContext();

    const [showNewInviteRow, setShowNewInviteRow] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const isAdmin = isCompanyAdmin(workspace.companyId) || isWorkspaceAdmin(workspace.id);

    const showUserInfo = (user: WorkspaceUserWithRole) => {
        const { email, firstName, lastName, id } = user.user;
        openModal({
            renderContent: (props) => <UserDetail showReset={false} user={{ email, firstName, lastName, id }} {...props} />,
            position: 'right',
            dismissable: true,
            fullHeight: true,
            fullWidth: true,
        });
    };

    const handleDeleteInviteClicked = (invite: WorkspaceInvite) => {
        openModal({
            renderContent: DeleteWorkspaceInvitationModal,
            renderContentProps: {
                invite,
            },
            dismissable: true,
            fullWidth: false,
            fullHeight: false,
        });
    };
    const handleDeleteUserClicked = (user: WorkspaceUserWithRole) => {
        openModal({
            renderContent: DeleteWorkspaceUserModal,
            renderContentProps: {
                workspaceId: workspace.id as WorkspaceId,
                user: user.user,
            },
            dismissable: true,
            fullWidth: false,
            fullHeight: false,
        });
    };

    if (isLoading || isLoadingInvites) {
        return <Loading />;
    }

    let tableContent = [];

    if (data?.items) {
        tableContent.push(
            (searchText.trim() ? data.items.filter((item) => item.user.email.toLowerCase().includes(searchText.trim().toLowerCase())) : data.items).map((item) => (
                <UserRow
                    key={item.user.id}
                    user={item}
                    clickable={true}
                    onClick={() => showUserInfo(item)}
                    deletable={isAdmin && user?.sub !== item.user.id}
                    onDeleteClicked={isAdmin && user?.sub !== item.user.id ? () => handleDeleteUserClicked(item) : undefined}
                />
            ))
        );
    }

    if (invites?.items) {
        tableContent.push(
            (searchText.trim() ? invites.items.filter((item) => item.invitedEmail.toLowerCase().includes(searchText.trim().toLowerCase())) : invites.items)
                .filter((i) => i.status !== 'COMPLETE')
                .map((item) => <InviteRow key={item.id} invite={item} deletable={isAdmin} onDeleteClicked={() => handleDeleteInviteClicked(item)} />)
        );
    }

    if (tableContent.length === 0) {
        tableContent = [
            <tr key={'empty'} className='bg-white-100 border-b border-gray-200'>
                <td className='pl-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900 text-center'>
                    <p className='py-2 text-sm font-medium text-gray-900'>No user found</p>
                </td>
            </tr>,
        ];
    }

    return (
        <div className={'mt-8'}>
            <div className='flex justify-end'>
                <div className='w-72'>
                    <Input icon={SearchIcon} placeholder={'Search'} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                </div>
            </div>
            <SimpleTable>
                <colgroup>
                    <col />
                </colgroup>
                <thead>
                    <tr className={'bg-gray-50'}>
                        <td className={'py-2 px-6 text-sm'}>USER</td>
                        <td className={'py-2 px-6 text-sm'}>ROLE</td>
                        <td className={'py-2 px-6 text-sm'}></td>
                    </tr>
                </thead>
                <tbody>
                    {_.orderBy(tableContent, 'key', 'asc')}
                    {showNewInviteRow && <NewWorkspaceInviteRow onComplete={() => setShowNewInviteRow(false)} workspace={workspace} />}
                </tbody>
            </SimpleTable>

            {isAdmin && !showNewInviteRow && (
                <Button variant='transparent' icon={() => <PlusIcon />} className='whitespace-nowrap' onClick={() => setShowNewInviteRow(!showNewInviteRow)}>
                    Invite another collaborator
                </Button>
            )}
        </div>
    );
};

type InviteRowProps = {
    onDeleteClicked: () => void;
    deletable?: boolean;
    invite: WorkspaceInvite;
};

const InviteRow = ({ invite, deletable = false, onDeleteClicked }: InviteRowProps) => {
    return (
        <tr className={classNames('bg-white-100 border-b border-gray-200 last:border-0')}>
            <td className='pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900'>{invite.invitedEmail}</td>
            <td className='pl-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
                <SimpleBadge label={invite.role} />
            </td>
            <td className='flex justify-between pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900'>
                <InviteStatusBadge status={invite.status} />
                {deletable && (
                    <TrashIcon
                        className={classNames(deletable ? 'cursor-pointer hover:text-red-900' : 'text-gray-900', 'h-5 w-5 text-red-500 mr-3 ')}
                        onClick={onDeleteClicked}
                    />
                )}
            </td>
        </tr>
    );
};

type UserRowProps = {
    clickable: boolean;
    user: WorkspaceUserWithRole;
    onClick?: () => void;
    deletable: boolean;
    onDeleteClicked?: () => void;
};

const UserRow = ({ user, onClick, deletable, onDeleteClicked }: UserRowProps) => {
    return (
        <tr className='bg-white-100 border-b border-gray-200 last:border-0'>
            <td className='cursor-pointer pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900' onClick={onClick}>
                {user.user.email}
            </td>
            <td className='pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900'>
                {user.role.map((role) => (
                    <div key={role} className='mb-1'>
                        <SimpleBadge label={role} />
                    </div>
                ))}
            </td>
            <td className='flex justify-end pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900'>
                {deletable && (
                    <TrashIcon
                        className={classNames(deletable ? 'cursor-pointer hover:text-red-900' : 'text-gray-900', 'h-5 w-5 text-red-500 mr-3 ')}
                        onClick={onDeleteClicked}
                    />
                )}
            </td>
        </tr>
    );
};

export default WorkspaceUsers;
