import { PlusIcon } from "@heroicons/react/solid";
import React, { Fragment, FunctionComponent } from "react";
import { Button } from "../../components/buttons";
import { Loading } from "../../components/loaders";
import { MainContentWrapper } from "../../features/layout/main";
import { Topbar } from "../../features/layout/topbar";
import { useUserInfo } from "../../features/userInfo/hooks";
import { WorkspaceInvites } from "./";
import { isCompanyAdmin } from "../../features/userInfo/hooks/useUserInfo";
import { useHistory } from "react-router";
import RouteConstants from "../../features/router/RouteConstants";
import CompanyUsers from "../../features/settings/components/CompanyUsers";

const Home: FunctionComponent = () => {
  const userInfo = useUserInfo();
  const isAdmin = isCompanyAdmin();
  const userInfoLoading = Object.keys(userInfo).length === 0;
  const history = useHistory();

  const handleCreateFirstWorkspaceClicked = () => {
    history.push(RouteConstants.createWorkspace);
  };

  return (
    <Fragment>
      <Topbar />
      <MainContentWrapper>
        <div className="px-6">
          <div className="py-8" data-testid="home-placeholder">
            <h1 className="text-lg text-black-100 font-semibold">Workspaces</h1>
            <WorkspaceInvites />
            {userInfoLoading ? (
              <Loading />
            ) : userInfo?.workspaces?.length === 0 ? (
              <div
                className="border bg-white-100 rounded-lg p-6 space-x-12 font-base text-sm flex flex-row items-center">
                <div className="flex-grow">
                  <div className="text-black-100 font-medium">Do you want to use Audiencerate for your company?</div>
                  <div className="text-gray-600">
                    Create a workspace, where you can start work with Audiencerate and invite operatives. You can also
                    invite other admins and let them do this
                    for you.
                  </div>
                </div>
                <div className="text-right">
                  <Button icon={PlusIcon} className="whitespace-nowrap" onClick={handleCreateFirstWorkspaceClicked}>
                    Create your first workspace
                  </Button>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
          {isAdmin && (
            <>
              <h1 className="text-lg text-black-100 font-semibold">Company users</h1>
              <CompanyUsers showSearch={false} clickable={false} />
            </>
          )}
        </div>
      </MainContentWrapper>
    </Fragment>
  );
};

export default Home;
