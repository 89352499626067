import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Loading } from '../../components/loaders';
import { persistor } from '../../App';

export default function Logout() {
    const { logout } = useAuth0();
    useEffect(() => {
        persistor.purge();
        logout({ logoutParams: { returnTo: window.location.origin } });
    }, []);
    return <Loading />;
}
