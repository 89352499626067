import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DestinationRow } from '../';
import { ISimpleTableProps, SimpleTable } from '../../../../components/tables';
import { DistributionChannel } from '../../../../models/distributionChannels';

interface IDestinationTableProps extends ISimpleTableProps {
  destinations: DistributionChannel[];
  onDelete?: (destination: DistributionChannel) => void;
}

const DestinationTable: FunctionComponent<IDestinationTableProps> = ({ destinations, onDelete, ...rest }) => {
  const { t } = useTranslation('destinations');

  const headings = [
    { label: t('table.audience_name') },
    { label: t('table.destination_name') },
    { label: t('table.resource') },
    { label: 'Last Sync' },
    { label: t('table.change') },
    // { label: t('table.total_users') },
  ];

  const renderRow = (destination: DistributionChannel, index: number, list: DistributionChannel[]) => {
    const key = `${destination.audience.id}-${destination.id}-${index}`;
    const prevDestination = index > 0 ? list[index - 1] : undefined;
    return <DestinationRow key={key} id={key} destination={destination} prevDestination={prevDestination} onDelete={onDelete} />;
  };

  return (
    <SimpleTable {...rest}>
      <thead>
        <tr>
          {headings.map((heading) => (
            <th
              key={heading.label}
              scope='col'
              className='px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider sticky top-0'
            >
              {heading.label}
            </th>
          ))}
          {onDelete && <th className='px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider sticky top-0'>&nbsp;</th>}
        </tr>
      </thead>
      <tbody>{destinations?.map(renderRow)}</tbody>
    </SimpleTable>
  );
};

export default DestinationTable;
