import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
    token?: string;
    shouldLogout?: boolean;
};

export const initialState: AuthState = {

};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        storeToken: (state, action: PayloadAction<AuthState['token']>) => ({ ...state, token: action.payload }),
        logout: (state) => ({ ...state, shouldLogout: true })
    },
});

export const { storeToken, logout } = authSlice.actions;

export default authSlice.reducer;
