export type TraitDataType = 'TEXT' | 'NUMBER' | 'DATE' | 'DATETIME';
export const TRAITS_DATA_TYPES = [ 
  { key: 'TEXT',     value: 'Text' }, 
  { key: 'NUMBER',   value: 'Number' }, 
  { key: 'DATE',     value: 'Date' }, 
  { key: 'DATETIME', value: 'Date time' }
];

export type Trait = {
  identifier: boolean;
  dataType?: TraitDataType;
} & EventTrait;

export type EventTrait = {
  identifier: boolean;
  id: string;
  displayName: string;
  dotName: string;
}

export type GroupedTraits = {
  customer: Trait[];
  event: EventTrait[];
}
