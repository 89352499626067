import { Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from '../../../components/buttons';
import { SpinnerIcon } from '../../../components/icons';
import { Input } from '../../../components/inputs';
import { Company, CompanyAdministrativeData } from '../../../models/company';
import { useLazyGetCompanyAdministativeDataQuery, useUpdateCompanyAdministrativeDataMutation } from '../../../services/endpoints/companies';
import { isCompanyAccounting, isCompanyAdmin } from '../../userInfo/hooks/useUserInfo';

interface ICompanySettingsProps {
  company: Company;
}

const CompanySettings: FunctionComponent<ICompanySettingsProps> = ({ company }) => {
  const { t } = useTranslation('settings');
  const formRef = useRef<FormikProps<CompanyAdministrativeData>>(null);

  const validationSchema = Yup.object({
    legalEntity: Yup.string().required(t('company.errors.legalEntityRequired')),
    licenseNumber: Yup.string().required(t('company.errors.licenseNumberRequired')),
    vatNumber: Yup.string().required(t('company.errors.vatNumberRequired')),
    email: Yup.string().email(t('company.errors.emailValid')).required(t('company.errors.emailRequired')),
    legalAddress1: Yup.string().required(t('company.errors.legalAddress1Required')),
    legalAddress2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    country: Yup.string().required(t('company.errors.legalEntityRequired')),
  });

  const initialSettings: CompanyAdministrativeData = {
    legalEntity: '',
    licenseNumber: '',
    email: '',
    vatNumber: '',
    legalAddress1: '',
    legalAddress2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    createdOn: '',
    updatedOn: '',
  };

  const canSeeSaveAdministativeData = isCompanyAdmin() || isCompanyAccounting();

  const [getAdministativeData, { data: loadedSettings, isLoading: isSettingsLoading, isError: isLoadError, error: loadError }] = useLazyGetCompanyAdministativeDataQuery();

  useEffect(() => {
    if (canSeeSaveAdministativeData) {
      getAdministativeData({
        companyId: company.id,
      });
    }
  }, [canSeeSaveAdministativeData]);

  const [
    updateSettings,
    { data: updatedSettings, isLoading: isUpdateLoading, isError: isUpdateError, error: updateError },
  ] = useUpdateCompanyAdministrativeDataMutation();

  useEffect(() => {
    if (!isSettingsLoading && !isLoadError && loadedSettings) {
      formRef.current?.setValues(
        {
          legalEntity: loadedSettings.legalEntity || '',
          licenseNumber: loadedSettings.licenseNumber || '',
          email: loadedSettings.email || '',
          vatNumber: loadedSettings.vatNumber || '',
          legalAddress1: loadedSettings.legalAddress1 || '',
          legalAddress2: loadedSettings.legalAddress2 || '',
          city: loadedSettings.city || '',
          state: loadedSettings.state || '',
          zip: loadedSettings.zip || '',
          country: loadedSettings.country || '',
          createdOn: loadedSettings.createdOn,
          updatedOn: loadedSettings.updatedOn,
        },
        false
      );
    }
  }, [isSettingsLoading, isLoadError, loadedSettings]);

  const handleSaveButtonClicked = async () => {
    await formRef.current?.submitForm();
    const values = formRef.current?.values;

    if (formRef.current?.isValid && values) {
      updateSettings({ companyId: company.id, body: values });
    }
  };

  return (
    <div className='px-6 py-8'>
      <h1 className='text-lg text-black-100 font-semibold mb-2'>
        {t('company.title')}
        <span className='text-blue-500 opacity-75 my-0 relative inline-block w-7 h-7 pt-3'>
          {isSettingsLoading && <SpinnerIcon className='ml-2 mr-3 h-5 w-5' loading />}
        </span>
      </h1>

      <Formik initialValues={initialSettings} validationSchema={validationSchema} innerRef={formRef} onSubmit={() => console.log('submit settings')}>
        {({ values, handleChange, errors }) => (
          <Form>
            <div className='border bg-white-100 rounded-lg p-6 grid grid-cols-2 gap-6'>
              <div className='col-span-2'>
                <Input label={t('company.company')} value={company.name} readOnly={true} disabled={true} />
              </div>

              <Input
                label={t('company.entity')}
                value={values.legalEntity}
                onChange={(e) => handleChange('legalEntity')(e.target.value)}
                error={errors.legalEntity}
                disabled={isSettingsLoading || isUpdateLoading || !canSeeSaveAdministativeData}
              />

              <Input
                label={t('company.license')}
                value={values.licenseNumber}
                onChange={(e) => handleChange('licenseNumber')(e.target.value)}
                disabled={true}
                error={errors.licenseNumber}
              />

              <Input label={t('company.vat')}
                value={values.vatNumber}
                onChange={(e) => handleChange('vatNumber')(e.target.value)}
                disabled={!canSeeSaveAdministativeData}
                error={errors.vatNumber} />

              <Input label={t('company.email')}
                value={values.email}
                onChange={(e) => handleChange('email')(e.target.value)}
                disabled={!canSeeSaveAdministativeData}
                error={errors.email} />

              <div className='col-span-2'>
                <label className='block text-sm font-medium text-gray-700 py-2'>{t('company.address.title')}</label>

                <div className='grid grid-cols-4 gap-6'>
                  <div className='col-span-2'>
                    <Input
                      placeholder={t('company.address.line1')}
                      value={values.legalAddress1}
                      onChange={(e) => handleChange('legalAddress1')(e.target.value)}
                      disabled={!canSeeSaveAdministativeData}
                      error={errors.legalAddress1}
                    />
                  </div>

                  <Input
                    placeholder={t('company.address.city')}
                    value={values.city}
                    onChange={(e) => handleChange('city')(e.target.value)}
                    disabled={!canSeeSaveAdministativeData}
                    error={errors.city}
                  />

                  <Input
                    placeholder={t('company.address.state')}
                    value={values.state}
                    onChange={(e) => handleChange('state')(e.target.value)}
                    disabled={!canSeeSaveAdministativeData}
                    error={errors.state}
                  />

                  <div className='col-span-2'>
                    <Input
                      placeholder={t('company.address.line2')}
                      value={values.legalAddress2}
                      onChange={(e) => handleChange('legalAddress2')(e.target.value)}
                      disabled={!canSeeSaveAdministativeData}
                      error={errors.legalAddress2}
                    />
                  </div>

                  <Input
                    placeholder={t('company.address.zipcode')}
                    value={values.zip}
                    onChange={(e) => handleChange('zip')(e.target.value)}
                    disabled={!canSeeSaveAdministativeData}
                    error={errors.zip}
                  />

                  <Input
                    placeholder={t('company.address.country')}
                    value={values.country}
                    onChange={(e) => handleChange('country')(e.target.value)}
                    disabled={!canSeeSaveAdministativeData}
                    error={errors.country}
                  />
                </div>
              </div>

              <div className='col-span-2 flex flex-row justify-end'>
                {canSeeSaveAdministativeData && <Button
                  variant='primary'
                  type='button'
                  className='inline-flex justify-center py-2 px-4'
                  disabled={isUpdateLoading}
                  onClick={handleSaveButtonClicked}
                >
                  Save
                </Button>}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanySettings;
