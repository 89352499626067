import { useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { Header } from '../../../models/dataSource';
import { EventTrait, GroupedTraits, Trait } from "../../../models/traits";
import { RootState } from '../../../reducers';
import { CONSENT_TRAIT, EVENT } from '../constants';
import { IColumnMapSelect, ITraitValue, TColumnItem, TRAITS } from '../types';

const canonicalize = (name: string): string => {
  return name.toLowerCase().replace(/ /gi, '').replace(/\./gi, '').replace(/_/gi, '').replace(/-/gi, '');
};

export const useColumnMapInitialValues = (fileHeader: string[], columnMapSelect: IColumnMapSelect[], groupedTraits?: GroupedTraits): TColumnItem[] => {
  const fileDataSource = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource);

  const traits = new Map<string, EventTrait>();

  const addTrait = (trait: EventTrait) => {
    const name = canonicalize(trait.dotName);
    traits.set(name, trait);

    if (trait.dotName.startsWith("address_")) {
      traits.set(name.substring(7), trait);
    }
  };

  if (groupedTraits) {
    groupedTraits.customer.forEach((trait) => addTrait(trait));

    if (fileDataSource?.configuration?.type === EVENT) {
      groupedTraits.event.forEach((trait) => addTrait(trait));
    }
  }

  const getTraitType = (headers: Header): IColumnMapSelect => {
    if (headers.custom) {
      return columnMapSelect[TRAITS.CUSTOM_TRAIT];
    }

    if (headers.traitId === CONSENT_TRAIT) {
      return columnMapSelect[TRAITS.CONSENT_TRAIT];
    }

    return columnMapSelect[TRAITS.MAP_TO_TRAIT];
  };

  const findTrait = (header: string): ITraitValue | undefined => {
    const trait = traits.get(canonicalize(header));
    return trait
      ? {
          id: trait.id,
          displayValue: trait.displayName,
        }
      : undefined;
  };

  if (fileDataSource && fileDataSource.configuration && fileDataSource.configuration.headers) {
    return fileHeader.map((header) => {
      const id = uuidV4();

      if (fileDataSource && fileDataSource.configuration && fileDataSource.configuration.headers) {
        const headers = fileDataSource.configuration.headers.find((dsHeader) => dsHeader.name === header);

        if (headers) {
          if (headers.skip) {
            return { id, header, trait: columnMapSelect[TRAITS.IGNORE_COLUMN] };
          }

          const traitType: IColumnMapSelect = getTraitType(headers);

          return {
            id,
            header,
            trait: traitType,
            traitValue: {
              id: headers.traitId || '',
              displayValue: headers.displayName || '',
            },
          };
        }
      }
      return { id, header, trait: columnMapSelect[0], traitValue: undefined };
    });
  } else {
    return fileHeader.map((header) => {
      const id = uuidV4();
      return { id, header, trait: columnMapSelect[0], traitValue: findTrait(header) };
    });
  }
};
