import { CogIcon, DocumentTextIcon, ViewGridIcon } from '@heroicons/react/outline';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DataSourceTabContent } from '.';
import { SpinnerIcon } from '../../components/icons';
import { useDelayedBoolean } from '../../hooks/useDelayedBoolean';
import { ETABS, TabType } from '../../models/tabs';
import { useGetDataSourceQuery } from '../../services/dataSources';
import { MainContentWrapper } from '../layout/main';
import { TopbarTabs } from '../layout/topbar';
import RouteConstants from '../router/RouteConstants';
import { useWorkspace } from '../workspaces/hooks';
import { useWorkspaceAdmin } from '../workspaces/hooks/useWorkspace';

const DataSourceDetails: FunctionComponent = () => {
  const { t } = useTranslation('data_source_details');
  const history = useHistory();

  const { id: dataSourceId } = useParams<{ id: string }>();

  const workspace = useWorkspace();
  const isWorkspaceAdmin = useWorkspaceAdmin();
  const settingsTab = isWorkspaceAdmin ? [{ id: ETABS.SETTINGS, label: t('tabs.settings'), current: false, icon: CogIcon }] : [];
  const [tabs, setTabs] = useState([
    { id: ETABS.OVERVIEW, label: t('tabs.overview'), current: true, icon: ViewGridIcon },
    { id: ETABS.DATA, label: t('tabs.data'), current: false, icon: DocumentTextIcon },
    ...settingsTab
  ]);

  const { isFetching, data: dataSource, isUninitialized, isError } = useGetDataSourceQuery({
    workspaceId: workspace.id,
    dataSourceId,
  });

  const isFetchingDelayed = useDelayedBoolean(isFetching, 400);

  const shouldShowLoading = isFetching || isFetchingDelayed || isUninitialized;
  const breadcrumbs = [
    {
      label: t('breadcrumbs.data_sources'),
    },
    {
      label: t('breadcrumbs.my_sources'),
      path: '/data-sources',
    },
    {
      label: dataSource?.name || '',
    },
  ];

  useEffect(() => {
    if (isError) {
      history.push(RouteConstants.defaultRoute);
    }
  }, [isError]);

  const handleTabChange = (selectedTab: TabType) => {
    setTabs(tabs.map((tab) => ({ ...tab, current: tab.id === selectedTab.id })));
  };

  if (shouldShowLoading) {
    return (
      <Fragment>
        <MainContentWrapper>
          <span className='text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0'>
            <SpinnerIcon className='-ml-1 mr-3 h-5 w-5' loading />
          </span>
        </MainContentWrapper>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <TopbarTabs tabs={tabs} onTabChange={handleTabChange} breadcrumbs={breadcrumbs} />
      <MainContentWrapper>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='h-auto py-4'>
            <DataSourceTabContent tabs={tabs} selectedDataSource={dataSource} />
          </div>
        </div>
      </MainContentWrapper>
    </Fragment>
  );
};

export default DataSourceDetails;
