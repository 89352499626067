import React, { FunctionComponent, PropsWithChildren } from 'react';
import { concatClassNames } from '../../utils';

interface ISlideOverContentWrapperProps {
    className?: string;
}

const SlideOverContentWrapper: FunctionComponent<PropsWithChildren<ISlideOverContentWrapperProps>> = ({ children, className }) => (
    <div className={concatClassNames('mt-6 relative flex-1 px-4 sm:px-6', className || '')}>
        <div className='h-full' aria-hidden='true'>
            <div className='flex-1 flex flex-col justify-between'>
                <div className='divide-y divide-gray-200'>
                    <div className='space-y-6 pt-6 pb-5'>{children}</div>
                </div>
            </div>
        </div>
    </div>
);

export default SlideOverContentWrapper;
