import { format } from "date-fns";
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "../../../../components/buttons";
import { SpinnerIcon } from "../../../../components/icons";
import { SimpleList } from "../../../../components/lists";
import { useModalContext } from "../../../../hooks/useModalContext";
import { Audience, AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import { DataSource } from "../../../../models/dataSource";
import { DistributionChannel } from "../../../../models/distributionChannels";
import { ToastType } from "../../../../models/toast";
import { useAppDispatch } from "../../../../reducers";
import { useGetDataSourcesByIdsQuery } from "../../../../services/dataSources";
import { useEvaluateAudienceMutation, useLazyGetNextScheduleQuery } from "../../../../services/endpoints/audiences";
import { useCreateDistributionChannelMutation, useLazyGetDistributionChannelsQuery } from "../../../../services/endpoints/distributionChannels";
import { mapAudienceExpressionToRules } from "../../../../utils";
import { ExpressionConditionRender } from "../../../audienceEdit/components";
import DestinationCreate from "../../../destinations/components/edit/DestinationCreate";
import { showToast } from "../../../toasts/toastsSlice";
import { useWorkspace } from "../../../workspaces/hooks";
import { useWorkspaceAdmin } from "../../../workspaces/hooks/useWorkspace";
import AudienceDestinations from "./AudienceDestinations";
import DisconnectDestinationModal from "./settings/modals/DisconnectDestinationModal";

interface IAudienceSourcesAndRulesProps {
  audience?: Audience;
}

const AudienceSourcesAndRules: FunctionComponent<IAudienceSourcesAndRulesProps> = ({ audience }) => {
  const { t } = useTranslation("audience_details");
  const history = useHistory();

  const workspace = useWorkspace();
  const dispatch = useAppDispatch();

  const { openModal } = useModalContext();

  const { isLoading, data } = useGetDataSourcesByIdsQuery({
    workspaceId: workspace.id,
    datasourceIds: audience?.datasourceIds || []
  });

  const dataSources = data || [];

  const rules = mapAudienceExpressionToRules(audience?.expression);

  const [destinations, setDestinations] = useState<DistributionChannel[]>([]);
  const destinationsRef = useRef<DistributionChannel[]>([]);

  const [getDistributionChannels, {
    isLoading: isDistributionChannelsLoading,
    data: distributionChannels
  }] = useLazyGetDistributionChannelsQuery();

  // const [launchCyclr, {
  //   isLoading: isLaunchLoading,
  //   isSuccess: isLaunchSuccess,
  //   isError: isLaunchError,
  //   data: launchData,
  //   error: launchError
  // }] = useLaunchCyclrMutation();

  const [createDistributionChannel, {
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    data: createData,
    error: createError
  }] = useCreateDistributionChannelMutation();

  const [evaluate] = useEvaluateAudienceMutation();

  const [getNextSchedule, {
    isLoading: isScheduleLoading,
    data: scheduleData,
    isFetching: isScheduleFetching
  }] = useLazyGetNextScheduleQuery();

  useEffect(() => {
    if (audience?.scheduleType === "SCHEDULED") {
      getNextSchedule({
        workspaceId: workspace.id,
        audienceId: audience.id
      });
    }
  }, []);

  const cyclrUrlRef = useRef<string>("");
  const cyclrPopupRef = useRef<Window | null>(null);

  useEffect(() => {
    audience && getDistributionChannels({ workspaceId: workspace.id, audienceIds: [audience.id] });
  }, [audience]);

  useEffect(() => {
    setDestinations(distributionChannels || []);
  }, [distributionChannels]);

  useEffect(() => {
    destinationsRef.current = destinations;
  }, [destinations]);

  const handleConnectToDestinationClick = () => {
    return openModal({
      renderContent: DestinationCreate,
      fullHeight: true,
      fullWidth: true,
      title: t("connect_to_destination_modal_title"),
      renderContentProps: {
        forceAudience: audience
      }
    });
  };

  const handleRefreshClick = () => {
    if (audience) {
      evaluate({ workspaceId: workspace.id, audienceId: audience.id });
      dispatch(
        showToast({
          type: ToastType.INFO,
          title: t("sources_and_rules.schedule.evaluate_title"),
          message: t("sources_and_rules.schedule.evaluate")
        })
      );
    }
  };

  // useEffect(() => {
  //   if (!isLaunchLoading && isLaunchSuccess && launchData) {
  //     cyclrUrlRef.current = launchData.LaunchUrl;
  //     cyclrPopupRef.current = window.open(launchData.LaunchUrl, "_blank");
  //   }
  // }, [isLaunchLoading, isLaunchSuccess, launchData]);

  // useEffect(() => {
  //   if (!isLaunchLoading && isLaunchError) {
  //     console.error(launchError);
  //     window.alert(t("destinations.launch_error"));
  //   }
  // }, [isLaunchLoading, isLaunchError, launchError]);

  useEffect(() => {
    const handlePopupMessage = (event: MessageEvent<string>) => {
      const cyclrUrl = cyclrUrlRef.current;
      const cyclrPopup = cyclrPopupRef.current;

      if (cyclrUrl && cyclrUrl.startsWith(event.origin) && cyclrPopup) {
        const response = JSON.parse(event.data);
        cyclrPopup.close();
        cyclrUrlRef.current = "";
        cyclrPopupRef.current = null;
        audience && createDistributionChannel({
          workspaceId: workspace.id,
          audienceId: audience.id,
          configData: response
        });
      }
    };

    window.addEventListener("message", handlePopupMessage);
    return () => window.removeEventListener("message", handlePopupMessage);
  }, [cyclrUrlRef, cyclrPopupRef]);

  useEffect(() => {
    if (!isCreateLoading && isCreateSuccess && createData) {
      console.log("Created:", createData);
      const currentDestinations = [...destinationsRef.current];
      currentDestinations.push(createData as any);
      setDestinations(currentDestinations);
    }
  }, [isCreateLoading, isCreateSuccess, createData, destinationsRef]);

  useEffect(() => {
    if (!isCreateLoading && createError) {
      console.error(createError);
      window.alert(t("destinations.create_error"));
    }
  }, [isCreateLoading, isCreateError, createError]);

  const handleGoToSourceClick = (data: DataSource) => {
    // open datasource details
    const { id } = data;

    history.push(`/data-sources/${id}`);
  };

  const handleOnDelete = (destination: DistributionChannel) => {
    console.log(destination);

    audience && openModal({
      renderContent: DisconnectDestinationModal,
      renderContentProps: {
        audience,
        destination
      },
      dismissable: true,
      fullWidth: false,
      fullHeight: false
    });
  };

  const renderScheduledSync = () => {
    const syncConfig: AudienceSyncTimeBasedSchedule = audience?.scheduleConfiguration as AudienceSyncTimeBasedSchedule;
    const cardinality: string = syncConfig.repeatsEvery === 1 ? "one" : "other";
    return t(`sources_and_rules.schedule.scheduled_${syncConfig.unit}_${cardinality}` as any, {
      count: syncConfig.repeatsEvery,
      day: syncConfig.dayOfMonth,
      days: syncConfig.weekDays?.map(day => t(`sources_and_rules.schedule.${day}` as any)).join(", ")
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex-1">
        <div className="py-8">
          <h1
            className="text-base font-bold text-gray-900 mb-2 leading-4">{t("sources_and_rules.data_sources_title")}</h1>
          {isLoading ? (
            <span className="text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
              <SpinnerIcon className="-ml-1 mr-3 h-5 w-5" loading />
            </span>
          ) : (
            <SimpleList<DataSource>
              items={dataSources}
              rightElement={(item: DataSource) => (
                <Button variant="secondary" onClick={() => handleGoToSourceClick(item)}>
                  {t("sources_and_rules.go_to_source")}
                </Button>
              )}
            />
          )}
        </div>
      </div>
      <div className="flex-1">
        <div className="py-8">
          <h1 className="text-base font-bold text-gray-900 mb-2 leading-4">{t("sources_and_rules.rules_title")}</h1>
          {rules.map((rule, index) => {
            return (
              <div key={index} className="flex mt-4 p-4 bg-white-100 shadow overflow-hidden sm:rounded-md">
                <h6 className="text-sm text-gray-900 text-right w-28 mr-6">
                  {t(index === 0 ? "sources_and_rules.rules.users_who" : "sources_and_rules.rules.and")}
                </h6>
                <div className="flex flex-col space-y-2 w-full">
                  <div className="border rounded p-1 space-y-2">
                    {rule.expressions.map((expression, index) => {
                      return (
                        <div key={index} className="flex p-3 items-center">
                          {index > 0 && <h6
                            className="text-sm text-gray-900 text-right w-6 mr-2">{t("sources_and_rules.rules.or")}</h6>}
                          <ExpressionConditionRender expression={expression} isViewMode={true} datasourceIds={dataSources.map(d => d.id)} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <AudienceDestinations
        isLoading={isDistributionChannelsLoading}
        destinations={destinations}
        onConnectClick={handleConnectToDestinationClick}
        onDelete={handleOnDelete}
      />

      <div className="flex-1">
        <div className="py-8">
          <h1 className="text-base font-bold text-gray-900 mb-2 leading-4">{t(`sources_and_rules.schedule.${audience?.scheduleType}` as any)}</h1>
          {audience?.scheduleType === "SCHEDULED" && (
            (isScheduleFetching || isScheduleLoading) ? (
              <div><SpinnerIcon className="h-4 w-4" fill="rgba(21,68,155,1)" loading /></div>
            ) : (
              <Fragment>
                <div className={"mr-1"}>{renderScheduledSync()}</div>
                <div>
                  <span className={"mr-1"}>{t("sources_and_rules.schedule.next_schedule")}</span>
                  {scheduleData?.nextSchedule
                    ? format(new Date(scheduleData.nextSchedule), "yyyy-MM-dd HH:mm")
                    : <span className={"font-semibold"}>not calculated</span>}
                </div>
              </Fragment>
            )
          )}
          {audience?.scheduleType === "MANUAL" && (
            <Button variant="primary" onClick={handleRefreshClick} disabled={!useWorkspaceAdmin()} className="mt-2">
              {t("sources_and_rules.schedule.refresh")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudienceSourcesAndRules;
