import React, { FunctionComponent } from 'react';
import { DestinationList } from './';
import { DistributionChannel } from '../../../models/distributionChannels';

interface IDestinationSync {
  destinations: DistributionChannel[];
}

const DestinationSync: FunctionComponent<IDestinationSync> = ({ destinations }) => {
  return destinations ? <DestinationList destinations={destinations} /> : null;
};

export default DestinationSync;
