import { map, values } from 'lodash';
import { Expression } from '../models/audiences';
import { DataSourceType } from '../models/dataSource';
import { EStatus } from '../models/fileUpload';
import { AudienceRule } from '../models/rules';

export function concatClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function prependHttp(url?: string) {
  if (!!url && !url.match(/^[a-zA-Z]+:\/\//)) {
    return 'http://' + url;
  }

  return url;
}

export function generateSlug(value?: string) {
  // 1) convert to lowercase
  // 2) remove Emojis (https://www.unicode.org/reports/tr51/#def_level1_emoji)
  // 3) trim
  // 4) remove Diacritial (https://en.wikipedia.org/wiki/Combining_Diacritical_Marks)
  // 5) remove dashes and pluses
  // 6) replace spaces with dashes
  // 7) remove everything but alphanumeric characters and dashes
  return value
    ?.toLowerCase()
    .replace(/\p{Extended_Pictographic}/ug, "")
    .trim()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    .replace(/-+/g, ' ')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-_]/g, '');
}

// Check if dataSourceType is RequestedDataSourceType or DataSourceType
export function isDataSourceType(obj: any): obj is DataSourceType {
  return obj.category !== undefined;
}

export const getStatus = (isLoading: boolean, isSuccess: boolean, isError: boolean) => {
  if (isLoading) {
    return EStatus.UPLOADING;
  }
  if (isSuccess) {
    return EStatus.SUCCESS;
  }
  if (isError) {
    return EStatus.ERROR;
  }
  return EStatus.NOT_SELECTED;
};

export const bytesToMegaBytes = (sizeInBytes: number): string => {
  return (sizeInBytes / (1024 * 1024)).toFixed(2);
};

// export function removeKey(obj: Record<string, Expression>, key: string): Record<string, Expression> {
//   return obj !== Object(obj)
//     ? obj
//     : Array.isArray(obj)
//       ? obj.map((item) => removeKey(item, key))
//       : Object.keys(obj)
//         .filter((k) => k !== key)
//         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//         // @ts-ignore
//         .reduce((acc, x) => Object.assign(acc, { [x]: removeKey(obj[x], key) }), {});
// }

export function mapAudienceExpressionToRules(expression?: Record<string, Expression>): AudienceRule[] {
  return map(values(expression), (exp, index) => {
    return {
      id: String(index),
      expressions: values(exp),
    };
  });
}

export const popupCenter = ({ url, title, w, h }: { url: string; title: string; w: number; h: number }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  if ((window as any).focus) newWindow?.focus();
  return newWindow;
};

export function upperCaseFirstChar(value: string) {
  return value.charAt(0).toUpperCase() + value.substring(1);
}

