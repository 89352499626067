import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SnippetPreview } from '../../../../../../components/snippet';
import { DataSource, EDataSourceStatus } from '../../../../../../models/dataSource';
import { useLazyGetDataSourceSetupQuery } from '../../../../../../services/dataSources';
import { CheckCodeSnippetConnection } from '../../../../../dataSourceEdit/components/categories/code';
import { useWorkspace } from '../../../../../workspaces/hooks';
import { ActivationStatus } from '../../../status';

interface IPendingDataSource {
  dataSource?: DataSource;
}

const PendingCodeDataSource: FunctionComponent<IPendingDataSource> = ({ dataSource }) => {
  const { t } = useTranslation('data_source_details');

  const workspace = useWorkspace();

  const [initDataSource, { data: setupData }] = useLazyGetDataSourceSetupQuery();

  useEffect(() => {
    if (dataSource?.status === EDataSourceStatus.PENDING) {
      initDataSource({
        workspaceId: workspace.id,
        dataSourceId: dataSource?.id || '',
      });
    }
  }, []);

  let script = undefined;
  if (setupData?.setup?.scriptUrl) {
    switch (dataSource?.type.id) {
      case 'GOOGLE_TAG_MANAGER':
        script = `<!-- Include 'analytics' from CDN -->
<script src="${setupData?.setup.scriptUrl}"></script>
<!--suppress JSUnresolvedVariable -->
<script type="text/javascript">
  /* Initialize analytics */
  window.Analytics = CDPAnalytics.create({
    apiUrl: '${setupData?.setup.ingestionUrl}',
    datasourceId: "${setupData?.setup.datasourceId}",
    trackGoogleDataLayer: true,
    enabled: true
  });
  Analytics.page();
</script>`;
        break;
      default:
        script = `<!-- Include 'analytics' from CDN -->
<script src="${setupData?.setup.scriptUrl}"></script>
<!--suppress JSUnresolvedVariable -->
<script type="text/javascript">
  /* Initialize analytics */
  window.Analytics = CDPAnalytics.create({
    apiUrl: '${setupData?.setup.ingestionUrl}',
    datasourceId: "${setupData?.setup.datasourceId}",
    enabled: true
  });

  Analytics.page();
</script>`;
        break;
    }
  }
  return (
    <div>
      <ActivationStatus dataSource={dataSource} />
      <div className='flex space-x-6'>
        <div className='flex-1 w-1/2'>
          <div className='py-8'>
            <SnippetPreview script={script} type={dataSource?.type} />
          </div>
          <hr className='mr-2' />
        </div>
        <div className='flex-1 w-1/2'>
          <div className='my-8 bg-gray-50 p-6'>
            <h1 className='text-base font-bold text-gray-900 mb-2 leading-4'>{t('overview.is_it_working')}</h1>
            <h4 className='text-sm font-normal text-gray-600 leading-4 mb-4'>
              {t('overview.snippet_install')} <br />
              {t('overview.snippet_install_minutes')}
            </h4>
            <CheckCodeSnippetConnection dataSource={dataSource} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingCodeDataSource;
