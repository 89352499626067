import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Input } from '../../../components/inputs';
import { Select } from '../../../components/selects';
import { DataSourceCodeConfig, TDataSource } from '../../../models/dataSource';
import { ISelectItem } from '../../../models/select';
import { TrackUserClick, TrackUserClickPanel } from '../../dataSourceEdit/components/categories/code/tracking';
import { ESelector, ITrackClickEvents } from '../../dataSourceEdit/types';
import TrackRuleForm from './TrackRuleForm';
import { useDeleteRuleMutation } from '../../../services/endpoints/rules';
import { useWorkspace } from '../../workspaces/hooks';
import { Button } from '../../../components/buttons';
import { TrashIcon } from '@heroicons/react/solid';

interface ITrackUserClickViewProps {
  dataSource?: TDataSource<DataSourceCodeConfig>;
  events: ITrackClickEvents[];
  className?: string;
}

const TrackUserClickView: FunctionComponent<ITrackUserClickViewProps> = ({ dataSource, events, className }) => {
  const { t } = useTranslation('data_source_edit');
  const workspace = useWorkspace();

  const selectItems: ISelectItem[] = [
    { id: ESelector.ID, label: t('code.track_events.clicks_on_id') },
    { id: ESelector.CLASS, label: t('code.track_events.clicks_on_class') },
  ];

  const [deleteRule] = useDeleteRuleMutation();

  const handleDeleteRule = (ruleId: string) => {
    if (ruleId && dataSource && workspace) {
      deleteRule({
        workspaceId: workspace.id,
        ruleId,
        dataSourceId: dataSource.id,
      });
    }
  };

  return (
    <TrackUserClickPanel className={className}>
      <div className='mt-4'>
        {events?.map((event, index) => (
          <div key={index} className='flex px-4 my-2'>
            <div className='flex w-1/2'>
              <Select items={selectItems} value={selectItems[event.selectorType]} className='ml-1 w-40' isViewMode={true} />
              <div className='relative flex-auto'>
                <span className={twMerge('absolute z-10 text-gray-400', event.selectorType === ESelector.ID ? 'mt-2 ml-2.5' : 'mt-2 ml-4')}>
                  {event.selectorType === ESelector.ID ? '#' : '.'}
                </span>
                <Input
                  className='mx-1 flex-auto'
                  placeholder={event.selectorType === ESelector.ID ? t('code.track_events.name_of_the_id') : t('code.track_events.name_of_the_class')}
                  value={event.selectorName}
                  isViewMode={true}
                />
              </div>
            </div>
            <div className='flex w-1/2'>
              <span className='py-2 ml-2 mr-1'>{t('code.track_events.creates')}</span>
              <div className='flex-auto'>
                <Input placeholder={t('code.track_events.name_of_the_event')} value={event.eventName} isViewMode={true} />
              </div>
              <div className='flex items-center'>
                <TrashIcon className={'cursor-pointer hover:text-red-900 h-6 w-6 text-red-500 ml-3'} onClick={() => handleDeleteRule(event.id)} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <TrackRuleForm dataSource={dataSource} currentEventCount={events?.length || 0} newEventCountFn={(values) => values.clickEvents.length}>
        {({ values, setFieldValue, setFieldError, errors }) => {
          return (
            <TrackUserClick
              values={values}
              handleSetFieldValue={setFieldValue}
              handleSetFieldError={setFieldError}
              handleDeleteRule={handleDeleteRule}
              errors={errors}
            />
          );
        }}
      </TrackRuleForm>
    </TrackUserClickPanel>
  );
};

export default TrackUserClickView;
