import { Fragment, FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useModalContext } from '../../../../hooks/useModalContext';
import { RootState, useAppDispatch } from '../../../../reducers';
import { useDeleteAudienceMutation } from '../../../../services/endpoints/audiences';
import { AudienceEdit } from '../../../audienceEdit';
import { clearStashedAudience } from '../../../audienceEdit/audienceEditSlice';
import { MainContentWrapper } from '../../../layout/main';
import { TopbarTabs } from '../../../layout/topbar';
import { useWorkspace } from '../../../workspaces/hooks';
import { useWorkspaceAdmin } from '../../../workspaces/hooks/useWorkspace';
import AudienceTypeSelector from '../dropdowns/AudienceTypeSelector';
import AudienceRegularListTab from './AudienceRegularListTab';

interface IAudienceListTabProps {}

const AudienceListTabs: FunctionComponent<IAudienceListTabProps> = () => {
  const dispatch = useAppDispatch();
  const workspace = useWorkspace();
  const isWorkspaceAdmin = useWorkspaceAdmin();

  const { t } = useTranslation('audiences');
  const { openModal } = useModalContext();

  const tabs = [{ id: 'audience.regular', current: true, label: t('tabs.regular') }];
  const breadcrumbs = [
    {
      label: t('title'),
    },
  ];

  const [deleteAudience] = useDeleteAudienceMutation();
  const stashedAudience = useSelector((state: RootState) => state.audienceEdit.audience);
  const stashedAudienceRef = useRef(stashedAudience);

  useEffect(() => {
    stashedAudienceRef.current = stashedAudience;
  }, [stashedAudience]);

  const handleCreateAudience = () => {
    dispatch(clearStashedAudience());

    openModal({
      renderContent: AudienceEdit,
      renderContentProps: {},
      fullHeight: true,
      fullWidth: true,
      onClose: handleModalClose,
    });
  };

  const handleTopBarRightElement = () => <AudienceTypeSelector onCreateAudience={handleCreateAudience} />;

  const handleModalClose = () => {
    const createdAudience = stashedAudienceRef.current;
    stashedAudienceRef.current = undefined;

    if (createdAudience && createdAudience.id && Object.keys(createdAudience.expression || {}).length === 0) {
      deleteAudience({
        workspaceId: workspace.id,
        audienceId: createdAudience.id,
      });
    }
  };

  const renderTab = () => {
    const activeTabId = tabs.find((tab) => tab.current)?.id || '';

    switch (activeTabId) {
      case 'audience.regular':
        return <AudienceRegularListTab />;

      default:
        return <div />;
    }
  };

  return (
    <Fragment>
      <TopbarTabs tabs={tabs} onTabChange={() => {}} rightElement={isWorkspaceAdmin ? handleTopBarRightElement : undefined} breadcrumbs={breadcrumbs} />
      <MainContentWrapper childClassName={'h-full'} data-testid='DataSourceList'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full'>
          <div className='h-full py-4'>
            <div className='h-full'>{renderTab()}</div>
          </div>
        </div>
      </MainContentWrapper>
    </Fragment>
  );
};

export default AudienceListTabs;
