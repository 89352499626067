import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from "axios";
import { updateUploadProgress } from "../../features/fileUploading/fileUploadingSlice";
import { Audience } from "../../models/audiences";
import {
    DataSource,
    DataSourceCrmField,
    DataSourceCrmFieldDefinition,
    DataSourceCrmParameter,
    DataSourceCrmParameterDefinition,
    DataSourceSetup,
    DataSourceStats,
    DataSourceStatus,
    DataSourceType,
    EDataSourceStatus,
    RequestedDataSourceType
} from "../../models/dataSource";
import { DataSourceEvent } from "../../models/events";
import { UploadedFile } from "../../models/fileUpload";
import { RootState } from "../../reducers";
import { API_URL, audiencerateApi } from "../audiencerate";
import { Trait } from "../../models/traits";

export const dataSourcesApi = audiencerateApi.injectEndpoints({
    endpoints: (build) => ({
        getDataSources: build.query<{ items: DataSource[]; limit: number; offset: number }, GetDataSourcesArg>({
            query: ({ workspaceId, limit, offset, status, searchText: q }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources`,
                params: { limit, offset, status, q }
            }),
            providesTags: ["DataSources"]
        }),
        getDataSourcesByIds: build.query<DataSource[], GetDataSourcesByIdsArg>({
            query: ({ workspaceId, datasourceIds }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/multi`,
                params: { datasourceId: datasourceIds }
            })
        }),
        getDataSource: build.query<DataSource, GetDataSourceArg>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}`
            }),
            providesTags: ["DataSource"]
        }),
        saveDataSource: build.mutation<CreateDataSourceResponse, SaveDataSourceArg>({
            query: ({ workspaceId, dataSource }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources`,
                method: "POST",
                body: dataSource
            }),
            invalidatesTags: ["DataSources", "DataSource"]
        }),
        updateDataSource: build.mutation<DataSource, UpdateDataSourceArg>({
            query: ({ workspaceId, dataSourceId, dataSource }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}`,
                method: "PATCH",
                body: dataSource
            }),
            invalidatesTags: ["DataSources", "DataSource"]
        }),
        deleteDataSource: build.mutation<void, GetDataSourceArg>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}`,
                method: "DELETE"
            }),
            invalidatesTags: ["DataSources"]
        }),
        getDataSourceSetup: build.query<DataSourceSetup, GetDataSourceArg>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/setup`
            })
        }),
        getDataSourceStatus: build.query<DataSourceStatus, GetDataSourceArg>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/status`
            })
        }),
        getDataSourceTypes: build.query<DataSourceType[], void>({
            query: () => ({
                url: `/v1/datasources/types`
            })
        }),
        getRequestedDataSourceTypes: build.query<RequestedDataSourceType[], void>({
            query: () => ({
                url: `/v1/datasources/types/requested`
            })
        }),
        getDataSourceStats: build.query<DataSourceStats, GetDataSourceStatsArg>({
            query: ({ workspaceId, dataSourceId, traitId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/stats`,
                params: { traitId }
            })
        }),
        postDataSourceDownloadUrl: build.mutation<{ url: string; expiration: string }, PostDataSourceDownloadUrlArg>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/presignedurl`,
                method: "POST"
            })
        }),
        crmDataSourceAuthenticated: build.mutation<CreateDataSourceResponse, DataSourceAuthenticatedArgs>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/authenticated`,
                method: "POST"
            })
        }),
        updateCrmDataSourceParameters: build.mutation<CreateDataSourceResponse, UpdateDataSourceParametersArg>({
            query: ({ workspaceId, dataSourceId, parameters }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/update-parameters`,
                method: "PUT",
                body: {
                    parameters
                }
            })
        }),
        updateCrmDataSourceFields: build.mutation<CreateDataSourceResponse, UpdateDataSourceFieldsArg>({
            query: ({ workspaceId, dataSourceId, fields }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/update-fields`,
                method: "PUT",
                body: {
                    fields
                }
            })
        }),
        uploadDataSourceFile: build.mutation<UploadedFile, UploadDataSourceFileArg>({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            queryFn: async (args, { getState, dispatch }) => {
                console.log(args);
                const token = (getState() as RootState).auth.token;
                const encrypted = (getState() as RootState).fileUploading.encrypted;

                const config: AxiosRequestConfig = {
                    headers: {
                        "x-ms-blob-type": "BlockBlob"
                    },
                    onUploadProgress: (progressEvent) => {
                        dispatch(
                            updateUploadProgress({
                                loaded: progressEvent.loaded,
                                total: progressEvent.total,
                                percentageCompleted: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            })
                        );
                    },
                    cancelToken: args.cancelTokenSource.token
                };

                try {
                    const file: File = args.file.get('file') as File;
                    const presignedurlResponse = await axios.post(`${API_URL}/v1/upload/tmp/presignedurl`, { encrypted: encrypted || false, filename: file.name }, { headers: { "Authorization": `Bearer ${token}` } });
                    const presignedurl = presignedurlResponse.data.url;
                    const path = presignedurlResponse.data.path;
                    console.log(presignedurl, path);
                    const result = await axios.put(presignedurl, file, config);
                    console.log(result);

                    return { data: { path } };
                } catch (axiosError) {
                    const err = axiosError as AxiosError;

                    return {
                        error: { status: err.response?.status, data: err.response?.data || err.message }
                    };
                }
            }
        }),
        getDataSourceTmpLines: build.query<string[], GetDataSourceTmpFileLinesArgs>({
            query: ({ workspaceId, key, limit }) => ({
                url: `/v1/upload/tmp?workspaceId=${workspaceId}&key=${key}&limit=${limit || 10}`
            })
        }),
        getDataSourceDependencies: build.query<Audiences, GetDataSourceArg>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/dependencies`
            })
        }),
        getDataSourceEvents: build.query<DataSourceEvent[], GetDataSourceEventsArgs>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/events`
            })
        }),
        getDataSourceFileLines: build.query<string[], GetDataSourceEventsArgs>({
            query: ({ workspaceId, dataSourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${dataSourceId}/lines`
            })
        }),
        getCustomProperties: build.query<GetCustomPropertyData, { workspaceId: string, datasourceIds: string[] }>({
            query: ({ workspaceId, datasourceIds }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/custom-properties`,
                params: {
                    datasourceIds
                }
            })
        }),
        getTraitDistributions: build.query<DatasourceTraitDistributions[], { workspaceId: string, datasourceIds: string[] }>({
            query: ({ workspaceId, datasourceIds }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/trait-distributions`,
                params: {
                    datasourceIds
                }
            })
        }),
        getSingleTraitDistributions: build.query<DatasourceTraitDistributions, { workspaceId: string, datasourceId: string }>({
            query: ({ workspaceId, datasourceId }) => ({
                url: `/v1/workspaces/${workspaceId}/datasources/${datasourceId}/trait-distributions`,
            })
        })
    }),
    overrideExisting: false
});

type Audiences = {
    audiences: Pick<Audience, "id" | "name">[];
};

type GetDataSourceArg = {
    workspaceId: string;
    dataSourceId: string;
};

type GetDataSourceStatsArg = {
    workspaceId: string;
    dataSourceId: string;
    traitId?: string;
};

type SaveDataSourceArg = {
    workspaceId: string;
    dataSource: Pick<DataSource, "name" | "configuration"> & { type: string };
};

type UpdateDataSourceArg = {
    workspaceId: string;
    dataSourceId: string;
    dataSource: Pick<DataSource, "name" | "configuration"> | { activate: boolean };
};

type GetDataSourcesArg = {
    workspaceId: string;
    limit?: number;
    offset?: number;
    status?: EDataSourceStatus;
    searchText?: string;
};

type GetDataSourcesByIdsArg = {
    workspaceId: string;
    datasourceIds: string[];
    limit?: number;
    offset?: number;
};

type PostDataSourceDownloadUrlArg = {
    workspaceId: string;
    dataSourceId: string;
};

type GetDataSourceEventsArgs = {
    workspaceId: string;
    dataSourceId: string;
};

type GetDataSourceTmpFileLinesArgs = {
    workspaceId: string;
    key: string;
    limit?: number;
};

type DataSourceAuthenticatedArgs = {
    workspaceId: string;
    dataSourceId: string;
};

type UpdateDataSourceParametersArg = {
    workspaceId: string;
    dataSourceId: string;
    parameters: DataSourceCrmParameter[];
};

type DataSourceIntegrationResult = {
    cyclr: {
        action: {
            authUrl?: string;
            token?: string;
            accountConnectors?: string[];
            parameters?: DataSourceCrmParameterDefinition[];
            fields?: DataSourceCrmFieldDefinition[];
        };
    };
}

export type CreateDataSourceResponse = {
    dataSource: DataSource;
    integrationsResult: DataSourceIntegrationResult;
}

type UpdateDataSourceFieldsArg = {
    workspaceId: string;
    dataSourceId: string;
    fields: DataSourceCrmField[];
};

export type UploadDataSourceFileArg = {
    file: FormData;
    cancelTokenSource: CancelTokenSource;
};

export type CustomProperty = {
    displayName: string;
    property: string;
    type: string;
}

export type GetCustomPropertyData = {
    properties: CustomProperty[]
}

export type TraitDistribution = {
    count: number;
    total: number;
    trait: Trait;
}

export type DatasourceTraitDistributions = {
    id: string;
    distributions: TraitDistribution[];
}

export const {
    useLazyGetDataSourcesQuery,
    useGetDataSourcesQuery,
    useGetDataSourcesByIdsQuery,
    useLazyGetDataSourcesByIdsQuery,
    useLazyGetDataSourceQuery,
    useGetDataSourceQuery,
    useSaveDataSourceMutation,
    useUpdateDataSourceMutation,
    useLazyGetDataSourceSetupQuery,
    useGetDataSourceSetupQuery,
    useLazyGetDataSourceStatusQuery,
    useGetDataSourceStatusQuery,
    useGetDataSourceTypesQuery,
    useDeleteDataSourceMutation,
    useGetRequestedDataSourceTypesQuery,
    useLazyGetRequestedDataSourceTypesQuery,
    useCrmDataSourceAuthenticatedMutation,
    useUpdateCrmDataSourceParametersMutation,
    useUpdateCrmDataSourceFieldsMutation,
    useUploadDataSourceFileMutation,
    useGetDataSourceStatsQuery,
    useLazyGetDataSourceStatsQuery,
    useGetDataSourceDependenciesQuery,
    useLazyGetDataSourceDependenciesQuery,
    usePostDataSourceDownloadUrlMutation,
    useGetDataSourceEventsQuery,
    useLazyGetDataSourceEventsQuery,
    useLazyGetDataSourceFileLinesQuery,
    useLazyGetDataSourceTmpLinesQuery,
    useGetCustomPropertiesQuery,
    useLazyGetCustomPropertiesQuery,
    useGetTraitDistributionsQuery,
    useGetSingleTraitDistributionsQuery
} = dataSourcesApi;
