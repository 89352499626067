import { TrashIcon } from "@heroicons/react/solid";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/buttons";
import { CompanyId, CompanyUser } from "../../../models/company";
import { IModalContentProps } from "../../../models/modals";
import { ToastType } from "../../../models/toast";
import { useAppDispatch } from "../../../reducers";
import { useDeleteCompanyUserMutation } from "../../../services/endpoints/companies";
import { showToast } from "../../toasts/toastsSlice";

interface IDeleteCompanyUserModal {
  companyId: CompanyId;
  user: CompanyUser;
}

const DeleteCompanyUserModal: FunctionComponent<IDeleteCompanyUserModal & IModalContentProps> = ({ companyId, user, closeModal }) => {
  const { t } = useTranslation('company');
  const dispatch = useAppDispatch();

  const [deleteUser, { isLoading, isSuccess }] = useDeleteCompanyUserMutation();

  const handleDeleteClicked = () => {
    deleteUser({ companyId, id: user.id });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('users.delete.success_title'),
          message: t('users.delete.success_message', { email: user.email }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className='flex justify-center h-60 '>
      <div className='max-w-4xl my-auto text-left p-5'>
        <h1 className='text-lg text-black-100 font-semibold mb-5'>{t('users.delete.modal_title')}</h1>
        <p className='text-base'>
          {t('users.delete.modal_confirm')}&nbsp;(<b>{user.email}</b>)
        </p>
        <Button className="mt-3" icon={TrashIcon} variant='delete' onClick={handleDeleteClicked} loading={isLoading} >
          {t('users.delete.modal_button_title')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteCompanyUserModal;
