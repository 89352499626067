import axios, { CancelTokenSource } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps, getIn } from 'formik';
import { first, isEmpty } from 'lodash';
import * as csvParser from 'papaparse';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Alert from '../../../../../components/alerts/Alert';
import { SpinnerIcon } from '../../../../../components/icons';
import { FileUploadInput, Input } from '../../../../../components/inputs';
import { FileUploadStatus } from '../../../../../components/inputs/';
import { useFileUploadContext } from '../../../../../hooks/useFileUploadContext';
import { DataSourceFileConfig, DataSourceTypeCodeConfig, TDataSource } from '../../../../../models/dataSource';
import { EStatus } from '../../../../../models/fileUpload';
import { useAppDispatch, useAppSelector } from '../../../../../reducers';
import { useCreateWorkspaceEncryptionKeyPairMutation, useGetWorkspaceEncryptionPublicKeyQuery } from '../../../../../services/endpoints/workspaces';
import { stashFileDataSource } from '../../../../dataSources/dataSourcesSlice';
import { resetUploadStatus, updateEncrypted, updateFileData, updateUploadStatus } from '../../../../fileUploading/fileUploadingSlice';
import { useWorkspace } from '../../../../workspaces/hooks';

export type FileDataSourceUploadFormModel = Partial<TDataSource<DataSourceFileConfig>>;

interface IFileDataSourceUploadFormProps {
  formRef: React.RefObject<FormikProps<FileDataSourceUploadFormModel>>;
  onSubmit: (form: FileDataSourceUploadFormModel) => void;
  dataSource?: Partial<TDataSource<DataSourceFileConfig>>;
}

const CSV_ROWS_NUMBER = 5;

const FileDataSourceUploadForm: FunctionComponent<IFileDataSourceUploadFormProps> = ({ formRef, onSubmit, dataSource }) => {
  const dispatch = useAppDispatch();
  const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | null>(null);
  const typeConfiguration = dataSource?.type?.configuration as DataSourceTypeCodeConfig | undefined;
  const encrypted = typeConfiguration?.encrypted;

  const uploadStatus = useAppSelector(state => state.fileUploading.status);
  const workspace = useWorkspace();
  const { t } = useTranslation();
  const { uploadFile } = useFileUploadContext();
  const { isLoading, data: publicKey } = useGetWorkspaceEncryptionPublicKeyQuery({ workspaceId: workspace.id });
  const [createEncryptionPair, { isLoading: isCreatingPublicKey }] = useCreateWorkspaceEncryptionKeyPairMutation();

  let alertView: ReactNode;
  if (encrypted) {
    if (isLoading || isCreatingPublicKey) {
      alertView = (
        <span className='text-blue-500 opacity-75 my-2 relative'>
          <SpinnerIcon className='mr-1 h-5 w-5' loading />
        </span>
      );
    } else if (publicKey?.keyId == null) {
      alertView = (
        <Alert
          type={'WARNING'}
          title={t('data_source_edit:file.encryption.public_key_missing')}
          description={t('data_source_edit:file.encryption.key_pair_creation_message')}
          actionButtonText={t('data_source_edit:file.encryption.key_pair_creation_action')}
          onAction={() => createEncryptionPair({ workspaceId: workspace.id })}
        />
      );
    } else {
      alertView = (
        <Alert
          type={'INFO'}
          title={t('data_source_edit:file.encryption.public_key_title')}
          description={t('data_source_edit:file.encryption.public_key_message')}
          actionButtonText={t('data_source_edit:file.encryption.public_key_download')}
          onAction={() => {
            const file = new File([publicKey.publicKeyRing], `${workspace.id}-public-key.txt`, { type: 'text/plain' });
            const url = window.URL.createObjectURL(file);
            const a = window.document.createElement('a');
            a.download = file.name;
            a.href = url;
            a.click();
          }}
        />
      );
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t('data_source_edit:validations.too_short'))
      .max(50, t('data_source_edit:validations.too_long'))
      .required(t('data_source_edit:validations.required')),
    configuration: Yup.object({
      filename: Yup.string().required(t('data_source_edit:file.name_and_file.file_upload_input_component.input_file_required')),
    }),
  });

  // FormikHelpers

  const handleChangeFile = ({ setFieldValue }: Pick<FormikHelpers<Partial<TDataSource<DataSourceFileConfig>>>, 'setFieldValue'>) => {
    setFieldValue('configuration.filename', undefined);

    dispatch(resetUploadStatus());
    dispatch(stashFileDataSource({ configuration: { ...dataSource?.configuration, url: undefined } }));
  };

  const handleCancelUploading = ({ setFieldValue }: Pick<FormikHelpers<Partial<TDataSource<DataSourceFileConfig>>>, 'setFieldValue'>) => {
    setFieldValue('configuration.filename', undefined);

    axiosCancelToken?.cancel(t('axios:uploading_canceled'));
    dispatch(resetUploadStatus());
  };

  const handleUploadFile = (file: File, cancelToken: CancelTokenSource) => {
    uploadFile(file, cancelToken);
  };

  const handleValidFile = (results: csvParser.ParseResult<string[]>, file: File) => {
    const csvFile = results.data;

    if (!isEmpty(csvFile)) {
      dispatch(resetUploadStatus());
      dispatch(updateFileData({ preview: csvFile.slice(0, CSV_ROWS_NUMBER), header: csvFile[0], isDefaultHeader: true }));
    } else {
      throwFileError();
      return;
    }

    const cancelToken = axios.CancelToken.source();
    setAxiosCancelToken(cancelToken);

    if (file && cancelToken) {
      handleUploadFile(file, cancelToken);
    }
  };

  const throwFileError = () => {
    dispatch(
      updateUploadStatus({
        uploadingStatus: EStatus.ERROR,
        data: undefined,
        error: t('data_source_edit:file.name_and_file.file_upload_input_component.file_not_loaded'),
      })
    );
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ ...dataSource, name: '' }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, setFieldError, setFieldValue }) => {
        return (
          <Form>
            {alertView}
            <Input
              name='name'
              id='name'
              type='text'
              label={t('data_source_edit:file.name_and_file.source_name')}
              value={values.name}
              placeholder={t('data_source_edit:file.name_and_file.source_name_placeholder')}
              onChange={(e) => {
                setFieldError('name', undefined);
                handleChange('name')(e.target.value);
              }}
              error={errors.name}
            />
            <label className='block text-sm font-medium text-left text-gray-700 py-2 mt-4'>{t('data_source_edit:file.csv_input_label')}</label>
            {uploadStatus && uploadStatus?.uploadingStatus !== EStatus.NOT_SELECTED ? (
              <FileUploadStatus
                handleChangeFile={() => handleChangeFile({ setFieldValue: setFieldValue })}
                handleCancel={() => handleCancelUploading({ setFieldValue: setFieldValue })}
                error={uploadStatus.error}
                fileUpload={{
                  status: uploadStatus.uploadingStatus,
                  percentage: uploadStatus.progress || { total: 0, loaded: 0, percentageCompleted: 0 },
                }}
              />
            ) : (
              <FileUploadInput
                multiple={false}
                name='configuration.filename'
                id='configuration.filename'
                error={getIn(errors.configuration, 'filename')}
                onChange={(files) => {
                  const file = first(files);
                  if (file && file.name) {
                    setFieldValue('configuration.filename', file.name);
                    setFieldError('configuration.filename', undefined);
                  } else {
                    setFieldError('configuration.filename', 'data_source_edit:file.name_and_file.file_upload_input_component.file_not_loaded');
                    setFieldValue('configuration.filename', undefined);
                    return;
                  }
                  dispatch(
                    updateEncrypted(encrypted)
                  );
                  if (!encrypted) {
                    // CSV VALIDATION
                    const csvPath = URL.createObjectURL(file);
            
                    csvParser.parse(csvPath, {
                      download: true,
                      skipEmptyLines: true,
                      complete: (results: csvParser.ParseResult<string[]>) => handleValidFile(results, file),
                      error: throwFileError,
                    });
                  } else {
                    const cancelToken = axios.CancelToken.source();
                    setAxiosCancelToken(cancelToken);

                    if (file && cancelToken) {
                      handleUploadFile(file, cancelToken);
                    }

                  }
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default FileDataSourceUploadForm;
