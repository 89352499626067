import React, { FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';
import { Landing } from '../landing';
import { ToastProvider } from '../../providers';
import { PublicRoute, AuthenticatedRoutes } from './components';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';

const Routes: FunctionComponent = () => {
  const { user } = useAuth0();
  if (user) {
    datadogRum.setUser({
      id: user.sub,
      email: user.email,
      name: user.name
    });
  }
  return (
    <>
      <Switch>
        <PublicRoute exact path='/' component={Landing} />
        <AuthenticatedRoutes />
      </Switch>
      <ToastProvider />
    </>
  );
};

export default Routes;
