import { createSlice } from '@reduxjs/toolkit';

type ThemeState = {
  className: string;
};

export const initialState: ThemeState = {
  className: 'theme-light',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggle(state) {
      if (state.className == 'theme-light') {
        state.className = 'theme-dark';
      } else {
        state.className = 'theme-light';
      }
    },
  },
});

export default themeSlice.reducer;
