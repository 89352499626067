import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSourceHeader } from '../..';
import { DataSourceType } from '../../../../../models/dataSource';

interface IEmptyDataSourceContainerProps {
  dataSourceType: DataSourceType;
  closeModal: () => void;
}

const EmptyDataSourceContainer: FunctionComponent<IEmptyDataSourceContainerProps> = ({ closeModal, dataSourceType }) => {
  const { t } = useTranslation('data_source_edit');

  return (
    <div className='h-full divide-y divide-gray-200 flex flex-col'>
      <div className='min-h-0 flex-1 flex flex-col pt-6 bg-gray-50 rounded-lg'>
        <DataSourceHeader closeModal={closeModal} title={t('title', { name: dataSourceType.name })} />
        <div className='mt-6 py-6 relative flex-1 px-4 sm:px-6 bg-white-100 overflow-y-auto'></div>
      </div>
    </div>
  );
};

export default EmptyDataSourceContainer;
