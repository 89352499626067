import React, { FunctionComponent, useEffect, useState } from 'react';
import * as csvParser from 'papaparse';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../reducers';
import { CSVTable } from '../..';
import { EStatus } from '../../../../../models/fileUpload';
import { SpinnerIcon } from '../../../../../components/icons';
import { useLazyGetDataSourceTmpLinesQuery } from '../../../../../services/dataSources';
import { useWorkspace } from '../../../../workspaces/hooks';
import { updateFileData } from '../../../../fileUploading/fileUploadingSlice';

interface IFileDataSourceCSVHeaderProps { }

const DEFAULT_HEADER = 'defaultHeader';
const ITEM_HEADER = 'itemHeader';

const FileDataSourceCSVHeader: FunctionComponent<IFileDataSourceCSVHeaderProps> = () => {
  const dispatch = useAppDispatch();
  const { id: workspaceId } = useWorkspace();
  const csvFileData = useAppSelector(state => state.fileUploading.fileData);
  const fileUploadStatus = useAppSelector(state => state.fileUploading.status);
  const encrypted = useAppSelector(state => state.fileUploading.encrypted);

  const [csvHeader, setCSVHeader] = useState<string>(csvFileData?.isDefaultHeader ? DEFAULT_HEADER : ITEM_HEADER);
  const [encryptedLoading, setEncryptedLoading] = useState(false);

  const [getLines, { isLoading: isLoadingTmpLines, isSuccess: isSuccessTmpLines, data: tmpLines }] = useLazyGetDataSourceTmpLinesQuery();

  const { t } = useTranslation('data_source_edit');
  useEffect(() => {
    if (encrypted) {
      if (fileUploadStatus?.uploadingStatus == EStatus.SUCCESS) {
        console.log('start preview download', fileUploadStatus);
        const key = fileUploadStatus?.data?.path;
        if (key) {
          getLines({ workspaceId, key, limit: 10 });
        }
      } else if (fileUploadStatus?.uploadingStatus == EStatus.UPLOADING) {
        setEncryptedLoading(true);
      } else {
        setEncryptedLoading(false);
      }
    }
  }, [encrypted, fileUploadStatus]);

  useEffect(() => {
    if (isSuccessTmpLines && tmpLines) {
      console.log(tmpLines);
      csvParser.parse(tmpLines.slice(0, 10).join("\n"), {
        skipEmptyLines: true,
        complete: (results: csvParser.ParseResult<string[]>) => {
          console.log(results);
          dispatch(updateFileData({ preview: results.data, header: results.data[0], isDefaultHeader: true }));
        }
      });
    }
  }, [isSuccessTmpLines]);

  return (
    <div className='flex flex-col justify-start'>
      <div className='block justify-start text-left mb-8'>
        <span className='text-gray-700 text-sm'>{t('file.header.is_this_header')}</span>
        <div className='mt-2 ml-2'>
          <div className='flex justify-start my-1'>
            <label className='inline-flex cursor-pointer'>
              <div className='flex m-auto'>
                <input
                  type='radio'
                  className='form-radio cursor-pointer'
                  name='radio'
                  value={DEFAULT_HEADER}
                  checked={csvHeader === DEFAULT_HEADER}
                  onChange={() => setCSVHeader(DEFAULT_HEADER)}
                />
              </div>
              <span className='ml-2 text-gray-700 text-sm'>{t('file.header.yes_this_is_the_header')}</span>
            </label>
          </div>
          <div className='flex justify-start my-1'>
            <label className='inline-flex cursor-pointer'>
              <div className='flex m-auto'>
                <input
                  type='radio'
                  className='form-radio cursor-pointer'
                  name='radio'
                  value={ITEM_HEADER}
                  checked={csvHeader === ITEM_HEADER}
                  onChange={() => setCSVHeader(ITEM_HEADER)}
                />
              </div>
              <span className='ml-2 text-gray-700 text-sm'>{t('file.header.no_its_an_item')}</span>
            </label>
          </div>
        </div>
      </div>
      <div>
        {(isLoadingTmpLines || encryptedLoading) && (
          <div className='flex text-center justify-center h-7 mb-2'>
            <SpinnerIcon className='h-8 w-8' fill='rgba(21,68,155,1)' loading />
          </div>
        )}
        {csvFileData ? (
          <div className='flex'>
            <div className='flex-initial'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 my-2' fill='#276EF1' viewBox='0 0 24 24' stroke='#276EF1'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M13 7l5 5m0 0l-5 5m5-5H6' />
              </svg>
            </div>
            <div className='flex-auto overflow-x-auto'>
              <CSVTable fileContent={csvFileData.preview} fileHeader={csvFileData.header} isDefaultHeaderCorrect={csvHeader === DEFAULT_HEADER} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FileDataSourceCSVHeader;
