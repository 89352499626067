import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, ICheckboxProps } from '../../../components/inputs';
import { DataSource } from '../../../models/dataSource';
import { useLazyGetDataSourceStatsQuery } from '../../../services/dataSources';
import { useWorkspace } from '../../workspaces/hooks';

type DefaultProps = ICheckboxProps & React.ComponentProps<'input'>;

interface IDataSourceCheckboxProps extends DefaultProps {
  dataSource: DataSource;
  traitId?: string;
}

const DataSourceCheckbox: FunctionComponent<IDataSourceCheckboxProps> = ({ dataSource, traitId, ...rest }) => {
  const { t } = useTranslation('audience_edit');
  const workspace = useWorkspace();

  const [getStats, { isLoading, data }] = useLazyGetDataSourceStatsQuery();

  useEffect(() => {
    getStats({
      workspaceId: workspace.id,
      dataSourceId: dataSource.id,
      traitId,
    });
  }, [traitId]);

  const description = data
    ? t('audience_details.datasource_stats', {
        users: data.users || 0,
        events: data.events || 0,
        traits: data.traits || 0,
      })
    : undefined;

  return <Checkbox {...rest} name={dataSource.id} id={dataSource.id} loading={isLoading} label={dataSource.name} description={description} />;
};

export default DataSourceCheckbox;
