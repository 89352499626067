import React, { FunctionComponent, useEffect, useState } from 'react';
import { Company } from '../../../../models/company';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '../../../../components/buttons';
import { useCreateCompanyMutation } from '../../../../services/endpoints/companies';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../../reducers';
import { useSelector } from 'react-redux';
import { setCompany } from '../../../userInfo/userInfoSlice';

interface CompanyCreateProps {
  complete?: () => void;
}

const CompanyCreate: FunctionComponent<CompanyCreateProps> = ({ complete }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('company');
  const { user } = useAuth0();

  const company = useSelector((r: RootState) => r.userInfo.company);
  const [createCompany, { data: createdCompany, isLoading: isCreateCompanyLoading }] = useCreateCompanyMutation();

  const [alreadyCreated, setAlreadyCreated] = useState(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [companyMainDomain, setCompanyMainDomain] = useState<string>('');
  const [hasOneDomain, setHasOneDomain] = useState<boolean>(true);
  const [otherDomains, setOtherDomains] = useState<string[]>(['']);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [domainErrorMap, setDomainErrorMap] = useState<{ [index: number]: boolean }>({});

  useEffect(() => {
    if (company) {
      setAlreadyCreated(true);
      setCompanyName(company.name);
      setCompanyMainDomain(company.domains[0]);
      setHasOneDomain(company.domains.length === 1);
      setOtherDomains(company.domains.slice(1));
    }
  }, [company]);

  useEffect(() => {
    console.log('createdCompany', createdCompany);
    if (createdCompany) {
      console.log('dispatch');
      dispatch(setCompany(createdCompany));
    }
  }, [createdCompany]);

  useEffect(() => {
    if (user) {
      const mainDomain = user?.email?.split('@')[1];
      if (mainDomain) {
        setCompanyMainDomain(mainDomain);
      }
    }
  }, [user]);

  const domainValidateRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  const create = () => {
    if (isCreateCompanyLoading) {
      return;
    }

    const domainErrorMap: { [index: number]: boolean } = {};
    let hasIncorrectDomain = false;
    for (let i = 0; i < otherDomains.length; i++) {
      const domain = otherDomains[i];
      if (domain) {
        const hasError = !domainValidateRegex.test(domain);
        domainErrorMap[i] = hasError;
        hasIncorrectDomain = hasIncorrectDomain || hasError;
      }
    }
    setDomainErrorMap(domainErrorMap);

    if (hasIncorrectDomain) {
      setErrorMessage(t('creation.check_your_domains'));
      return;
    } else {
      setErrorMessage('');
    }

    const company: Partial<Company> = {
      name: companyName,
      domains: hasOneDomain ? [companyMainDomain] : [companyMainDomain, ...otherDomains.filter((domain) => !!domain)],
    };
    // return console.log(company);
    createCompany({ company });
  };

  return (
    <div className={'bg-wave flex items-center justify-center h-full w-full bg-gray-100'}>
      <div className={'flex flex-col items-center bg-white-100 p-8 shadow-md rounded-lg'} style={{ width: '400px' }}>
        <h3 className={'font-semibold mb-5 text-xl'}>Create the company</h3>
        <div className={'image-area rounded-full h-16 w-16 flex items-center justify-center bg-blue-500 mb-6 text-lg'}>
          {companyName ? companyName[0].toUpperCase() : 'C'}
        </div>
        {errorMessage && <div className={'border-red-300 bg-red-600 text-white-100 w-full p-2 mb-3 rounded'}>{errorMessage}</div>}
        <form className={'w-full'} >
          <div className={'mb-4'}>
            <label className={'block mb-1 font-medium'}>{t('creation.company_name')}</label>
            <input
              className={'border-gray-300 rounded-md w-full shadow-sm'}
              type={'text'}
              value={companyName}
              disabled={alreadyCreated}
              readOnly={isCreateCompanyLoading || alreadyCreated}
              onChange={(event) => setCompanyName(event.target.value)}
            />
          </div>
          <div className={'mb-4'}>
            <label className={'block mb-1 font-medium'}>{t('creation.domain')}</label>
            <input className={'border-gray-300 rounded-md w-full bg-gray-100 shadow-sm'} disabled={true} type={'text'} value={companyMainDomain} />
          </div>
          <div className={'mb-6'}>
            <label>
              <input
                className={'mr-2'}
                type={'checkbox'}
                checked={hasOneDomain}
                disabled={alreadyCreated}
                readOnly={alreadyCreated}
                onChange={(event) => !alreadyCreated && !isCreateCompanyLoading && setHasOneDomain(event.target.checked)}
              />
              {t('creation.my_company_has_only_one_domain')}
            </label>
          </div>
          {!hasOneDomain &&
            otherDomains.map((domain, index) => (
              <div key={index} className={'mb-3'}>
                <input
                  className={`rounded-md shadow-sm w-full ${domainErrorMap[index] ? 'border-red-300' : 'border-gray-300'}`}
                  type={'text'}
                  placeholder={'other-domain.com'}
                  readOnly={isCreateCompanyLoading || alreadyCreated}
                  value={domain}
                  onChange={(event) => {
                    otherDomains[index] = event.target.value;
                    if (otherDomains[otherDomains.length - 1]) {
                      // last element not empty
                      otherDomains.push('');
                    } else {
                      // last element empty
                      let i = otherDomains.length - 1;
                      while (i > 0 && !otherDomains[i - 1]) {
                        otherDomains.pop();
                        i--;
                      }
                    }
                    setOtherDomains([...otherDomains]);
                  }}
                />
              </div>
            ))}

          {alreadyCreated ? (
            <Button className={'w-full'} type={'button'} loading={isCreateCompanyLoading} onClick={ () => complete && complete() }>
              {t('creation.btn_continue')}
            </Button>
          ) : (
            <Button className={'w-full'} type={'button'} disabled={!companyName} loading={isCreateCompanyLoading} onClick={() => create() }>
              {t('creation.btn_continue')}
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default CompanyCreate;
