import { format, setDate } from "date-fns";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Select } from "../../../../components/selects";
import { AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import { ISelectItem } from "../../../../models/select";

interface IAudienceSyncRepeatMonthdayProps {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const AudienceSyncRepeatMonthday: FunctionComponent<IAudienceSyncRepeatMonthdayProps> = ({ scheduleConfiguration, setFieldValue }) => {
  const seed = new Date();
  const days: ISelectItem[] = Array.from(Array(31).keys()).map((value) => ({
    id: value + 1,
    label: format(setDate(seed, value + 1), "do")
  }));
  const [day, setDay] = useState<ISelectItem>(scheduleConfiguration.dayOfMonth ? days[scheduleConfiguration.dayOfMonth - 1] : days[0]);

  useEffect(() => {
    if (day) {
      setFieldValue("scheduleConfiguration.dayOfMonth", day.id);
    }
  }, [day]);

  return (
    <div className="flex flex-row items-center mb-8">
      <span className="mr-4">
        On the
      </span>
      <div className="w-32 mr-4">
        <Select
          items={days}
          value={day}
          onChange={setDay}
        />
      </div>
    </div>
  );
};

export default AudienceSyncRepeatMonthday;
