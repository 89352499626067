import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from '../../components/buttons';
import { Input } from '../../components/inputs';
import { useUserInfo } from '../userInfo/hooks';
import RoleSelect, { IRoleSelectItem } from './components/RoleSelect';
import LeadingIcon from '../../assets/icons/leading.svg?react';
import EyeIcon from '../../assets/icons/eye.svg?react';
import { Workspace } from '../../models/workspace';
import { useInviteWorkspaceMutation, useSaveWorkspaceMutation } from '../../services/endpoints/workspaces';
import RouteConstants from '../router/RouteConstants';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../reducers';
import { setCurrentWorkspace } from '../workspaces/workspacesSlice';

type Collaborator = {
    email: string;
    role: string;
};

type CollaboratorsFormModel = { collaborators: Collaborator[] };

interface IInviteWorkspaceExternalCollaboratorsProps {
    workspace: Workspace;
    internalCollaborators?: Collaborator[];
    internalCollaboratorsChange?: (collaborators: Collaborator[]) => void;
    externalCollaborators?: Collaborator[];
    externalCollaboratorsChange?: (collaborators: Collaborator[]) => void;
    prevStep?: () => void;
}

const InviteWorkspaceExternalCollaborators: FunctionComponent<IInviteWorkspaceExternalCollaboratorsProps> = ({
    workspace,
    internalCollaborators,
    internalCollaboratorsChange,
    externalCollaborators,
    externalCollaboratorsChange,
    prevStep,
}) => {
    const { t } = useTranslation('workspaces');
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [saveWorkspace, { isLoading: isWorkspaceSaveLoading, data: createdWorkspace }] = useSaveWorkspaceMutation();
    const [sendInvite, { isLoading: isInviteLoading }] = useInviteWorkspaceMutation();

    const validationSchema = Yup.object({
        collaborators: Yup.array(
            Yup.object({
                email: Yup.string().email(t('edit.validations.not_valid_email')),
                role: Yup.string().test('required-and-empty', t('edit.validations.required'), (value, control) => {
                    return !control.parent.email || !!value;
                }),
            })
        ),
    });

    useEffect(() => {
        if (createdWorkspace) {
            const allCollaborators = [...(internalCollaborators || []), ...(externalCollaborators || [])];
            for (const collaborator of allCollaborators) {
                sendInvite({
                    workspaceId: createdWorkspace.id,
                    collaborator,
                });
            }
            history.push(RouteConstants.home);
            dispatch(setCurrentWorkspace(createdWorkspace));
        }
    }, [createdWorkspace]);

    const roles: IRoleSelectItem[] = [
        {
            id: 'ADMIN',
            label: 'Admin',
            description: 'Can create workspaces and invite collaborators, and all the rest',
            image: <LeadingIcon className={'w-5 h-5'} />,
        },
        {
            id: 'READONLY',
            label: 'Readonly',
            description: 'Can normally use the platform, doing things like update data or create sources',
            image: <EyeIcon className={'w-5 h-5'} />,
        },
    ];

    const [errorMessage, setErrorMessage] = useState<string>('');
    const myForm = useRef<FormikProps<CollaboratorsFormModel>>(null);

    const doSubmit = () => {
        myForm.current?.submitForm();
    };

    const saveEverything = () => {
        saveWorkspace({ workspace });
    };

    const handleOnSubmit = (form: CollaboratorsFormModel) => {
        externalCollaboratorsChange?.(form.collaborators.filter((collaborator) => !!collaborator.email.trim()));
        // nextStep?.();
        saveEverything();
    };

    const handleCancel = () => {
        externalCollaboratorsChange?.(myForm.current?.values.collaborators.filter((collaborator) => !!collaborator.email.trim()) || []);
        prevStep?.();
    };

    return (
        <div className={'bg-wave flex items-center justify-center h-full w-full bg-gray-100'}>
            <div className={'flex flex-col items-center bg-white-100 p-8 shadow-md rounded-lg'} style={{ width: '448px' }}>
                <h3 className={'font-semibold mb-8 text-xl'}>{t('create.invite_external.title')}</h3>
                <p className={'text-medium mb-8'} dangerouslySetInnerHTML={{ __html: t('create.invite_external.description') }}></p>
                {errorMessage && <div className={'border-red-300 bg-red-600 text-white-100 w-full p-2 mb-3 rounded'}>{errorMessage}</div>}
                <Formik<CollaboratorsFormModel>
                    initialValues={{
                        collaborators: new Array(3)
                            .fill({
                                email: '',
                                role: '',
                            })
                            .map((item, i) => (externalCollaborators && externalCollaborators.length > i ? externalCollaborators[i] : { ...item })),
                    }}
                    onSubmit={handleOnSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={validationSchema}
                    innerRef={myForm}
                >
                    {({ values, errors, setFieldValue }) => {
                        return (
                            <Form className={'w-full'}>
                                <table className='mb-3 w-full'>
                                    {values.collaborators.map((collaborator, index) => (
                                        <tr key={index}>
                                            <td className={'pb-2'} valign={'top'}>
                                                <Input
                                                    name='name'
                                                    id='name'
                                                    type='text'
                                                    value={collaborator.email}
                                                    readOnly={isWorkspaceSaveLoading || isInviteLoading}
                                                    placeholder={t('create.invite_external.email')}
                                                    onChange={(e) => {
                                                        setFieldValue(`collaborators.${index}.email`, e.target.value);
                                                    }}
                                                    error={
                                                        errors.collaborators && errors.collaborators[index] ? (errors.collaborators[index] as FormikErrors<Collaborator>).email : undefined
                                                    }
                                                />
                                            </td>
                                            <td className={'pb-2 pl-3'} valign={'top'}>
                                                <RoleSelect
                                                    placeholder={'Choose'}
                                                    className={'w-full'}
                                                    disabled={isWorkspaceSaveLoading || isInviteLoading}
                                                    items={roles}
                                                    value={collaborator.role}
                                                    onChange={(value) => setFieldValue(`collaborators.${index}.role`, value)}
                                                />
                                                {errors.collaborators && errors.collaborators[index] && (
                                                    <span className={'mt-2 text-sm text-red-600'}>{(errors.collaborators[index] as FormikErrors<Collaborator>).role}</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </table>

                                <div className='flex justify-end flex-col'>
                                    <Button variant='confirm' type='submit' className='justify-center py-2 px-4 w-full mb-4' loading={isWorkspaceSaveLoading || isInviteLoading}>
                                        {t('create.invite_external.continue')}
                                    </Button>
                                    <Button
                                        variant='light'
                                        type='button'
                                        className='justify-center py-2 px-4 w-full'
                                        onClick={handleCancel}
                                        loading={isWorkspaceSaveLoading || isInviteLoading}
                                    >
                                        {t('create.invite_external.back')}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default InviteWorkspaceExternalCollaborators;
