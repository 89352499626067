import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerIcon } from '../../components/icons';

interface CyclrAuthRedirectProps { }

const CyclrAuthRedirect: FunctionComponent<CyclrAuthRedirectProps> = () => {
  const { t } = useTranslation('cyclr_integration_redirect');
  const opener = window.opener;
  if (opener) {
    const cyclrCallback = opener.window.cyclrAuthenticationCompleted;
    if (cyclrCallback) {
      cyclrCallback(true, window);
    } else if (opener.window.cyclrDeauthenticationCompleted) {
      opener.window.cyclrDeauthenticationCompleted(true, window);
    } else {
      window.close();
    }
  } else {
    window.close();
    // window.location.href = '/';
  }

  return (
    <div className={'text-center grow h-full'}>
      <span className='text-blue-500 opacity-75 my-0 relative inline-block w-7 h-7 pt-3'>
        <SpinnerIcon className='-ml-1 mr-3 h-5 w-5' loading />
      </span>
      {t('redirecting')}
    </div>
  );
};
export default CyclrAuthRedirect;
