import { ChatIcon } from '@heroicons/react/solid';
import { AiChat } from '@nlux/react';
import { highlighter } from '@nlux/highlighter';
import '@nlux/themes/nova.css';
import './custom-nlux-theme.css';
import React, { useEffect } from 'react';
import { useWebSocketChatAdapterContext } from '../../providers/WebSocketChatAdapterHook';
import { useAuth0 } from '@auth0/auth0-react';
import { API_URL } from '../../services/audiencerate';
import { useAppDispatch, useAppSelector } from '../../reducers';
import { toggleChat } from '../../features/chat/chatSlice';
import { ConversationItem } from '@nlux/core';

const botStyle = {
    background: 'linear-gradient(yellow, orange)',
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const userStyle = {
    background: 'gray',
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const wsUrl = `wss://${API_URL.replace('https://', '')}/chat`;

const ChatFloatingActionButton: React.FC = () => {
    const { user } = useAuth0();
    const dispatch = useAppDispatch();
    const open = useAppSelector((state) => state.chat.open);
    const { useAdapter, close, conversationHistory } = useWebSocketChatAdapterContext();
    // console.log("user", user, useAdapter, close);
    // const { useAdapter, close } = useWebSocketChatAdapter();
    const toggleAndClose = () => {
        if (open) {
            close();
        }
        dispatch(toggleChat());
    };

    useEffect(() => {
        // HACK: we need to wait for the DOM to be ready
        setTimeout(() => {
            const e = document.querySelector('.nluxc-conversation-messages-container');
            if (e) {
                // @ts-ignore
                e.scrollTo(0, e.scrollTopMax);
            }
        }, 10);
    }, [open]);

    return (
        <>
            {open && (
                <div className='fixed bottom-36 right-8 bg-gray-100 border border-gray-200 p-4 rounded-lg shadow-lg transition-opacity duration-500 ease-in-out'>
                    <AiChat
                        className='cdp-ai-chat'
                        initialConversation={conversationHistory as ConversationItem[]}
                        syntaxHighlighter={highlighter}
                        adapter={useAdapter(wsUrl)}
                        personaOptions={{
                            bot: {
                                name: 'Marketing Platform Assistant',
                                picture: <span style={botStyle}>🤖</span>,
                                tagline: 'An assistant for querying your workspace data',
                            },
                            user: {
                                name: user?.name || user?.email || 'User',
                                picture: <span style={userStyle}>{(user?.name || user?.email || 'U').toUpperCase()[0]}</span>,
                            },
                        }}
                        layoutOptions={{
                            height: 500,
                            maxWidth: 600,
                        }}
                    />
                </div>
            )}
            <button
                onClick={toggleAndClose}
                className='disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600 fixed bottom-16 right-8 bg-blue-600 hover:bg-blue-700 text-white p-4 rounded-full shadow-lg transition duration-300 ease-in-out'
            >
                <ChatIcon className='h-6 w-6 text-white-100' />
            </button>
        </>
    );
};

export default ChatFloatingActionButton;
