import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Transition } from '@headlessui/react';
import { MenuAlt2Icon, UserIcon } from '@heroicons/react/outline';
import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../features/auth/authSlice';
import { toggleSidebar } from '../../../features/sidebar/sidebarSlice';
import { useAppDispatch } from '../../../reducers';
import { concatClassNames } from '../../../utils';

interface ITopbar {
  title?: string;
  rightElement?: () => JSX.Element;
}

const Topbar: FunctionComponent<ITopbar> = ({ title, rightElement }) => {
  const { t: tTopbar } = useTranslation('topbar');
  const { t: tSrOnly } = useTranslation('sr_only');
  const { user } = useAuth0();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const onOpenSidebarClicked = () => {
    dispatch(toggleSidebar(true));
  };

  const onLogoutClicked = () => {
    dispatch(logout());
  };

  return (
    <div className='relative z-10 flex-shrink-0 flex h-16 bg-white-100 shadow'>
      <button
        type='button'
        className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden'
        onClick={onOpenSidebarClicked}
      >
        <span className='sr-only'>{tSrOnly('open_sidebar')}</span>
        <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
      </button>
      <div className='flex-1 px-4 flex justify-between'>
        <div className='flex-1 flex items-center'>
          <h1 className='text-gray-900 text-xl ml-4 md:ml-6'>{title}</h1>
        </div>
        <div className='ml-4 flex items-center md:ml-6'>
          {rightElement && rightElement()}
          {/*<button className='bg-white-100 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
            <span className='sr-only'>{t('sr_only:view_notification')}</span>
            <BellIcon className='h-6 w-6' aria-hidden='true' />
          </button>*/}

          {/* Profile dropdown */}
          <Menu as='div' className='ml-3 relative'>
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className='max-w-xs bg-white-100 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
                    <span className='sr-only'>{tSrOnly('open_user_menu')}</span>
                    <UserIcon className='h-6 w-6 text-gray-400' aria-hidden='true' />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items
                    static
                    className='origin-top-right absolute right-0 mt-2 min-w-48 rounded-md shadow-lg py-1 bg-white-100 ring-1 ring-black ring-opacity-5 focus:outline-none'
                  >
                    <Menu.Item>
                      {() => (
                        <span
                          className={'block px-4 py-2 text-sm'}
                        >
                          {user && <span className="flex text-sm text-gray-500">{user.name || user.email}</span>}
                        </span>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href='#'
                          onClick={() => history.push('/settings')}
                          className={concatClassNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          {tTopbar('company_settings')}
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href='#'
                          onClick={onLogoutClicked}
                          className={concatClassNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          {tTopbar('sign_out')}
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
