import { FunctionComponent, useEffect } from 'react';
import { useModalContext } from '../../../../hooks/useModalContext';
import { DataSource, DataSourceCodeConfig, DataSourceCrmConfig, DataSourceFileConfig, EDataSourceCategory } from '../../../../models/dataSource';
import { useLazyGetDataSourceDependenciesQuery, useUpdateDataSourceMutation } from '../../../../services/dataSources';
import { prependHttp } from '../../../../utils';
import { useWorkspace } from '../../../workspaces/hooks';
import { DeleteDataSource, GeneralDataSourceSettings } from './settings';
import { DeleteDataSourceModal } from './settings/modals';

interface IDataSourceSettingsProps {
  dataSource: DataSource | undefined;
}

const DataSourceSettings: FunctionComponent<IDataSourceSettingsProps> = ({ dataSource }) => {
  const workspace = useWorkspace();

  const { openModal } = useModalContext();

  const [getDataSourceDependencies, { data }] = useLazyGetDataSourceDependenciesQuery();
  const [updateDataSource, { isLoading: isUpdateLoading }] = useUpdateDataSourceMutation();

  useEffect(() => {
    if (dataSource && dataSource.id) {
      getDataSourceDependencies({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
      });
    }
  }, [dataSource?.id]);

  const handleDeleteDataSourceClicked = () => {
    if (dataSource) {
      openModal({
        renderContent: DeleteDataSourceModal,
        renderContentProps: {
          dataSource,
        },
        dismissable: true,
        fullWidth: false,
        fullHeight: false,
      });
    }
  };

  const onDataSourceRenameClicked = (form: DataSource) => {
    const dataSource = { ...form };

    switch (dataSource.type.category) {
      case EDataSourceCategory.CODE: {
        const configuration = dataSource.configuration as DataSourceCodeConfig;

        updateDataSource({
          workspaceId: workspace.id,
          dataSourceId: dataSource.id,
          dataSource: {
            name: dataSource.name,
            configuration: { ...configuration, url: prependHttp(configuration.url) },
          },
        });
        break;
      }

      case EDataSourceCategory.FILE: {
        const configuration = dataSource.configuration as DataSourceFileConfig;

        updateDataSource({
          workspaceId: workspace.id,
          dataSourceId: dataSource.id,
          dataSource: {
            name: dataSource.name,
            configuration: { ...configuration },
          },
        });
        break;
      }

      case EDataSourceCategory.CRM: {
        const configuration = dataSource.configuration as DataSourceCrmConfig;

        updateDataSource({
          workspaceId: workspace.id,
          dataSourceId: dataSource.id,
          dataSource: {
            name: dataSource.name,
            configuration: { ...configuration },
          },
        });
        break;
      }

      default:
        break;
    }
  };

  return (
    <div className='flex space-x-6'>
      <div className='flex-1'>
        <div className='py-8 space-y-24'>
          <div className='flex flex-col space-y-24'>
            {dataSource && <GeneralDataSourceSettings dataSource={dataSource} onSubmit={onDataSourceRenameClicked} isUpdateLoading={isUpdateLoading} />}
          </div>
          <div className='flex'>
            <div className='flex-1 '>
              <DeleteDataSource handleDeleteDataSourceClicked={handleDeleteDataSourceClicked} audiences={data?.audiences} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSourceSettings;
