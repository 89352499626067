const RouteConstants = {
  defaultRoute: "/data-sources",
  home: "/home",
  callbackLanding: "/cb",
  logout: "/logout",
  createWorkspace: "/create-workspace",
  createCompany: "/create-company",
  dataSources: "/data-sources",
  audiences: "/audiences",
  destinations: "/destinations",
  reports: "/reports",
  componentShowcase: "/components-list",
  settings: "/settings",
  cyclrAuthRedirect: "/cyclr/auth/redirect"
} as const;

export const SidebarRoutes = [
  RouteConstants.home,
  RouteConstants.dataSources,
  RouteConstants.audiences,
  RouteConstants.destinations,
  RouteConstants.reports,
  RouteConstants.componentShowcase,
  RouteConstants.settings,
  RouteConstants.cyclrAuthRedirect
];

export default RouteConstants;
