import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DataSourceRows } from '..';
import { ISimpleTableProps, SimpleTable } from '../../../../components/tables';
import { DataSource } from '../../../../models/dataSource';

interface IDataSourceTableProps extends ISimpleTableProps {
  dataSources?: DataSource[];
}

const DataSourceTable: FunctionComponent<IDataSourceTableProps> = ({ dataSources, ...rest }) => {
  const { t } = useTranslation('data_sources');

  const headings = [
    {
      label: '',
      className: ""
    },
    {
      label: t("table.id"),
      className: "pl-2"
    },
    {
      label: t("table.data_source_name"),
      className: ""
    },
    {
      label: t("table.data_source_created_at"),
      className: ""
    },
    {
      label: t("table.data_source_status"),
      className: ""
    }
  ];

  const history = useHistory();

  const handleClick = (data: DataSource) => {
    // open datasource details
    const { id } = data;

    history.push(`/data-sources/${id}`);
  };

  return (
    <SimpleTable {...rest}>
      <colgroup>
        <col style={{width: '50px'}}/>
        <col style={{width: '200px'}}/>
        <col style={{}} />
        <col style={{ width: "150px" }} />
        <col style={{ width: "100px" }} />
      </colgroup>
      <thead>
        <tr>
          {headings.map((heading) => (
            <th
              key={heading.label}
              scope='col'
              className={`px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider sticky top-0 ${heading.className}`}
            >
              {heading.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataSources?.map((data) => (
          <DataSourceRows key={data.id} data={data} onClick={(data) => handleClick(data)} />
        ))}
      </tbody>
    </SimpleTable>
  );
};

export default DataSourceTable;
