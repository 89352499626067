import { useAuth0 } from '@auth0/auth0-react';
import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { Loading } from '../../../components/loaders';
import RouteConstants from "../RouteConstants";

const PublicRoute: FunctionComponent<RouteProps<string>> = ({ component, ...props }) => {
  const { isLoading, isAuthenticated, error } = useAuth0();

  if (!component) {
    console.error('Component cannot be null');
    return null;
  }

  if (error) {
    console.error(error);
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return <Redirect to={RouteConstants.home} />;
  }

  return <Route {...props} component={component} />;
};

export default PublicRoute;
