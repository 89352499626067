import { configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  FLUSH,
  PAUSE,
  PERSIST, persistReducer, persistStore, PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { showToast } from '../features/toasts/toastsSlice';
import { ToastType } from '../models/toast';
import { DEFAULT_DISMISS_DURATION } from "../providers/ToastProvider";
import { audiencerateApi } from '../services/audiencerate';
import { rootReducer, RootState } from './index';

const persistConfig = {
  timeout: 0,
  key: 'audiencerate.cdp.root',
  storage,
  blacklist: [
    'auth', 'sidebar', 'slideOver', 'fileUploading', 'toasts', audiencerateApi.reducerPath
  ]
};

const errorToastMap: Record<string, number> = {};

const shouldShowErrorToast = (action: any) => {
  const title = `Error - ${action.payload?.status}`;
  const message = action.payload?.data?.message;

  const key = `${title}|${message}`;
  const currentTime = errorToastMap[key] || 0;
  const now = Date.now();

  if (currentTime + DEFAULT_DISMISS_DURATION > now) {
    return false;
  }
  else {
    errorToastMap[key] = now;
    return true;
  }
};

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      console.error(action);
      if (shouldShowErrorToast(action)) {
        api.dispatch(showToast({
          type: ToastType.ERROR,
          title: `Error - ${action.payload?.status}`,
          message: action.payload?.data?.message
        }));
      }
    }

    return next(action);
  };

export function configureAppStore(preloadedState: any) {
  const reducer = persistReducer<RootState>(persistConfig, rootReducer);

  const store = configureStore({
    reducer,
    // put here RTK api with .concat on gdm
    middleware: (gdm) => gdm({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(rtkQueryErrorLogger).concat(audiencerateApi.middleware),
    preloadedState
  });
  const persistor = persistStore(store);

  setupListeners(store.dispatch);

  return { store, persistor };
}
