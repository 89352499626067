import { FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { AudienceForm, AudienceFormModel, ExpressionContainer } from '.';
import { Step } from '../../../components/steppers';
import { Trait } from '../../../models/traits';
import { RootState, useAppDispatch } from '../../../reducers';
import { stashAudience } from '../audienceEditSlice';
import AudienceSyncContainer, { AudienceSyncFormModel } from './sync/AudienceSyncContainer';
import { CheckIcon } from '@heroicons/react/solid';

interface IAudienceContentProps {
  createFormRef: React.RefObject<FormikProps<AudienceFormModel>>;
  syncFormRef: React.RefObject<FormikProps<AudienceSyncFormModel>>;
  step?: Step;
  traits?: Trait[];
}

export enum EAudienceSteps {
  NAME_AND_METADATA = 1,
  EXPRESSION = 2,
  SYNC = 3,
  CONFIRMATION = 4,
}

const AudienceContent: FunctionComponent<IAudienceContentProps> = ({ step, createFormRef, syncFormRef, traits }) => {
  const dispatch = useAppDispatch();

  const audience = useSelector((state: RootState) => state.audienceEdit.audience);

  const handleSubmit = async (form: AudienceFormModel) => {
    if (form.name && form.matchingTrait && form.datasourceIds) {
      dispatch(stashAudience({ ...form }));
    }
  };

  const stepTemplate = () => {
    switch (step?.id) {
      case EAudienceSteps.NAME_AND_METADATA:
        return <AudienceForm formRef={createFormRef} onSubmit={handleSubmit} traits={traits} audience={audience} />;
      case EAudienceSteps.EXPRESSION:
        return <ExpressionContainer />;
      case EAudienceSteps.SYNC:
        return <AudienceSyncContainer formRef={syncFormRef} />;
      case EAudienceSteps.CONFIRMATION:
        return (
          <p>
            <span className='m-auto w-10 h-10 my-8 flex items-center justify-center bg-green-600 rounded-full'>
              <CheckIcon className='w-6 h-6 text-white-100' aria-hidden='true' />
            </span>
            Congratulations, your audience was correctly created!
          </p>
        );
      default:
        return null;
    }
  };

  return <div className='rounded-lg h-auto mt-6'>{stepTemplate()}</div>;
};

export default AudienceContent;
