import { FunctionComponent } from 'react';
import { concatClassNames } from '../../../../../../utils';

interface IFIleDataSourceRadioButtonProps {
  title: string;
  description: string;
  currentValue: string;
  value: string;
  onClick: () => void;
}

const FileDataSourceRadioButton: FunctionComponent<IFIleDataSourceRadioButtonProps> = (props) => {
  const { title, description, currentValue, value, onClick } = props;

  return (
    <div
      onClick={onClick}
      className={concatClassNames(
        'inline-flex w-3/4 border p-5 rounded-sm cursor-pointer',
        currentValue === value ? 'border-blue-300 bg-blue-100' : 'border-gray-300'
      )}
    >
      <div className='flex-none justify-start mr-3'>
        <input type='radio' className='form-radio cursor-pointer' name='radio' value={value} readOnly checked={currentValue === value} />
      </div>
      <div className='flex-auto'>
        <span className='text-gray-700 text-sm font-semibold'>{title}</span>
        <br />
        <span className='text-gray-700 text-sm'>{description}</span>
      </div>
    </div>
  );
};

export default FileDataSourceRadioButton;
