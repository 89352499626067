import { ArrowRightIcon, PlusIcon, RefreshIcon } from '@heroicons/react/solid';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { SearchInput } from '../../components/tables/search';
import { useModalContext } from '../../hooks/useModalContext';
import { DistributionChannel } from '../../models/distributionChannels';
import { TabType } from '../../models/tabs';
import { RootState, useAppDispatch } from '../../reducers';
import { MainContentWrapper } from '../layout/main';
import { TopbarTabs } from '../layout/topbar';
import { useWorkspaceAdmin } from '../workspaces/hooks/useWorkspace';
import { DestinationEmptySet, DestinationSync } from './components';
import ConnectorsCatalog from './components/ConnectorsCatalog';
import DestinationCreate from './components/edit/DestinationCreate';
import { setDestinationTabs, setSearchText } from './destinationsSlice';

interface IDestinationListProps {
    destinations: DistributionChannel[];
    pager: React.ReactNode;
}

const DestinationList: FunctionComponent<IDestinationListProps> = ({ destinations, pager }) => {
    const { t } = useTranslation('destinations');
    const dispatch = useAppDispatch();

    const tabs = useSelector((state: RootState) => state.destinations.ui?.tabs);
    const { openModal, closeModal } = useModalContext();

    const mappedTabs = tabs.map((tab) => {
        switch (tab.id) {
            case 'destination.sync':
                return { ...tab, label: t('tabs.sync'), icon: RefreshIcon };
            case 'destination.list':
                return { ...tab, label: t('tabs.list'), icon: ArrowRightIcon };
        }
    });

    const handleTabChange = (selectedTab: TabType) => {
        console.log('selected', selectedTab);
        console.log(
            'newTabs',
            tabs?.map((tab) => ({ id: tab.id, current: tab.id === selectedTab.id }))
        );
        dispatch(setDestinationTabs(tabs?.map((tab) => ({ id: tab.id, current: tab.id === selectedTab.id }))));
    };

    const renderTabContent = () => {
        const currentTab = tabs?.find((tab) => tab.current);

        switch (currentTab?.id) {
            case 'destination.sync':
                if (destinations.length > 0) {
                    return (
                        <Fragment>
                            <SearchInput
                                stateSelector={(state: RootState) => state.destinations.ui.searchText}
                                stateUpdater={setSearchText}
                                placeholder={t('table.search_placeholder')}
                            />
                            <DestinationSync destinations={destinations || []} />
                            {pager}
                        </Fragment>
                    );
                } else {
                    return (
                        <Fragment>
                            <SearchInput
                                stateSelector={(state: RootState) => state.destinations.ui.searchText}
                                stateUpdater={setSearchText}
                                placeholder={t('table.search_placeholder')}
                            />
                            <DestinationEmptySet />
                        </Fragment>
                    );
                }
            case 'destination.list':
                return <ConnectorsCatalog />;
            default:
                return null;
        }
    };

    const openCreateDialog = () => {
        return openModal({
            renderContent: DestinationCreate,
            fullHeight: true,
            fullWidth: true,
            title: t('edit.title'),
        });
    };

    const topbarRightElements = () => (
        <Button variant='confirm' icon={PlusIcon} className='h-9 mr-6' onClick={openCreateDialog}>
            {t('buttons.create_sync')}
        </Button>
    );

    return (
        <Fragment>
            <TopbarTabs label={t('title')} tabs={mappedTabs} onTabChange={handleTabChange} rightElement={useWorkspaceAdmin() ? topbarRightElements : undefined} />
            <MainContentWrapper>
                <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
                    <div className='h-auto'>{renderTabContent()}</div>
                </div>
            </MainContentWrapper>
        </Fragment>
    );
};

export default DestinationList;
