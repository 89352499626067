import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { toggleSidebar } from '../../../features/sidebar/sidebarSlice';
import { NavigationItem } from '../../../models/navigation';
import { RootState, useAppDispatch } from '../../../reducers';
import SidebarContent from './SidebarContent';

interface ISidebarProps {
  items: NavigationItem[];
  onItemClick?: (item: NavigationItem) => void;
}

const Sidebar: FunctionComponent<ISidebarProps> = ({ items, onItemClick }) => {
  const dispatch = useAppDispatch();
  const { show } = useSelector((state: RootState) => state.sidebar);

  const onCloseSidebarClicked = () => {
    dispatch(toggleSidebar(false));
  };

  const onSidebarContentClick = (item: NavigationItem) => {
    onCloseSidebarClicked();

    if (onItemClick) {
      onItemClick(item);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      {/* Check if need a toggle function instead of close */}
      <Dialog as='div' static className='fixed inset-0 flex z-40 md:hidden' open={show} onClose={onCloseSidebarClicked}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter='transition ease-in-out duration-300 transform'
          enterFrom='-translate-x-full'
          enterTo='translate-x-0'
          leave='transition ease-in-out duration-300 transform'
          leaveFrom='translate-x-0'
          leaveTo='-translate-x-full'
        >
          <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-blue-700'>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='absolute top-0 right-0 -mr-12 pt-2'>
                <button
                  type='button'
                  className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white-100'
                  onClick={onCloseSidebarClicked}
                >
                  <span className='sr-only'>Close sidebar</span>
                  <XIcon className='h-6 w-6 text-white-100' aria-hidden='true' />
                </button>
              </div>
            </Transition.Child>
            <SidebarContent items={items} onItemClick={onSidebarContentClick} />
          </div>
        </Transition.Child>
        <div className='flex-shrink-0 w-14' aria-hidden='true'>
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Sidebar;
