import React, { ChangeEvent, Dispatch, PropsWithChildren, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { Input } from '../inputs';
import { SpinnerIcon } from '../icons';

type ResultContainerProps = {
    visible?: boolean;
};
const ResultContainer: React.FC<PropsWithChildren<ResultContainerProps>> = ({ children, visible = true }) => (
    /*
    Used by results, but also by the loading indicator which renders the word "Loading" as
    a single non-clickable result.
    */
    <div
        role='listbox'
        className={`
      absolute min-w-full z-10 mt-0.5
      py-2
      bg-white-100
      rounded-md rounded-t-none
      shadow-md
      focus:ring-0 focus:outline-none focus-visible:outline-none
      max-h-60 overflow-auto
      ${visible ? 'block' : 'hidden'}
    `}
    >
        {children}
    </div>
);

/*
The Headless UI library  does a lot of heavy lifting for our `DropDown` component where a user
clicks a button that reveals a list of options below (essentially a custom `<select>`). This
component is very similar except instead of a button the user has a text input to filter the
options. Very likely Headless will acquire such a component in the future with all the bells and
whistles like keyboard navigation, accessibility and solid testing in various environments.

I tried to adapt the Headless component but the click and keyboard event handling needs to be
quite different. For now we are not handling keyboard events so the user has to use a mouse to
choose a result.

The component doesn't handle any querying or search operations, expecting that as an `items`
props from the parent component.

Structured as a generic so should handle a broad range of autocomplete or search use cases.
Rendering of the actual results happens in the `children` callable.

The component takes care of debouncing the query string so if it changes rapidly it waits 500ms
before actually calling `setQuery`.

Example use:

```
const Search = () => {

  const [query, setQuery] = useState('');
  const [pickedResult, setPickedResult] = useState();  // Could come from parent component
  const [getData, { data, loading }] = useSearchLazyQuery();

  useEffect(() => {
    if (!query) getData({ variables: { query } });
  }, [query, getData]);

  let results: Result[] = [];
  if (data?.search.__typename === 'SearchSuccess') {
    results = data?.search.results;
  }

  <SearchDropDown
    item={pickedResult}
    label="Search things"
    items={results}
    setItem={setPickedResult}
    setQuery={setQuery}
    loading={loading}>
    {(result) => <span>{result.name}</span>}
  </SearchDropDown>
}
```

*/

interface IdObj {
    id: string | number;
    // displayName: string;
}

interface Props<T> {
    id?: string; // Associates labels with inputs for testing and accessability
    placeholder?: string; // The input placeholder text to indicate what to search, like "Add location"
    label?: string; // A further <label> to describe the input field
    eager?: boolean; // Whether to wait for user input before rendering the `items` in results
    item?: T | null; // The selected item if any
    items: T[]; // The results to show, probably based on the query set in the `setQuery` prop
    setItem: (item: T) => void; // Called when the user picks an item from the list
    setQuery?: Dispatch<SetStateAction<string>>; // Called when the user types in a query in the input
    loading: boolean;
    additionalItemNode?: React.ReactNode; // Used for rendering additional actions at the bottom of results
    children: (item: T) => React.ReactNode; // Callable to render the item
    openWithFocus?: boolean;
    displayField?: keyof T;
    acceptCustomValue?: boolean;
    readOnly?: boolean;
}

const SearchDropDown = <T extends IdObj>({
    id,
    label,
    placeholder,
    eager = false,
    item = null,
    items,
    setItem,
    setQuery,
    loading,
    additionalItemNode,
    children,
    openWithFocus,
    displayField,
    acceptCustomValue,
    ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'children'> & Props<T>): React.ReactElement => {
    const textField = displayField || ('displayName' as keyof T);
    const [value, setValue] = useState(item ? item[textField] : '');
    const [open, setOpen] = useState(eager);
    const [selected, setSelected] = useState<Pick<T, 'id'> | null>(item);
    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setValue(item ? item[textField] : '');
        setSelected(item);
    }, [item]);

    useEffect(() => {
        // When clicking outside component, pressing escape etc. close the widget
        // TODO add keyboard events
        const onClick = (event: MouseEvent) => {
            const { target } = event;
            if (!ref?.current) return;
            const clickedOutsideComponent = !ref?.current.contains(target as Node);
            if (clickedOutsideComponent) {
                setOpen(false);
            }
        };
        document.addEventListener('click', onClick, true);
        return () => document.removeEventListener('click', onClick, true);
    }, []);

    const debouncedQuery = useDebounce(value, 500);

    useEffect(() => {
        setQuery?.(debouncedQuery as any);
    }, [debouncedQuery, setQuery]);

    const onSelect = (item: T) => {
        setSelected(item);
        setItem(item);
        setValue(item[textField]);
        setOpen(false);
    };

    const showResults = !!(items && open && !loading);

    const onFocus = ({ target }: any) => {
        setQuery?.('');
        target.select();
        if (eager || (!props.readOnly && openWithFocus)) {
            setOpen(true);
        }
    };

    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const isEmpty = !!target.value;
        setOpen(openWithFocus || isEmpty);
        setValue(target.value);
        if (acceptCustomValue) {
            const item = { [textField]: target.value } as any;
            setSelected(item);
            setItem(item);
            setValue(item[textField]);
        }
    };
    const itemsView = items.map((node, index) => (
        <button
            id={`result-${index}`}
            className={`
${location && node.id === item?.id ? 'bg-glass-blue10' : 'bg-white'}
${selected?.id === node.id ? 'bg-blue-300' : ''}
cursor-pointer px-5 py-2 w-full block text-left hover:bg-glass-blue30`}
            key={node.id}
            type='button'
            onClick={() => {
                onSelect(node);
            }}
        >
            {children(node)}
        </button>
    ));

    return (
        <div className='relative flex-grow' ref={ref}>
            {!props.readOnly ? (
                <Input
                    // inchSize="small"
                    name='notASearch' // This to disable Safari autofill
                    id={id}
                    label={label}
                    placeholder={placeholder}
                    autoComplete='off'
                    value={value as string}
                    onFocus={onFocus}
                    onChange={onChange}
                    readOnly={setQuery === undefined}
                    onBlur={() => setValue(selected ? (selected as any)[textField] : undefined)}
                    {...props}
                    type='text'
                />
            ) : (
                <div {...props} className={`text-sm rounded-md ${props.className}`}>
                    {value as any}
                </div>
            )}

            {loading && <SpinnerIcon className='absolute h-4 w-4 top-1.5 right-2' loading />}

            {showResults && (
                <ResultContainer visible={showResults}>
                    {itemsView}
                    {additionalItemNode}
                </ResultContainer>
            )}
        </div>
    );
};

export default SearchDropDown;
