import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISimpleTableProps, SimpleTable } from '../../../../components/tables';
import { DataSourceFileConfig, Header, TDataSource } from '../../../../models/dataSource';
import { useGetTraitsQuery } from '../../../../services/endpoints/traits';
import { useWorkspace } from '../../../workspaces/hooks';

interface IPreviewCSVTableProps extends ISimpleTableProps {
  fileContent: string[][];
  isDefaultHeaderCorrect: boolean;
  dataSource?: TDataSource<DataSourceFileConfig>;
}

const PreviewCSVTable: FunctionComponent<IPreviewCSVTableProps> = ({ fileContent, isDefaultHeaderCorrect, dataSource, ...rest }) => {
  const { t } = useTranslation('data_source_edit');

  const workspace = useWorkspace();

  const [tableContent, setTableContent] = useState<string[][]>([]);

  const { data: traits, isLoading } = useGetTraitsQuery({
    workspaceId: workspace.id,
  });

  const headers = dataSource?.configuration.headers || [];

  useEffect(() => {
    if (fileContent) {
      setTableContent(fileContent.filter((_, i) => i > 0));
    }
  }, [fileContent]);

  useEffect(() => {
    if (isDefaultHeaderCorrect) {
      setTableContent(fileContent.filter((_, i) => i > 0));
    } else {
      setTableContent(fileContent.filter((_, i) => i >= 0));
    }
  }, [isDefaultHeaderCorrect]);

  const retrieveHeaderName = (index: number) => {
    return isDefaultHeaderCorrect ? fileContent[0][index] : `${t('file.header.column')} ${index + 1}`;
  };

  const retrieveHeaderMapping = (header: Header) => {
    return header.skip ? t('file.header.ignored') : header.displayName;
  };

  return (
    <div>
      {headers && tableContent ? (
        <SimpleTable {...rest}>
          <thead className='rounded-lg'>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className='truncate px-2 py-1 text-left text-xs font-medium bg-blue-50 text-blue-700 tracking-wider border border-blue-500'>
                  {`${retrieveHeaderName(index)} - ${retrieveHeaderMapping(header)}`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableContent.map((rows, index) => (
              <tr key={index}>
                {rows.map((row, index) => (
                  <td key={index} className='truncate px-2 py-1 text-left border border-gray-300 bg-white-100'>
                    <span className='text-sm text-gray-700 leading-5 font-normal'>{row}</span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </SimpleTable>
      ) : null}
    </div>
  );
};

export default PreviewCSVTable;
