import React, { FunctionComponent } from 'react';
import { DestinationTable } from '../';
import { DistributionChannel } from '../../../../models/distributionChannels';

interface IDestinationListProps {
  destinations: DistributionChannel[];
}

const DestinationList: FunctionComponent<IDestinationListProps> = ({ destinations }) => {
  return (
    <div className='mx-auto'>
      <div className='h-auto'>
        <DestinationTable destinations={destinations} />
      </div>
    </div>
  );
};

export default DestinationList;
