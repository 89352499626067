import { isEmpty } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ITSelectProps } from '../../../../components/selects';
import { Rule } from '../../../../models/rules';
import { RootState } from '../../../../reducers';
import { useGetRulesQuery } from '../../../../services/endpoints/rules';
import { useWorkspace } from '../../../workspaces/hooks';
import { SearchDropDown } from '../../../../components/dropdowns';

interface IEventSelectProps extends Partial<ITSelectProps<Rule>> {
  ruleId?: string;
  type?: 'TRACK' | 'VIEW';
}

const EventSelect: FunctionComponent<IEventSelectProps> = ({ ruleId, type, ...rest }) => {
  const { t } = useTranslation('audience_edit');

  const workspace = useWorkspace();
  const audience = useSelector((state: RootState) => state.audienceEdit.audience);

  const { isLoading, data } = useGetRulesQuery({
    workspaceId: workspace.id,
    type,
    datasourceIds: audience?.datasourceIds,
  });

  const rules = (data || []);
  const rule = rules.find((a) => a.id === ruleId);

  const [searchQuery, setSearchQuery] = useState('');

  if (!isLoading && isEmpty(rules)) {
    return (
      <div className='grid h-full place-content-center'>
        <p className='text-sm'>{t('rules.there_are_no_events')}</p>
      </div>
    );
  }

  return (
    <SearchDropDown
      items={searchQuery?.trim() ? rules.filter((item) => item.name.toLowerCase().includes(searchQuery?.trim().toLowerCase())) : rules}
      item={rule}
      setItem={(item) => rest.onChange && rest.onChange(item as any)}
      setQuery={setSearchQuery}
      loading={isLoading}
      className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
      readOnly={rest.isViewMode}
      openWithFocus={true}
      displayField={'name'}
      placeholder={'Event'}
    >
      {(item) => <div className={'whitespace-nowrap'}>{item.name}</div>}
    </SearchDropDown>
  );
};

export default EventSelect;
