import { omit } from 'lodash';
import React, { FunctionComponent } from 'react';
import { concatClassNames } from '../../utils';
import { SpinnerIcon } from '../icons/';

interface IButtonProps {
  variant?: 'primary' | 'secondary' | 'light' | 'confirm' | 'delete' | 'transparent' | 'transparent-red';
  loading?: boolean;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

type TButtonType = IButtonProps & React.ComponentProps<'button'>;

const Button: FunctionComponent<TButtonType> = ({ variant = 'primary', disabled, onClick, children, className, loading, type = 'button', ...rest }) => {
  let style = 'px-3 py-2 h-45 rounded text-base justify-center outline-none inline-flex items-center shadow-sm disabled:opacity-40 focus:outline-none';
  let loadingStyle = 'absolute w-full h-full flex justify-center items-center -ml-3 rounded';
  const iconStyle = 'mr-4 flex-shrink-0 h-6 w-6';

  switch (variant) {
    case 'primary':
      style = concatClassNames(style, 'bg-blue-600 text-white-100 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-700');
      loadingStyle = concatClassNames(loadingStyle, 'bg-blue-600 hover:bg-blue-700');
      break;
    case 'secondary':
      style = concatClassNames(style, 'bg-blue-50 text-blue-700 hover:bg-blue-100');
      loadingStyle = concatClassNames(loadingStyle, 'bg-blue-50 hover:bg-blue-100');
      break;
    case 'transparent-red':
      style = concatClassNames(style, 'transparent text-red-700 shadow-none');
      break;
    case 'transparent':
      style = concatClassNames(style, 'transparent text-blue-700 shadow-none');
      break;
    case 'light':
      style = concatClassNames(
        style,
        'bg-white-100 text-gray-700 hover:bg-gray-50 border border-solid border-gray-400 focus:ring-offset-0 focus:ring-blue-700'
      );
      loadingStyle = concatClassNames(loadingStyle, 'bg-white-100 hover:bg-gray-50');
      break;
    case 'confirm':
      style = concatClassNames(style, 'bg-green-600 text-white-100 hover:bg-green-700');
      loadingStyle = concatClassNames(loadingStyle, 'bg-green-600 hover:bg-green-700');
      break;
    case 'delete':
      style = concatClassNames(style, 'bg-red-600 text-white-100 hover:bg-red-700');
      loadingStyle = concatClassNames(loadingStyle, 'bg-red-600 hover:bg-red-700');
      break;
    default:
      break;
  }

  if (disabled) {
    style = concatClassNames(style, 'cursor-not-allowed');
  }

  if (loading) {
    style = concatClassNames(style, 'relative cursor-not-allowed');
  }

  return (
    <button
      {...omit(rest, 'icon')}
      type={type}
      className={className ? concatClassNames(className, style) : style}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {rest.icon && <rest.icon className={iconStyle} />}
      {children}
      {loading && (
        <div className={loadingStyle}>
          <SpinnerIcon className='absolute h-6 w-6' loading />
        </div>
      )}
    </button>
  );
};

export default Button;
