import { storeToken } from "../../features/auth/authSlice";
import { PagingObject } from "../../models/pagination";
import { Workspace, WorkspaceInvite, WorkspaceUsageSummary, WorkspaceUser, WorkspaceUserWithRole } from "../../models/workspace";
import { audiencerateApi } from "../audiencerate";
import { Auth0Service } from "../auth0Service";

const workspaceApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkspaces: build.query<{ items: Workspace[]; limit: number; offset: number }, GetWorkspacesArg>({
      query: ({ limit, offset }) => ({
        url: `/v1/workspaces`,
        params: { limit, offset }
      }),
      providesTags: ["Workspaces"]
    }),
    saveWorkspace: build.mutation<Workspace, SaveWorkspaceArg>({
      query: ({ workspace }) => ({
        url: `/v1/workspaces`,
        method: "POST",
        body: workspace
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data: workspace } = await queryFulfilled;
          // `onSuccess` side-effect
          console.log('refreshing token');
          const token = await Auth0Service.getAccessTokenSilently({ cacheMode: "off" });
          console.log('Post workspace creation token refresh', token);
          dispatch(storeToken(token));
        } catch (err) {
          console.error('onQueryStarted: Could not refresh token', err);
          // `onError` side-effect
        }
      },
      invalidatesTags: ["Workspaces"]
    }),
    inviteWorkspace: build.mutation<any, InviteWorkspaceArg>({
      query: ({ workspaceId, collaborator }) => ({
        url: `/v1/workspaces/${workspaceId}/invite`,
        method: "POST",
        body: collaborator
      }),
      invalidatesTags: ["WorkspaceInvites"]
    }),
    getWorkspace: build.query<Workspace, GetWorkspaceArg>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}`,
        method: "GET"
      }),
      providesTags: ["Workspace"]
    }),
    updateWorkspace: build.mutation<Workspace, UpdateWorkspaceArg>({
      query: ({ workspaceId, workspace }) => ({
        url: `/v1/workspaces/${workspaceId}`,
        method: "PATCH",
        body: workspace
      }),
      invalidatesTags: ["Workspace", "Workspaces"]
    }),
    deleteWorkspace: build.mutation<void, GetWorkspaceArg>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Workspace", "Workspaces"]
    }),
    getWorkspaceUsageSummary: build.query<WorkspaceUsageSummary, GetWorkspaceArg>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/usage/summary`
      })
    }),
    acceptWorkspaceInvite: build.mutation<any, { inviteId: string }>({
      query: ({ inviteId }) => ({
        url: `/v1/workspaces/accept-invite/${inviteId}`,
        method: "POST"
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const _ignore = await queryFulfilled;
          // `onSuccess` side-effect
          console.log('refreshing token');
          const token = await Auth0Service.getAccessTokenSilently({ cacheMode: "off" });
          console.log('Post workspace creation token refresh', token);
          dispatch(storeToken(token));
        } catch (err) {
          console.error('onQueryStarted: Could not refresh token', err);
          // `onError` side-effect
        }
      },
      invalidatesTags: ["userInfo"]
    }),
    workspaceUsers: build.query<PagingObject<WorkspaceUserWithRole>, { workspaceId: string }>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/users`
      }),
      providesTags: ["WorkspaceUsers"]
    }),
    deleteWorkspaceUser: build.mutation<unknown, { workspaceId: string } & Pick<WorkspaceInvite, 'id'>>({
      query: ({ workspaceId, id }) => ({
        url: `/v1/workspaces/${workspaceId}/users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ["WorkspaceUsers"]
    }),
    getWorkspaceEncryptionPublicKey: build.query<GetWorkspaceEncryptionPublicKey, { workspaceId: string }>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/encryption/public-key`
      }),
      providesTags: ["WorkspacePublicKey"]
    }),
    createWorkspaceEncryptionKeyPair: build.mutation<GetWorkspaceEncryptionPublicKey, { workspaceId: string }>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/encryption/key-pair`,
        method: 'POST'
      }),
      invalidatesTags: ["WorkspacePublicKey"]
    }),
    getWorkspaceInvites: build.query<PagingObject<WorkspaceInvite>, { workspaceId: string }>({
      query: ({ workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/list-invite`,
        params: { limit: 50, offset: 0 }
      }),
      providesTags: [{ type: "WorkspaceInvites", id: "LIST" }]
    }),
    deleteWorkspaceInvite: build.mutation<unknown, Pick<WorkspaceInvite, 'id' | 'workspaceId'>>({
      query: ({ id, workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/delete-invite/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: "WorkspaceInvites", id: "LIST" }]
    })
  }),
  overrideExisting: false
});

type GetWorkspaceArg = {
  workspaceId: string;
};

type GetWorkspacesArg = {
  limit?: number;
  offset?: number;
};

type InviteWorkspaceArg = {
  workspaceId: string;
  collaborator: any;
}

type SaveWorkspaceArg = {
  workspace: Pick<Workspace, "name" | "slug" | "description">;
};

type UpdateWorkspaceArg = {
  workspaceId: string;
  workspace: Pick<Workspace, "name" | "slug">;
};

type GetWorkspaceEncryptionPublicKey = {
  publicKeyRing: string;
  keyId: number;
}

export const {
  useGetWorkspacesQuery,
  useLazyGetWorkspacesQuery,
  useSaveWorkspaceMutation,
  useGetWorkspaceQuery,
  useUpdateWorkspaceMutation,
  useDeleteWorkspaceMutation,
  useInviteWorkspaceMutation,
  useGetWorkspaceUsageSummaryQuery,
  useAcceptWorkspaceInviteMutation,
  useWorkspaceUsersQuery,
  useLazyWorkspaceUsersQuery,
  useGetWorkspaceEncryptionPublicKeyQuery,
  useCreateWorkspaceEncryptionKeyPairMutation,
  useGetWorkspaceInvitesQuery,
  useDeleteWorkspaceInviteMutation,
  useDeleteWorkspaceUserMutation
} = workspaceApi;
