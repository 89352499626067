import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

const Auth0ProviderWithHistory: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const history = useHistory();
    const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
    const domain = import.meta.env.VITE_AUTH0_DOMAIN;
    const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
    console.log('auth0', clientId, domain, audience);

    if (!clientId || !domain) {
        console.error('CLIENT_ID or DOMAIN not initialized', clientId, domain);
        return null;
    }

    const onRedirectCallback = (appState?: AppState) => {
        // console.log('redirect callback', appState);
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            clientId={clientId}
            domain={domain}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation='localstorage'
            authorizationParams={{
                audience,
                redirect_uri: window.location.origin,
            }}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
