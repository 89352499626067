import { XIcon } from '@heroicons/react/solid';
import { FieldArray, FieldArrayRenderProps, FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../../../../../components/buttons';
import { Input, PrefixInput } from '../../../../../../components/inputs';
import { DataSourceCodeConfig, TDataSource } from '../../../../../../models/dataSource';
import { IPageEventsError, ITrackClickEvents, ITrackPageVisitEvents, ITrackWebsiteValue } from '../../../../types';
import { AddButton } from '../buttons';

interface ITrackPageVisitProps {
  values: ITrackWebsiteValue;
  errors: FormikErrors<ITrackWebsiteValue>;
  dataSource: Partial<TDataSource<DataSourceCodeConfig>> | undefined;
  handleSetFieldValue: (field: string, value: ITrackClickEvents | ITrackPageVisitEvents) => void;
  handleSetFieldError: (field: string, message: string | undefined) => void;
  handleDeleteRule: (ruleId: string) => void;
}

const TrackPageVisit: FunctionComponent<ITrackPageVisitProps> = (props) => {
  const { values, errors, dataSource, handleSetFieldValue, handleSetFieldError, handleDeleteRule } = props;

  const { t } = useTranslation('data_source_edit');

  const handleAddPage = (pageVisitEventHelper: FieldArrayRenderProps) => {
    const identifier = uuidv4();
    const baseUrl = `${dataSource?.configuration?.url}/`;

    pageVisitEventHelper.push({ identifier, baseUrl, pagePath: '', eventName: '' });
  };

  const handleDeleteEvent = (pageVisitEventHelper: FieldArrayRenderProps, index: number, ruleId?: string) => {
    if (ruleId) {
      handleDeleteRule(ruleId);
    }
    pageVisitEventHelper.remove(index);
  };

  const getInfoByPath = (event: ITrackPageVisitEvents): string => {
    const path = `${event.baseUrl}${event.pagePath ?? ''}`;

    const parser = document.createElement('a');
    parser.href = path;

    if (parser.pathname !== '/') {
      if (parser.pathname.slice(-2) === '/*') {
        const pathPage = path.substring(0, path.length - 2);
        return t('code.track_events.track_all_pages', { path: pathPage });
      } else {
        return t('code.track_events.track_page', { path: path });
      }
    }

    return t('code.track_events.track_homepage');
  };

  return (
    <div>
      <FieldArray
        name='pageVisitEvents'
        validateOnChange={false}
        render={(pageVisitEventHelper) => (
          <div>
            {pageVisitEventHelper &&
              !isEmpty(pageVisitEventHelper) &&
              values.pageVisitEvents.map((event, index) => (
                <div key={index} className='flex px-4 my-2'>
                  <div className='flex-auto w-1/2'>
                    <PrefixInput
                      value={event.pagePath}
                      onChange={(e) => {
                        handleSetFieldError(`pageVisitEvents.${index}.pagePathError`, undefined);
                        handleSetFieldValue(`pageVisitEvents.${index}`, { ...event, pagePath: e.target.value });
                      }}
                      error={errors.pageVisitEvents && errors.pageVisitEvents[index] ? (errors.pageVisitEvents[index] as IPageEventsError).pagePath : undefined}
                      extraInfo={getInfoByPath(event)}
                      disabled={event.id !== undefined}
                      prefix={event.baseUrl}
                    />
                  </div>
                  <div className='flex w-1/2'>
                    <span className='py-2 ml-2 mr-1'>{t('code.track_events.creates')}</span>
                    <div className='flex-auto'>
                      <Input
                        placeholder={t('code.track_events.name_of_the_event')}
                        value={event.eventName}
                        onChange={(e) => {
                          handleSetFieldError(`pageVisitEvents.${index}.eventError`, undefined);
                          handleSetFieldValue(`pageVisitEvents.${index}`, { ...event, eventName: e.target.value });
                        }}
                        error={
                          errors.pageVisitEvents && errors.pageVisitEvents[index] ? (errors.pageVisitEvents[index] as IPageEventsError).eventName : undefined
                        }
                        disabled={event.id !== undefined}
                      />
                    </div>
                    <Button variant="transparent" type='button' className='flex-0 ml-1 h-11' onClick={() => handleDeleteEvent(pageVisitEventHelper, index, event.id)}>
                      <XIcon className='h-6 w-6 text-gray-500' aria-hidden='true' />
                    </Button>
                  </div>
                </div>
              ))}
            <div className='bg-white py-6 px-5'>
              <AddButton label={t('code.track_events.buttons.add_page')} onClick={() => handleAddPage(pageVisitEventHelper)} />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default TrackPageVisit;
