import { FunctionComponent } from 'react';

interface IIconContainerProps {
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  path?: string;
  className?: string;
}

const IconContainer: FunctionComponent<IIconContainerProps> = ({ path, className, ...rest }) => {
  if (rest.icon) {
    return <rest.icon className={className} />;
  } else if (path) {
    return <img src={path} className={className} />;
  } else {
    return null;
  }
};

export default IconContainer;
