import { FunctionComponent } from "react";
import { DataSourceType, EDataSourceCategory } from "../../models/dataSource";
import { IModalContentProps } from "../../models/modals";
import { EmptyDataSourceContainer, FileDataSourceContainer } from "./components";
import { CodeDataSourceContainer } from "./components/categories/code";
import { CrmDataSourceContainer } from "./components/categories/crm";

interface IDataSourceEditProps {
  dataSourceType: DataSourceType;
}

const DataSourceEdit: FunctionComponent<IDataSourceEditProps & IModalContentProps> = ({ closeModal, dataSourceType }) => {
  const renderModalContent = (dataSourceType: DataSourceType) => {
    switch (dataSourceType.category) {
      case EDataSourceCategory.CODE:
        return <CodeDataSourceContainer dataSourceType={dataSourceType} closeModal={closeModal} />;
      case EDataSourceCategory.CRM:
        return <CrmDataSourceContainer dataSourceType={dataSourceType} closeModal={closeModal} />;
      case EDataSourceCategory.FILE:
        return <FileDataSourceContainer dataSourceType={dataSourceType} closeModal={closeModal} />;
      default:
        return <EmptyDataSourceContainer dataSourceType={dataSourceType} closeModal={closeModal} />;
    }
  };

  return renderModalContent(dataSourceType);
};

export default DataSourceEdit;
