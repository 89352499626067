import { FormikProps } from 'formik';
import { first, last } from 'lodash';
import { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '../../../../../components/buttons';
import { EStepStatus, Step, Stepper } from '../../../../../components/steppers';
import { DataSourceCodeConfig, DataSourceType, EDataSourceStatus, TDataSource } from '../../../../../models/dataSource';
import { RootState, useAppDispatch } from '../../../../../reducers';
import { useSaveDataSourceMutation, useUpdateDataSourceMutation } from '../../../../../services/dataSources';
import { prependHttp } from '../../../../../utils';
import { setDataSourceTabs, stashDataSource } from '../../../../dataSources/dataSourcesSlice';
import { useWorkspace } from '../../../../workspaces/hooks';
import { ECodeSteps } from '../../../constants';
import { stashSteps } from '../../../dataSourceEditSlice';
import { useDataSourceSteps } from '../../../hooks/useDataSourceSteps';
import { DataSourceHeader } from '../..';
import { CodeDataSourceContent } from '.';
import { DataSourceFormModel, ITrackWebsiteValue } from '../../../types';
import { useLazyGetRulesByDataSourceIdQuery, useSaveRulesMutation } from '../../../../../services/endpoints/rules';
import { stashRules } from '../../../../rules/rulesSlice';
import { mapEventsToRules } from './tracking/utils';

interface ICodeDataSourceContainer {
  dataSourceType: DataSourceType;
  closeModal: () => void;
}

const CodeDataSourceContainer: FunctionComponent<ICodeDataSourceContainer> = ({ dataSourceType, closeModal }) => {
  const { t } = useTranslation('data_source_edit');

  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const dataSourceInfoFormRef = useRef<FormikProps<DataSourceFormModel>>(null);
  const trackWebsiteActivityFormRef = useRef<FormikProps<ITrackWebsiteValue>>(null);

  const tabs = useSelector((state: RootState) => state.dataSources.ui.tabs);
  const dataSource = useSelector((state: RootState) => state.dataSources.edit?.dataSource) as Partial<TDataSource<DataSourceCodeConfig>> | undefined;

  const [steps, handleNextStep, handlePrevStep] = useDataSourceSteps();

  const [saveDataSource, { isLoading: isSaveLoading, isSuccess: isSaveSuccess, data: createdDataSource }] = useSaveDataSourceMutation();
  const [updateDataSource, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, data: updatedDataSource }] = useUpdateDataSourceMutation();

  const [saveRules, { data: createdRules, isLoading: isSaveRulesLoading }] = useSaveRulesMutation();
  const [getRulesByDataSourceId, rulesByDataSourceId] = useLazyGetRulesByDataSourceIdQuery();

  const isLoading = isSaveLoading || isUpdateLoading || isSaveRulesLoading;

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);
  const isLastStepCurrent = last(steps)?.status === EStepStatus.CURRENT;
  const isFirstStepCompleted = first(steps)?.status === EStepStatus.COMPLETE;

  const codeSteps: Step[] = [
    {
      id: ECodeSteps.SOURCE_DETAILS,
      label: t('code.steps.source_details'),
      status: EStepStatus.CURRENT,
    },
    {
      id: ECodeSteps.TRACK_ACTIVITY,
      label: t('code.steps.track_events'),
      status: EStepStatus.UPCOMING,
    },
    {
      id: ECodeSteps.INSTALL_SNIPPET,
      label: t('code.steps.install_snippet'),
      status: EStepStatus.UPCOMING,
    },
    {
      id: ECodeSteps.CHECK_INSTALLATION,
      label: t('code.steps.check_installation'),
      status: EStepStatus.UPCOMING,
    },
  ];

  useEffect(() => {
    if (rulesByDataSourceId.data) {
      dispatch(stashRules(rulesByDataSourceId.data));
    }
  }, [rulesByDataSourceId.data]);

  useEffect(() => {
    dispatch(stashSteps(codeSteps));
  }, []);

  useEffect(() => {
    if (createdDataSource) {
      dispatch(stashDataSource(createdDataSource.dataSource));
    }
  }, [createdDataSource]);

  useEffect(() => {
    if (isUpdateSuccess) {
      handleNextStep();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (updatedDataSource) {
      dispatch(stashDataSource(updatedDataSource));
    }
  }, [updatedDataSource]);

  useEffect(() => {
    if (isSaveSuccess) {
      handleNextStep();
    }
  }, [isSaveSuccess]);

  useEffect(() => {
    if (createdRules) {
      handleNextStep();
    }
  }, [createdRules]);

  const createOrUpdateDataSource = (dataSource: Partial<TDataSource<DataSourceCodeConfig>>) => {
    if (!!dataSource.name && dataSource.configuration) {
      if (!dataSource.id) {
        saveDataSource({
          workspaceId: workspace.id,
          dataSource: {
            type: dataSourceType.id,
            name: dataSource.name,
            configuration: { ...dataSource.configuration, url: prependHttp(dataSource.configuration?.url) },
          },
        });
      } else {
        updateDataSource({
          workspaceId: workspace.id,
          dataSourceId: dataSource.id,
          dataSource: {
            name: dataSource.name,
            configuration: { ...dataSource.configuration, url: prependHttp(dataSource.configuration?.url) },
          },
        });
      }
    }
  };

  const submitRules = (events: ITrackWebsiteValue) => {
    const rules = mapEventsToRules(events);

    if (dataSource && dataSource.id) {
      const dataSourceId = dataSource.id;

      // adding new rules (without id)
      const createRules = rules.filter((rule) => rule.id === undefined);

      if (createRules) {
        saveRules({
          workspaceId: workspace.id,
          dataSourceId: dataSourceId,
          rules: createRules,
        });
      }
    }
  };

  const handleNextButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;

    if (currentStepId === ECodeSteps.SOURCE_DETAILS) {
      await dataSourceInfoFormRef.current?.submitForm();

      if (dataSourceInfoFormRef.current?.isValid && dataSourceInfoFormRef.current?.values) {
        createOrUpdateDataSource(dataSourceInfoFormRef.current?.values);
      }

      return;
    }

    if (currentStepId === ECodeSteps.TRACK_ACTIVITY) {
      if (dataSource && dataSource.id) {
        getRulesByDataSourceId({
          dataSourceId: dataSource.id,
          workspaceId: workspace.id,
        });
      }
      await trackWebsiteActivityFormRef.current?.submitForm();
      const events = trackWebsiteActivityFormRef.current?.values;

      if (trackWebsiteActivityFormRef.current?.isValid && events) {
        submitRules(events);
      }
      return;
    }

    if (isLastStepCurrent) {
      closeModal();
      dispatch(setDataSourceTabs(tabs.map((t) => ({ ...t, current: t.id === 'datasource.table' }))));

      return;
    }

    handleNextStep();
  };

  const handleOnSubmit = () => {
    // handlesubmit
  };

  return (
    <div className='h-full divide-y divide-gray-200 flex flex-col'>
      <div className='flex-1 flex flex-col pt-6 bg-gray-50 rounded-lg'>
        <DataSourceHeader closeModal={closeModal} title={t('title', { name: dataSourceType.name })} loading={isLoading} />
        <div className='mt-6 py-6 relative flex-1 px-4 sm:px-6 bg-white-100 overflow-y-auto'>
          <Stepper steps={steps} />
          <CodeDataSourceContent
            dataSourceType={dataSourceType}
            step={currentStep}
            dataSourceInfoFormRef={dataSourceInfoFormRef}
            trackWebsiteActivityFormRef={trackWebsiteActivityFormRef}
            goNext={handleNextButtonClicked}
            loading={isLoading}
            handleOnSubmit={handleOnSubmit}
          />
        </div>
      </div>
      <div className='flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg'>
        {isFirstStepCompleted && (
          <Button
            variant='light'
            type='button'
            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'
            onClick={handlePrevStep}
            disabled={isLoading}
          >
            {t('back')}
          </Button>
        )}
        <Button
          variant={!isLastStepCurrent ? 'primary' : 'confirm'}
          type='submit'
          className='ml-4 inline-flex justify-center py-2 px-4'
          onClick={handleNextButtonClicked}
          disabled={isLastStepCurrent && dataSource?.status !== EDataSourceStatus.ACTIVE}
          loading={isLoading}
        >
          {t(!isLastStepCurrent ? 'next' : 'finish')}
        </Button>
      </div>
    </div>
  );
};

export default CodeDataSourceContainer;
