import { isEmpty } from "lodash";
import React, { Fragment, FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { SpinnerIcon } from "../../components/icons";
import { useDelayedBoolean } from "../../hooks/useDelayedBoolean";
import { RootState, useAppDispatch } from "../../reducers";
import { useGetAudiencesQuery } from "../../services/endpoints/audiences";
import { MainContentWrapper } from "../layout/main";
import { useWorkspace } from "../workspaces/hooks";
import { reset } from "./audiencesSlice";
import { AudienceEmptySet } from "./components";
import AudienceListTabs from "./components/lists/AudienceListTabs";

interface IAudienceProps {
}

const MINIMUM_LOADING_DURATION = 400;

const Audiences: FunctionComponent<IAudienceProps> = () => {
  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const uiState = useSelector((state: RootState) => state.audiences.ui);
  if (!uiState.regular) {
    dispatch(reset());
  }

  const { data: audiences, isLoading, isUninitialized, isFetching } = useGetAudiencesQuery({
    workspaceId: workspace.id,
    offset: 0,
    limit: 1
  });
  const isLoadingDelayed = useDelayedBoolean(isLoading, MINIMUM_LOADING_DURATION);
  const shouldShowLoading = isLoading || isLoadingDelayed || isUninitialized;

  return shouldShowLoading ? (
    <Fragment>
      <MainContentWrapper>
        <span className="text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <SpinnerIcon className="-ml-1 mr-3 h-5 w-5" loading />
        </span>
      </MainContentWrapper>
    </Fragment>
  ) : (isFetching || (audiences && !isEmpty(audiences)) || Boolean(uiState.regular.searchText)) ? (
    <AudienceListTabs />
  ) : (
    <AudienceEmptySet />
  );
};

export default Audiences;
