import { CheckIcon } from '@heroicons/react/solid';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadingFile } from '../../..';
import { Button } from '../../../../../../components/buttons';
import { IFileUploadStatus } from '../../../../../../models/fileUpload';

interface IUploadOngoing {
  fileUpload: IFileUploadStatus;
  filename: string;
  onClick: () => void;
}

const UploadingOngoing: FunctionComponent<IUploadOngoing> = ({ fileUpload, filename, onClick }) => {
  const { t } = useTranslation('data_source_edit');

  return (
    <div className='flex flex-col justify-center text-center'>
      <span className='m-auto w-10 h-10 flex items-center justify-center bg-green-600 rounded-full'>
        <CheckIcon className='w-6 h-6 text-white-100' aria-hidden='true' />
      </span>
      <h3 className='font-medium text-lg text-gray-900 mt-3 mb-3'>{t('file.confirmation.all_columns_are_mapped')}</h3>
      <p className='font-normal text-base text-gray-900 w-2/3 m-auto mb-9'>{t('file.confirmation.all_columns_are_mapped_description')}</p>
      <div className='border border-gray-300 bg-gray-50 p-5 w-2/3 m-auto rounded-sm'>
        <p className='font-normal text-sm text-gray-900 m-auto mb-9'>{t('file.confirmation.continue_working')}</p>
        <UploadingFile fileUpload={fileUpload} filename={filename} />
      </div>
      <div className='flex justify-end w-2/3 my-3 m-auto'>
        <Button variant='confirm' onClick={onClick}>
          {t('file.confirmation.continue_in_background')}
        </Button>
      </div>
    </div>
  );
};

export default UploadingOngoing;
