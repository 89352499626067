import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon, UserIcon } from "@heroicons/react/outline";
import { Fragment, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Breadcrumb, Breadcrumbs } from ".";
import { logout } from "../../../features/auth/authSlice";
import { toggleSidebar } from "../../../features/sidebar/sidebarSlice";
import { TabType } from "../../../models/tabs";
import { useAppDispatch } from "../../../reducers";
import { concatClassNames } from "../../../utils";

interface ITopbarTabsProps {
  tabs: TabType[];
  onTabChange: (tab: TabType) => void;
  breadcrumbs?: Breadcrumb[];
  label?: string;
  rightElement?: () => JSX.Element;
}

const TopbarTabs: FunctionComponent<ITopbarTabsProps> = ({ tabs, onTabChange, breadcrumbs, label, rightElement }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { user } = useAuth0();

  const onOpenSidebarClicked = () => {
    dispatch(toggleSidebar(true));
  };

  const onLogoutClicked = () => {
    dispatch(logout());
  };

  return (
    <div
      className={concatClassNames("bg-white-100 relative z-10 flex-shrink-0 shadow", rightElement ? "pt-3" : "pt-4")}>
      <div className="flex flex-col ">
        <button
          type="button"
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
          onClick={onOpenSidebarClicked}
        >
          <span className="sr-only">{t("sr_only:open_sidebar")}</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 px-4 justify-between">
          <div className="flex-1 flex items-center">
            {breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : !!label &&
              <h1 className="text-xl text-gray-900 ml-4 md:ml-6">{label}</h1>}
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            {rightElement && rightElement()}
            {/*<button className='bg-white-100 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
              <span className='sr-only'>{t('sr_only:view_notification')}</span>
              <BellIcon className='h-6 w-6' aria-hidden='true' />
            </button>*/}

            <Menu as="div" className="ml-3">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button
                      className="max-w-xs bg-white-100 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      <span className="sr-only">{t("sr_only:open_user_menu")}</span>
                      <UserIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 min-w-48 rounded-md shadow-lg py-1 bg-white-100 ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <Menu.Item>
                        {() => (
                          <span
                            className={'block px-4 py-2 text-sm'}
                          >
                            {user && <span className="flex text-sm text-gray-500">{user.name || user.email}</span>}
                          </span>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href='#'
                            onClick={() => history.push('/settings')}
                            className={concatClassNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            {t('topbar:company_settings')}
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={onLogoutClicked}
                            className={concatClassNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700")}
                          >
                            {t("topbar:sign_out")}
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <div className="pt-3">
        <div className="flex flex-col sm:hidden px-4 ml-4 md:ml-6">
          <label htmlFor="tabs" className="sr-only">
            {t("sr_only:select_tab")}
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-1/2 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
            defaultValue={tabs.find((tab) => tab.current)?.label}
          >
            {tabs.map((tab) => (
              <option key={tab.id}>{tab.label}</option>
            ))}
          </select>
        </div>
        <div className="hidden flex-col sm:block px-4 ml-4 md:ml-6">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                key={tab.id}
                onClick={() => onTabChange(tab)}
                className={concatClassNames(
                  tab.current ? " text-blue-600 border-blue-600 border-b-2" : "text-gray-600 hover:text-gray-800",
                  "font-medium text-sm py-2 px-1 inline cursor-pointer "
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {!!tab.icon && <tab.icon className="inline h-4 w-4 mr-2" />}
                <p className="inline h-4">{tab.label}</p>
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TopbarTabs;
