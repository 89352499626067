import { TrashIcon } from "@heroicons/react/solid";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/buttons";
import { IModalContentProps } from "../../../models/modals";
import { ToastType } from "../../../models/toast";
import { WorkspaceId, WorkspaceUser } from "../../../models/workspace";
import { useAppDispatch } from "../../../reducers";
import { useDeleteWorkspaceUserMutation } from "../../../services/endpoints/workspaces";
import { showToast } from "../../toasts/toastsSlice";

interface IDeleteWorkspaceUserModal {
  workspaceId: WorkspaceId;
  user: WorkspaceUser;
}

const DeleteWorkspaceUserModal: FunctionComponent<IDeleteWorkspaceUserModal & IModalContentProps> = ({ workspaceId, user, closeModal }) => {
  const { t } = useTranslation('workspaces');
  const dispatch = useAppDispatch();

  const [deleteInvite, { isLoading, isSuccess }] = useDeleteWorkspaceUserMutation();

  const handleDeleteClicked = () => {

    deleteInvite({ workspaceId, id: user.id });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('users.delete.success_title'),
          message: t('users.delete.success_message', { email: user.email }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className='flex justify-center h-60 '>
      <div className='max-w-4xl my-auto text-left p-5'>
        <h1 className='text-lg text-black-100 font-semibold mb-5'>{t('users.delete.modal_title')}</h1>
        <p className='text-base'>
          {t('users.delete.modal_confirm')}&nbsp;(<b>{user.email}</b>)
        </p>
        <Button className="mt-3" icon={TrashIcon} variant='delete' onClick={handleDeleteClicked} loading={isLoading} >
          {t('users.delete.modal_button_title')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteWorkspaceUserModal;
