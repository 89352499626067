import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Rule } from '../../models/rules';

type rulesState = {
  rulesList: Partial<Rule>[] | [];
};

export const initialState: rulesState = {
  rulesList: [],
};

const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    stashRules: (state, action: PayloadAction<Partial<Rule>[]>) => ({
      ...state,
      rulesList: action.payload,
    }),
    clearStashedRules: (state) => ({
      ...state,
      rulesList: [],
    }),
  },
});

export const { stashRules, clearStashedRules } = rulesSlice.actions;

export default rulesSlice.reducer;
