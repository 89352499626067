import { Popover, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/solid';
import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Condition } from '../../../../models/rules';
import UserGroupIcon from '../../../../assets/icons/user-group.svg?react';
import HandPointerIcon from '../../../../assets/icons/hand-pointer.svg?react';
import EyeIcon from '../../../../assets/icons/eye.svg?react';
import IdBadgeIcon from '../../../../assets/icons/id-badge.svg?react';
import { useWorkspace } from '../../../workspaces/hooks';
import { useGetAudiencesQuery } from '../../../../services/endpoints/audiences';
import classNames from 'classnames';
import { useGetRulesQuery } from '../../../../services/endpoints/rules';

interface IConditionSelectorProps {
    datasourceIds?: string[];
    onConditionSelected: (condition: Condition) => void;
    isFirstCondition?: boolean;
}

const CONDITIONS: { condition: Condition; name: string; icon: any }[] = [
    {
        name: 'Part of an audience',
        icon: UserGroupIcon,
        condition: {
            id: 1,
            kind: 'MEMBERSHIP',
        },
    },
    {
        name: 'Performed an event',
        icon: HandPointerIcon,
        condition: {
            id: 2,
            kind: 'EVENT',
        },
    },
    {
        name: 'Viewed a page',
        icon: EyeIcon,
        condition: {
            id: 3,
            kind: 'VISIT',
        },
    },
    {
        name: 'Have a property',
        icon: IdBadgeIcon,
        condition: {
            id: 4,
            kind: 'PROPERTY',
        },
    },
];

const ConditionSelector: FunctionComponent<IConditionSelectorProps> = ({ onConditionSelected, isFirstCondition, datasourceIds }) => {
    const { t } = useTranslation('audience_edit');

    const workspace = useWorkspace();

    const { isLoading: isAudiencesLoading, data: audiences } = useGetAudiencesQuery({
        workspaceId: workspace.id,
    });

    const { isLoading: isLoadingView, data: viewRules } = useGetRulesQuery({
        workspaceId: workspace.id,
        type: 'VIEW',
        datasourceIds: datasourceIds || [],
    });

    const { isLoading: isLoadingTrack, data: trackRules } = useGetRulesQuery({
        workspaceId: workspace.id,
        type: 'TRACK',
        datasourceIds: datasourceIds || [],
    });

    return (
        <Popover className='relative max-w-max'>
            {() => (
                <>
                    <Popover.Button
                        className={`px-3 py-2 h-45 rounded text-base justify-center outline-none inline-flex items-center disabled:opacity-40 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 bg-white-100 text-blue-700 hover:bg-blue-100 font-medium`}
                    >
                        <PlusIcon className={`mr-2 h-5 w-5 text-blue-700 transition ease-in-out duration-150`} aria-hidden='true' />
                        <span className=''>{t(isFirstCondition ? 'rules.buttons.add_first_condition' : 'rules.buttons.add_condition')}</span>
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100 translate-y-0'
                        leaveTo='opacity-0 translate-y-1'
                    >
                        <Popover.Panel static className='absolute origin-top-right top-full -left-1 mt-1 z-10'>
                            {({ close }) => (
                                <div className='overflow-hidden rounded-lg shadow-lg w-full border'>
                                    <div className='relative gap-8 bg-white-100'>
                                        {CONDITIONS.map((c) => {
                                            let isDisabled = false;
                                            let isLoading = false;
                                            switch (c.condition.kind) {
                                                case 'MEMBERSHIP':
                                                    isDisabled = audiences?.items.length == 0;
                                                    isLoading = isAudiencesLoading;
                                                    break;
                                                case 'EVENT':
                                                    isDisabled = trackRules?.length == 0;
                                                    isLoading = isLoadingTrack;
                                                    break;
                                                case 'VISIT':
                                                    isDisabled = viewRules?.length == 0;
                                                    isLoading = isLoadingView;
                                            }
                                            return (
                                                <>
                                                    <button
                                                        key={c.condition.id}
                                                        className={classNames(
                                                            isDisabled ? 'bg-gray-100' : 'hover:bg-blue-600 bg-white-100',
                                                            `group flex items-center w-full py-2 pl-3 pr-6 `
                                                        )}
                                                        disabled={isLoading || isDisabled}
                                                        onClick={() => {
                                                            onConditionSelected(c.condition);
                                                            close();
                                                        }}
                                                    >
                                                        <c.icon className={'w-5 h-5 mr-3'} />
                                                        <span className={classNames(isDisabled ? '' : 'group-hover:text-white-100', `text-gray-700 truncate `)}>{c.name}</span>
                                                    </button>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default ConditionSelector;
