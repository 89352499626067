import { useSelector } from 'react-redux';
import { Workspace } from '../../../models/workspace';
import { RootState } from '../../../reducers';
import { isWorkspaceAdmin } from '../../userInfo/hooks/useUserInfo';

function assertWorkspaceIsDefined(workspace: Workspace | undefined) {
    if (!workspace) {
        throw new Error(`'RootState' has an undefined 'workspace' configured, expected a workspace-defined state`);
    }
}

export function useWorkspace() {
    const workspace = useSelector((state: RootState) => state.workspaces.currentWorkspace);

    assertWorkspaceIsDefined(workspace);

    return workspace!; // TypeScript knows this is a workspace defined
}

export function useWorkspaceAdmin() {
    const w = useWorkspace()!;
    return isWorkspaceAdmin(w.id);
}
