import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router';
import { Loading } from '../../../components/loaders';

//TODO error handling
const PrivateRoute: FunctionComponent<RouteProps<string>> = ({ component, ...props }) => {
  if (!component) {
    console.error('Component cannot be null');
    return null;
  }

  // const { error } = useAuth0();

  // if (error) {
  //   console.error(error);
  // }

  return <Route component={withAuthenticationRequired(component, { onRedirecting: () => <Loading /> })} {...props} />;
};

export default PrivateRoute;
