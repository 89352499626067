import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { NavigationItem } from '../../../models/navigation';
import { concatClassNames } from '../../../utils';

interface ISidebarLinkProps {
  item: NavigationItem;
  onClick?: (item: NavigationItem) => void;
}

const SidebarLink: FunctionComponent<ISidebarLinkProps> = ({ item, onClick, ...rest }) => {
  const { name, to, current } = item;

  const onLinkClicked = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <Link
      {...rest}
      to={to}
      onClick={onLinkClicked}
      className={concatClassNames(
        current ? 'bg-blue-800 text-white-100' : 'text-blue-100 hover:bg-blue-800',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md focus:bg-blue-900'
      )}
    >
      <item.icon className='mr-4 flex-shrink-0 h-6 w-6 text-blue-300' aria-hidden='true' />
      {name}
    </Link>
  );
};

export default SidebarLink;
