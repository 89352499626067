import React, { FunctionComponent, useEffect, useState } from "react";
import { AudienceSyncTimeBasedSchedule, WeekDay, WeekDays, WeekDayShortNames } from "../../../../models/audiences";

interface IAudienceSyncRepeatWeekdayProps {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

type Day = {
  id: WeekDay;
  label: string;
  selected: boolean;
}

const AudienceSyncRepeatWeekday: FunctionComponent<IAudienceSyncRepeatWeekdayProps> = ({ scheduleConfiguration, setFieldValue }) => {
  const initialDays = scheduleConfiguration.weekDays || [];
  const [days, setDays] = useState<Day[]>(WeekDays.map((weekday, index) => ({
    id: weekday,
    label: WeekDayShortNames[index],
    selected: initialDays.indexOf(weekday) > -1
  })));

  useEffect(() => {
    setFieldValue(
      "scheduleConfiguration.weekDays",
      days.filter(day => day.selected).map(day => day.id)
    );
  }, [days]);

  const onDayClick = (days: Day[], clickedDay: Day) => {
    setDays(days.map(day => day.id !== clickedDay.id ? day : { ...day, selected: !clickedDay.selected }));
  };

  return (
    <div className="flex flex-row items-center space-x-2">
      <span className={'mr-2'}>
        on
      </span>
      {days.map(day => day.selected ? (
        <span
          key={day.id}
          className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-blue-600 bg-blue-600 rounded-full cursor-pointer"
          onClick={() => onDayClick(days, day)}
        >
          <span className="text-sm text-white-100">{day.label}</span>
        </span>
      ) : (
        <span
          key={day.id}
          className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-200 bg-gray-200 rounded-full cursor-pointer"
          onClick={() => onDayClick(days, day)}
        >
          <span className="text-sm text-gray-700">{day.label}</span>
        </span>
      ))}
    </div>
  );
};

export default AudienceSyncRepeatWeekday;
