import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { ErrorStatus, SuccessStatus, UploadingStatus } from '.';
import { EStatus, IFileUploadStatus } from '../../models/fileUpload';

interface IFileUploadStatusProps {
  fileUpload: IFileUploadStatus;
  handleCancel: () => void;
  handleChangeFile: () => void;
  error?: FetchBaseQueryError | SerializedError | string;
}

const FileUploadStatus: FunctionComponent<IFileUploadStatusProps> = ({ fileUpload, handleCancel, handleChangeFile, error }) => {
  switch (fileUpload.status) {
    case EStatus.UPLOADING:
      return <UploadingStatus handleCancel={handleCancel} fileUpload={fileUpload} />;
    case EStatus.SUCCESS:
      return <SuccessStatus handleChangeFile={handleChangeFile} />;
    case EStatus.ERROR:
      return <ErrorStatus handleTryAgain={handleChangeFile} error={error} />;
    default:
      return null;
  }
};

export default FileUploadStatus;
