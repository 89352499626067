import { isEmpty } from 'lodash';
import { TFunction } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Rule } from '../../../../../../../models/rules';
import { ITrackWebsiteValue, ESelector, ITrackClickEvents, TTrackWebsiteError } from '../../../../../types';

export const mapRulesToEvents = (rules: Partial<Rule>[], baseUrl: string) => {
  const events: ITrackWebsiteValue = { clickEvents: [], pageVisitEvents: [] };

  rules.forEach((rule) => {
    if (rule.type === 'TRACK') {
      events.clickEvents.push({
        id: rule.id,
        eventName: rule.name || '',
        selectorName: rule.configuration?.selector || '',
        selectorType: rule.configuration?.element === 'ID' ? ESelector.ID : ESelector.CLASS,
        identifier: uuidv4(),
      });
    }
    if (rule.type === 'VIEW') {
      const path = rule.configuration?.path;
      const pagePath = path ? (path.charAt(0) === '/' ? path.substring(1) : path) : '';

      events.pageVisitEvents.push({
        id: rule.id,
        eventName: rule.name || '',
        pagePath: pagePath,
        baseUrl: baseUrl,
        identifier: uuidv4(),
      });
    }
  });

  return events;
};

export const mapEventsToRules = (events: ITrackWebsiteValue): Partial<Rule>[] => {
  const rules: Partial<Rule>[] = [];

  events.clickEvents.forEach((event) => {
    const isClassElement = event.selectorType === ESelector.CLASS;
    rules.push({
      name: event.eventName,
      type: 'TRACK',
      configuration: {
        type: 'CLICK',
        selector: event.selectorName,
        element: isClassElement ? 'CLASS' : 'ID',
      },
      ...(event.id && { id: event.id }),
    });
  });

  events.pageVisitEvents.forEach((event) => {
    if (!event.pagePath) {
      event.pagePath = '/';
    }

    const path = `${event.pagePath.charAt(0) === '/' ? '' : '/'}${event.pagePath}`;

    rules.push({
      name: event.eventName,
      type: 'VIEW',
      configuration: {
        path,
      },
      ...(event.id && { id: event.id }),
    });
  });

  return rules;
};

// returns a list of ids
export const isEventNameUnique = (eventName: string, events: Pick<ITrackClickEvents, 'identifier' | 'eventName'>[]): string[] => {
  const sameEventNameList = events.filter((event) => event.eventName === eventName);

  if (sameEventNameList.length > 0) {
    return sameEventNameList.map((event) => event.identifier);
  }
  return [];
};

// events validation
export const customValidation = (values: ITrackWebsiteValue, t: TFunction<'data_source_edit'>): TTrackWebsiteError | [] => {
  const errors: TTrackWebsiteError = { clickEvents: [], pageVisitEvents: [] };

  // CLICK EVENTS VALIDATION
  if (!isEmpty(values.clickEvents)) {
    values.clickEvents.filter((event, index) => {
      if (!event.selectorName) {
        const errorObj = errors.clickEvents[index];
        errors.clickEvents[index] = { ...errorObj, selectorName: t('validations.required') };
      } else {
        if (/\s/.test(event.selectorName)) {
          const errorObj = errors.clickEvents[index];
          errors.clickEvents[index] = { ...errorObj, selectorName: t('validations.invalid_selector') };
        }
      }
      if (!event.eventName) {
        const errorObj = errors.clickEvents[index];
        errors.clickEvents[index] = { ...errorObj, eventName: t('validations.required') };
      } else {
        // returns a list of ids
        const sameClickEventNameIdentifiers = isEventNameUnique(event.eventName, values.clickEvents);

        if (sameClickEventNameIdentifiers.length > 1) {
          sameClickEventNameIdentifiers.filter((identifier) => {
            // get values.events index from id
            const index = values.clickEvents.findIndex((value) => value.identifier === identifier);
            const errorObj = errors.clickEvents[index];
            errors.clickEvents[index] = { ...errorObj, eventName: t('validations.unique') };
          });
        }
      }
    });
  }

  // PAGE EVENTS VALIDATION
  if (!isEmpty(values.pageVisitEvents)) {
    values.pageVisitEvents.filter((event, index) => {
      if (event.pagePath) {
        const errorObj = errors.pageVisitEvents[index];
        // check if a valid url
        // eslint-disable-next-line no-useless-escape
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

        const path = `${event.baseUrl}${event.pagePath}`;

        if (!path.match(urlRegex)) {
          errors.pageVisitEvents[index] = { ...errorObj, pagePath: t('validations.not_valid_url') };
        }
      }

      if (!event.eventName) {
        const errorObj = errors.pageVisitEvents[index];
        errors.pageVisitEvents[index] = { ...errorObj, eventName: t('validations.required') };
      } else {
        // returns a list of ids
        const samePageEventNameIdentifiers = isEventNameUnique(event.eventName, values.pageVisitEvents);
        const sameClickEventNameIdentifiers = isEventNameUnique(event.eventName, values.clickEvents);

        const isEventNameDuplicate = samePageEventNameIdentifiers.length + sameClickEventNameIdentifiers.length > 1;

        if (!isEmpty(samePageEventNameIdentifiers) && isEventNameDuplicate) {
          samePageEventNameIdentifiers.filter((identifier) => {
            // get values.events index from identifier
            const index = values.pageVisitEvents.findIndex((value) => value.identifier === identifier);
            const errorObj = errors.pageVisitEvents[index];
            errors.pageVisitEvents[index] = { ...errorObj, eventName: t('validations.unique') };
          });
        }

        if (!isEmpty(sameClickEventNameIdentifiers) && isEventNameDuplicate) {
          sameClickEventNameIdentifiers.filter((identifier) => {
            // get values.events index from id
            const index = values.clickEvents.findIndex((value) => value.identifier === identifier);
            const errorObj = errors.clickEvents[index];
            errors.clickEvents[index] = { ...errorObj, eventName: t('validations.unique') };
          });
        }
      }
    });
  }

  return isEmpty(errors.clickEvents) && isEmpty(errors.pageVisitEvents) ? [] : errors;
};
