import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSourceCard, DataSourceTypePanel } from '.';
import { useSlideOverContext } from '../../../hooks/useSlideOverContext';
import { DataSourceType, EDataSourceCategory } from '../../../models/dataSource';
import { useGetDataSourceTypesQuery } from '../../../services/dataSources';
import { MainContentWrapper } from '../../layout/main';
import { Topbar } from '../../layout/topbar';

interface IAddFirstDataSourceProps {
  onConfirmClicked: (dataSourceType: DataSourceType) => void;
}

const AddFirstDataSource: FunctionComponent<IAddFirstDataSourceProps> = ({ onConfirmClicked }) => {
  const { t } = useTranslation();
  const { openSlideOver } = useSlideOverContext();

  const { isLoading, data } = useGetDataSourceTypesQuery();

  const availableSources = data?.filter((data) => data.category === EDataSourceCategory.CODE || data.category === EDataSourceCategory.FILE);

  const mostUsedResources = availableSources?.filter((data) => data.category === EDataSourceCategory.CODE);
  const otherResources = availableSources?.filter((data) => data.category !== EDataSourceCategory.CODE);

  const handleClick = (dataSourceType: DataSourceType) => {
    openSlideOver({
      renderContent: DataSourceTypePanel,
      renderContentProps: { dataSourceType, onAddDataSourceClicked: onConfirmClicked },
    });
  };

  return (
    <Fragment>
      <Topbar title={t('data_sources:add_first_data_source.title')} />
      <MainContentWrapper>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='mb-10 mt-8 w-1/2'>
            <p className='text-sm text-gray-600 leading-5'>{t('data_sources:add_first_data_source.description')}</p>
          </div>
          <h1 className='text-lg font-semibold text-gray-900 leading-7'>{t('data_sources:add_first_data_source.most_used_sources')}</h1>
          <div className='grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-8 pt-4 mb-12'>
            {mostUsedResources?.map((source) => (
              <DataSourceCard key={source.name} dataSourceType={source} onClick={() => handleClick(source)} />
            ))}
          </div>
        </div>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <h1 className='text-lg font-semibold text-gray-900 leading-7'>{t('data_sources:add_first_data_source.other_resources')}</h1>
          <div className='grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-8 pt-4 mb-12'>
            {otherResources?.map((source) => (
              <DataSourceCard key={source.name} dataSourceType={source} onClick={() => handleClick(source)} />
            ))}
          </div>
        </div>
      </MainContentWrapper>
    </Fragment>
  );
};

export default AddFirstDataSource;
