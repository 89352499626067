import React, { FunctionComponent } from 'react';
import { concatClassNames } from '../../utils';
import { SpinnerIcon } from '../icons';
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from 'uuid';

export interface ICheckboxProps {
  id?: string;
  label?: string;
  description?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  loading?: boolean;
  extraInfo?: string;
  additionalRequiredLabel?: boolean;
  forceShowingDescription?: boolean;
}

type TInputType = ICheckboxProps & React.ComponentProps<'input'>;

const Checkbox: FunctionComponent<TInputType> = ({ id, name, label, className, error, placeholder, extraInfo, description, loading, additionalRequiredLabel, forceShowingDescription, ...rest }) => {
  const {t} = useTranslation('checkbox');

  let inputStyle = 'focus:ring-blue-500 h-5 w-5 border-gray-300 rounded mt-1';

  if (error) {
    inputStyle = concatClassNames(inputStyle, 'border-red-500 hover:border-red-500 focus:border-red-500 outline-none');
  }

  if (rest.disabled) {
    inputStyle = concatClassNames(inputStyle, "mt-2 bg-gray-200 text-blue-400");
  } else {
    inputStyle = concatClassNames(inputStyle, "mt-2 text-blue-600");
  }

  if(!id) {
    id= uuidV4();
  }

  return (
    <div className='inline-flex flex-col w-full my-2 text-left'>
      <div className='relative flex items-start'>
        <div className='flex items-center h-5'>
          <input
            {...rest}
            id={id}
            name={name}
            type="checkbox"
            placeholder={placeholder}
            className={className ? concatClassNames(inputStyle, className) : inputStyle}
          />
        </div>
        <div className='ml-3 text-base'>
          {label && (
            <label htmlFor={id} className='block font-medium text-gray-700 cursor-pointer'>
              {label}
              {additionalRequiredLabel && rest.required && <sub> ({t('required')})</sub>}
            </label>
          )}
          <p id='checkbox-description' className={`text-gray-500 font-normal ${forceShowingDescription && !description ? 'h-6' : ''}`}>
            {description}
          </p>
        </div>
        {loading && (
          <div className='flex text-center justify-center h-6 ml-4'>
            <SpinnerIcon className='h-4 w-4' fill='rgba(21,68,155,1)' loading />
          </div>
        )}
      </div>
      {extraInfo && <p className='mt-2 text-sm text-gray-800'>{extraInfo}</p>}
      {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
    </div>
  );
};

export default Checkbox;
