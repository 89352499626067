import { FieldArray, Form, Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";
import React, { Fragment, FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "../../../../../components/inputs";
import { RootState } from "../../../../../reducers";
import { ICrmField, ICrmFields } from "../../../types";

interface ICrmDataSourceFieldsFormProps {
  dataSourceFieldsFormRef: React.RefObject<FormikProps<ICrmFields>>;
}

const CrmDataSourceFieldsForm: FunctionComponent<ICrmDataSourceFieldsFormProps> = ({ dataSourceFieldsFormRef }) => {
  const fieldDefs = useSelector((state: RootState) => state.dataSourceIntegration.fieldDefinitions);

  const storedFields = useSelector((state: RootState) => state.dataSourceIntegration.fields);
  const storedFieldMap = new Map<number, ICrmField>(storedFields.map(field => [field.fieldId, field]));

  const fields: ICrmField[] = fieldDefs.map(fieldDef => ({
    ...fieldDef,
    checked: storedFieldMap.has(fieldDef.fieldId) ? (storedFieldMap.get(fieldDef.fieldId) as ICrmField).checked : true
  }));

  const onSubmit = () => {
    console.debug("On fields submit");
  };

  return (
    <Formik
      innerRef={dataSourceFieldsFormRef}
      initialValues={{ fields }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <FieldArray
              name="fields"
              validateOnChange={false}
              render={(clickEventHelper) => (
                <Fragment>
                  {
                    clickEventHelper && !isEmpty(clickEventHelper) && values.fields.map((field, index) => (
                      <Checkbox
                        key={field.fieldId}
                        label={field.name}
                        description={field.description}
                        disabled={!field.isOptional}
                        checked={field.checked}
                        onChange={(e) => setFieldValue(
                          `fields.${index}`,
                          { ...field, checked: e.target.checked }
                        )}
                      />
                    ))
                  }
                </Fragment>
              )}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CrmDataSourceFieldsForm;
