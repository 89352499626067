import { SearchIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/solid';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/buttons';
import { Input } from '../../../components/inputs';
import { useDebounce } from '../../../hooks/useDebounce';
import { useModalContext } from '../../../hooks/useModalContext';
import { Company } from '../../../models/company';
import { Workspace } from '../../../models/workspace';
import { useGetCompanyWorkspacesQuery } from '../../../services/endpoints/companies';
import { WorkspaceEdit } from '../../workspaceEdit';
import { WorkspacesTable } from './';

interface IWorkspacesProps { 
  company: Company
}

const CompanyWorkspaces: FunctionComponent<IWorkspacesProps> = ({ company }) => {
  const { t } = useTranslation('settings');
  const { openModal } = useModalContext();

  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText, 500);

  const { data, isLoading: isGetLoading, isError: isGetError, error: getError } = useGetCompanyWorkspacesQuery({ q: debouncedSearchText, companyId: company.id });

  const filterWorkspace = (workspace: Workspace, searchText: string): boolean => {
    return workspace.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  };

  useEffect(() => {
    if (!isGetLoading && !isGetError && data) {
      setWorkspaces(data);
    }
  }, [data, isGetLoading, isGetError]);

  const handleCreateNewWorkspaceClicked = () => {
    openModal({
      renderContent: WorkspaceEdit,
      renderContentProps: {},
      dismissable: true,
      fullHeight: true,
    });
  };

  return (
    <div className='py-6'>
      <div className='flex flex-row justify-end mb-2'>
        <div className='w-1/5'>
          <Input icon={SearchIcon} placeholder={t('workspace.search')} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        </div>
      </div>

      <WorkspacesTable
        workspaces={debouncedSearchText ? workspaces.filter((ws) => filterWorkspace(ws, debouncedSearchText)) : workspaces}
        isLoading={isGetLoading}
      />

      <Button variant='transparent' icon={PlusIcon} className='whitespace-nowrap' onClick={handleCreateNewWorkspaceClicked}>
        {t('workspace.create')}
      </Button>
    </div>
  );
};

export default CompanyWorkspaces;
