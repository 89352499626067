const ExclamationIcon = (): JSX.Element => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'>
      <path
        opacity='0.4'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.9878 24.5035L17.8987 5.27764C17.0465 3.79873 14.909 3.79873 14.0554 5.27764L2.96631 24.5035C2.11501 25.9797 3.18076 27.8311 4.88751 27.8311H27.0661C28.7701 27.8311 29.8419 25.9824 28.9878 24.5035ZM15.9771 24.8733C15.1603 24.8733 14.4981 24.2111 14.4981 23.3943C14.4981 22.5776 15.1603 21.9154 15.9771 21.9154C16.7938 21.9154 17.456 22.5776 17.456 23.3943C17.456 24.2111 16.7938 24.8733 15.9771 24.8733ZM17.1528 19.7705L17.7444 13.8549C17.7651 13.647 17.697 13.4401 17.5568 13.2852C17.4166 13.1303 17.2175 13.042 17.0086 13.0419H14.9455C14.7369 13.0425 14.5383 13.1311 14.3985 13.2859C14.2588 13.4407 14.1909 13.6473 14.2116 13.8549L14.8032 19.7705C14.8409 20.1486 15.159 20.4365 15.5389 20.4365H16.417C16.797 20.4365 17.115 20.1486 17.1528 19.7705Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9455 13.042H17.0086C17.2171 13.0425 17.4158 13.1311 17.5556 13.2859C17.6953 13.4407 17.7632 13.6474 17.7425 13.8549L17.1509 19.7706C17.1132 20.1486 16.7951 20.4365 16.4151 20.4366H15.537C15.1571 20.4365 14.839 20.1486 14.8013 19.7706L14.2097 13.8549C14.189 13.6471 14.2571 13.4401 14.3973 13.2852C14.5374 13.1304 14.7366 13.042 14.9455 13.042ZM14.4981 23.3944C14.4981 22.5776 15.1602 21.9155 15.977 21.9155C16.7938 21.9155 17.4559 22.5776 17.4559 23.3944C17.4559 24.2112 16.7938 24.8733 15.977 24.8733C15.1602 24.8733 14.4981 24.2112 14.4981 23.3944Z'
        fill='black'
      />
    </mask>
    <g mask='url(#mask0)'>
      <rect width='38.4' height='38.4' transform='translate(-3.2002 -3.2002)' fill='#C48B06' />
    </g>
  </svg>
);

export default ExclamationIcon;
