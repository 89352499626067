import { ArrowRightIcon, DatabaseIcon } from '@heroicons/react/solid';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SimpleTable } from '../../../components/tables';
import { Workspace } from '../../../models/workspace';

interface IWorkspacesTableProps {
  workspaces: Workspace[];
  isLoading: boolean;
}

const WorkspacesTable: FunctionComponent<IWorkspacesTableProps> = ({ workspaces, isLoading }) => {
  const { t } = useTranslation('settings');

  const headings = [
    {
      label: t('workspace.table.workspace'),
      className: '',
    },
    {
      label: t('workspace.table.users'),
      className: 'text-right',
    },
  ];

  return (
    <div>
      <SimpleTable>
        <colgroup>
          <col style={{}} />
          <col style={{ width: '180px' }} />
        </colgroup>
        <thead>
          <tr>
            {headings.map((heading) => (
              <th
                key={heading.label}
                scope='col'
                className={`px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider sticky top-0 ${heading.className}`}
              >
                {heading.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr className='bg-white-100 border-b border-gray-200'></tr>
          ) : workspaces.length ? (
            workspaces.map((workspace) => (
              <tr key={workspace.id} className='bg-white-100 border-b border-gray-200 group'>
                <td className='pl-6 py-2 whitespace-nowrap'>
                  <div className='flex flex-row content-center'>
                    <span className='w-7 h-7 rounded-lg mr-1'>
                      <DatabaseIcon className='h-7' />
                    </span>
                    <p className='text-sm font-medium text-gray-900 mt-1'>{workspace.name}</p>
                  </div>
                </td>
                <td className='px-6 py-2 whitespace-nowrap text-right'>
                  <p className='block group-hover:hidden text-sm font-medium text-gray-900'>{workspace?.userCount || 'N/A'}</p>
                  <Link to={`/settings/workspace/${workspace.id}`} className='hidden group-hover:inline underline text-blue-600 my-auto'>
                    <div className='flex flex-row content-center justify-end'>
                      {t('workspace.table.details')}
                      <span className='w-6 h-6 rounded-lg ml-2'>
                        <ArrowRightIcon className='h-6' />
                      </span>
                    </div>
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr className='bg-white-100 border-b border-gray-200'>
              <td className='px-6 py-2 whitespace-nowrap text-center' colSpan={headings.length}>
                <p className='text-sm font-medium text-gray-500'>{t('workspace.table.empty')}</p>
              </td>
            </tr>
          )}
        </tbody>
      </SimpleTable>
    </div>
  );
};

export default WorkspacesTable;
