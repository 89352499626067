import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EStepStatus, Step, Stepper } from '../../../../components/steppers';
import { DistributionChannelContent } from '../../../../models/distributionChannels';
import DestinationCreateHeader from './DestinationCreateHeader';
import DestinationStepData from './DestinationStepData';
import DestinationStepDestination from './DestinationStepDestination';
import DestinationStepSync from './DestinationStepSync';
import { BaseAudience } from '../../../../models/audiences';

interface IDestinationCreateProps {
  closeModal: () => void;
  forceAudience?: BaseAudience,
}

const DestinationCreate: FunctionComponent<IDestinationCreateProps> = ({ closeModal, forceAudience }) => {
  const { t } = useTranslation('destinations');
  const [distributionChannelContent, setDistributionChannelContent] = useState<DistributionChannelContent>();

  const allSteps: Step[] = [
    {
      id: 1,
      label: t('edit.step.destination.title'),
      status: EStepStatus.CURRENT,
    },
    {
      id: 2,
      label: t('edit.step.data.title'),
      status: EStepStatus.UPCOMING,
    },
    {
      id: 3,
      label: t('edit.step.sync.title'),
      status: EStepStatus.UPCOMING,
    },
  ];

  const [steps, setSteps] = useState<Step[]>(allSteps);
  const [activeStep, setActiveStep] = useState(0);
  const [isExtendedAudience, setIsExtendedAudience] = useState<boolean>(false);

  const toDataStep = (distributionChannelContent: DistributionChannelContent, isExtended: boolean) => {
    steps[0]!.status = EStepStatus.COMPLETE;
    steps[1]!.status = EStepStatus.CURRENT;
    setDistributionChannelContent(distributionChannelContent);
    setActiveStep(1);
    setSteps(steps);
    setIsExtendedAudience(isExtended);
  };

  const nextStep = (distributionChannelContent: DistributionChannelContent) => {
    setDistributionChannelContent(distributionChannelContent);
    if (activeStep < steps.length - 1) {
      steps[activeStep]!.status = EStepStatus.COMPLETE;
      steps[activeStep + 1]!.status = EStepStatus.CURRENT;
      setActiveStep(activeStep + 1);
      setSteps(steps);
    }
  };

  // const prevStep = () => {
  //   if (activeStep > 0) {
  //     steps[activeStep].status = EStepStatus.COMPLETE;
  //     steps[activeStep - 1].status = EStepStatus.CURRENT;
  //     setActiveStep(activeStep - 1);
  //     setSteps(steps);
  //   }
  // };

  // const setStep = (step: Step) => {
  //   if (step.status !== EStepStatus.UPCOMING) {
  //     const currStep = steps[activeStep];
  //     currStep.status = EStepStatus.COMPLETE;
  //     step.status = EStepStatus.CURRENT;
  //     setActiveStep(steps.findIndex((s) => s.id === step.id));
  //     setSteps(steps);
  //   }
  // };

  const stepTemplate = () => {
    const currentStep = steps.find((step) => step.status === EStepStatus.CURRENT);
    switch (currentStep?.id) {
      case 1:
        return <DestinationStepDestination nextStep={toDataStep} distributionChannelContent={distributionChannelContent} forceAudience={forceAudience} />;
      case 2:
        return distributionChannelContent && <DestinationStepData distributionChannelContent={distributionChannelContent} nextStep={nextStep} />;
      case 3:
        return distributionChannelContent && <DestinationStepSync distributionChannelContent={distributionChannelContent} isExtendedAudience={isExtendedAudience} closeModal={closeModal} />;
      default:
        return null;
    }
  };

  return (
    <div className='h-full divide-y divide-gray-200 flex flex-col' data-testid='audienceEdit'>
      <div className='min-h-0 flex-1 flex flex-col pt-6 bg-gray-50 rounded-lg'>
        <DestinationCreateHeader closeModal={closeModal} />
        <div className='mt-6 pt-6 relative flex-1 bg-white-100 overflow-y-auto flex flex-col'>
          <div className={'px-6'}>
            <Stepper steps={steps} />
          </div>
          {stepTemplate()}
        </div>
      </div>
    </div>
  );
};

export default DestinationCreate;
