import { Form, Formik, FormikProps } from "formik";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Input, VisualInput } from "../../../../../components/inputs";
import { DataSourceCrmConfig, DataSourceType, EDataSourceCategory, TDataSource } from "../../../../../models/dataSource";
import { DataSourceCrmCreateFormModel } from "../../../types";

interface ICrmDataSourceCreateFormProps {
  dataSourceInfoFormRef: React.RefObject<FormikProps<DataSourceCrmCreateFormModel>>;
  onSubmit: (form: DataSourceCrmCreateFormModel) => void;
  dataSource?: Partial<TDataSource<DataSourceCrmConfig>>;
}

const CrmDataSourceCreateForm: FunctionComponent<ICrmDataSourceCreateFormProps> = ({ dataSource, onSubmit, dataSourceInfoFormRef }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t('data_source_edit:validations.too_short'))
      .max(50, t('data_source_edit:validations.too_long'))
      .required(t('data_source_edit:validations.required')),
    configuration: Yup.object({
    }),
  });

  const retrieveLabelForDataSourceType = (type?: DataSourceType) => {
    switch (type?.category) {
      case EDataSourceCategory.CRM:
        return type?.name;
      case EDataSourceCategory.CODE:
      case EDataSourceCategory.FILE:
      case EDataSourceCategory.OTHER:
      default:
        return 'TBD';
    }
  };

  return (
    <Formik
      innerRef={dataSourceInfoFormRef}
      initialValues={{ ...dataSource }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, setFieldError }) => {
        return (
          <Form>
            <VisualInput
              name='type'
              id='type'
              label={t('data_source_edit:crm.source_details.source_type')}
              value={retrieveLabelForDataSourceType(values.type)}
              iconUrl={values.type?.logoUrl}
            />
            <Input
              name='name'
              id='name'
              type='text'
              label={t('data_source_edit:crm.source_details.source_name')}
              value={values.name}
              placeholder={t('data_source_edit:crm.source_details.source_name_placeholder')}
              onChange={(e) => {
                setFieldError('name', undefined);
                handleChange('name')(e.target.value);
              }}
              error={errors.name}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CrmDataSourceCreateForm;
