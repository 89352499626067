import { FunctionComponent } from 'react';
import { SpinnerIcon } from '../icons/';

const Loading: FunctionComponent = () => {
  return (
    <div data-testid='loading' className='w-full h-full fixed block top-0 left-0 bg-white-100 opacity-75 z-50'>
      <span className='text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0'>
        <SpinnerIcon className='-ml-1 mr-3 h-5 w-5' loading />
      </span>
    </div>
  );
};

export default Loading;
