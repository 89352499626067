import React, { FunctionComponent, useEffect, useState } from 'react';
import { ITSelectProps } from '../../../../components/selects';
import { Trait, TraitDataType } from '../../../../models/traits';
import { useGetTraitsQuery } from '../../../../services/endpoints/traits';
import { useWorkspace } from '../../../workspaces/hooks';
import { SearchDropDown } from '../../../../components/dropdowns';
import { useGetCustomPropertiesQuery, useGetTraitDistributionsQuery } from '../../../../services/dataSources';
import _ from 'lodash';

interface ITraitSelectProps extends Partial<ITSelectProps<Trait>> {
  traitId?: string;
  datasourceIds?: string[];
}

export type DistributionCountMapType = { [key: string]: { count: number; total: number; percent: number } };

const TraitSelect: FunctionComponent<ITraitSelectProps> = ({ traitId, datasourceIds, ...rest }) => {
  const workspace = useWorkspace();

  const { isLoading, data } = useGetTraitsQuery({
    workspaceId: workspace.id,
  });

  const { data: customPropertiesData } = useGetCustomPropertiesQuery({
    workspaceId: workspace.id,
    datasourceIds: datasourceIds || [],
  });

  const { data: traitDistributionsData } = useGetTraitDistributionsQuery({
    workspaceId: workspace.id,
    datasourceIds: datasourceIds || [],
  });

  const customerTraits = data?.customer || [];
  const eventTraits = data?.event || [];
  const traits = _.sortBy([...customerTraits, ...eventTraits], (e) => e.displayName);
  const trait: any = traits.find((a) => a.id === traitId) || { displayName: traitId };
  const [searchQuery, setSearchQuery] = useState('');

  const [mergedItems, setMergedItems] = useState<Trait[]>([]);
  const [distributionCountMap, setDistributionCountMap] = useState<DistributionCountMapType>({});

  useEffect(() => {
    if (traitDistributionsData) {
      const map: DistributionCountMapType = {};
      for (const traitDistributiondata of traitDistributionsData) {
        for (const distribution of traitDistributiondata.distributions) {
          const distributionCount = map[distribution.trait.id] || { count: 0, percent: 0, total: 0 };
          distributionCount.total += distribution.total;
          distributionCount.count += distribution.count;
          distributionCount.percent = Math.round((distributionCount.count / distributionCount.total) * 100);
          map[distribution.trait.id] = distributionCount;
        }
      }
      setDistributionCountMap(map);
    }
  }, [traitDistributionsData]);

  useEffect(() => {
    let newMergedItems = [];
    if (traits.length > 0) {
      newMergedItems.push(...traits);
    }
    if (customPropertiesData) {
      newMergedItems.push(
        ...customPropertiesData.properties.map((customProperty) => ({
          id: customProperty.property,
          displayName: `${customProperty.displayName || customProperty.property} (${customProperty.type})`,
          dataType: customProperty.type,
          custom: true,
        }))
      );
    }
    if (searchQuery?.trim()) {
      newMergedItems = newMergedItems.filter((m) => ((m as any).display || m.displayName).toLowerCase().includes(searchQuery?.trim().toLowerCase()));
    }
    setMergedItems(newMergedItems as any);
  }, [traits, customPropertiesData, searchQuery]);

  const labelColor = (percent: number) => {
    if (percent < 35) {
      return 'bg-red-200';
    } else if (percent < 70) {
      return 'bg-yellow-200';
    } else {
      return 'bg-green-200';
    }
  };

  return (
    <SearchDropDown
      items={mergedItems}
      item={trait}
      setItem={(traitItemValue) => {
        console.log('new trait', traitItemValue);
        rest.onChange?.({
          property: String(traitItemValue.id || traitItemValue.displayName),
          dataType: traitItemValue.dataType,
          value: '', // reset value every time a trait is selected
          trait: !!traitItemValue.id && !(traitItemValue as any).custom, //
        });
      }}
      setQuery={setSearchQuery}
      loading={isLoading}
      className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
      placeholder={'property'}
      openWithFocus={true}
      acceptCustomValue={true}
      readOnly={rest.isViewMode}
    >
      {(item) => (
        <div className={'whitespace-nowrap flex'}>
          {(item as any).display || item.displayName}
          {distributionCountMap[item.id] && (
            <span className={'ml-auto'}>
              <i
                className={`ml-4 ${labelColor(distributionCountMap[item.id].percent)} text-grey-800 flex items-center justify-center max-w-max rounded-xl py-0.5 px-3 text-xs leading-4`}
              >
                %{distributionCountMap[item.id].percent}
              </i>
            </span>
          )}
        </div>
      )}
    </SearchDropDown>
  );
  // return <TSelect {...rest} items={traits} keyField='id' labelField='displayName' className='w-full text-left' value={trait} />;
};

export default TraitSelect;
