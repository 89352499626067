import React, { Fragment, FunctionComponent } from "react";
import { AudienceSyncSchedule, AudienceSyncScheduleType, AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import AudienceSyncRepeatConfig from "./AudienceSyncRepeatConfig";
import AudienceSyncTypeConfig from "./AudienceSyncTypeConfig";

interface IAudienceSyncConfigProps {
  isFetchingSummary: boolean;
  realTimeAudienceLeft: number;
  scheduleType: AudienceSyncScheduleType;
  scheduleConfiguration: AudienceSyncSchedule;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const AudienceSyncConfig: FunctionComponent<IAudienceSyncConfigProps> = (
  {
    isFetchingSummary,
    realTimeAudienceLeft,
    scheduleType,
    scheduleConfiguration,
    handleChange,
    setFieldValue
  }
) => {
  return (
    <div className="border-solid border border-gray-200 rounded-lg shadow-md h-auto mt-6">
      <AudienceSyncTypeConfig
        isFetchingSummary={isFetchingSummary}
        realTimeAudienceLeft={realTimeAudienceLeft}
        scheduleType={scheduleType}
        handleChange={handleChange}
      />
      {scheduleType === "SCHEDULED" && (
        <Fragment>
          <hr />
          <AudienceSyncRepeatConfig
            scheduleConfiguration={scheduleConfiguration as AudienceSyncTimeBasedSchedule}
            setFieldValue={setFieldValue}
          />
        </Fragment>
      )}
    </div>
  );
};

export default AudienceSyncConfig;