import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataSourceCrmFieldDefinition, DataSourceCrmParameterDefinition } from "../../models/dataSource";
import { ICrmField, ICrmParameter } from "../dataSourceEdit/types";

type integrationState = {
  parameterDefinitions: DataSourceCrmParameterDefinition[];
  parameters: ICrmParameter[];
  fieldDefinitions: DataSourceCrmFieldDefinition[];
  fields: ICrmField[];
};

export const initialState: integrationState = {
  parameterDefinitions: [],
  parameters: [],
  fieldDefinitions: [],
  fields: []
};

const dataSourceIntegrationSlice = createSlice({
  name: "dataSourceIntegration",
  initialState,
  reducers: {
    stashParameterDefinitions: (state, action: PayloadAction<DataSourceCrmParameterDefinition[]>) => ({
      ...state,
      parameterDefinitions: action.payload
    }),
    stashParameters: (state, action: PayloadAction<ICrmParameter[]>) => ({
      ...state,
      parameters: action.payload
    }),
    stashFieldDefinitions: (state, action: PayloadAction<DataSourceCrmFieldDefinition[]>) => ({
      ...state,
      fieldDefinitions: action.payload
    }),
    stashFields: (state, action: PayloadAction<ICrmField[]>) => ({
      ...state,
      fields: action.payload
    }),
    clearIntegrationData: () => ({
      ...initialState
    })
  }
});

export const {
  stashParameterDefinitions,
  stashParameters,
  stashFieldDefinitions,
  stashFields,
  clearIntegrationData
} = dataSourceIntegrationSlice.actions;

export default dataSourceIntegrationSlice.reducer;
