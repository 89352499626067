import { Fragment, FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../router/components';
import RouteConstants from '../router/RouteConstants';
import { WorkspaceDetails } from '../workspaceEdit';
import { Settings } from './';

const SettingsRouter: FunctionComponent = () => (
  <Fragment>
    <Switch>
      <PrivateRoute path='/settings/workspace/:id' component={WorkspaceDetails} />
      <PrivateRoute path={RouteConstants.settings} component={Settings} />
    </Switch>
  </Fragment>
);

export default SettingsRouter;
