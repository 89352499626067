import { createContext, FunctionComponent, PropsWithChildren, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSlideOverModalContext } from '../hooks/useSlideOverModalContext';
import { IManagedModalOptions, IModalContext, IModalOptions } from '../models/modals';
import { RootState } from '../reducers';
import ModalProviderContent from './ModalProviderContent';

// Context
export const ModalContext = createContext<IModalContext>({} as IModalContext);

const ModalProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [modalsOptions, setModalsOptions] = useState<IManagedModalOptions<unknown>>();
  const [dismissable, toggleDismissable] = useState(!!modalsOptions?.dismissable);

  const isSlideOverVisible = useSelector((state: RootState) => state.slideOver.isVisible);

  const { openModalAboveSlideOver, closeModalAboveSlideOver } = useSlideOverModalContext();

  const openModal = <T,>(options: IModalOptions<T>) => {
    if (isSlideOverVisible) {
      openModalAboveSlideOver({ ...options, visible: true });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setModalsOptions({
        ...options,
        visible: true,
      });
      if (options.dismissable) {
        toggleDismissable(options.dismissable);
      }
    }
  };

  const closeModal = () => {
    if (isSlideOverVisible) {
      closeModalAboveSlideOver();
    } else {
      setModalsOptions({
        ...modalsOptions,
        visible: false,
      });
    }
  };

  const onModalClosed = () => {
    modalsOptions?.onClose && modalsOptions.onClose();
    setModalsOptions(undefined);
  };

  const onModalBackdropClicked = () => {
    // this is a method needed for handling events on modal backdrop
    if (dismissable) {
      closeModal();
    }
  };

  const onToggleDismissable = (toggle: boolean) => {
    toggleDismissable(toggle);
  };

  const contextValue: IModalContext = {
    openModal: useCallback(openModal, [isSlideOverVisible]),
    closeModal: useCallback(closeModal, []),
  };

  return (
    <>
      <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
      <ModalProviderContent
        modalsOptions={modalsOptions}
        closeModal={closeModal}
        onModalClosed={onModalClosed}
        onModalBackdropClicked={onModalBackdropClicked}
        onToggleDismissable={onToggleDismissable}
      />
    </>
  );
};

export default ModalProvider;
