import { useAuth0 } from '@auth0/auth0-react';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { persistor } from '../../../App';
import { Loading } from '../../../components/loaders';
import { RootState, useAppDispatch } from '../../../reducers';
import { Auth0Service } from '../../../services/auth0Service';
import { storeToken } from '../../auth/authSlice';
import { WorkspaceLayout } from '../../workspaces/components';
import { CallbackLanding } from '../../landing';
import PrivateRoute from './PrivateRoute';
import RouteConstants from '../RouteConstants';

const AuthenticatedRoutes: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const token = useSelector((state: RootState) => state.auth.token);
  const { loginWithRedirect, isLoading, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();

  /* 
                  This is needed because logout method is inside useAuth0 hook 
                  and isn't easily callable from action creator. So we dispatch logout
                  action, and here we handle the logic to clear user data and then logoff the user
                  */
  const shouldLogout = useSelector((state: RootState) => state.auth.shouldLogout);

  useEffect(() => {
    const initToken = async () => {
      // console.log('initToken isAuthenticated', isAuthenticated, 'isLoading', isLoading);
      const returnTo = location.pathname + location.search + location.hash;
      if (!isLoading) {
        if (isAuthenticated) {
          Auth0Service.setTokenGenerator(getAccessTokenSilently);

          try {
            const token = await getAccessTokenSilently();
            console.log(token);

            dispatch(storeToken(token));
          } catch (err) {
            console.log('could not init token', err);

            /*
                                                                                                            If we target a route without being authenticated, getAccessToken will fail
                                                                                                            with an error, so we need to send user back to landing
                                                                                                            */
            logout({
              logoutParams: { returnTo: window.location.origin },
            });
          }
        } else {
          console.log('returnTo', returnTo);
          loginWithRedirect({ appState: { returnTo } });
        }
      }
    };

    initToken();
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    /*
                                    Keep in mind we aren't setting shouldLogout back to false
                                    because the slice isn't persisted and login is via Auth0
                                    so during login we're redirect and this restore authSlice state
                                    to initial on every login.
                                    If you decide to move away from Auth0, you need to change something
                                    in the login/logout flow
                                    */
    if (shouldLogout) {
      // clear user data from redux store here!
      persistor.purge();
      logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }
  }, [shouldLogout]);

  if (isLoading) return <Loading />;

  return (
    <React.Fragment>
      {isAuthenticated && !!token && <WorkspaceLayout />}
      <PrivateRoute path={RouteConstants.callbackLanding} component={CallbackLanding} />
    </React.Fragment>
  );
};

export default AuthenticatedRoutes;
