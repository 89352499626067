import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/buttons';
import DestinationCreate from './edit/DestinationCreate';
import { useModalContext } from '../../../hooks/useModalContext';
import SyncYourData from '../../../assets/image/sync_your_data.svg?react';

interface IDestinationEmptySetProps { }

const DestinationEmptySet: FunctionComponent<IDestinationEmptySetProps> = () => {
    const { openModal } = useModalContext();

    const { t } = useTranslation('destinations');

    const handleCreateDestination = () => {
        return openModal({
            renderContent: DestinationCreate,
            fullHeight: true,
            fullWidth: true,
            title: t('edit.title'),
        });
    };

    return (
        <div className='mt-30 flex flex-row justify-around items-center h-full'>
            <div style={{ maxWidth: '400px' }}>
                <h3 className='text-black text-lg font-bold'>{t('empty_set.title')}</h3>
                <p className='mb-6'>{t('empty_set.text')}</p>
                <Button onClick={handleCreateDestination}>{t('buttons.connect_first_audience')}</Button>
            </div>
            <div className=''>
                <SyncYourData />
            </div>
        </div>
    );
};

export default DestinationEmptySet;
