import { FunctionComponent } from 'react';
import { IconContainer } from '../../../../../../components/icons';
import { IFileUploadStatus } from '../../../../../../models/fileUpload';
import { bytesToMegaBytes } from '../../../../../../utils';

interface IUploadingFileProps {
  fileUpload: IFileUploadStatus;
  filename: string;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

const UploadingFile: FunctionComponent<IUploadingFileProps> = ({ filename, fileUpload, icon }) => (
  <div className='flex w-full items-center'>
    <div className='flex-none m-auto mr-4'>
      {icon && <IconContainer icon={icon} />}
      {/* <svg width='30' height='40' viewBox='0 0 30 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30 10V9.52344C29.9998 9.02827 29.8031 8.5534 29.4531 8.20313L21.8047 0.546881C21.4521 0.195529 20.9743 -0.00121494 20.4766 5.64561e-06H20V10H30ZM17.5 10.625V8.67071e-06H1.87501C1.37734 -0.00124018 0.899704 0.195903 0.547802 0.547805C0.1959 0.899707 -0.00124295 1.37735 5.89733e-06 1.87501V38.125C-0.00124295 38.6227 0.1959 39.1003 0.547802 39.4522C0.899704 39.8041 1.37734 40.0013 1.87501 40H28.125C28.6227 40.0013 29.1003 39.8041 29.4522 39.4522C29.8041 39.1003 30.0013 38.6227 30 38.125V12.5H19.375C18.3407 12.497 17.503 11.6593 17.5 10.625ZM10 21.875C10 22.2202 9.72018 22.5 9.375 22.5H8.75C8.05965 22.5 7.5 23.0597 7.5 23.75V26.25C7.5 26.9404 8.05965 27.5 8.75 27.5H9.375C9.72018 27.5 10 27.7798 10 28.125V29.375C10 29.7202 9.72018 30 9.375 30H8.75C6.67894 30 5 28.3211 5 26.25V23.75C5 21.6789 6.67894 20 8.75 20H9.375C9.72018 20 10 20.2798 10 20.625V21.875ZM12.5 30H13.4586C15.2859 30 16.7711 28.6461 16.7703 26.9828C16.7623 26.1328 16.3816 25.3291 15.7289 24.7844L14.0188 23.318C13.9227 23.2467 13.8623 23.1372 13.8531 23.018C13.8531 22.7742 14.2016 22.5008 14.6664 22.5008H15.625C15.9702 22.5008 16.25 22.221 16.25 21.8758V20.6258C16.25 20.2806 15.9702 20.0008 15.625 20.0008H14.668C12.8391 20.0008 11.3547 21.3539 11.3547 23.018C11.3627 23.868 11.7434 24.6717 12.3961 25.2164L14.1063 26.6828C14.2023 26.7541 14.2627 26.8636 14.2719 26.9828C14.2719 27.2266 13.9234 27.5 13.4586 27.5H12.5C12.1548 27.5 11.875 27.7798 11.875 28.125V29.375C11.875 29.7202 12.1548 30 12.5 30ZM20 20.625V22.25C19.9996 23.8181 20.4321 25.3558 21.25 26.6938C22.0679 25.3558 22.5004 23.8181 22.5 22.25V20.625C22.5 20.2798 22.7798 20 23.125 20H24.375C24.7202 20 25 20.2798 25 20.625V22.25C25 25.0219 23.9938 27.632 22.1656 29.6008C21.9291 29.8553 21.5974 29.9998 21.25 29.9998C20.9026 29.9998 20.5709 29.8553 20.3344 29.6008C18.5063 27.632 17.5 25.0219 17.5 22.25V20.625C17.5 20.2798 17.7798 20 18.125 20H19.375C19.7202 20 20 20.2798 20 20.625Z'
          fill='#1F5CCD'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.375 20H8.75C6.67893 20 5 21.6789 5 23.75V26.25C5 28.3211 6.67893 30 8.75 30H9.375C9.72018 30 10 29.7202 10 29.375V28.125C10 27.7798 9.72018 27.5 9.375 27.5H8.75C8.05964 27.5 7.5 26.9404 7.5 26.25V23.75C7.5 23.0596 8.05964 22.5 8.75 22.5H9.375C9.72018 22.5 10 22.2202 10 21.875V20.625C10 20.2798 9.72018 20 9.375 20ZM15.7289 24.7844L14.0187 23.318C13.9227 23.2466 13.8623 23.1372 13.8531 23.018C13.8531 22.7742 14.2016 22.5008 14.6664 22.5008H15.625C15.9702 22.5008 16.25 22.221 16.25 21.8758V20.625C16.25 20.2798 15.9702 20 15.625 20H14.668C12.8406 20 11.3547 21.3531 11.3547 23.0172C11.3627 23.8672 11.7434 24.6709 12.3961 25.2156L14.1062 26.682C14.2023 26.7534 14.2627 26.8628 14.2719 26.982C14.2719 27.2258 13.9234 27.4992 13.4586 27.4992H12.5C12.1548 27.4992 11.875 27.779 11.875 28.1242V29.3742C11.875 29.7194 12.1548 29.9992 12.5 29.9992H13.4586C15.2859 29.9992 16.7711 28.6453 16.7703 26.982C16.762 26.1323 16.3814 25.3289 15.7289 24.7844ZM23.125 20H24.375C24.7202 20 25 20.2798 25 20.625V22.25C25 25.0219 23.9937 27.632 22.1656 29.6008C21.9291 29.8552 21.5974 29.9998 21.25 29.9998C20.9026 29.9998 20.5709 29.8552 20.3344 29.6008C18.5063 27.632 17.5 25.0219 17.5 22.25V20.625C17.5 20.2798 17.7798 20 18.125 20H19.375C19.7202 20 20 20.2798 20 20.625V22.25C19.9996 23.8181 20.4321 25.3558 21.25 26.6937C22.0679 25.3558 22.5004 23.8181 22.5 22.25V20.625C22.5 20.2798 22.7798 20 23.125 20Z'
          fill='#1F5CCD'
        />
      </svg> */}
    </div>
    <div className='flex-grow m-auto text-left'>
      <p className='text-gray-900 text-sm'>{filename}</p>
      <div className='relative pt-1'>
        <div className='overflow-hidden h-2 mb-1 text-xs flex rounded bg-gray-200'>
          <div
            style={{ width: `${fileUpload.percentage.percentageCompleted}%` }}
            className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600'
          ></div>
        </div>
      </div>
      <div className='flex'>
        <div className='flex flex-1 justify-start'>
          <p className='text-gray-500 text-xs'>{`${fileUpload.percentage.percentageCompleted}%`}</p>
        </div>
        <div className='flex flex-1 justify-end'>
          <p className='text-gray-500 text-xs'>{`${bytesToMegaBytes(fileUpload.percentage.loaded)}/${bytesToMegaBytes(fileUpload.percentage.total)}MB`}</p>
        </div>
      </div>
    </div>
  </div>
);

export default UploadingFile;
