import React, { FunctionComponent } from 'react';
import { useUserInfo } from '../../features/userInfo/hooks';
import { SimpleTable } from '../../components/tables';
import WorkspaceInviteRow from './WorkspaceInviteRow';

const WorkspaceInvites: FunctionComponent = () => {
  const userInfo = useUserInfo();

  return !userInfo.workspaceInvites || !userInfo.workspaceInvites.length ? (
    <div className='border rounded-lg mb-2 p-6 font-base text-sm text-gray-600'>No invites from other workspaces, yet</div>
  ) : (
    <div className={'mb-2'}>
      <SimpleTable>
        <colgroup></colgroup>
        <tbody>
            {userInfo.workspaceInvites.filter(i => i.status == 'PENDING').map((invite) => (
            <WorkspaceInviteRow key={invite.workspaceId} invite={invite} />
          ))}
        </tbody>
      </SimpleTable>
    </div>
  );
};

export default WorkspaceInvites;
