import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { delay } from 'lodash';
import { Fragment, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { hideToast, removeToastFromQueue } from '../features/toasts/toastsSlice';
import { Toast, ToastType } from '../models/toast';
import { RootState, useAppDispatch } from '../reducers';

// Keep in mind to check toastsSlice for some default config on how toasts are presented
// also used by rtkQueryErrorLogger to group similar errors
export const DEFAULT_DISMISS_DURATION = 4000;

const ToastProvider: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const toasts = useSelector((state: RootState) => state.toasts.toasts);

  const onDismissToastClicked = (id: string) => {
    dispatch(hideToast(id));
  };

  const onToastDismissed = (id: string) => {
    dispatch(removeToastFromQueue(id));
  };

  const onStartTimerForToast = (toast: Toast) => {
    if (toast.autodismiss) {
      delay(() => {
        dispatch(hideToast(toast.id));
      }, toast.dismissDuration || DEFAULT_DISMISS_DURATION);
    }
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live='assertive' className='fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'>
        <div className='w-full flex flex-col items-center space-y-4 sm:items-end '>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          {toasts?.map((toast) => (
            <Transition
              key={toast.id}
              appear={true}
              show={toast.visible}
              as={Fragment}
              enter='transform ease-out duration-300 transition'
              enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
              enterTo='translate-y-0 opacity-100 sm:translate-x-0'
              leave='transition ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterEnter={() => onStartTimerForToast(toast)}
              afterLeave={() => onToastDismissed(toast.id)}
            >
              <div className='max-w-sm w-full bg-white-100 shadow-lg rounded-lg pointer-events-auto overflow-hidden'>
                <div className='p-4'>
                  <div className='flex items-start'>
                    <div className='flex-shrink-0'>
                      <ToastIcon toastStatus={toast.type} />
                    </div>
                    <div className='ml-3 w-0 flex-1 pt-0.5'>
                      <p className='text-sm font-medium text-gray-900'>{toast.title}</p>
                      <p className='mt-1 text-sm text-gray-500'>{toast.message}</p>
                    </div>
                    <div className='ml-4 flex-shrink-0 flex'>
                      <button className='bg-white-100 rounded-md inline-flex text-gray-400 hover:text-gray-500' onClick={() => onDismissToastClicked(toast.id)}>
                        <span className='sr-only'>Close</span>
                        <XIcon className='h-5 w-5' aria-hidden='true' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          ))}
        </div>
      </div>
    </>
  );
};

interface IToastIcon {
  toastStatus: ToastType;
}
const ToastIcon: FunctionComponent<IToastIcon> = ({ toastStatus }) => {
  switch (toastStatus) {
    case ToastType.SUCCESS:
      return <CheckCircleIcon className='h-6 w-6 text-green-400' aria-hidden='true' />;
    case ToastType.ERROR:
      return <ExclamationCircleIcon className='h-6 w-6 text-red-400' aria-hidden='true' />;
    case ToastType.WARNING:
      return <ExclamationIcon className='h-6 w-6 text-yellow-400' aria-hidden='true' />;
    case ToastType.INFO:
      return <InformationCircleIcon className='h-6 w-6 text-gray-400' aria-hidden='true' />;
    default:
      return <InformationCircleIcon className='h-6 w-6 text-gray-400' aria-hidden='true' />;
  }
};

export default ToastProvider;
