import React, { FunctionComponent, useEffect, useState } from "react";
import { Input } from "../../components/inputs";
import { Button } from "../../components/buttons";
import { Dialog } from "@headlessui/react";
import { IModalContentProps } from "../../models/modals";
import { useTranslation } from "react-i18next";
import { useChangeUserInfoMutation, useChangeUserPasswordMutation } from "../../services/endpoints/users";
import { useAppDispatch } from "../../reducers";
import { showToast } from "../toasts/toastsSlice";
import { ToastType } from "../../models/toast";
import { SpinnerIcon } from "../../components/icons";

interface IUserDetailProps {
  disabled?: boolean;
  showReset?: boolean;
  showSave?: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
  };
}

const UserDetail: FunctionComponent<IUserDetailProps & IModalContentProps> = ({ user, closeModal, disabled = true, showReset = false, showSave = false }) => {
  const { t } = useTranslation('users');
  const dispatch = useAppDispatch();

  const [editedUser, setEditedUser] = useState(user);
  const [changePassword, { isLoading, isSuccess, isError }] = useChangeUserPasswordMutation();
  const [updateUserInfo, { isLoading: isLoadingUpdateUserInfo, isSuccess: isSuccessUserInfo, isError: isErrorUserInfo}] = useChangeUserInfoMutation();

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('password.change_success'),
          message: t('password.change_success_message', { email: editedUser.email }),
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(
        showToast({
          type: ToastType.ERROR,
          title: t('password.change_error'),
        })
      );
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccessUserInfo) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('info.update_success'),
        })
      );
    }
  }, [isSuccessUserInfo]);

  useEffect(() => {
    if (isErrorUserInfo) {
      dispatch(
        showToast({
          type: ToastType.ERROR,
          title: t('info.update_error'),
        })
      );
    }
  }, [isErrorUserInfo]);

  return (
    <div className={"w-full h-full flex flex-col"}>
      <div className="min-h-0 flex flex-col py-6 bg-gray-50 rounded-lg">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <div className={"flex w-full items-center"}>
              <Dialog.Title
                className="text-lg font-medium text-gray-900">
                {((user.firstName || "") + ' ' + (user.lastName || "")).trim() || user.email}
              </Dialog.Title>
              <div
                className={"bg-gray-500 w-12 h-12 rounded-full inline-flex items-center justify-center ml-auto"}>
                {((user.firstName?.[0] || "") + (user.lastName?.[0] || "")) || user.email?.[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex-grow p-6"}>
        <h3 className={"text-left font-semibold mb-6"}>Personal info</h3>
        <div className={"flex mb-2"}>
          <div className={"w-1/2 pr-2"}>
            <Input label={"Name"} value={editedUser.firstName || ''} disabled={!showSave} onChange={e => setEditedUser({ ...editedUser, firstName: e.target.value })} />
          </div>
          <div className={"w-1/2 pl-2"}>
            <Input label={"Surname"} value={editedUser.lastName || ''} disabled={!showSave} onChange={e => setEditedUser({ ...editedUser, lastName: e.target.value })} />
          </div>
        </div>
        <div>
          <Input label={"Email"} type={"email"} value={editedUser.email} disabled={disabled} />
        </div>
      </div>
      <div className={"flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg mt-auto"}>
        {isLoading &&
          <SpinnerIcon className='absolute h-6 w-6' loading />}
        {showReset && !isLoading && <span className={"mr-2 py-2 text-blue-500 hover:text-blue-800 cursor-pointer underline"} onClick={() => changePassword({ userId: user.id })}>{t('slideover.password_reset')}</span>}
        {showSave && <Button className="mr-2" variant={"primary"} disabled={isLoadingUpdateUserInfo} onClick={() => updateUserInfo({ userId: editedUser.id, firstName: editedUser.firstName, lastName: editedUser.lastName }) }>{t('slideover.save')}</Button>}
        <Button variant={"light"} onClick={closeModal}>{t('slideover.close')}</Button>
      </div>
    </div>
  );
};

export default UserDetail;
