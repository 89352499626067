import { CheckIcon, CodeIcon } from '@heroicons/react/outline';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSourceType, DataSourceTypeCodeConfig, EDataSourceCategory } from '../../../../models/dataSource';

interface IDataSourceTypePanelContentProps {
  dataSourceType?: DataSourceType;
}

const DataSourceTypePanelContent: FunctionComponent<IDataSourceTypePanelContentProps> = ({ dataSourceType }) => {
  const { t } = useTranslation();

  // switch base on data source type category here!
  const configuration = dataSourceType?.configuration as DataSourceTypeCodeConfig | undefined;

  return (
    <div>
      <div className='mb-3'>
        <div>
          {configuration?.website && (
            <div className='inline-block mr-5'>
              <a href={configuration.website} className='inline-block underline text-blue-500 text-sm'>
                {t('data_sources:slideover.content.visit_website')}
              </a>
            </div>
          )}
          {dataSourceType?.category === EDataSourceCategory.CODE ? (
            <div className='inline-block'>
              <CodeIcon className='h-5 inline-block text-gray-500 mr-1' />
              <p className='inline-block text-gray-900 text-sm font-medium mb-3'>{t('data_sources:slideover.content.code_required')}</p>
            </div>
          ) : (
            <div className='inline-block'>
              <CheckIcon className='h-5 inline-block text-gray-500 mr-1' />
              <p className='inline-block text-gray-900 text-sm font-medium mb-3'>{t('data_sources:slideover.content.no_code_required')}</p>
            </div>
          )}
        </div>
      </div>
      <div className='mb-3'>
        <p className='text-gray-600 text-sm'>{configuration?.description}</p>
      </div>
      <div className='mb-3'>
        <p className='text-gray-900 text-sm font-medium mb-3'>{t('data_sources:slideover.content.best_used_for')}</p>
        <ul>
          {configuration?.usedFor?.map((use, index) => (
            <li key={index} className='text-gray-600 text-sm list-none'>
              <CheckIcon className='h-5 inline-block text-green-500 mr-2' />
              {use}
            </li>
          ))}
        </ul>
      </div>
      <div className='mb-3'>
        <p className='text-gray-900 text-sm font-medium mb-3'>{t('data_sources:slideover.content.user_need')}</p>
        <ul className='list-disc ml-4'>
          {configuration?.requirements?.map((requirement) => (
            <li key={requirement} className='text-gray-600 text-sm'>
              {requirement}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DataSourceTypePanelContent;
