import { createSlice } from '@reduxjs/toolkit';

type ChatState = {
    open: boolean;
};
export const initialState: ChatState = {
    open: false,
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        toggleChat: (state) => ({ ...state, open: !state.open }),
    },
});

export const { toggleChat } = chatSlice.actions;
export default chatSlice.reducer;
