import { TrashIcon } from "@heroicons/react/solid";
import { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/buttons";
import { CompanyInvite } from "../../../models/invites";
import { IModalContentProps } from "../../../models/modals";
import { ToastType } from "../../../models/toast";
import { useAppDispatch } from "../../../reducers";
import { useDeleteCompanyInviteMutation } from "../../../services/endpoints/companies";
import { showToast } from "../../toasts/toastsSlice";

interface IDeleteCompanyInvitationModal {
  invite: CompanyInvite;
}

const DeleteCompanyInvitationModal: FunctionComponent<PropsWithChildren<IDeleteCompanyInvitationModal & IModalContentProps>> = ({ invite, closeModal }) => {
  const { t } = useTranslation('company');
  const dispatch = useAppDispatch();

  const [deleteInvite, { isLoading, isSuccess }] = useDeleteCompanyInviteMutation();

  const handleDeleteClicked = () => {
    deleteInvite({ companyId: invite.companyId, id: invite.id });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('invite.delete.successful_title'),
          message: t('invite.delete.successful_message', { email: invite.email }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className='flex justify-center h-60 '>
      <div className='max-w-4xl my-auto text-left p-5'>
        <h1 className='text-lg text-black-100 font-semibold mb-5'>{t('invite.delete.delete_title')}</h1>
        <p className='text-base'>
          {t('invite.delete.delete_confirm')}&nbsp;(<b>{invite.email}</b>)
        </p>
        <Button className="mt-3" icon={TrashIcon} variant='delete' onClick={handleDeleteClicked} loading={isLoading} >
          {t('invite.delete.delete_button_title')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteCompanyInvitationModal;
