import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { createContext, Fragment, FunctionComponent, PropsWithChildren, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalProviderContent } from '.';
import { CircleButton } from '../components/buttons';
import { toggleSlideOver } from '../features/slideOver/slideOverSlice';
import { IManagedModalOptions, IModalOptions } from '../models/modals';
import { IManagedSlideOverOptions, ISlideOverModalContext, ISlideOverOptions } from '../models/slideOver';
import { RootState, useAppDispatch } from '../reducers';

// Context
export const SlideOverContext = createContext<ISlideOverModalContext>({} as ISlideOverModalContext);

export const SLIDEOVER_TRANSITION_DURATION = 300;

const SlideOverProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [slideOverOptions, setSlideOverOptions] = useState<IManagedSlideOverOptions<unknown>>();
  const [modalsOptions, setModalsOptions] = useState<IManagedModalOptions<unknown>>();

  const dispatch = useAppDispatch();

  const isVisible = useSelector((state: RootState) => state.slideOver.isVisible);

  const openModalAboveSlideOver = <M,>(options: IModalOptions<M>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setModalsOptions({
      ...options,
      visible: true,
    });
  };

  const closeModalAboveSlideOver = () => {
    setModalsOptions({
      ...modalsOptions,
      visible: false,
    });
  };

  const openSlideOver = <T,>(options: ISlideOverOptions<T>) => {
    dispatch(toggleSlideOver(true));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSlideOverOptions({
      ...options,
      visible: true,
    });
  };

  const closeSlideOver = () => {
    dispatch(toggleSlideOver(false));

    setSlideOverOptions({
      ...slideOverOptions,
      visible: false,
    });
  };

  const onModalAboveSlideOverClosed = () => {
    setModalsOptions(undefined);
  };

  const onSlideOverClosed = () => {
    setSlideOverOptions(undefined);
  };

  const onModalBackdropClicked = () => {
    // this is a method needed for handling events on modal backdrop
  };

  const slideOverContextValue: ISlideOverModalContext = {
    openSlideOver: useCallback(openSlideOver, [slideOverOptions]),
    closeSlideOver: useCallback(closeSlideOver, [slideOverOptions]),
    openModalAboveSlideOver: useCallback(openModalAboveSlideOver, [isVisible]),
    closeModalAboveSlideOver: useCallback(closeModalAboveSlideOver, [isVisible]),
  };
  const renderContentProps: any = slideOverOptions?.renderContentProps;
  return (
    <>
      <SlideOverContext.Provider value={slideOverContextValue}>{children}</SlideOverContext.Provider>
      <Transition.Root show={slideOverOptions?.visible || false} as={Fragment} afterLeave={onSlideOverClosed}>
        <Dialog as='div' static className='fixed z-20 inset-0 overflow-hidden' open={slideOverOptions?.visible} onClose={closeSlideOver}>
          <div className='absolute inset-0 overflow-hidden'>
            <Transition.Child
              as={Fragment}
              enter='transition backdrop-filter ease-in-out duration-300'
              enterFrom='opacity-0 backdrop-blur-none'
              enterTo='opacity-100 backdrop-blur-md'
              entered='backdrop-filter backdrop-blur-md'
              leave='transition backdrop-filter ease-in-out duration-300'
              leaveFrom='opacity-100 backdrop-blur-md'
              leaveTo='opacity-0 backdrop-blur-none'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black-24' />
            </Transition.Child>

            <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-300'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-300'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <div className='relative w-screen max-w-md'>
                  <div className='absolute hidden top-0 left-0 -ml-16 pt-4 px-2 md:block'>
                    <CircleButton icon={XIcon} onClick={closeSlideOver} />
                  </div>
                  {!!slideOverOptions?.renderContent && (
                    <slideOverOptions.renderContent {...renderContentProps} closeSlideOver={closeSlideOver} />
                  )}
                </div>
              </Transition.Child>
            </div>
          </div>
          <ModalProviderContent
            modalsOptions={modalsOptions}
            closeModal={closeModalAboveSlideOver}
            onModalClosed={onModalAboveSlideOverClosed}
            onModalBackdropClicked={onModalBackdropClicked}
          />
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SlideOverProvider;
