export type DataSourceEventTraits = {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
}

export type DataSourceTrackEvent = {
  timestamp: string;
  type: string;
  name: string;
  path: string;
  channel: string;
  event: {
    _id: string;
    type: string;
    element: string;
    selector: string;
    anonymousId?: string;
    userId?: string;
    traits?: DataSourceEventTraits;
  }
}

export type DataSourceViewEvent = {
  timestamp: string;
  type: string;
  name: string;
  path: string;
  channel: string;
  event: {
    _id: string;
    type?: string;
    path: string;
    anonymousId?: string;
    userId?: string;
    traits?: DataSourceEventTraits;
  }
}

export type DataSourceEvent = DataSourceTrackEvent | DataSourceViewEvent;

export function isTrackEvent(event: DataSourceEvent): event is DataSourceTrackEvent {
  return (event as DataSourceTrackEvent).type === "track";
}
