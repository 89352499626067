import { delay } from 'lodash';
import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddDataSourceButton, DataSourceTable } from '.';
import CatalogIconIcon from '../../../assets/icons/catalog.svg?react';
import DataSourcesIcon from '../../../assets/icons/data-sources.svg?react';
import { SearchInput } from '../../../components/tables/search';
import { useModalContext } from '../../../hooks/useModalContext';
import { useSlideOverContext } from '../../../hooks/useSlideOverContext';
import { DataSource, DataSourceType } from '../../../models/dataSource';
import { EStatus } from '../../../models/fileUpload';
import { TabType } from '../../../models/tabs';
import { SLIDEOVER_TRANSITION_DURATION } from '../../../providers';
import { RootState, useAppDispatch } from '../../../reducers';
import { clearIntegrationData } from '../../cyclr/dataSourceIntegrationSlice';
import { DataSourceEdit } from '../../dataSourceEdit';
import { resetUploadStatus } from '../../fileUploading/fileUploadingSlice';
import { MainContentWrapper } from '../../layout/main';
import { TopbarTabs } from '../../layout/topbar';
import { clearStashedRules } from '../../rules/rulesSlice';
import { useWorkspaceAdmin } from '../../workspaces/hooks/useWorkspace';
import { clearStashedDataSource, clearStashedFileDataSource, setDataSourceTabs, setSearchText } from '../dataSourcesSlice';
import DataSourceCatalog from './tabs/DataSourcesCatalog';

interface DataSourceListProps {
    dataSources?: DataSource[];
    pager: React.ReactNode;
}

const DataSourceList: FunctionComponent<DataSourceListProps> = ({ dataSources, pager }) => {
    const { t } = useTranslation('data_sources');
    const dispatch = useAppDispatch();
    const { closeSlideOver } = useSlideOverContext();
    const { openModal } = useModalContext();
    const isWorkspaceAdmin = useWorkspaceAdmin();

    const fileUploadStatus = useSelector((state: RootState) => state.fileUploading.status);

    const tabs = useSelector((state: RootState) => state.dataSources.ui.tabs)
        .map((tab) => {
            switch (tab.id) {
                case 'datasource.table':
                    return { ...tab, label: t('tabs.my_sources.title'), icon: () => <DataSourcesIcon className={'inline h-4 w-4 mr-1'} /> };
                case 'datasource.catalog':
                    return isWorkspaceAdmin ? { ...tab, label: t('tabs.catalog.title'), icon: () => <CatalogIconIcon className={'inline h-4 w-4 mr-1'} /> } : null;
            }
        })
        .filter((t) => t !== null)
        .map((t) => t!);

    const breadcrumbs = [
        {
            label: t('title'),
        },
    ];

    const onAddDataSourceClicked = (dataSourceType: DataSourceType) => {
        if (fileUploadStatus?.uploadingStatus !== EStatus.UPLOADING) {
            dispatch(resetUploadStatus());
            dispatch(clearStashedFileDataSource());
        }

        dispatch(clearStashedDataSource());
        dispatch(clearStashedRules());
        dispatch(clearIntegrationData());

        closeSlideOver();
        // open data source modal after animation of slideover is finished
        delay(() => {
            openModal({
                renderContent: DataSourceEdit,
                renderContentProps: {
                    dataSourceType,
                },
                fullHeight: true,
                fullWidth: true,
            });
        }, SLIDEOVER_TRANSITION_DURATION);
    };

    const handleTabChange = (selectedTab: TabType) => {
        dispatch(
            setDataSourceTabs(
                tabs.map((tab) => ({ id: tab.id === 'datasource.table' ? 'datasource.table' : 'datasource.catalog', current: tab.id === selectedTab.id }))
            )
        );
    };

    const handleMoveToTab = (tabId: string) => {
        dispatch(
            setDataSourceTabs(
                tabs.map((tab) => ({
                    id: tab.id === 'datasource.table' ? 'datasource.table' : 'datasource.catalog',
                    current: tab.id === tabId,
                }))
            )
        );
    };

    const handleTopBarRightElement = () => <AddDataSourceButton onClick={() => handleMoveToTab('datasource.catalog')} />;

    const renderTabContent = (onAddDataSourceClicked: (type: DataSourceType) => void, dataSources?: DataSource[]) => {
        const currentTab = tabs.find((tab) => tab.current);

        switch (currentTab?.id) {
            case 'datasource.table':
                return (
                    <Fragment>
                        <SearchInput
                            stateSelector={(state: RootState) => state.dataSources.ui.searchText}
                            stateUpdater={setSearchText}
                            placeholder={t('tabs.my_sources.search_sources')}
                        />
                        <DataSourceTable dataSources={dataSources} />
                        {pager}
                    </Fragment>
                );
            case 'datasource.catalog':
                return <DataSourceCatalog onConfirmClicked={onAddDataSourceClicked} />;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <TopbarTabs tabs={tabs} onTabChange={handleTabChange} rightElement={isWorkspaceAdmin ? handleTopBarRightElement : undefined} breadcrumbs={breadcrumbs} />
            <MainContentWrapper data-testid='DataSourceList'>
                <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
                    <div className='h-auto py-4'>{renderTabContent(onAddDataSourceClicked, dataSources)}</div>
                </div>
            </MainContentWrapper>
        </Fragment>
    );
};

export default DataSourceList;
