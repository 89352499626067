import { CheckIcon } from '@heroicons/react/outline';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { concatClassNames } from '../../../utils';
import { Button } from '../../buttons';
import { ExclamationIcon, IconContainer } from '../../icons';

interface ISuccessStatus {
  handleChangeFile: () => void;
}

const SuccessStatus: FunctionComponent<ISuccessStatus> = ({ handleChangeFile }) => {
  const [isChangeFileClicked, setChangeFileClicked] = useState(false);

  const { t } = useTranslation('data_source_edit');

  return (
    <div className='w-full mt-1 sm:mt-0 sm:col-span-2'>
      <div
        className={concatClassNames(
          'flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md h-52',
          isChangeFileClicked ? 'border-gray-200 bg-gray-50' : 'border-green-100 bg-green-50'
        )}
      >
        {isChangeFileClicked ? (
          <div className='space-y-1 m-auto text-center'>
            <div className='flex text-center justify-center h-7 mb-2'>
              <IconContainer icon={ExclamationIcon} path='assets/fileuploading/exclamation.svg' />
            </div>
            <div className='flex flex-col'>
              <p className='bg-whitpe rounded-md font-medium text-xl text-blue-900 mb-3'>{t('file.name_and_file.file_upload_input_component.cancel_upload')}</p>
              <br />
              <div>
                <Button variant='delete' onClick={handleChangeFile} className='mr-2'>
                  {t('file.name_and_file.file_upload_input_component.delete_file')}
                </Button>
                <Button variant='transparent' onClick={() => setChangeFileClicked(false)} className='text-blue-700'>
                  {t('file.name_and_file.file_upload_input_component.cancel')}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className='space-y-1 text-center m-auto transition duration-500'>
            <div className='flex text-center justify-center h-7 mb-2'>
              <CheckIcon className='h-8 w-8 text-green-600' />
            </div>
            <div className='flex flex-col'>
              <p className='bg-white rounded-md font-medium text-xl text-green-900 mb-2'>{t('file.name_and_file.file_upload_input_component.success')}</p>
              <br />
              <Button variant='light' onClick={() => setChangeFileClicked(true)}>
                {t('file.name_and_file.file_upload_input_component.change_file')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessStatus;
