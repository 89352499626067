import { FunctionComponent, PropsWithChildren } from 'react';

export interface ISimpleTableProps {
  paginationComponent?: JSX.Element;
  columnDropdownComponent?: JSX.Element;
}

const SimpleTable: FunctionComponent<PropsWithChildren<ISimpleTableProps>> = ({ children, columnDropdownComponent, paginationComponent }) => {
  return (
    <div className='flex flex-col'>
      <div className='flex justify-end'>{columnDropdownComponent}</div>
      <div className='py-2 align-middle inline-block min-w-full'>
        <div className='shadow-md overflow-y-auto h-auto rounded-md border border-grey-200'>
          <div className='overflow-x-auto'>
            <table className='min-w-full border-collapse table-auto'>{children}</table>
          </div>
          {paginationComponent}
        </div>
      </div>
    </div>
  );
};

export default SimpleTable;
