import { differenceInCalendarDays, format, formatDistanceToNow, formatRelative, parse, parseISO } from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import { AudienceSyncTimeBasedSchedule } from "../../../../models/audiences";
import { upperCaseFirstChar } from "../../../../utils";
import { formatInTimeZone } from 'date-fns-tz';

interface IAudienceSyncRepeatStartProps {
  scheduleConfiguration: AudienceSyncTimeBasedSchedule;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const AudienceSyncRepeatStart: FunctionComponent<IAudienceSyncRepeatStartProps> = ({ scheduleConfiguration, setFieldValue }) => {
  const initialStartsOn = scheduleConfiguration.startsOn ? parseISO(scheduleConfiguration.startsOn) : new Date();
  initialStartsOn.setSeconds(0);
  initialStartsOn.setMilliseconds(0);

  const [startDay, setStartDay] = useState<Date>(initialStartsOn);
  const [startHour, setStartHour] = useState<string>(format(initialStartsOn, 'HH:mm'));
  const [readableStartDate, setReadableStartDate] = useState<string>();

  useEffect(() => {
    const parsed = parse(startHour, 'HH:mm', startDay);
    setFieldValue('scheduleConfiguration.startsOn', `${formatInTimeZone(parsed, 'UTC', 'yyyy-MM-dd')}T${formatInTimeZone(parsed, 'UTC', 'HH:mm')}:00.000Z`);

    const daysDiff = differenceInCalendarDays(parsed, new Date());
    if (daysDiff < -6) {
      setReadableStartDate(`(${formatDistanceToNow(parsed)} ago)`);
    }
    else if (daysDiff === 0 || daysDiff === 1) {
      setReadableStartDate(`(${upperCaseFirstChar(formatRelative(parsed, new Date()))})`);
    }
    else if (daysDiff < 7) {
      setReadableStartDate(`(This ${formatRelative(parsed, new Date())})`);
    }
    else if (daysDiff < 14) {
      setReadableStartDate(`(${format(parsed, `iiii 'of the next week at' KK:mm`)})`);
    }
    else {
      setReadableStartDate(`(in ${formatDistanceToNow(parsed)})`);
    }
  }, [startDay, startHour]);

  return (
    <div className='mb-6 flex flex-row items-center'>
      <span className='mr-4'>It starts on</span>

      <div className='w-32 mr-4'>
        <DatePickerInput
          dateFormat='dd/MM/yyyy'
          className={'shadow-sm'}
          selected={startDay}
          onChange={(e) => e !== null && !Array.isArray(e) && setStartDay(e)}
        />
      </div>
      <div className='w-24 mr-4'>
        <input
          type='time'
          className='px-2 sm:text-sm shadow-sm border border-gray-300 focus:border-blue-600 focus:border focus:outline-none rounded-md placeholder-gray-400'
          value={startHour}
          onChange={(e) => setStartHour(e.target.value)}
        />
      </div>
      {readableStartDate && <span>{readableStartDate}</span>}
    </div>
  );
};

export default AudienceSyncRepeatStart;
