import devConfig from '../config.dev.json';
import prodConfig from '../config.prod.json';

export const deployEnv = import.meta.env.VITE_DEPLOY_ENV;
const nodeEnv = import.meta.env.NODE_ENV;

const env = deployEnv || nodeEnv;

export function shouldMockAPIs(): boolean {
  return env === 'test' || env === 'mock' || env === 'storybook';
}

export default env === 'prod' || env === 'production' ? prodConfig : devConfig;
