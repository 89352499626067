import { useAuth0 } from '@auth0/auth0-react';
import { FunctionComponent } from 'react';

const Landing: FunctionComponent = () => {
  const { loginWithRedirect } = useAuth0();

  const onLoginButtonClicked = () => {
    loginWithRedirect();
  };

  return (
    <div className='h-screen w-screen flex flex-wrap bg-blue-600 content-center'>
      <div className='max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8'>
        <div className='flex flex-col items-center justify-center flex-shrink-0 px-4 mb-10'>
          <img className='h-10 w-auto' src='/assets/sidebar/logo.svg' alt='logo' />
          <span className='text-lg text-white-100'>
            Audiencerate
          </span>
        </div>
        <h2 className='text-3xl font-extrabold text-white-100 sm:text-4xl'>
          <span className='block'>Marketing Platform</span>
        </h2>
        <p className='mt-3 text-lg leading-6 text-blue-100'>Where data meets security and marketing meets success.</p>
        <a
          href='#'
          onClick={onLoginButtonClicked}
          className='mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white-100 hover:bg-blue-100 sm:w-auto'
        >
          Sign In
        </a>
      </div>
    </div>
  );
};

export default Landing;
