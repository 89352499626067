import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface IUploadCompletedProps {
  filename: string;
}

const UploadCompleted: FunctionComponent<IUploadCompletedProps> = ({ filename }) => {
  const { t } = useTranslation();

  return (
    <div className='flex w-96 items-center'>
      <div className='flex-none mr-4'>
        <svg width='30' height='40' viewBox='0 0 30 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            opacity='0.4'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M30 10V9.52657C30 9.03044 29.8033 8.55455 29.4531 8.20313L21.8016 0.546881C21.449 0.195529 20.9712 -0.00121494 20.4734 5.64561e-06H20V10H30ZM17.5 10.625V5.72205e-06H1.875C0.839466 5.72205e-06 0 0.839472 0 1.87501V38.125C0 39.1605 0.839466 40 1.875 40H28.125C29.1605 40 30 39.1605 30 38.125V12.5H19.375C18.3407 12.497 17.503 11.6593 17.5 10.625ZM22.2758 18.8539L24.4766 21.0727C24.6524 21.2493 24.7506 21.4887 24.7496 21.7379C24.7485 21.9871 24.6483 22.2256 24.4711 22.4008L13.2992 33.4797C13.1226 33.6555 12.8832 33.7537 12.634 33.7527C12.3848 33.7517 12.1463 33.6515 11.9711 33.4742L5.52344 26.9664C5.34764 26.7898 5.2494 26.5504 5.25043 26.3012C5.25146 26.052 5.35166 25.8135 5.52891 25.6383L7.74766 23.4375C7.92429 23.2617 8.16365 23.1635 8.41286 23.1645C8.66206 23.1655 8.9006 23.2657 9.07578 23.443L12.6695 27.0641L20.9508 18.8469C21.3187 18.4832 21.9117 18.4864 22.2758 18.8539Z'
            fill='#098942'
          />
          <path
            d='M5.52878 25.6404L7.74753 23.4373C7.92416 23.2615 8.16352 23.1632 8.41272 23.1643C8.66193 23.1653 8.90047 23.2655 9.07565 23.4427L12.6694 27.0638L20.9507 18.8466C21.1272 18.6707 21.3665 18.5724 21.6157 18.5732C21.8649 18.5741 22.1035 18.6742 22.2788 18.8513L24.4796 21.0701C24.6554 21.2467 24.7536 21.4861 24.7526 21.7353C24.7515 21.9845 24.6513 22.223 24.4741 22.3982L13.3022 33.4771C13.1256 33.6529 12.8862 33.7511 12.637 33.7501C12.3878 33.7491 12.1493 33.6489 11.9741 33.4716L5.52331 26.9662C5.15873 26.5986 5.16117 26.005 5.52878 25.6404Z'
            fill='#098942'
          />
        </svg>
      </div>
      <div className='flex-grow m-auto text-left'>
        <p className='text-gray-900 text-sm'>{filename}</p>
        <p className='text-gray-500 text-xs'>{t('data_source_edit:file.progress_bar.upload_completed')}</p>
      </div>
    </div>
  );
};

export default UploadCompleted;
