import { CheckIcon } from "@heroicons/react/solid";
import { FunctionComponent } from "react";

export enum EStepStatus {
  COMPLETE,
  CURRENT,
  UPCOMING,
}

export type Step = {
  id: number;
  label: string;
  status: EStepStatus;
  disabled?: boolean;
};

interface IStepperProps {
  steps: Step[];
  onStepChanged?: (step: Step) => void;
}

const Stepper: FunctionComponent<IStepperProps> = ({ steps, onStepChanged}) => {
  const handleStepChanged = (step: Step) => {
    if (onStepChanged) {
      onStepChanged(step);
    }
  };

  return (
    <nav aria-label="Progress">
      <ol role="list" className={`border border-gray-200 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 bg-white-100`}>
        {steps.map((step, stepIdx) => (
          <li key={step.label} className="relative md:flex-1 md:flex">
            <div className={step.disabled ? 'opacity-50' : ''}>
              {step.status === EStepStatus.COMPLETE ? (
                <span className="group flex items-center w-full" onClick={() => handleStepChanged(step)}>
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-blue-600 rounded-full">
                      <CheckIcon className="w-6 h-6 text-white-100" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{step.label}</span>
                  </span>
                </span>
              ) : step.status === EStepStatus.CURRENT ? (
                <span className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step" onClick={() => handleStepChanged(step)}>
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-blue-600 rounded-full">
                    <span className="text-blue-600">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-blue-600">{step.label}</span>
                </span>
              ) : (
                <span className="group flex items-center" onClick={() => handleStepChanged(step)}>
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                      <span className="text-gray-500">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500">{step.label}</span>
                  </span>
                </span>
              )}
            </div>

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Stepper;
