
export function getLocalStorageItem(context: string, key: string): string | null {
  const value = localStorage.getItem(`arcdp:${context}:${key}`);
  if (!value) return null;

  return value;
}

export function setLocalStorageItem(context: string, key: string, value: string) {
  localStorage.setItem(`arcdp:${context}:${key}`, value);
}
