import { FunctionComponent } from 'react';
import { ActionCondition, MembershipCondition, PropertyCondition } from '..';
import { ExpressionCondition } from '../../../../models/audiences';

interface IExpressionConditionRenderProps {
  expression?: ExpressionCondition;
  onChange?: (Condition: ExpressionCondition) => void;
  isViewMode?: boolean;
  datasourceIds?: string[];
}

const ExpressionConditionRender: FunctionComponent<IExpressionConditionRenderProps> = ({ expression, onChange, isViewMode, datasourceIds }) => {
  switch (expression?.type) {
    case 'MEMBERSHIP':
      return <MembershipCondition expression={expression} onChange={(exp) => onChange?.(exp)} isViewMode={isViewMode} />;
    case 'ACTION':
      return <ActionCondition expression={expression} onChange={(exp) => onChange?.(exp)} isViewMode={isViewMode} />;
    case 'PROPERTY':
      return <PropertyCondition expression={expression} onChange={(exp) => onChange?.(exp)} isViewMode={isViewMode} datasourceIds={datasourceIds} />;
    default:
      return (
        <div className='flex w-full'>
          <p>Expression of type {expression?.type}</p>
        </div>
      );
  }
};

export default ExpressionConditionRender;
