import { CancelTokenSource } from 'axios';
import { createContext, FunctionComponent, PropsWithChildren, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { clearStashedFileDataSource, stashFileDataSource } from '../features/dataSources/dataSourcesSlice';
import { resetUploadStatus, updateFileInfo, updateUploadStatus } from '../features/fileUploading/fileUploadingSlice';
import { IFileUploadContext } from '../models/fileUpload';
import { RootState, useAppDispatch } from '../reducers';
import { useSaveDataSourceMutation, useUploadDataSourceFileMutation } from '../services/dataSources';
import { getStatus } from '../utils';

export const FileUploadContext = createContext<IFileUploadContext>({} as IFileUploadContext);

const FileUploadProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const fileDataSource = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource);
  const backgroundStatus = useSelector((state: RootState) => state.fileUploading.backgroundStatus);

  const [saveDataSource] = useSaveDataSourceMutation();
  const [sendFile, { isLoading, isError, isSuccess, data, error }] = useUploadDataSourceFileMutation();

  useEffect(() => {
    // TEMP
    // quando l'utente cancella l'uploading del file, useUploadDataSourceFileMutation restituisce isSuccess=true e data=undefined
    // trovare un modo intelligente per controllare se l'utente ha annullato l'operazione di caricamento

    if (isSuccess && !data) {
      dispatch(resetUploadStatus());
      return;
    }
    if (isSuccess && data) {
      dispatch(stashFileDataSource({ configuration: { ...fileDataSource?.configuration, url: data.path } }));
      if (
        backgroundStatus?.continue &&
        fileDataSource &&
        fileDataSource.name &&
        fileDataSource.configuration &&
        fileDataSource.type &&
        !!backgroundStatus.workspaceId
      ) {
        saveDataSource({
          workspaceId: backgroundStatus.workspaceId,
          dataSource: {
            type: fileDataSource.type.id,
            name: fileDataSource.name,
            configuration: { ...fileDataSource?.configuration, url: data.path },
          },
        });
        dispatch(resetUploadStatus());
        dispatch(clearStashedFileDataSource());
      }
    }

    if (isError && backgroundStatus?.continue) {
      dispatch(resetUploadStatus());
      return;
    }

    dispatch(
      updateUploadStatus({
        uploadingStatus: getStatus(isLoading, isSuccess, isError),
        data,
        error,
      })
    );
  }, [isLoading, isError, isSuccess, data, error]);

  const uploadFile = (file: File, cancelTokenSource: CancelTokenSource) => {
    const filename = file.name;
    const path = URL.createObjectURL(file);
    dispatch(updateFileInfo({ path, filename }));

    const formData = new FormData();
    formData.append('file', file);

    sendFile({ file: formData, cancelTokenSource: cancelTokenSource });
  };

  const contextValue: IFileUploadContext = {
    uploadFile: useCallback(uploadFile, []),
  };

  return <FileUploadContext.Provider value={contextValue}>{children}</FileUploadContext.Provider>;
};

export default FileUploadProvider;
