import { Fragment, FunctionComponent, useState } from 'react';
import { IModalContentProps } from '../../models/modals';
import WorkspaceCreate from './WorkspaceCreate';
import { Workspace } from '../../models/workspace';
import InviteWorkspaceInternalCollaborators from './InviteWorkspaceInternalCollaborators';
import InviteWorkspaceExternalCollaborators from './InviteWorkspaceExternalCollaborators';

interface IWorkspaceCreationProps { }

const WorkspaceCreation: FunctionComponent<IWorkspaceCreationProps & Partial<IModalContentProps>> = () => {
    const [activeStep, setActiveStep] = useState<string>('create');
    const [workspace, setWorkspace] = useState<Workspace>({} as Workspace);
    const [internalCollaborators, setInternalCollaborators] = useState<any[]>([]);
    const [externalCollaborators, setExternalCollaborators] = useState<any[]>([]);

    const renderTabContent = () => {
        switch (activeStep) {
            case 'invite_internal':
                return (
                    <InviteWorkspaceInternalCollaborators
                        collaborators={internalCollaborators}
                        collaboratorsChange={setInternalCollaborators}
                        nextStep={() => setActiveStep('invite_external')}
                        prevStep={() => setActiveStep('create')}
                    />
                );
            case 'invite_external':
                return (
                    <InviteWorkspaceExternalCollaborators
                        workspace={workspace}
                        internalCollaborators={internalCollaborators}
                        internalCollaboratorsChange={setInternalCollaborators}
                        externalCollaborators={externalCollaborators}
                        externalCollaboratorsChange={setExternalCollaborators}
                        prevStep={() => setActiveStep('invite_internal')}
                    />
                );
            default:
            case 'create':
                return <WorkspaceCreate workspace={workspace} workspaceChange={setWorkspace} nextStep={() => setActiveStep('invite_internal')} />;
        }
    };

    return <Fragment>{renderTabContent()}</Fragment>;
};

export default WorkspaceCreation;
