import { GroupedTraits } from '../../models/traits';
import { audiencerateApi } from '../audiencerate';

const traitsApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    getTraits: build.query<GroupedTraits, GetTraitsArg>({
      query: ({ limit, offset, workspaceId }) => ({
        url: `/v1/workspaces/${workspaceId}/traits`,
        params: { limit, offset },
      }),
      providesTags: ['Traits'],
    }),
  }),
  overrideExisting: false,
});

type GetTraitsArg = {
  limit?: number;
  offset?: number;
  workspaceId: string;
};

export const { useGetTraitsQuery, useLazyGetTraitsQuery } = traitsApi;
