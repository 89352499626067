import { first } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpressionCondition } from '../../../../models/audiences';
import { ISelectItem } from '../../../../models/select';
import { AudienceSelect } from '../selects';
import { SearchDropDown } from '../../../../components/dropdowns';
import  UserGroupIcon  from '../../../../assets/icons/user-group.svg?react';

interface IMembershipConditionProps {
  expression: ExpressionCondition;
  onChange?: (condition: ExpressionCondition) => void;
  isViewMode?: boolean;
}

const MembershipCondition: FunctionComponent<IMembershipConditionProps> = ({ expression, isViewMode, onChange }) => {
  const { t } = useTranslation('audience_edit');

  const membershipOps: (ISelectItem & { displayName: string })[] = [
    {
      id: 0,
      label: t('rules.belongs_to'),
      displayName: t('rules.belongs_to'),
    },
    {
      id: 1,
      label: t('rules.not_belongs_to'),
      displayName: t('rules.not_belongs_to'),
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className='flex w-full'>
      <div className={'inline-flex mx-2 items-center text-sm'}>
        <span className={'font-bold'}>
          <UserGroupIcon className={'w-6 h-6 inline-block'} />
        </span>
      </div>
      <div className='mx-2'>
        <SearchDropDown
          items={
            searchQuery?.trim() ? membershipOps.filter((item) => item.displayName.toLowerCase().includes(searchQuery?.trim().toLowerCase())) : membershipOps
          }
          item={expression.membership && (expression.membership === 'IN' ? membershipOps[0] : membershipOps[1])}
          placeholder={'belongs or not'}
          setItem={(opItemValue) => {
            onChange?.({
              ...expression,
              membership: opItemValue.id === 0 ? 'IN' : 'NOT_IN',
            });
          }}
          setQuery={setSearchQuery}
          loading={false}
          readOnly={isViewMode}
          openWithFocus={true}
          className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
        >
          {(item) => <div className={'whitespace-nowrap'}>{item.displayName}</div>}
        </SearchDropDown>
      </div>
      <div className='mx-2'>
        <AudienceSelect
          audienceId={first(expression.audienceIds)}
          onChange={(audienceItemValue) => {
            onChange?.({
              ...expression,
              audienceIds: [String(audienceItemValue.id)],
            });
          }}
          isViewMode={isViewMode}
        />
      </div>
    </div>
  );
};

export default MembershipCondition;
