import { isEmpty } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ITSelectProps } from '../../../../components/selects';
import { Audience } from '../../../../models/audiences';
import { RootState } from '../../../../reducers';
import { useGetAudiencesQuery } from '../../../../services/endpoints/audiences';
import { useWorkspace } from '../../../workspaces/hooks';
import { SearchDropDown } from '../../../../components/dropdowns';

interface IAudienceSelectProps extends Partial<ITSelectProps<Audience>> {
  audienceId?: string;
  isViewMode?: boolean;
}

const AudienceSelect: FunctionComponent<IAudienceSelectProps> = ({ audienceId, isViewMode, ...rest }) => {
  const { t } = useTranslation('audience_edit');

  const stashedAudience = useSelector((state: RootState) => state.audienceEdit.audience);
  const workspace = useWorkspace();

  const { isLoading, data } = useGetAudiencesQuery({
    workspaceId: workspace.id,
  });

  const audiences = data?.items || [];
  const audience = audiences.find((a) => a.id === audienceId);
  const validAudiences = isViewMode ? audiences : audiences.filter((a) => a.id !== stashedAudience?.id);

  if (!isLoading && isEmpty(validAudiences)) {
    return (
      <div className='grid h-full place-content-center'>
        <p className='text-sm'>{t('rules.there_are_no_audience')}</p>
      </div>
    );
  }

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <SearchDropDown
      items={searchQuery?.trim() ? validAudiences.filter((item) => item.name.toLowerCase().includes(searchQuery?.trim())) : validAudiences}
      item={audience}
      setItem={(value) => rest.onChange && rest.onChange(value as any)}
      setQuery={setSearchQuery}
      loading={isLoading}
      readOnly={isViewMode}
      openWithFocus={true}
      displayField={'name'}
      placeholder={'some audience'}
      className={'py-1 px-2 bg-indigo-50 text-purple-500 border-0'}
    >
      {(item) => <div className={'whitespace-nowrap'}>{item.name}</div>}
    </SearchDropDown>
  );
};

export default AudienceSelect;
