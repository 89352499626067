import { CogIcon } from "@heroicons/react/outline";
import { UserGroupIcon } from "@heroicons/react/solid";
import React, { Fragment, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SpinnerIcon } from "../../components/icons";
import { useDelayedBoolean } from "../../hooks/useDelayedBoolean";
import { useModalContext } from "../../hooks/useModalContext";
import { ETABS, TabType } from "../../models/tabs";
import { Workspace } from "../../models/workspace";
import { useGetWorkspaceQuery } from "../../services/endpoints/workspaces";
import { MainContentWrapper } from "../layout/main";
import { TopbarTabs } from "../layout/topbar";
import { ConfirmWorkspaceDelete } from "../settings/components";
import { WorkspaceEdit } from "./";
import { WorkspaceUsers } from "./components";
import { isCompanyAdmin, isWorkspaceAdmin, useUserRoles } from "../userInfo/hooks/useUserInfo";

const WorkspaceDetails: FunctionComponent = () => {
  const { t } = useTranslation("workspaces");
  const { id: workspaceId } = useParams<{ id: string }>();
  const { openModal } = useModalContext();

  const [tabs, setTabs] = useState([
    { id: ETABS.SETTINGS, label: t("details.tabs.edit"), current: true, icon: CogIcon },
    ...(isCompanyAdmin() || isWorkspaceAdmin(workspaceId) ? [{
      id: ETABS.OVERVIEW,
      label: t("details.tabs.users"),
      current: false,
      icon: UserGroupIcon
    }] : [])
  ]);

  const { data: workspace, isFetching, isUninitialized, isError } = useGetWorkspaceQuery({ workspaceId });
  const isFetchingDelayed = useDelayedBoolean(isFetching, 400);
  const shouldShowLoading = isFetching || isFetchingDelayed || isUninitialized;

  const breadcrumbs = [
    {
      label: t("details.breadcrumbs.settings")
    },
    {
      label: t("details.breadcrumbs.workspaces"),
      path: "/settings"
    },
    {
      label: workspace?.name || ""
    }
  ];

  const handleTabChange = (selectedTab: TabType) => {
    setTabs(tabs.map((tab) => ({ ...tab, current: tab.id === selectedTab.id })));
  };

  const handleOnDeleteClicked = () => {
    openModal({
      renderContent: ConfirmWorkspaceDelete,
      renderContentProps: {
        workspace: workspace as Workspace
      },
      dismissable: true
    });
  };

  const renderTabContent = () => {
    const currentTab = tabs.find((tab) => tab.current);

    switch (currentTab?.id) {
      case ETABS.SETTINGS:
        return <WorkspaceEdit workspace={workspace} onDeleteClicked={handleOnDeleteClicked} />;

      case ETABS.OVERVIEW:
        return <WorkspaceUsers workspace={workspace as Workspace} />;

      default:
        return null;
    }
  };

  return shouldShowLoading ? (
    <Fragment>
      <MainContentWrapper>
        <span className="text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <SpinnerIcon className="-ml-1 mr-3 h-5 w-5" loading />
        </span>
      </MainContentWrapper>
    </Fragment>
  ) : (
    <Fragment>
      <TopbarTabs tabs={tabs} onTabChange={handleTabChange} breadcrumbs={breadcrumbs} />
      <MainContentWrapper>
        <div className="max-w-7xl mx-auto">{renderTabContent()}</div>
      </MainContentWrapper>
    </Fragment>
  );
};

export default WorkspaceDetails;
