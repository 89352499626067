import { FunctionComponent } from 'react';
import { Audience } from '../../../../models/audiences';

interface IAudienceOverviewProps {
  audience?: Audience;
}

const AudienceOverview: FunctionComponent<IAudienceOverviewProps> = () => {
  return (
    <div className='flex space-x-6'>
      <div className='flex-1'></div>
      <div className='flex-1'></div>
    </div>
  );
};

export default AudienceOverview;
