import {
  DataSourceCodeConfig,
  DataSourceCrmConfig,
  DataSourceCrmFieldDefinition,
  DataSourceCrmParameterDefinition,
  TDataSource
} from "../../../models/dataSource";
import { ISelectItem } from "../../../models/select";
import { TraitDataType } from "../../../models/traits";

// FILE DATASOURCE TYPES

export enum TRAITS {
  MAP_TO_TRAIT,
  CUSTOM_TRAIT,
  CONSENT_TRAIT,
  IGNORE_COLUMN,
}

export interface ITraitValue {
  id: number | string;
  displayValue: string;
}

export type TColumnItem = {
  id: string;
  header: string;
  trait: ISelectItem;
  traitValue?: ITraitValue;
  customDataType?: TraitDataType;
};

export interface IColumnMapSelect {
  id: TRAITS;
  label: string;
}

// CRM DATASOURCE TYPES

export type DataSourceCrmCreateFormModel = Partial<TDataSource<DataSourceCrmConfig>>;

// CODE DATASOURCE TYPES

export type DataSourceFormModel = Partial<TDataSource<DataSourceCodeConfig>>;

export enum ESelector {
  ID,
  CLASS,
}

export interface ITrackClickEvents {
  id?: string;
  selectorType: ESelector;
  selectorName: string;
  eventName: string;
  identifier: string; // serve solo lato frontend
}

export interface ITrackPageVisitEvents {
  id?: string;
  pagePath: string;
  eventName: string;
  baseUrl: string;
  identifier: string; // serve solo lato frontend
}

export interface ITrackWebsiteValue {
  clickEvents: ITrackClickEvents[];
  pageVisitEvents: ITrackPageVisitEvents[];
}

export interface IClickEventsError {
  selectorName?: string;
  eventName?: string;
}

export interface IFormTrackEvents {
  id: string;
  selectorType: ESelector;
  selectorName?: string;
  eventName?: string;
}

export interface IPageEventsError {
  pagePath?: string;
  eventName?: string;
}

export type TTrackWebsiteError = {
  clickEvents: Record<number, IClickEventsError>;
  pageVisitEvents: Record<number, IPageEventsError>;
};

export interface ICrmParameter extends DataSourceCrmParameterDefinition {
  value: unknown;
}

export interface ICrmParameters {
  parameters: ICrmParameter[];
}

export interface ICrmField extends DataSourceCrmFieldDefinition {
  checked: boolean;
}

export interface ICrmFields {
  fields: ICrmField[];
}
