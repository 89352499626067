import { FunctionComponent } from "react";
import { DataSource } from "../../../../../../models/dataSource";
import { CommonActiveDataSource } from "../common";

interface IActiveCrmDataSourceProps {
  dataSource?: DataSource;
}

const ActiveCrmDataSource: FunctionComponent<IActiveCrmDataSourceProps> = ({ dataSource }) => {
  return dataSource ? (<CommonActiveDataSource dataSource={dataSource} polling={false} />) : (<div />);
};

export default ActiveCrmDataSource;
