import { PlusIcon } from '@heroicons/react/outline';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/buttons';

interface IAddSourceButtonProps {
  onClick?: () => void;
}

const AddDataSourceButton: FunctionComponent<IAddSourceButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button variant='confirm' icon={PlusIcon} className='h-9 mr-6' onClick={onClick}>
      {t('data_sources:buttons.add_first_data_source.add_source')}
    </Button>
  );
};

export default AddDataSourceButton;
