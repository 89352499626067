import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { CancelTokenSource } from 'axios';

export interface IFileUploadContext {
  uploadFile: (file: File, cancelTokenSource: CancelTokenSource) => void;
}

export type UploadProgress = {
  loaded: number;
  total: number;
  percentageCompleted: number;
};

export enum EStatus {
  UPLOADING,
  SUCCESS,
  ERROR,
  NOT_SELECTED,
}

export interface IFileUploadStatus {
  status: EStatus;
  percentage: UploadProgress;
}
export type UploadedFile = {
  path: string;
};

export type TFileInfo = {
  filename: string;
  path: string;
};

export type TFileData = {
  preview: string[][];
  header: string[];
  isDefaultHeader: boolean;
};

export type TFileStatus = {
  uploadingStatus: EStatus;
  data?: UploadedFile;
  error?: FetchBaseQueryError | SerializedError | string;
  progress?: UploadProgress;
};
